<template >
    <div class="content" v-loading="loading" v-cloak>
        <div class="page_title">车辆跟踪统计分析</div>
        <div class="content_box">
            <div class="page_card page-card_bottom">
                <!--检索部分-->
                <el-form :inline="true" :model="navForm" class="search-form-inline">
                    <el-form-item label="设备编号/车架号/车牌号:">
                        <el-input class="width_150" v-model="navForm.search" size="small" placeholder="请输入" clearable></el-input>
                    </el-form-item>

                    <el-form-item label="观察时间：" class="search-form_item">
                        <el-date-picker v-model="rangeDate" type="daterange" size="small" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" />
                    </el-form-item>
                    <el-form-item class="search-form_item">
                        <el-button type="primary" @click="loadTrackingAnalysisData">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="page_card page-card_bottom">
                <p style="font-weight: bold; line-height: 30px; font-size: medium">
                    驾驶行为趋势解读：<br />
                    1. 整体驾驶行为改善；<br />
                    2. 疲劳驾驶减少；<br />
                    3. 三急一超频率减少。<br />
                </p>
            </div>
            <div class="page_card page-card_bottom">
                <div class="detail_title">驾驶评分日均变化趋势</div>
                <div v-if="lineChartData.drivingScore.length > 0">
                    <div id="drivingScoreLineChart" class="line_charts"></div>
                </div>
                <div class="null_bg" style="padding-top: 0.9rem" v-else>
                    <div><img src="../../../public/images/msi/data/null_bg2.png" /></div>
                    <p>暂无数据</p>
                </div>
            </div>
            <div class="page_card page-card_bottom">
                <div class="detail_title">日均疲劳驾驶变化趋势</div>
                <div v-if="lineChartData.fatiguedDrivingHour.length > 0">
                    <div id="fatiguedDrivingHourLineChart" class="line_charts"></div>
                </div>
                <div class="null_bg" style="padding-top: 0.9rem" v-else>
                    <div><img src="../../../public/images/msi/data/null_bg2.png" /></div>
                    <p>暂无数据</p>
                </div>
            </div>
            <div class="page_card page-card_bottom">
                <div class="detail_title">百公里三急一超变化趋势</div>
                <div v-if="lineChartData.alarmNum100KM.length > 0">
                    <div id="alarmNum100KMLineChart" class="line_charts"></div>
                </div>
                <div class="null_bg" style="padding-top: 0.9rem" v-else>
                    <div><img src="../../../public/images/msi/data/null_bg2.png" /></div>
                    <p>暂无数据</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment'
import * as echarts from 'echarts'

export default {
    data() {
        return {
            loading: false,
            navForm: {
                //储存搜索框的检索条件信息
                search: '',
                ruleType: -1,
            },
            rangeDate: [moment().add(-30, 'days').format('YYYY-MM-DD'), moment().add(-1, 'days').format('YYYY-MM-DD')],
            lineChartData: {
                drivingScore: [],
                fatiguedDrivingHour: [],
                alarmNum100KM: [],
                flagName: '',
                drivingScoreFlag: null,
                fatiguedDrivingHourFlag: null,
                alarmNum100KMFlag: null,
                days: []
            },
            maxVal: {
                drivingScore: 100,
                fatiguedDrivingHour: 4,
                alarmNum100KM: 100,
            },
            currentUser: {
                userTeantType: 2
            },
            userInfo: null
        }
    },
    mounted() {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
        if (this.$route.query.search) {
            this.navForm.search = this.$route.query.search
            this.navForm.ruleType = this.$route.query.ruleType
            this.loadTrackingAnalysisData()
        }
        window.addEventListener('resize', () => {
            if (this.lineChartData.days.length > 0) {
                setTimeout(() => {
                    this.drivingScoreLineChart.resize()
                    this.fatiguedDrivingHourLineChart.resize()
                    this.alarmNum100KMLineChart.resize()
                }, 500)
            }
        })
    },
    methods: {
        loadTrackingAnalysisData() {
            if (!this.navForm.search) {
                this.$message({ message: '请输入设备编号/车架号/车牌号进行查询分析', type: 'error' })
                return
            }
            this.fullscreenLoading = this.$loading({
                lock: true,
                text: '数据加载中，请稍后',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            const queryParams = JSON.parse(JSON.stringify(this.navForm))
            if (this.rangeDate && this.rangeDate.length > 0) {
                queryParams.startTime = this.rangeDate[0] + ' 00:00:00'
                queryParams.endTime = this.rangeDate[1] + ' 00:00:00'
            }
            this.lineChartData.drivingScores = []
            this.lineChartData.fatiguedDrivingHour = []
            this.lineChartData.alarmNum100KM = []
            this.lineChartData.days = []
            this.instance.post('/ubi/api/trackingAnalysis', queryParams).then((res) => {
                let data = res ? res.data : null
                this.fullscreenLoading.close()
                if (data && data.code == 200) {
                    const trackingData = data.data;
                    if (trackingData.deviceReports.length == 0) {
                        this.$alert('您查找的车辆 ' + this.navForm.search + ' 在 ' + this.rangeDate[0] + ' 至 ' + this.rangeDate[1] + ' 时间区间内无出行记录！')
                    } else {
                        for (var i = 0; i < trackingData.deviceReports.length; i++) {
                            const e = trackingData.deviceReports[i]
                            this.lineChartData.drivingScore.push(e.drivingScore.toFixed(2))
                            this.lineChartData.fatiguedDrivingHour.push(e.fatiguedDrivingHour.toFixed(2))
                            this.lineChartData.alarmNum100KM.push(e.alarmNum100KM.toFixed(2))
                            this.lineChartData.days.push(e.day)
                        }
                        this.lineChartData.flagName = trackingData.ruleName;
                        this.lineChartData.alarmNum100KMFlag = trackingData.alarm100KMFlag;
                        this.lineChartData.fatiguedDrivingHourFlag = trackingData.fatiguedDrivingDurationAvgDayFlag;
                        this.lineChartData.drivingScoreFlag = trackingData.drivingScoreFlag;
                        setTimeout(() => {
                            this.loadLineCharts()
                            this.fullscreenLoading.close()
                        }, 300)
                    }
                }
            })
        },
        loadLineCharts() {
          this.loadLineChart('drivingScore', '驾驶评分', '分')
          this.loadLineChart('fatiguedDrivingHour', '疲劳驾驶时长', '小时')
          this.loadLineChart('alarmNum100KM', '百公里三急一超', '次')
        },
        loadLineChart(name, text, unit) {
            const lineChartName = name + 'LineChart'
            console.log(name)
            console.log(lineChartName)
            var chartDom = document.getElementById(lineChartName)
            console.log(chartDom)
            this[lineChartName] = echarts.init(chartDom, null, { renderer: 'svg' })
            var option = {
                color: ['rgba(255, 143, 57, 1)'],
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    // splitNumber:5,
                    axisLabel: {
                        showMaxLabel: true,
                        // interval:2,
                        textStyle: {
                            color: '#666666' //坐标值得具体的颜色
                        }
                    },
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            width: '2',
                            color: '#E7E7EE'
                        }
                    },
                    data: this.lineChartData.days
                },
                yAxis: {
                    type: 'value',
                    name: text,
                    min: 0,
                    max: value => Math.ceil(Math.max(this.maxVal[name], value.max)),
                    axisLabel: {
                        textStyle: {
                            color: '#666666' //坐标值得具体的颜色
                        },
                        formatter: '{value} ' + unit
                    },
                    nameTextStyle: {
                        color: '#666666'
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            width: '2',
                            color: '#E7E7EE'
                        }
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dashed'
                        }
                    }
                },
                tooltip: {
                    trigger: 'axis',
                    valueFormatter: (value) => value + ' ' + unit
                },
                grid: {
                    top: '30',
                    bottom: '30',
                    left: '60',
                    right: '50'
                },
                series: [
                    {
                        name: text,
                        type: 'line',
                        smooth: true,
                        showSymbol: false,
                        lineStyle: {
                            color: 'rgba(255, 143, 57, 1)'
                        },
                        areaStyle: {
                            color: 'rgba(255, 143, 57, 0.3)'
                        },

                        data: this.lineChartData[name],
                        markLine: {
                            label: {
                                show: true,
                                formatter: '{b}: {c}' + unit,
                                position: 'middle'
                            },
                            lineStyle: {
                                color: 'red'
                            },
                            emphasis: {
                                disabled: true
                            },
                            data: [
                                {
                                    name: this.lineChartData.flagName + '-' + text,
                                    // 支持 'average', 'min', 'max'
                                    yAxis: this.lineChartData[name + 'Flag']
                                }
                            ]
                        }
                    }
                ]
            }
            this[lineChartName].setOption(option)
        }
    }
}
</script>
<style scoped>
@import '../../assets/css/table_white.css';
@import '../../assets/css/public.css';
.content {
    position: relative;
    height: 100%;
}
.content_box {
    height: 96%;
    overflow-y: scroll;
}
.page_content {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.page_none {
    background: #fff;
}

.null_bg {
    padding-top: 0.68rem;
    text-align: center;
    font-size: 0.14rem;
    color: #828893;
}
.null_bg div {
    width: 1.95rem;
    margin: 0 auto 0.15rem;
}
.line_charts {
    width: 100%;
    height: 3.8rem;
    margin-top: 0.2rem;
}
</style>