<template>
    <div class="content" v-cloak v-loading.fullscreen.lock="fullscreenLoading" element-loading-text="正在加载中，请稍后！">
        <div class="page_title">设备数据</div>
        <div class="content_box overlength_content">
            <div class="search_box">
                <!--检索部分-->
                <el-form :inline="true" :model="navForm" class="demo-form-inline">
                    <el-form-item label="选择分公司:" prop="companyId">
                        <el-select class="width_180" v-model="navForm.companyId" placeholder="请选择" size="small" clearable @change="getBatchIdList">
                            <el-option v-for="(item, index) in companyList" :key="index" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="选择车队:" prop="fleetId">
                        <el-select class="width_150" v-model="navForm.fleetId" placeholder="请选择" size="small" clearable>
                            <el-option v-for="(item, index) in batchIdList" :key="index" :label="item.customerId" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="行驶时间:" class="dateBox">
                        <el-row style="width: 400px">
                            <el-col :span="8">
                                <el-date-picker size="small" placeholder="请选择月份" v-model="navForm.startTime" type="month" value-format="yyyy-MM" style="width: 100%"> </el-date-picker>
                            </el-col>
                            <el-col class="line" style="text-align: center" :span="2">至</el-col>
                            <el-col :span="8">
                                <el-date-picker size="small" placeholder="请选择月份" v-model="navForm.endTime" type="month" value-format="yyyy-MM" style="width: 100%"> </el-date-picker>
                            </el-col>
                            <el-col :span="1" style="height: 20px"></el-col>
                            <el-col :span="5">
                                <el-button type="primary" size="small" @click="selectMonths(3)">前三月</el-button>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="searchTable" size="small">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div v-show="deviceNum">
                <ul class="device_totle">
                    <li @click="linkDetail('fachu')">
                        <div class="totle_item">
                            <p>设备发出总数</p>
                            <div class="active">
                                <b>{{ deviceNum.issueNum ? deviceNum.issueNum : '--' }}</b>
                                <span> 台</span>
                            </div>
                        </div>
                        <div class="totle_icon">
                            <img src="../../../public/images/msi/data/data_icon11.png" />
                        </div>
                    </li>
                    <li @click="linkDetail('jihuo')">
                        <div class="totle_item">
                            <p>设备激活总数</p>
                            <div class="active">
                                <b>{{ deviceNum.activeNum ? deviceNum.activeNum : '--' }}</b>
                                <span> 台</span>
                            </div>
                        </div>
                        <div class="totle_icon">
                            <img src="../../../public/images/msi/data/data_icon12.png" />
                        </div>
                    </li>
                    <li @click="linkDetail('xingshi')">
                        <div class="totle_item">
                            <p>行驶设备总数</p>
                            <div class="active">
                                <b>{{ deviceNum.drivingNum ? deviceNum.drivingNum : '--' }}</b>
                                <span> 台</span>
                            </div>
                        </div>
                        <div class="totle_icon">
                            <img src="../../../public/images/msi/data/data_icon13.png" />
                        </div>
                    </li>
                    <li class="special_item" @click="linkDetail('tingshi')">
                        <div class="totle_item">
                            <p>停驶设备总数</p>
                            <div class="active">
                                <b>{{ deviceNum.activeNum != null && deviceNum.drivingNum != null ? deviceNum.activeNum - deviceNum.drivingNum : '--' }}</b>
                                <span> 台</span>
                            </div>
                            <!-- <div class="special">
		  					  <div>
		  						    拔出离线 <b>{{deviceNum.offNum}}</b>
		  							<span>台</span>
		  					  </div>
		  					  <div>
		  							未拔出离线 <b>{{deviceNum.offNum}}</b>
		  							<span>台</span>
		  					  </div>
		  				  </div> -->
                        </div>
                        <div class="totle_icon">
                            <img src="../../../public/images/msi/data/data_icon14.png" />
                        </div>
                    </li>
                </ul>
                <div class="statistical_chart content_box" v-loading="pieLoading">
                    <div class="detail_title">设备月平均插拔频次统计图</div>
                    <div class="charts_box flex" v-if="statisticsPlugNum.length > 0">
                        <div id="pie_charts"></div>
                        <div class="statistical_table">
                            <ul>
                                <li class="table_header">
                                    <div>月插拔频次分类</div>
                                    <div>设备数量（台）</div>
                                    <div>设备占比</div>
                                    <div>操作</div>
                                </li>
                                <li v-for="(item, index) in statisticsPlugTable" :key="index">
                                    <div>{{ item.name }}</div>
                                    <div class="font-bold">{{ item.num }}</div>
                                    <div class="font-bold">{{ item.ratio }}%</div>
                                    <div class="table_link" @click="linkDetail(index + 1, true)">查看详情</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="null_bg" v-else-if="!firstLoad">
                        <div><img src="../../../public/images/msi/data/null_bg.png" /></div>
                        <p>当月无设备插拔哦～</p>
                    </div>
                </div>
                <div class="trend_chart content_box" v-loading="pieLoading">
                    <div class="detail_title">
                        <b>设备数量走势图</b>
                        <!-- <span>（{{navForm.startTime}}至{{navForm.endTime}}）</span> -->
                    </div>
                    <div v-if="deviceNumDirection.length > 0">
                        <div id="line_charts"></div>
                    </div>
                    <div class="null_bg" style="padding-top: 0.9rem" v-else-if="!firstLoadLine">
                        <div><img src="../../../public/images/msi/data/null_bg2.png" /></div>
                        <p>暂无数据</p>
                    </div>
                </div>
            </div>
            <div v-show="!deviceNum" style="margin-top: 0.8rem">
                <el-empty description="暂无数据"></el-empty>
            </div>
        </div>
    </div>
</template>

<script>
import { EventBus } from '../../assets/js/EventBus.js';
import * as echarts from 'echarts';
export default {
    name: 'DeviceData',
    props: {
        msg: String
    },
    data() {
        return {
            pieLoading: true,
            lineLoading: true,
            firstLoad: true,
            firstLoadLine: true,
            rid: '',
            chartLine: '',
            chartPie: '',
            loading: false,
            fullscreenLoading: false,
            detailPageVisible: false,
            deviceNum: {},
            formType: '', //页面类型
            tableData: [],
            statisticsPlugTable: [], //插拔频次table
            statisticsPlugNum: [], //插拔频次统计图
            deviceNumDirection: '', //设备数量走势图
            batchIdList: [], //车队
            navForm: {
                //储存搜索框的检索条件信息
                companyId: null,
                startTime: null,
                endTime: null,
                fleetId: null
            },
            companyList: [],
            queryFormFlag: false
        };
    },
    mounted() {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.selectMonths(3, 'defalut');
        this.getCompanyList();
        this.getData();
        window.addEventListener('resize', () => {
            if (this.chartLine) {
                setTimeout(() => {
                    this.chartLine.resize();
                    this.chartPie.resize();
                }, 500);
            }
        });
    },
    methods: {
        selectMonths(num, sign) {
            var nowDate = new Date();
            var year = nowDate.getFullYear();
            var month = nowDate.getMonth() + 1; //0-11表示1-12月
            var startMonth = '',
                endMonth = '';
            if (month - num <= 0) {
                startMonth = year - 1 + '-' + (12 - (num - month)).toString().padStart(2, '0');
            } else {
                startMonth = year + '-' + (month - num).toString().padStart(2, '0');
            }
            let _num = sign == 'defalut' ? 0 : 1;

            if (month - 1 == 0) {
                if (sign == 'defalut') {
                    endMonth = year + '-' + month.toString().padStart(2, '0');
                } else {
                    endMonth = year - 1 + '-' + (12 - (_num - month)).toString().padStart(2, '0');
                }
            } else {
                endMonth = year + '-' + (month - _num).toString().padStart(2, '0');
            }

            this.navForm.startTime = startMonth;
            this.navForm.endTime = endMonth;
            this.$forceUpdate();
        },
        //获取车队
        getBatchIdList(id) {
            this.navForm.fleetId = '';
            if (id) {
                this.instance.get('/ubi/api/tenant/fleetList?companyId=' + id).then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.batchIdList = data.data;
                    }
                });
            } else {
                this.batchIdList = [];
            }
        },
        //获取分公司
        getCompanyList() {
            this.instance.get('/ubi/api/tenant/companyList').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.companyList = data.data;
                }
            });
        },
        //跳转设备详情
        linkDetail(val, hiddenFlag) {
            let queryForm = this.navForm;
            if (this.queryFormFlag) {
                queryForm.tmpBatchIdList = this.batchIdList;
            }

            this.$router.push({
                path: '/device/deviceManage',
                query: {
                    isPlugDetail: Boolean(hiddenFlag),
                    pluggingCountLevel: val,
                    queryForm: queryForm
                }
            });
        },
        openNewPage(row) {
            let routeUrl = this.$router.resolve({
                path: '/vehicleMap',
                query: {
                    deviceNumber: row.deviceNumber,
                    startTime: row.startTime,
                    endTime: row.endTime
                }
            });
            window.open(routeUrl.href, '_blank');
        },
        getData() {
            this.getPieCharts();
            this.getLineCharts();
        },

        //点击查询
        searchTable() {
            if (this.navForm.startTime && this.navForm.endTime && this.calcMonthInterval(this.navForm.startTime, this.navForm.endTime) > 12) {
                this.$message.error('抱歉，仅支持查询12个月及以内的数据！');
                return false;
            }
            this.currentPage = 1;
            this.getLineCharts();
            this.getPieCharts();
        },
        //加载设备数量走势图
        getLineCharts() {
            this.firstLoadLine = true;
            this.lineLoading = true;
            this.loading = true;
            this.deviceNumDirection = [];
            const { startTime, endTime, pageNo, pageSize, ...newNavForm } = this.navForm;
            newNavForm.tripTimeMin = startTime ? startTime + '-01' : null;
            newNavForm.tripTimeMax = endTime ? endTime + '-01' : null;
            this.instance.post('/ubi/api/device/deviceCountTrend', newNavForm).then((res) => {
                this.loading = false;
                this.firstLoadLine = false;
                this.lineLoading = false;
                let data = res.data;
                if (data.code == 200) {
                    this.queryFormFlag = true;
                    this.deviceNumDirection = data.data;
                    this.deviceNum = data.data.main;
                    this.deviceNumDirection = data.data.trend;
                    setTimeout(() => {
                        this.loadLineCharts();
                    }, 300);
                }
            });
        },
        //加载设备数量走势图
        loadLineCharts() {
            var response = this.deviceNumDirection;
            var chartDom = document.getElementById('line_charts');
            this.chartLine = echarts.init(chartDom, null, { renderer: 'svg' });
            let activeNum = [],
                days = [],
                issueNum = [],
                onlineNum = [];
            response.length > 0 &&
                response.forEach((val, key) => {
                    activeNum.push(val.activeNum);
                    days.push(val.day);
                    issueNum.push(val.issueNum);
                    onlineNum.push(val.drivingNum);
                });
            var option = {
                color: ['rgba(255, 143, 57, 1)', 'rgba(123, 76, 255, 1)', 'rgba(31, 185, 183, 1)'],
                legend: {
                    right: '50',
                    data: ['设备发出量', '设备激活量', '设备在线量']
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    // splitNumber:5,
                    axisLabel: {
                        showMaxLabel: true,
                        // interval:2,
                        textStyle: {
                            color: '#666666' //坐标值得具体的颜色
                        }
                    },
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            width: '2',
                            color: '#E7E7EE'
                        }
                    },
                    data: days
                    // data:response.scores
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        textStyle: {
                            color: '#666666' //坐标值得具体的颜色
                        }
                    },
                    name: '(台)',
                    nameTextStyle: {
                        color: '#666666'
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            width: '2',
                            color: '#E7E7EE'
                        }
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dashed'
                        }
                    }
                },
                tooltip: {
                    trigger: 'axis'
                    // formatter: '{b0} : {c0} 台',
                },
                grid: {
                    top: '30',
                    bottom: '30',
                    left: '60',
                    right: '50'
                },
                series: [
                    {
                        name: '设备发出量',
                        type: 'line',
                        smooth: true,
                        showSymbol: false,
                        lineStyle: {
                            color: 'rgba(255, 143, 57, 1)'
                        },
                        areaStyle: {
                            color: 'rgba(255, 143, 57, 0.3)'
                        },

                        data: issueNum
                    },
                    {
                        name: '设备激活量',
                        type: 'line',
                        smooth: true,
                        showSymbol: false,
                        areaStyle: {
                            color: 'rgba(0, 37, 249, 0.3)'
                        },
                        lineStyle: {
                            color: 'rgba(123, 76, 255, 1)'
                        },
                        data: activeNum
                    },
                    {
                        // data: response.nums,
                        data: onlineNum,
                        name: '设备在线量',
                        type: 'line',
                        smooth: true,
                        showSymbol: false,
                        areaStyle: {
                            color: 'rgba(31, 185, 183, 0.3)'
                        },
                        lineStyle: {
                            color: 'rgba(31, 185, 183, 1)'
                        }
                    }
                ]
            };
            this.chartLine.setOption(option);
        },
        //获取插拔频次统计图
        getPieCharts() {
            this.firstLoad = true;
            this.pieLoading = true;
            this.statisticsPlugNum = [];
            const { startTime, endTime, ...newNavForm } = this.navForm;
            newNavForm.tripTimeMin = startTime ? startTime + '-01 00:00:00' : null;
            newNavForm.tripTimeMax = endTime ? endTime + '-01 00:00:00' : null;

            this.instance.post('/ubi/api/device/pluggingAlarmStat', newNavForm).then((res) => {
                this.firstLoad = false;
                this.pieLoading = false;
                if (res.data.code == 200) {
                    this.statisticsPlugNum = res.data.data;
                    setTimeout(() => {
                        this.loadPieCharts();
                    }, 300);
                    var statisticsPlugTable = [];
                    var sum = res.data.data.reduce(function (a, b) {
                        return a + b;
                    }, 0);
                    res.data.data.forEach((val, key) => {
                        var obj = {
                            name: '',
                            num: val,
                            ratio: 0
                        };
                        obj.ratio = sum == 0 ? 0 : ((val / sum) * 100).toFixed(1);
                        if (key == 0) {
                            obj.name = '正常（月插拔次数≤5）';
                        } else if (key == 1) {
                            obj.name = '频繁（5<月插拔次数≤10）';
                        } else if (key == 2) {
                            obj.name = '非常频繁（月插拔次数>10次）';
                        }
                        statisticsPlugTable.push(obj);
                    });
                    this.statisticsPlugTable = statisticsPlugTable;
                }
            });
        },
        //加载插拔频次统计图
        loadPieCharts() {
            var response = this.statisticsPlugNum;
            var chartDom = document.getElementById('pie_charts');
            this.chartPie = echarts.init(chartDom, null, { renderer: 'svg' });
            var travelData = [
                { value: response[0], name: '正常' },
                { value: response[1], name: '频繁' },
                { value: response[2], name: '非常频繁' }
            ];
            var option = {
                color: ['rgba(5, 169, 197, 1)', 'rgba(255, 180, 0, 1)', 'rgba(220, 76, 0, 1)'],
                tooltip: {
                    formatter: '{b0} : {c0}台 ',
                    // formatter: '{b0} : {c0}辆 ({d}%)',
                    show: true
                },
                legend: {
                    orient: 'horizontal',
                    bottom: '20',
                    left: 'center',
                    itemGap: 30,
                    itemWidth: 14
                },
                series: [
                    {
                        // name: 'Nightingale Chart',
                        type: 'pie',
                        radius: ['25%', '50%'],
                        center: ['50%', '40%'],
                        label: {
                            show: false
                        },
                        data: travelData
                    }
                ]
            };
            this.chartPie.setOption(option);
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../../assets/css/table_white.css';
.null_bg {
    padding-top: 0.68rem;
    text-align: center;
    font-size: 0.14rem;
    color: #828893;
}
.null_bg div {
    width: 1.95rem;
    margin: 0 auto 0.15rem;
}
.content {
    position: relative;
    height: 100%;
}
.statistical_chart {
    /* height: 3.94rem; */
    height: auto !important;
    overflow: inherit;
}
.charts_box {
    width: 100%;
    padding: 0 0.6rem;
}
#pie_charts {
    width: 30%;
    height: 3.4rem;
}
#line_charts {
    width: 100%;
    height: 3.8rem;
    margin-top: 0.2rem;
}
.trend_chart {
    /* height: 4.58rem; */
    margin-top: 0.25rem;
}
.detail_title {
    margin: 0;
}
.detail_title span {
    font-size: 0.2rem;
    color: #000000;
}
.statistical_table {
    width: 60%;
}
.font-bold {
    font-weight: 600;
}
.statistical_table ul {
    /* height: 2.85rem; */
    border-radius: 4px;
    border: 1px solid #8fdbe5;
}
.statistical_table li {
    height: 0.71rem;
    line-height: 0.71rem;
    border-top: 1px dashed #8fdbe5;
    display: flex;
    justify-content: space-between;
}
.statistical_table .table_header {
    border: 0;
    background-color: rgba(186, 247, 255, 0.22);
    font-weight: 600;
}
.statistical_table li > div {
    width: 20%;
    text-align: center;
}
.statistical_table li > div:nth-child(1) {
    width: 40%;
}
.table_link {
    color: #00acc9;
    cursor: pointer;
}
.device_totle {
    margin: 0.27rem auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.device_totle li {
    width: 23.8%;
    height: 1.52rem;
    background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0.19) 34%, rgba(77, 194, 214, 0.08) 100%);
    box-shadow: 0px 2px 14px 0px rgba(222, 222, 222, 0.5);
    border-radius: 6px;
    padding: 0.17rem 0.23rem;
    display: flex;
    justify-content: space-between;
    font-size: 0.22rem;
    font-weight: 400;
    color: #000000;
    cursor: pointer;
}
.totle_item b {
    font-size: 0.32rem;
    font-weight: 600;
}
.totle_item span {
    color: rgba(130, 136, 147, 1);
}
/* .device_totle .special_item{
		width:29.24%;
	} */
.totle_icon {
    width: 0.64rem;
    margin-top: 0.54rem;
}
.special {
    display: flex;
    font-size: 0.18rem;
    margin-top: 0.14rem;
    color: #828893;
}
.special b {
    color: rgba(5, 169, 197, 1);
    font-size: 0.24rem;
}
.special span {
    color: rgba(5, 169, 197, 1);
}
.special div:first-child {
    margin-right: 0.3rem;
}
.totle_item .active {
    margin-top: 0.2rem;
}
</style>
