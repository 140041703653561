<template>
    <div style="height: 100%">
        <div class="page_title">运营报表</div>
        <forms :reportStatus="reportStatus"></forms>
    </div>
</template>
<script>
import Forms from '@/components/forms.vue'
export default {
    components: {
        Forms
    },
    data() {
        return {
            reportStatus: 1
        }
    }
}
</script>
<style>
.page_title {
    font-size: 0.24rem;
    font-weight: 500;
    color: #051932;
    margin-bottom: 0.2rem;
}
</style>