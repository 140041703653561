
<template>
    <div class="content" v-cloak>
        <div class="page_title">用户运维</div>
        <div class="content_box">
            <div class="search_box">
                <!--检索部分-->
                <el-form :inline="true" :model="navForm" class="demo-form-inline">
                    <el-form-item label="用户姓名:">
                        <el-input class="width_150" v-model="navForm.userName" :disabled="advancedSearchVisible" size="small" placeholder="请输入姓名" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话:">
                        <el-input class="width_150" v-model="navForm.userPhone" :disabled="advancedSearchVisible" size="small" placeholder="请输入手机号" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="设备号:">
                        <el-input class="width_150" v-model="navForm.deviceNumber" :disabled="advancedSearchVisible" size="small" placeholder="请输入设备号" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="设备在离线状态:">
                        <el-select v-model="navForm.deviceStatus" :disabled="advancedSearchVisible" placeholder="请选择" size="small" class="width_150">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="在线" value="1"></el-option>
                            <el-option label="离线" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="设备发放机构:">
                        <el-input class="width_150" v-model="navForm.company" :disabled="advancedSearchVisible" size="small" placeholder="请输入" clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="small" @click="searchTable(1)" style="margin-left: 0.2rem" :disabled="advancedSearchVisible">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="advanced_search">
                <div class="advanced_title">
                    <p @click="handleAdvancedSearch">高级筛选</p>
                    <div v-show="checkedRadioText">
                        <span>{{ checkedRadioText }}</span>
                        <b @click="clearAdvanced"></b>
                    </div>
                </div>
                <div class="advanced_search_box" v-show="advancedSearchVisible">
                    <el-radio-group v-model="radio" @change="radioChange" ref="radio">
                        <el-radio name="收到设备用户" label="received">收到设备用户</el-radio>
                        <el-radio label="notActiveDays">收到设备<el-input class="advanced_input" v-model="notActiveDays" maxlength="3" type="tel"></el-input>天未激活用户</el-radio>
                        <el-radio label="activated">激活设备用户</el-radio>
                        <el-radio label="activatedAndOffline">激活设备未上线用户</el-radio>
                        <el-radio label="offlineDays">收到设备<el-input class="advanced_input" v-model="offlineDays" maxlength="3" type="tel"></el-input>天未上线用户</el-radio>
                        <el-radio label="online">上线用户</el-radio>
                        <el-radio label="replaced">更换设备用户</el-radio>
                        <el-radio label="returned">退回设备用户</el-radio>
                    </el-radio-group>
                    <div style="text-align: right">
                        <el-button type="primary" plain size="medium" @click="searchTable(2)">确定</el-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="table_box content_box">
            <!--table表格部分-->
            <el-table ref="multipleTable" :data="tableData" v-loading="loading" height="320px">
                <el-table-column label="序号" type="index" width="80" :index="hIndex"></el-table-column>
                <el-table-column prop="userName" label="姓名"></el-table-column>
                <el-table-column prop="userPhone" label="联系电话"></el-table-column>
                <el-table-column prop="plateNo" label="车牌号"></el-table-column>
                <el-table-column prop="deviceNumber" label="设备号"></el-table-column>
                <el-table-column prop="insureCompanyName" label="发放机构"></el-table-column>
                <el-table-column prop="receiveTime" label="设备收到时间"></el-table-column>
                <!-- <el-table-column label="设备收到时间">
				  <template slot-scope="scope">
						<span>{{scope.row.receiveTime||'暂未收到'}}</span>
				  </template>
			  </el-table-column> -->
                <el-table-column prop="activationTime" label="设备激活时间"></el-table-column>
                <el-table-column prop="firstTripTime" label="设备上线时间"></el-table-column>
                <!-- <el-table-column label="小程序激活时间" >
				  <template slot-scope="scope">
						<span>{{scope.row.latestTripTime||'--'}}</span>
				  </template>
			  </el-table-column> -->
                <el-table-column label="操作" width="80px">
                    <template slot-scope="scope">
                        <div class="operation_box" style="justify-content: center">
                            <span @click="handleLookDetail(scope.row)">详情</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <!--分页部分-->
            <el-pagination style="margin-top: 0.2rem" @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="currentPage" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="10" :total="totalPage"> </el-pagination>
        </div>
        <operations-detail ref="userDetail" :dialogVisible="dialogVisible" :detailForm="detailForm" @refreshDetail="handleLookDetail(checkedRow)" @refreshList="getTbodyData" @beforeClose="beforeClose"></operations-detail>
    </div>
</template>

<script>
import OperationsDetail from '@/components/OperationsDetail.vue'
import qs from 'qs'
export default {
    components: {
        OperationsDetail
    },
    data() {
        return {
            rid: '',
            radio: '',
            notActiveDays: '',
            offlineDays: '',
            advancedSearchVisible: false,
            loading: false,
            dialogVisible: false,
            fullscreenLoading: false,
            detailPageVisible: false,
            checkedRadioText: '',
            formType: '', //页面类型
            navForm: {
                //储存搜索框的检索条件信息
                userName: '',
                userPhone: '',
                deviceStatus: '',
                deviceNumber: '',
                company: '',
                companyId: ''
            },
            detailForm: {
                operationsWorkOrderVos: []
            },
            currentPage: 1, //当前页
            pageSize: 10, //一页多少条数据
            totalPage: 1, //总页数tableData:[],
            tableData: []
        }
    },
    mounted() {
        // console.log(this.$refs.radio);
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
        this.navForm.companyId = this.userInfo.company
        this.getData()
    },
    methods: {
        //清除筛选条件
        clearAdvanced() {
            this.radio = ''
            this.checkedRadioText = ''
            this.notActiveDays = ''
            this.offlineDays = ''
            this.currentPage = 1
            this.getTbodyData()
        },
        //点击查询
        searchTable(sign) {
            if (sign == 1) {
                this.radio = ''
                this.checkedRadioText = ''
            } else {
                if (this.radio == 'notActiveDays' && !this.notActiveDays) {
                    this.$message.error('抱歉，请填写收到设备未激活天数')
                    return false
                }
                if (this.radio == 'offlineDays' && !this.offlineDays) {
                    this.$message.error('抱歉，请填写收到设备未上线天数')
                    return false
                }
                this.advancedSearchVisible = !this.advancedSearchVisible
                this.checkedRadioText =
                    this.radio == 'received'
                        ? '收到设备用户'
                        : this.radio == 'notActiveDays'
                        ? '收到设备' + this.notActiveDays + '天未激活用户'
                        : this.radio == 'activated'
                        ? '激活设备用户'
                        : this.radio == 'activatedAndOffline'
                        ? '激活设备未上线用户'
                        : this.radio == 'offlineDays'
                        ? '收到设备' + this.offlineDays + '天未上线用户'
                        : this.radio == 'online'
                        ? '上线用户'
                        : this.radio == 'replaced'
                        ? '更换设备用户'
                        : this.radio == 'returned'
                        ? '退回设备用户'
                        : ''
            }
            this.currentPage = 1
            this.getTbodyData()
        },
        //高级筛选radio change事件
        radioChange(e) {
            console.log(e)
        },
        //点击展开高级筛选
        handleAdvancedSearch() {
            this.advancedSearchVisible = !this.advancedSearchVisible
            this.navForm.userName = ''
            this.navForm.userPhone = ''
            this.navForm.deviceStatus = ''
            this.navForm.deviceNumber = ''
            this.navForm.company = ''
        },
        //重新计算index序号
        hIndex(index) {
            // index索引从零开始，index +1即为当前数据序号
            this.currentPage <= 0 ? (this.currentPage = 1) : this.currentPage
            // 如果当前不是第一页数据
            if (this.currentPage != 1) {
                // index + 1 + (( 当前页 - 1) * 每页展示条数)
                // 比如是第二页数据 1 + ((2 - 1)*5) = 6,第二页数据也就是从序号6开始
                return index + 1 + (this.currentPage - 1) * this.pageSize
            }
            // 否则直接返回索引+1作为序号
            return index + 1
        },
        //关闭模态框
        beforeClose() {
            this.$emit('update:messageParent', false)
            this.dialogVisible = false
        },
        linkHeat() {
            let routeUrl = this.$router.resolve({
                path: '/vehicleMapHeat'
            })
            window.open(routeUrl.href, '_blank')
        },
        //点击查看详情
        handleLookDetail(row) {
            this.checkedRow = row
            // console.log(row)
            this.$emit('update:messageParent', true)
            this.$refs.userDetail.userDisabled = true
            this.detailForm = { operationsWorkOrderVos: [] }

            this.dialogVisible = true
            this.instance({
                method: 'post',
                url: '/user/devops/userDevOpsDetail',
                data: qs.stringify({ vehicleId: row.vehicleId }),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((res) => {
                if (res.data.code == 1) {
                    var response = res.data.data
                    response.operationsWorkOrderVos.length > 0 &&
                        response.operationsWorkOrderVos.forEach((val, key) => {
                            if (val.workOrderType != 3) {
                                val.disabled = true
                            } else {
                                val.sendOut.workOrderType = 3
                                val.sendBack.workOrderType = 3
                                val.sendOut.operationType = 1
                                val.sendBack.operationType = 2
                                val.sendBack.workOrderId = val.workOrderId
                                val.sendOut.workOrderId = val.workOrderId
                                if (val.sendOut.id) {
                                    val.sendOut.disabled = true
                                    val.disabled = true
                                }
                                if (val.sendBack.id) {
                                    val.sendBack.disabled = true
                                    val.disabled = true
                                }
                            }
                        })
                    response.addTime = response.addTime ? response.addTime.split(' ')[0] : response.addTime
                    this.detailForm = response
                    this.dialogVisible = true
                }
            })
        },
        getData: function () {
            this.getTbodyData()
        },
        //获取tbody里的数据
        getTbodyData: function () {
            this.loading = true
            var _this = this
            _this.tableData = [] //  清空table的值
            // _this.totalPage=0;
            var json = JSON.parse(JSON.stringify(this.navForm))
            if (this.radio) {
                if (this.radio == 'notActiveDays') {
                    json[this.radio] = this.notActiveDays
                } else if (this.radio == 'offlineDays') {
                    json[this.radio] = this.offlineDays
                } else {
                    json[this.radio] = 'sure'
                }
            }
            json.pageNum = _this.currentPage
            json.pageSize = _this.pageSize
            this.instance({
                method: 'post',
                url: '/user/devops/getUserDevOpsList',
                data: json
                //  headers:{
                // 'Content-Type':"application/x-www-form-urlencoded",
                //  },
            }).then((res) => {
                this.loading = false
                if (res.data.code == 1) {
                    _this.tableData = res.data.data.list
                    _this.totalPage = parseInt(res.data.data.total)
                } else {
                    _this.totalPage = 0
                }
            })
        },
        //分页数据多少条change
        handleSizeChange(val) {
            this.pageSize = val
            this.getTbodyData()
        },
        //分页：当前页改变之后的函数
        handleCurrentChange: function (val) {
            this.currentPage = Number(val)
            this.getTbodyData()
        }
    }
}
</script>
<style scoped>
@import '../../assets/css/table_white.css';
.content {
    position: relative;
    height: 100%;
}
.table_box {
    margin-top: 0.2rem;
}
.advanced_title {
    display: flex;
    align-items: center;
    color: #05a9c5;
    margin-bottom: 0.15rem;
}
.advanced_title div {
    padding: 5px 10px;
    background: #f3fdff;
    border-radius: 4px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    /* text-align: center; */
    margin-left: 10px;
    display: flex;
    align-items: center;
    color: #666;
}
.advanced_title b {
    width: 20px;
    height: 20px;
    background: url(../../../public/images/msi/user/close.png) no-repeat;
    background-size: 100% 100%;
    margin-left: 5px;
    cursor: pointer;
}
.advanced_title p {
    /* cursor: pointer; */
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    cursor: pointer;
}
.advanced_search_box {
    width: 100%;
    padding: 0.3rem 0.2rem;
    background: #fafafa;
    border-radius: 4px;
    margin-bottom: 0.1rem;
}
.advanced_search_box .el-radio {
    margin-bottom: 15px;
}
.advanced_search_box .advanced_input {
    width: 70px;
    margin: 0 6px;
}
</style>
