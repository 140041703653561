<template>
    <div style="height: 100%">
        <div class="page_title" @click="clickSum">项目运营报告</div>
        <el-button v-if="!sumFlag" class="fr" style="margin-top: -36px; margin-bottom: 10px" type="primary" :disabled="!downloadbtn" :loading="exportLoading" @click="getPdf('#pdfContent')">{{ exportLoading ? '下载中' : '下载' }}</el-button>
        <div class="echarts_card echarts_search">
            <!--检索部分-->
            <el-form :inline="true" :model="searchForm" class="search-form-inline">
                <el-form-item label="城市：" v-if="0">
                    <el-select v-model="value" clearable placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="月份：">
                    <el-date-picker v-model="searchForm.reportCycle" type="month" placeholder="选择月" format="yyyy 年 MM 月" value-format="yyyy-MM"> </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="searchReportData">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="page_content" v-if="showReport && sumFlag" v-loading="queryLoading">
            <!-- 设备发货统计 -->
            <div class="echarts_card" v-if="0">
                <el-table :data="tableData12" :span-method="objectSpanMethod" border style="width: 100%; margin-top: 20px">
                    <el-table-column prop="id" label="月份" width="180"> </el-table-column>
                    <el-table-column prop="cityName" label="地区"> </el-table-column>
                    <el-table-column prop="deliverGoodsNum" label="发货数量"> </el-table-column>
                    <el-table-column prop="time" label="最后修改时间"> </el-table-column>
                    <el-table-column fixed="right" label="操作">
                        <template slot-scope="scope">
                            <span @click="editRow(scope.row)">修改</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="echarts_card">
                <p class="card_title" style="text-align: center; font-size: 24px; margin-bottom: 40px; margin-top: 10px">{{ queryDate ? '环球车享车队运营报告  ' + queryDate : '' }}</p>
                <p class="card_title">设备拔出率排名</p>
                <el-button class="fr" style="margin-top: -36px" type="primary" size="small" :disabled="!downloadbtn" :loading="exportLoading" @click="getPdf('#pdfContent')">{{ exportLoading ? '下载中' : '下载' }}</el-button>
                <!-- 表格 -->
                <div class="card_content clearfix">
                    <div class="card-content_left fl">
                        <el-table class="report_table report_none" :data="datas" border style="width: 100%">
                            <el-table-column prop="sort" label="拔出率排名" width="120"></el-table-column>
                            <el-table-column prop="region" label="地区"></el-table-column>
                            <el-table-column prop="currentRatio" label="拔出率">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.currentRatio ? scope.row.currentRatio + '%' : '' }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="currentPullNum" label="拔出数量">
                                <template slot-scope="scope">
                                    <div class="span_txt" style="color: #409eff; cursor: pointer" @click="clickPopup(scope.row)">{{ scope.row.currentPullNum ? scope.row.currentPullNum : '' }}</div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="lastRatio" :label="datasTitle1 + '月拔出率'">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.lastRatio ? scope.row.lastRatio + '%' : '' }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="previousRatio" :label="datasTitle2 + '月拔出率'">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.previousRatio ? scope.row.previousRatio + '%' : '' }}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="card-content_right fr">
                        <el-tabs v-model="datasObj.activeName">
                            <el-tab-pane label="风险分析" name="first">
                                <div class="abnormal fl">
                                    <div class="abnormal-txt">
                                        <ul>
                                            <template v-if="datasObj.textArr.length > 0">
                                                <li style="margin-top: 5px; line-height: 20px; list-style: disc" v-for="(item, index) in datasObj.textArr" :key="index" v-html="item"></li>
                                            </template>
                                        </ul>
                                    </div>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </div>
                <p class="card_title" style="margin-top: 30px">危险驾驶车辆跟踪</p>
                <div class="card_content clearfix">
                    <div class="card-content_left fl">
                        <!-- 危险驾驶车辆跟踪 折柱图 -->
                        <div id="badDrivingVehicleChat" class="echarts_category"></div>
                    </div>
                    <div class="card-content_right fr">
                        <el-tabs v-model="badDrivingVehicleActiveName">
                            <el-tab-pane label="风险分析" name="first">
                                <div class="abnormal fl">
                                    <div class="abnormal-txt">
                                        <ul>
                                            <template v-if="drivingVehicleData.textArr.length > 0">
                                                <li style="margin-top: 5px; line-height: 20px; list-style: disc" v-for="(item, index) in drivingVehicleData.textArr" :key="index" v-html="item"></li>
                                            </template>
                                        </ul>
                                    </div>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </div>
                <p class="card_title">疲劳驾驶指数排名</p>
                <div class="card_content clearfix">
                    <div class="card-content_left fl">
                        <!-- 危险驾驶车辆跟踪 柱状图 -->
                        <div id="fatiguedDrivingIndexSorChat" class="echarts_category"></div>
                    </div>
                    <div class="card-content_right fr">
                        <el-tabs v-model="fatiguedDrivingIndexSorActiveName">
                            <el-tab-pane label="风险分析" name="first">
                                <div class="abnormal fl">
                                    <div class="abnormal-txt">
                                        <ul>
                                            <template v-if="drivingIndexSortData.textArr.length > 0">
                                                <li style="margin-top: 5px; line-height: 20px; list-style: disc" v-for="(item, index) in drivingIndexSortData.textArr" :key="index" v-html="item"></li>
                                            </template>
                                        </ul>
                                    </div>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </div>
            </div>
            <!-- 设备运行情况 -->
            <div class="echarts_card">
                <p class="card_title">设备总体运行情况</p>
                <el-table class="report_table" :data="tableData" border style="width: 100%">
                    <el-table-column prop="cityName" label="地区"></el-table-column>
                    <el-table-column prop="deliverGoodsNum" label="发货数量"></el-table-column>
                    <el-table-column prop="bindDeviceNum" label="安装数量"></el-table-column>
                    <el-table-column prop="activationDeviceNum" label="激活数量"></el-table-column>
                    <el-table-column prop="activationDeviceRatio" label="激活率">
                        <template slot-scope="scope">
                            <div>
                                <span style="color: red" v-if="Number(scope.row.activationDeviceRatio) < 100">{{ scope.row.activationDeviceRatio + '%' }}</span>
                                <span v-else>{{ scope.row.activationDeviceRatio + '%' }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="onlineDeviceNum" label="在线数量"></el-table-column>
                    <el-table-column prop="onlineDeviceRatio" label="在线率">
                        <template slot-scope="scope">
                            <div>
                                <span style="color: red" v-if="Number(scope.row.onlineDeviceRatio) < 80">{{ scope.row.onlineDeviceRatio + '%' }}</span>
                                <span v-else>{{ scope.row.onlineDeviceRatio + '%' }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="pullDeviceNum" label="拔出数量"></el-table-column>
                    <el-table-column prop="pullDeviceRatio" label="拔出率">
                        <template slot-scope="scope">
                            <div>
                                <span style="color: red" v-if="Number(scope.row.pullDeviceRatio) > 10">{{ scope.row.pullDeviceRatio + '%' }}</span>
                                <span v-else>{{ scope.row.pullDeviceRatio + '%' }}</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="echarts_card">
                <p class="card_title">车辆运行情况-行驶里程情况</p>
                <div class="cards_box">
                    <!-- 各地区车辆行驶里程 - 柱状图 -->
                    <div id="drivingMileageLine" class="echarts_category"></div>
                    <!-- 各地区车辆行驶里程 - 表格 -->
                    <el-table class="report_table" :data="drivingMileageData" style="width: 100%">
                        <el-table-column prop="cityName" label="地区"> </el-table-column>
                        <el-table-column label="总里程(Km)">
                            <el-table-column prop="mileage" label="本月"> </el-table-column>
                            <el-table-column prop="mileageRingRatio" label="环比">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.mileageRingRatio ? scope.row.mileageRingRatio + '%' : '-' }}</span>
                                </template>
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="有行程车辆数">
                            <el-table-column prop="drivingVehNum" label="本月"> </el-table-column>
                            <el-table-column prop="drivingVehNumRingRatio" label="环比">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.drivingVehNumRingRatio ? scope.row.drivingVehNumRingRatio + '%' : '-' }}</span>
                                </template>
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="车均里程(Km)">
                            <el-table-column prop="mileageAvgVeh" label="本月"> </el-table-column>
                            <el-table-column prop="mileageAvgVehRingRatio" label="环比">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.mileageAvgVehRingRatio ? scope.row.mileageAvgVehRingRatio + '%' : '-' }}</span>
                                </template>
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="日均里程(Km)">
                            <el-table-column prop="mileageAvgDay" label="本月"> </el-table-column>
                            <el-table-column prop="mileageAvgDayRingRatio" label="环比">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.mileageAvgDayRingRatio ? scope.row.mileageAvgDayRingRatio + '%' : '-' }}</span>
                                </template>
                            </el-table-column>
                        </el-table-column>
                        <el-table-column prop="mileageSum" label="累计里程(km)" width="130"> </el-table-column>
                    </el-table>
                    <!-- 里程>10000 - 表格 -->
                    <el-table class="report_table" :data="mileageData" style="width: 100%; margin-top: 50px">
                        <el-table-column prop="cityName" label="地区"> </el-table-column>
                        <el-table-column prop="drivingVehNum" label="有行程车辆数"> </el-table-column>
                        <el-table-column prop="vehNumMileage10000ToUp" label="里程>10000km 车辆数"> </el-table-column>
                        <el-table-column prop="vehNumMileage0To1000" label="里程<1000km 车辆数"> </el-table-column>
                        <el-table-column prop="mileageSumAvgMonth" label="月均里程(km)" width="130"> </el-table-column>
                    </el-table>
                </div>
                <div class="cards_box clearfix">
                    <!-- 车辆累计里程分布 -->
                    <div id="accumulatePie" class="echarts_left fl"></div>
                    <!-- 车辆单次行程最大里程分布 -->
                    <div id="singlePie" class="echarts_right fr"></div>
                </div>
            </div>
            <div class="echarts_card">
                <p class="card_title">车辆运行情况-行驶时长情况</p>
                <div class="cards_box">
                    <!-- 各地区车辆行驶时长 柱状图-->
                    <div id="eachRegionCategory" class="echarts_category"></div>
                    <!-- 各地区车辆行驶时长 表格 -->
                    <el-table class="report_table" :data="eachRegionData" style="width: 100%">
                        <el-table-column prop="cityName" label="地区"> </el-table-column>
                        <el-table-column label="总时长(H)">
                            <el-table-column prop="drivingDuration" label="本月"> </el-table-column>
                            <el-table-column prop="drivingDurationRingRatio" label="环比">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.drivingDurationRingRatio ? scope.row.drivingDurationRingRatio + '%' : '-' }}</span>
                                </template>
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="有行程车辆数">
                            <el-table-column prop="drivingVehNum" label="本月"> </el-table-column>
                            <el-table-column prop="drivingVehNumRingRatio" label="环比">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.drivingVehNumRingRatio ? scope.row.drivingVehNumRingRatio + '%' : '-' }}</span>
                                </template>
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="车均时长(H)">
                            <el-table-column prop="drivingDurationAvgVeh" label="本月"> </el-table-column>
                            <el-table-column prop="drivingDurationAvgVehRingRatio" label="环比">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.drivingDurationAvgVehRingRatio ? scope.row.drivingDurationAvgVehRingRatio + '%' : '-' }}</span>
                                </template>
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="日均时长(H)">
                            <el-table-column prop="drivingDurationAvgDay" label="本月"> </el-table-column>
                            <el-table-column prop="drivingDurationAvgDayRingRatio" label="环比">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.drivingDurationAvgDayRingRatio ? scope.row.drivingDurationAvgDayRingRatio + '%' : '-' }}</span>
                                </template>
                            </el-table-column>
                        </el-table-column>
                        <el-table-column prop="drivingDurationSum" label="累计时长(H)"> </el-table-column>
                    </el-table>
                </div>
                <div class="cards_box clearfix">
                    <!-- 车辆累计时长分布 -->
                    <div id="accumulatedDurationPie" class="echarts_left fl"></div>
                    <!-- 车辆单次行程最大时长分布 -->
                    <div id="maximumDurationPie" class="echarts_right fr"></div>
                </div>
            </div>
            <div class="echarts_card">
                <p class="card_title">驾驶行为分析-疲劳驾驶情况</p>
                <div class="cards_box">
                    <!-- 各地区疲劳驾驶情况  折/柱图 -->
                    <div id="fatigueDrivingLine" class="echarts_category"></div>

                    <!-- 各地区疲劳驾驶情况 表格 -->
                    <el-table class="report_table" :data="fatigueDrivingData" style="width: 100%">
                        <el-table-column prop="cityName" label="地区"> </el-table-column>
                        <el-table-column prop="drivingVehNum" label="有行程车辆数"> </el-table-column>
                        <el-table-column prop="fatiguedDrivingVehRatio" label="疲劳驾驶车辆占比">
                            <template slot-scope="scope">
                                <span>{{ scope.row.fatiguedDrivingVehRatio ? scope.row.fatiguedDrivingVehRatio + '%' : '-' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="日均疲劳驾驶时长(h)">
                            <el-table-column prop="fatiguedDrivingDurationAvgDay" label="本月"> </el-table-column>
                            <el-table-column prop="fatiguedDrivingDurationAvgDayRingRatio" label="环比">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.fatiguedDrivingDurationAvgDayRingRatio ? scope.row.fatiguedDrivingDurationAvgDayRingRatio + '%' : '-' }}</span>
                                </template>
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="日均疲劳驾驶次数">
                            <el-table-column prop="fatiguedDrivingNumAvgDay" label="本月"> </el-table-column>
                            <el-table-column prop="fatiguedDrivingNumAvgDayRingRatio" label="环比">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.fatiguedDrivingNumAvgDayRingRatio ? scope.row.fatiguedDrivingNumAvgDayRingRatio + '%' : '-' }}</span>
                                </template>
                            </el-table-column>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="cards_box">
                    <div class="clearfix">
                        <!-- 车辆日均疲劳驾驶时长分布 -->
                        <div id="fatigueDrivingTimePie" class="echarts_left echarts_30 fl"></div>
                        <!-- 车辆日均疲劳驾驶次数分布 -->
                        <div id="fatigueDrivingSumPie" class="echarts_right echarts_30 fl"></div>
                        <!-- 车辆单次行程最大疲劳驾驶时长分布 饼图 -->
                        <div id="maximumFatigueDrivingPie" class="echarts_left echarts_30 fl"></div>
                    </div>
                </div>
            </div>
            <div class="echarts_card">
                <p class="card_title">驾驶行为分析-三急一超情况</p>
                <div class="cards_box">
                    <!-- 三急一超情况 柱状图 -->
                    <div id="threeUrgentAndOneSuper" class="echarts_right echarts_100"></div>
                </div>
                <!-- 三急一超情况 表格 -->
                <div class="cards_box">
                    <el-table class="report_table" :data="threeUrgentAndOneSuperData" style="width: 100%">
                        <el-table-column prop="cityName" label="地区"> </el-table-column>
                        <el-table-column prop="drivingVehNum" label="有行程车辆数"> </el-table-column>
                        <el-table-column label="百公里急加速次数">
                            <el-table-column prop="sharpUpNum100km" label="本月"> </el-table-column>
                            <el-table-column prop="sharpUpNum100kmRingRatio" label="环比">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.sharpUpNum100kmRingRatio ? scope.row.sharpUpNum100kmRingRatio + '%' : '-' }}</span>
                                </template>
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="百公里急减速次数">
                            <el-table-column prop="sharpDownNum100km" label="本月"> </el-table-column>
                            <el-table-column prop="sharpDownNum100kmRingRatio" label="环比">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.sharpDownNum100kmRingRatio ? scope.row.sharpDownNum100kmRingRatio + '%' : '-' }}</span>
                                </template>
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="百公里急加转弯次数">
                            <el-table-column prop="sharpTurnNum100km" label="本月"> </el-table-column>
                            <el-table-column prop="sharpTurnNum100kmRingRatio" label="环比">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.sharpTurnNum100kmRingRatio ? scope.row.sharpTurnNum100kmRingRatio + '%' : '-' }}</span>
                                </template>
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="百公里超速次数">
                            <el-table-column prop="overSpeedNum100km" label="本月"> </el-table-column>
                            <el-table-column prop="overSpeedNum100kmRingRatio" label="环比">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.overSpeedNum100kmRingRatio ? scope.row.overSpeedNum100kmRingRatio + '%' : '-' }}</span>
                                </template>
                            </el-table-column>
                        </el-table-column>
                    </el-table>
                </div>
                <!-- 车辆驾驶评分分布 柱状图 -->
                <div id="drivingRatingColumnar" class="echarts_category"></div>
            </div>
            <div class="echarts_card">
                <p class="card_title">车辆驾驶评分情况</p>
                <!-- 车辆驾驶评分情况 表格 -->
                <el-table class="report_table" :data="drivingRatingData" style="width: 100%">
                    <el-table-column prop="cityName" label="地区"> </el-table-column>
                    <el-table-column prop="drivingVehNum" label="有行程车辆数"> </el-table-column>
                    <el-table-column label="驾驶评分">
                        <el-table-column prop="drivingScoreAvgVeh" label="本月"> </el-table-column>
                        <el-table-column prop="drivingScoreAvgVehRingRatio" label="环比">
                            <template slot-scope="scope">
                                <span>{{ scope.row.drivingScoreAvgVehRingRatio ? scope.row.drivingScoreAvgVehRingRatio + '%' : '-' }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="page_content page_none" v-if="!showReport && sumFlag">
            <el-empty description="暂无数据"></el-empty>
        </div>
        <preview-pdf ref="previewPdf" v-if="reportData.searchReportData" :reportData="reportData"></preview-pdf>
        <!-- 设备拔出率明细 弹窗 -->
        <el-dialog class="chartDialog" :title="pulloutDialogTitle" center :visible.sync="pulloutDialogVisible" width="60%" append-to-body>
            <el-button class="fr" type="text" @click="downloadPulloutFile" v-if="pulloutDialogData.length > 0">下载</el-button>
            <el-table :data="pulloutDialogData" height="380" border v-loading="pulloutDialogLoading" @sort-change="tiredSortChange">
                <el-table-column prop="deviceNumber" label="设备号" width="130"> </el-table-column>
                <el-table-column prop="plateNo" label="车牌号" width="130"> </el-table-column>
                <el-table-column prop="subTenantName" label="公司名称" width="180">
                    <template slot-scope="scope">
                        <el-tooltip v-if="scope.row.subTenantName && scope.row.subTenantName.length > 7" class="item" effect="dark" :content="scope.row.subTenantName" placement="top-start">
                            <span>{{ scope.row.subTenantName.slice(0, 7) + '...' }}</span>
                        </el-tooltip>
                        <span v-else>{{ scope.row.subTenantName }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="fleetName" label="车队名称" width="160"> </el-table-column>
                <el-table-column prop="pulloutTime" label="拔出时间"> </el-table-column>
            </el-table>
        </el-dialog>
        <!-- 危险驾驶车辆明细 弹窗 -->
        <el-dialog class="chartDialog" :title="chartDialogTitle" center :visible.sync="chartDialogVisible" width="60%" append-to-body>
            <el-button class="fr" type="text" @click="downloadChartFile" v-if="chartDialogData.length > 0">下载</el-button>
            <el-table :data="chartDialogData" height="380" border>
                <el-table-column prop="plateNo" label="车牌号"> </el-table-column>
                <el-table-column prop="companyName" label="所属分公司" width="140">
                    <template slot-scope="scope">
                        <el-tooltip v-if="scope.row.companyName && scope.row.companyName.length > 7" class="item" effect="dark" :content="scope.row.companyName" placement="top-start">
                            <span>{{ scope.row.companyName.slice(0, 7) + '...' }}</span>
                        </el-tooltip>
                        <span v-else>{{ scope.row.companyName }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="fleetName" label="所属车队"> </el-table-column>
                <el-table-column prop="drivingScore" label="驾驶评分"> </el-table-column>
                <el-table-column prop="endCheckTime" label="解除跟踪日期" width="120"> </el-table-column>
                <el-table-column prop="accidentNum" label="半年内出险次数" width="120"> </el-table-column>
            </el-table>
        </el-dialog>
        <!-- 疲劳驾驶明细 弹窗 -->
        <el-dialog class="chartDialog" :title="tiredDialogTitle" center :visible.sync="tiredDialogVisible" width="65%" append-to-body>
            <el-button class="fr" type="text" @click="downloadTiredFile" v-if="tiredDialogData.length > 0">下载</el-button>
            <el-table :data="tiredDialogData" height="400" border v-loading="tiredDialogLoading" @sort-change="tiredSortChange">
                <el-table-column prop="plateNo" label="车牌号" width="100"> </el-table-column>
                <el-table-column prop="fatiguedDrivingIndex" label="疲劳驾驶指数" sortable width="130"> </el-table-column>
                <el-table-column prop="fatiguedDrivingDurationAvgDay" label="日均疲劳驾驶时长(h)" sortable> </el-table-column>
                <el-table-column prop="fatiguedDrivingNum" label="本月疲劳驾驶次数" sortable> </el-table-column>
                <el-table-column prop="fatiguedDrivingDays" label="本月疲劳驾驶天数" sortable> </el-table-column>
                <el-table-column prop="mileageAvgDay" label="日均行驶里程(km)" sortable> </el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>
<script>
import * as echarts from 'echarts'
import XLSXST from 'xlsx-js-style'
import JSZip from 'jszip'
import jsPDF from 'jspdf'
import FileSaver from 'file-saver'
import html2Canvas from 'html2canvas'
import htmlToPdf from '@/utils/htmlToPdfs'
import PreviewPdf from '../../components/PreviewPdf.vue'
export default {
    components: {
        PreviewPdf
    },
    data() {
        return {
            queryLoading: false,
            excelBlob: null,
            pdfBlob: null,
            exportLoading: false,
            downloadbtn: false,
            options: [
                {
                    value: '选项1',
                    label: '黄金糕'
                },
                {
                    value: '选项2',
                    label: '双皮奶'
                }
            ],
            value: '',
            searchForm: {
                periodType: 2,
                reportCycle: ''
            },
            tableData: [],
            drivingMileageLineChart: null, //各地区车辆行驶里程 柱状图
            drivingMileageData: [], //各地区车辆行驶里程 表格
            vehicleMileageCount: [], //月均里程及单车里程分析 表格
            accumulatePieChart: null, //车辆累计里程分布 饼图
            singlePieChart: null, //车辆单次行程最大里程分布 饼图
            eachRegionData: [], //各地区车辆行驶时长 表格
            accumulatedDurationPieChart: null, //车辆累计时长分布 饼图
            maximumDurationPieChart: null, //车辆单次行程最大时长分布 饼图
            fatigueDrivingLineChart: null, //各地区疲劳驾驶情况  折/柱图
            fatigueDrivingData: [], //各地区疲劳驾驶情况 表格
            fatigueDrivingTimePieChart: null, //各地区疲劳驾驶情况 - 疲劳驾驶时长 饼图
            fatigueDrivingSumPieChart: null, //各地区疲劳驾驶情况 - 疲劳驾驶次数 饼图
            maximumFatigueDrivingPieChart: null, //车辆单次行程最大疲劳驾驶时长分布 饼图
            threeUrgentAndOneSuperChart: null, //三急一超情况 柱状图
            threeUrgentAndOneSuperData: [], //三急一超情况 表格
            drivingRatingData: [], //车辆驾驶评分情况 表格
            mileageData: [],
            drivingRatingColumnarChart: null, //车辆驾驶评分分布 柱状图
            reportData: {
                searchReportData: null
            }, //生成文件数据
            tableData12: [
                {
                    id: '1',
                    cityName: '王小虎',
                    deliverGoodsNum: '234',
                    time: '2012-09'
                },
                {
                    id: '1',
                    cityName: '王小虎',
                    deliverGoodsNum: '234',
                    time: '2012-09'
                }
            ],
            showReport: false,
            queryDate: '',
            sum: 0,
            sumFlag: true,
            badDrivingVehicleChatChart: null,
            datas: [],
            datasTitle1: '',
            datasTitle2: '',
            datasActiveName: 'first',
            badDrivingVehicleActiveName: 'first',
            fatiguedDrivingIndexSorActiveName: 'first',
            datasObj: {
                activeName: 'first',
                textArr: []
            },
            drivingVehicleData: {
                activeName: 'first',
                textArr: []
            },
            drivingIndexSortData: {
                activeName: 'first',
                textArr: []
            },
            chartDialogVisible: false,
            chartDialogData: [],
            chartDialogLoading: false,
            chartDialogTitle: '危险驾驶且出险车辆明细',
            queryChartFromData: {
                periodType: '',
                reportCycle: '',
                subTenantId: '',
                orders: []
            },
            tiredDialogVisible: false,
            tiredDialogData: [],
            tiredDialogLoading: false,
            tiredDialogTitle: '疲劳驾驶排名',
            queryTriedCityId: '',
            queryTriedFromData: {
                periodType: '',
                reportCycle: '',
                subTenantId: '',
                orders: []
            },
            pulloutDialogVisible: false,
            pulloutDialogTitle: '设备拔出率明细',
            queryPulloutFromData: {
                periodType: '',
                reportCycle: '',
                subTenantId: '',
                orders: []
            },
            pulloutDialogLoading: false,
            pulloutDialogData: [],
            badDrivingVehicleChatChart: null,
            drivingMileageLineChart: null,
            drivingMileageLineChart1: null,
            drivingMileageLineChart2: null,
            singlePieChart: null,
            eachRegionCategoryChart: null,
            accumulatedDurationPieChart: null,
            maximumDurationPieChart: null,
            fatigueDrivingLineChart: null,
            fatigueDrivingTimePieChart: null,
            fatigueDrivingSumPieChart: null,
            maximumFatigueDrivingPieChart: null,
            threeUrgentAndOneSuperChart: null,
            drivingRatingColumnarChart: null
        }
    },
    mounted() {
        let date = new Date(new Date() - 30 * 24 * 3600 * 1000)
        this.searchForm.reportCycle = date.getFullYear() + '-' + (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1)
        this.searchReportData()
        this.sum = 0

        let _this = this
        window.addEventListener('resize', function () {
            _this.badDrivingVehicleChatChart && _this.badDrivingVehicleChatChart.resize()
            _this.drivingMileageLineChart && _this.drivingMileageLineChart.resize()
            _this.drivingMileageLineChart2 && _this.drivingMileageLineChart2.resize()
            _this.drivingMileageLineChart1 && _this.drivingMileageLineChart1.resize()
            _this.singlePieChart && _this.singlePieChart.resize()
            _this.eachRegionCategoryChart && _this.eachRegionCategoryChart.resize()
            _this.accumulatedDurationPieChart && _this.accumulatedDurationPieChart.resize()
            _this.maximumDurationPieChart && _this.maximumDurationPieChart.resize()
            _this.fatigueDrivingLineChart && _this.fatigueDrivingLineChart.resize()
            _this.fatigueDrivingTimePieChart && _this.fatigueDrivingTimePieChart.resize()
            _this.fatigueDrivingSumPieChart && _this.fatigueDrivingSumPieChart.resize()
            _this.maximumFatigueDrivingPieChart && _this.maximumFatigueDrivingPieChart.resize()
            _this.threeUrgentAndOneSuperChart && _this.threeUrgentAndOneSuperChart.resize()
            _this.drivingRatingColumnarChart && _this.drivingRatingColumnarChart.resize()
        })
    },
    methods: {
        editRow() {},
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0) {
                if (rowIndex % 2 === 0) {
                    return {
                        rowspan: 2,
                        colspan: 1
                    }
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0
                    }
                }
            }
        },
        clickSum() {
            this.sum++
            if (this.sum == 13) {
                this.sumFlag = false
            } else if (this.sum > 14) {
                this.sum = 0
                this.sumFlag = true
            }
            this.$refs.previewPdf.clickSum(this.sumFlag)
        },
        searchReportData() {
            this.downloadbtn = false
            this.queryLoading = true
            this.reportData.searchReportData = null
            this.queryDate = JSON.parse(JSON.stringify(this.searchForm.reportCycle))
            let tmpDateArr = this.queryDate.split('-')
            this.datasTitle1 = Number(tmpDateArr[1]) - 1
            this.datasTitle2 = Number(tmpDateArr[1]) - 2
            // excel 数据
            if (!this.exportLoading) {
                this.instance.post('/ubi/api/report/searchReportDeviceList', this.searchForm).then((res) => {
                    let data = res.data
                    let dataD10000 = [['设备号', '车牌号', '总里程']]
                    let dataX10000 = [['设备号', '车牌号', '总里程']]
                    if (data.code == 200) {
                        data.data.forEach((item) => {
                            if (Number(item.mileage) > 10000) {
                                dataD10000.push([item.deviceNumber, item.plateNo, item.mileage])
                            } else if (Number(item.mileage) > 0 && Number(item.mileage) < 1000) {
                                dataX10000.push([item.deviceNumber, item.plateNo, item.mileage])
                            }
                        })
                    }
                    this.exportExcel(dataD10000, dataX10000, '当月里程>10000km车辆明细', '当月里程<1000km车辆明细')
                })
            }
            // 统计数据
            this.instance.post('/ubi/api/report/searchReportData', this.searchForm).then((res) => {
                let data = res.data
                if (data.code == 200 && data.data.hasData) {
                    this.reportData.searchForm = this.searchForm
                    this.$forceUpdate()
                    this.showReport = true
                    this.$nextTick(() => {
                        // 总览
                        // 设备拔出率排名
                        this.datas = data.data.pulloutRatioSortList
                        this.datasObj.textArr = data.data.pulloutRatioSortText
                        // 危险驾驶车辆跟踪 折柱图
                        this.setBadDrivingVehicleChat(data.data.badDrivingVehicleReportList)
                        this.drivingVehicleData.textArr = data.data.badDrivingVehicleReportText
                        // 疲劳驾驶指数排名 柱状图
                        this.setFatiguedDrivingIndexSorChat(data.data.fatiguedDrivingIndexSortList)
                        this.drivingIndexSortData.textArr = data.data.fatiguedDrivingIndexSortText

                        this.tableData = data.data.deviceRunSituationList
                        // 各地区车辆行驶里程 柱状图
                        this.setdrivingMileageLine(data.data.vehicleRunMileageSituations)
                        // 各地区车辆行驶里程 表格
                        this.drivingMileageData = data.data.vehicleRunMileageSituations
                        // 月均里程及单车里程分析 表格
                        this.vehicleMileageCount = data.data.vehicleMileageAnalysis.vehicleMileageCount
                        // 车辆累计里程分布 饼图
                        this.setaccumulatePie(data.data.vehicleMileageAnalysis.mileageSumDistribution)
                        // 车辆单次行程最大里程分布 饼图
                        this.setsinglePie(data.data.vehicleMileageAnalysis.maxMileageDistribution)
                        // 各地区车辆行驶时长 柱状图
                        this.eachRegionCategory(data.data.vehicleRunDurationSituations)
                        // 各地区车辆行驶时长 表格
                        this.eachRegionData = data.data.vehicleRunDurationSituations
                        // 车辆累计时长分布 饼图
                        this.setaccumulatedDurationPie(data.data.vehicleDurationAnalysis.durationSumDistribution)
                        // 车辆单次行程最大里程分布 饼图
                        this.setmaximumDurationPie(data.data.vehicleDurationAnalysis.maxDurationDistribution)
                        // 各地区疲劳驾驶情况  折/柱图
                        this.setfatigueDrivingLine(data.data.reportList)
                        // 各地区疲劳驾驶情况 表格
                        this.fatigueDrivingData = data.data.reportList
                        // 里程>10000 表格
                        this.mileageData = data.data.vehicleMileageAnalysis.vehicleMileageCount
                        // 各地区疲劳驾驶情况 - 疲劳驾驶时长
                        this.setfatigueDrivingTimePie(data.data.drivingBehaviorFatigued.drivingBehaviorDurationDistribution)
                        // 各地区疲劳驾驶情况 - 疲劳驾驶次数
                        this.setfatigueDrivingSumPie(data.data.drivingBehaviorFatigued.drivingBehaviorCountDistribution)
                        // 车辆单次行程最大疲劳驾驶时长分布 饼图
                        this.setmaximumFatigueDrivingPie(data.data.drivingBehaviorFatigued.drivingBehaviorMaxDurationDistribution)
                        // 三急一超情况 柱状图
                        this.setthreeUrgentAndOneSuper(data.data.drivingBehaviorOverSpeed)
                        // 三急一超情况 表格
                        this.threeUrgentAndOneSuperData = data.data.drivingBehaviorOverSpeed
                        // 车辆驾驶评分情况 表格
                        this.drivingRatingData = data.data.drivingBehaviorDriveScore
                        // 车辆驾驶评分分布 柱状图
                        this.setdrivingRatingColumnar(data.data.drivingBehaviorDriveScore)
                    })

                    if (!this.exportLoading) {
                        setTimeout(() => {
                            this.downloadbtn = true
                            this.queryLoading = false
                            // this.getPdf('#pdfContent')
                        }, 2000)
                    }

                    setTimeout(() => {
                        this.reportData.searchReportData = data.data
                    }, 1100)
                } else {
                    this.showReport = false
                }
            })
        },
        // 生成excelblob
        exportExcel(sheetData1, sheetData2, sheetName1, sheetName2) {
            this.excelBlob = null
            let workbook = XLSXST.utils.book_new()
            const worksheet1 = XLSXST.utils.aoa_to_sheet(sheetData1)
            const worksheet2 = XLSXST.utils.aoa_to_sheet(sheetData2)

            worksheet1['!cols'] = [
                {
                    width: 14
                },
                {
                    width: 14
                },
                {
                    width: 14
                }
            ]

            worksheet2['!cols'] = [
                {
                    width: 14
                },
                {
                    width: 14
                },
                {
                    width: 14
                }
            ]

            XLSXST.utils.book_append_sheet(workbook, worksheet1, sheetName1)
            XLSXST.utils.book_append_sheet(workbook, worksheet2, sheetName2)
            let workbookObj = XLSXST.write(workbook, { bookType: 'xlsx', type: 'array' })
            this.excelBlob = new Blob([workbookObj], { type: 'application/octet-stream' })
            console.log('excel转换完成')
        },
        downloadFileZip(downloadFlag) {
            this.exportLoading = true
            if (downloadFlag) {
                console.log('开始下载zip：')
                let _this = this
                let zip = new JSZip()
                let fileName = '环球车享运营报告-总览-' + this.searchForm.reportCycle

                zip.file(fileName + '.pdf', _this.pdfBlob, { binary: true }) // 逐个添加文件

                zip.file(fileName + '.xlsx', _this.excelBlob, { binary: true }) // 逐个添加文件

                zip.generateAsync({ type: 'blob' }).then((content) => {
                    // 生成二进制流
                    FileSaver.saveAs(content, fileName + '.zip') // 利用file-saver保存文件  blogTitle:自定义文件名
                })

                setTimeout(() => {
                    this.exportLoading = false
                }, 1000)
            }
        },
        // 危险驾驶车辆跟踪 折柱图
        setBadDrivingVehicleChat(data) {
            let xAxisData = []
            let data1 = []
            let data2 = []
            let data3 = []
            let cityIdObj = {}
            if (data && data.length > 0) {
                data.forEach((item) => {
                    cityIdObj[item.cityName] = item.subTenantId
                    xAxisData.push(item.cityName)
                    data1.push(item.dangerousDrivingVehicleCount)
                    data2.push(item.accidentCount)
                    data3.push(item.accidentRatio)
                })
                let tmpData2 = JSON.parse(JSON.stringify(data2))
                let sortArr2 = tmpData2.sort((a, b) => a - b)
                let tmpData3 = JSON.parse(JSON.stringify(data3))
                let sortArr3 = tmpData3.sort((a, b) => a - b)

                let option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999'
                            }
                        }
                    },
                    legend: {
                        data: ['危险驾驶车辆', '其中近6月出险车辆', '半年出险率']
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: xAxisData,
                            triggerEvent: true,
                            axisLabel: {
                                //x轴文字的配置
                                show: true,
                                textStyle: {
                                    color: '#409EFF'
                                }
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            min: sortArr2[0],
                            max: sortArr2[sortArr2.length - 1]
                        },
                        {
                            type: 'value',
                            min: sortArr3[0],
                            max: sortArr3[sortArr3.length - 1],
                            splitLine: { show: false },
                            axisLabel: {
                                formatter: '{value} %'
                            }
                        }
                    ],
                    dataZoom: [
                        {
                            type: 'slider',
                            start: 0,
                            end: 50
                        }
                    ],
                    series: [
                        {
                            name: '危险驾驶车辆',
                            type: 'bar',
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value
                                }
                            },
                            data: data1
                        },
                        {
                            name: '其中近6月出险车辆',
                            type: 'bar',
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value
                                }
                            },
                            data: data2
                        },
                        {
                            name: '半年出险率',
                            type: 'line',
                            yAxisIndex: 1,
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + '%'
                                }
                            },
                            data: data3
                        }
                    ]
                }

                let tmpDom = document.getElementById('badDrivingVehicleChat')
                if (tmpDom) {
                    let _this = this
                    this.badDrivingVehicleChatChart = echarts.init(tmpDom)
                    this.badDrivingVehicleChatChart.setOption(option)
                    this.badDrivingVehicleChatChart.on('click', function (params) {
                        if (params.componentType == 'xAxis' && params.value != 'null') {
                            _this.chartDialogVisible = true
                            _this.chartDialogTitle = params.value + '危险驾驶且出险车辆明细'

                            _this.queryChartFromData.periodType = _this.searchForm.periodType
                            _this.queryChartFromData.reportCycle = _this.searchForm.reportCycle
                            _this.queryChartFromData.subTenantId = cityIdObj[params.value]
                            _this.queryDrivingVehicleData()
                        }
                    })
                }
            }
        },
        // 查询危险驾驶车辆明细
        queryDrivingVehicleData() {
            this.chartDialogLoading = true
            this.chartDialogData = []
            this.instance.post('/ubi/api/report/searchRiskVehicleList', this.queryChartFromData).then((res) => {
                let data = res.data
                this.chartDialogLoading = false
                if (data.code == 200) {
                    data.data.forEach((item) => {
                        let tmpTime = item.endCheckTime ? item.endCheckTime.split(' ')[0] : '--'
                        item.endCheckTime = tmpTime
                    })
                    this.chartDialogData = data.data
                }
            })
        },
        downloadChartFile() {
            this.instance({
                method: 'post',
                url: '/ubi/api/report/downloadRiskVehicleList',
                data: this.queryChartFromData,
                responseType: 'blob'
            }).then((res) => {
                let url = window.URL.createObjectURL(new Blob([res.data]))
                let link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute('download', this.chartDialogTitle + '.xls') //指定下载后的文件名，防跳转
                document.body.appendChild(link)
                link.click()
            })
        },
        // 疲劳驾驶车辆跟踪 柱状图
        setFatiguedDrivingIndexSorChat(data) {
            let xAxisData = []
            let data1 = []
            let cityIdObj = {}
            if (data && data.length > 0) {
                data.forEach((item) => {
                    cityIdObj[item.region] = item.subTenantId
                    xAxisData.push(item.region)
                    data1.push(item.fatiguedDrivingIndex)
                })

                let option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999'
                            }
                        }
                    },
                    xAxis: {
                        type: 'category',
                        data: xAxisData,
                        triggerEvent: true,
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLabel: {
                            //x轴文字的配置
                            show: true,
                            textStyle: {
                                color: '#409EFF'
                            }
                        }
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            data: data1,
                            type: 'bar'
                        }
                    ]
                }

                let tmpDom = document.getElementById('fatiguedDrivingIndexSorChat')
                if (tmpDom) {
                    let _this = this
                    this.drivingMileageLineChart = echarts.init(tmpDom)
                    this.drivingMileageLineChart.setOption(option)
                    this.drivingMileageLineChart.on('click', function (params, charts, event) {
                        if (params.componentType == 'xAxis' && params.value != 'null') {
                            _this.tiredDialogVisible = true
                            _this.tiredDialogTitle = params.value + '疲劳驾驶排名'

                            _this.queryTriedFromData.periodType = _this.searchForm.periodType
                            _this.queryTriedFromData.reportCycle = _this.searchForm.reportCycle
                            _this.queryTriedFromData.subTenantId = cityIdObj[params.value]
                            _this.queryTiredDrivingVehicleData()
                        }
                    })
                }
            }
        },
        queryTiredDrivingVehicleData(sortOrders) {
            this.queryTriedFromData.orders = sortOrders || []
            this.tiredDialogLoading = true
            this.tiredDialogData = []
            this.instance.post('/ubi/api/report/searchFatiguedDrivingList', this.queryTriedFromData).then((res) => {
                let data = res.data
                this.tiredDialogLoading = false
                if (data.code == 200) {
                    this.tiredDialogData = data.data
                }
            })
        },
        downloadTiredFile() {
            this.instance({
                method: 'post',
                url: '/ubi/api/report/downloadFatiguedDrivingList',
                data: this.queryTriedFromData,
                responseType: 'blob'
            }).then((res) => {
                let url = window.URL.createObjectURL(new Blob([res.data]))
                let link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute('download', this.tiredDialogTitle + '.xls') //指定下载后的文件名，防跳转
                document.body.appendChild(link)
                link.click()
            })
        },
        tiredSortChange(sortData) {
            let sortOrders = [{ asc: sortData.order == 'descending', column: sortData.prop }]
            this.queryTiredDrivingVehicleData(sortOrders)
        },
        // 各地区车辆行驶里程 柱状图
        setdrivingMileageLine(data) {
            let cityNameArr = []
            let mileageAvgDayArr = []
            if (data && data.length > 0) {
                data.forEach((item) => {
                    if (item.cityName != '总数') {
                        cityNameArr.push(item.cityName)
                        mileageAvgDayArr.push(item.mileageAvgDay)
                    }
                })
                let option = {
                    title: {
                        text: '各地区日均里程情况(km)',
                        left: 'center'
                    },
                    xAxis: {
                        type: 'category',
                        data: cityNameArr
                    },
                    yAxis: {
                        type: 'value',
                        // 去掉网格线
                        splitLine: {
                            show: false
                        }
                    },
                    series: [
                        {
                            data: mileageAvgDayArr,
                            type: 'bar'
                            //显示数值
                            // itemStyle: {
                            //     normal: {
                            //         label: {
                            //             show: true, //开启显示
                            //             position: 'top', //在上方显示
                            //             textStyle: {
                            //                 //数值样式
                            //                 color: 'black',
                            //                 fontSize: 12
                            //             }
                            //         }
                            //     }
                            // }
                        }
                    ]
                }

                let tmpDom = document.getElementById('drivingMileageLine')
                if (tmpDom) {
                    this.drivingMileageLineChart2 = echarts.init(tmpDom)
                    this.drivingMileageLineChart2.setOption(option)
                }
            }
        },
        // 车辆累计里程分布 饼图
        setaccumulatePie(data) {
            let seriesData = []

            if (data && data.length > 0) {
                data.forEach((item) => {
                    seriesData.push({
                        value: item.itemCount,
                        name: item.itemName
                    })
                })
                let option = {
                    title: {
                        text: '车辆累计里程分布(km)',
                        left: 'center'
                    },
                    // tooltip: {
                    //     trigger: 'item'
                    // },
                    legend: {
                        orient: 'vertical',
                        left: 'left'
                    },
                    series: [
                        {
                            type: 'pie',
                            radius: '50%',
                            data: seriesData,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            },
                            label: {
                                normal: {
                                    formatter: '{c}辆\n{d}%',
                                    textStyle: {
                                        // 提示文字的样式
                                        color: '#595959',
                                        fontSize: 14
                                    }
                                }
                            }
                        }
                    ]
                }

                let tmpDom = document.getElementById('accumulatePie')
                if (tmpDom) {
                    this.drivingMileageLineChart1 = echarts.init(tmpDom)
                    this.drivingMileageLineChart1.setOption(option)
                }
            }
        },
        // 车辆单次行程最大里程分布 饼图
        setsinglePie(data) {
            let seriesData = []

            if (data && data.length > 0) {
                data.forEach((item) => {
                    seriesData.push({
                        value: item.itemCount,
                        name: item.itemName
                    })
                })
                let option = {
                    title: {
                        text: '车辆单次行程最大里程分布',
                        left: 'center'
                    },
                    // tooltip: {
                    //     trigger: 'item'
                    // },
                    legend: {
                        orient: 'vertical',
                        left: 'left'
                    },
                    series: [
                        {
                            type: 'pie',
                            radius: '50%',
                            data: seriesData,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            },
                            label: {
                                normal: {
                                    formatter: '{c}辆\n{d}%',
                                    textStyle: {
                                        // 提示文字的样式
                                        color: '#595959',
                                        fontSize: 14
                                    }
                                }
                            }
                        }
                    ]
                }
                let tmpDom = document.getElementById('singlePie')
                if (tmpDom) {
                    this.singlePieChart = echarts.init(tmpDom)
                    this.singlePieChart.setOption(option)
                }
            }
        },
        // 各地区车辆行驶里程 柱状图
        eachRegionCategory(data) {
            let cityNameArr = []
            let mileageAvgDayArr = []
            if (data && data.length > 0) {
                data.forEach((item) => {
                    if (item.cityName != '总数') {
                        cityNameArr.push(item.cityName)
                        mileageAvgDayArr.push(item.drivingDurationAvgDay)
                    }
                })
                let option = {
                    title: {
                        text: '各地区日均时长情况(h)',
                        left: 'center'
                    },
                    xAxis: {
                        type: 'category',
                        data: cityNameArr
                    },
                    yAxis: {
                        type: 'value',
                        // 去掉网格线
                        splitLine: {
                            show: false
                        }
                    },
                    series: [
                        {
                            data: mileageAvgDayArr,
                            type: 'bar'
                            //显示数值
                            // itemStyle: {
                            //     normal: {
                            //         label: {
                            //             show: true, //开启显示
                            //             position: 'top', //在上方显示
                            //             textStyle: {
                            //                 //数值样式
                            //                 color: 'black',
                            //                 fontSize: 12
                            //             }
                            //         }
                            //     }
                            // }
                        }
                    ]
                }
                let tmpDom = document.getElementById('eachRegionCategory')
                if (tmpDom) {
                    this.eachRegionCategoryChart = echarts.init(tmpDom)
                    this.eachRegionCategoryChart.setOption(option)
                }
            }
        },
        // 车辆累计时长分布 饼图
        setaccumulatedDurationPie(data) {
            let seriesData = []

            if (data && data.length > 0) {
                data.forEach((item) => {
                    seriesData.push({
                        value: item.itemCount ? item.itemCount : 0,
                        name: item.itemName
                    })
                })
                let option = {
                    title: {
                        text: '车辆累计时长分布',
                        left: 'center'
                    },
                    // tooltip: {
                    //     trigger: 'item'
                    // },
                    legend: {
                        orient: 'vertical',
                        left: 'left'
                    },
                    series: [
                        {
                            type: 'pie',
                            radius: '50%',
                            data: seriesData,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            },
                            label: {
                                normal: {
                                    formatter: '{c}辆\n{d}%',
                                    textStyle: {
                                        // 提示文字的样式
                                        color: '#595959',
                                        fontSize: 14
                                    }
                                }
                            }
                        }
                    ]
                }
                let tmpDom = document.getElementById('accumulatedDurationPie')
                if (tmpDom) {
                    this.accumulatedDurationPieChart = echarts.init(tmpDom)
                    this.accumulatedDurationPieChart.setOption(option)
                }
            }
        },
        // 车辆单次行程最大时长分布 饼图
        setmaximumDurationPie(data) {
            let seriesData = []

            if (data && data.length > 0) {
                data.forEach((item) => {
                    seriesData.push({
                        value: item.itemCount ? item.itemCount : 0,
                        name: item.itemName
                    })
                })
                let option = {
                    title: {
                        text: '车辆单次行程最大时长分布',
                        left: 'center'
                    },
                    // tooltip: {
                    //     trigger: 'item'
                    // },
                    legend: {
                        orient: 'vertical',
                        left: 'left'
                    },
                    series: [
                        {
                            type: 'pie',
                            radius: '50%',
                            data: seriesData,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            },
                            label: {
                                normal: {
                                    formatter: '{c}辆\n{d}%',
                                    textStyle: {
                                        // 提示文字的样式
                                        color: '#595959',
                                        fontSize: 14
                                    }
                                }
                            }
                        }
                    ]
                }
                let tmpDom = document.getElementById('maximumDurationPie')
                if (tmpDom) {
                    this.maximumDurationPieChart = echarts.init(tmpDom)
                    this.maximumDurationPieChart.setOption(option)
                }
            }
        },
        // 各地区疲劳驾驶情况  折/柱图
        setfatigueDrivingLine(data) {
            let cityNameArr = []
            let dataArr1 = []
            let dataArr2 = []

            if (data && data.length > 0) {
                data.forEach((item) => {
                    if (item.cityName != '总数') {
                        cityNameArr.push(item.cityName)
                        // 日均疲劳驾驶时长
                        dataArr1.push(item.fatiguedDrivingDurationAvgDay)
                        // 日均疲劳驾驶次数
                        dataArr2.push(item.fatiguedDrivingNumAvgDay)
                    }
                })

                let option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999'
                            }
                        }
                    },
                    legend: {
                        data: ['日均疲劳驾驶时长', '日均疲劳驾驶次数']
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: cityNameArr,
                            axisPointer: {
                                type: 'shadow'
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            name: '日均疲劳驾驶时长',
                            // min: 0,
                            // max: 250,
                            // interval: 50,
                            // 去掉网格线
                            splitLine: {
                                show: false
                            },
                            axisLabel: {
                                formatter: '{value} h'
                            }
                        },
                        {
                            type: 'value',
                            name: '日均疲劳驾驶次数',
                            // min: 0,
                            // max: 25,
                            // interval: 5,
                            // 去掉网格线
                            splitLine: {
                                show: false
                            },
                            axisLabel: {
                                formatter: '{value} 次'
                            }
                        }
                    ],
                    series: [
                        {
                            name: '日均疲劳驾驶时长',
                            type: 'bar',
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + ' h'
                                }
                            },
                            data: dataArr1
                        },
                        {
                            name: '日均疲劳驾驶次数',
                            type: 'line',
                            yAxisIndex: 1,
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + ' 次'
                                }
                            },
                            data: dataArr2
                        }
                    ]
                }
                let tmpDom = document.getElementById('fatigueDrivingLine')
                if (tmpDom) {
                    this.fatigueDrivingLineChart = echarts.init(tmpDom)
                    this.fatigueDrivingLineChart.setOption(option)
                }
            }
        },
        // 各地区疲劳驾驶情况 - 疲劳驾驶时长 饼图
        setfatigueDrivingTimePie(data) {
            let seriesData = []

            if (data && data.length > 0) {
                data.forEach((item) => {
                    seriesData.push({
                        value: item.itemCount ? item.itemCount : 0,
                        name: item.itemName
                    })
                })
                let option = {
                    title: {
                        text: '车辆日均疲劳驾驶时长分布',
                        left: 'center'
                    },
                    // tooltip: {
                    //     trigger: 'item'
                    // },
                    legend: {
                        orient: 'vertical',
                        left: 'left'
                    },
                    series: [
                        {
                            type: 'pie',
                            radius: '50%',
                            data: seriesData,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            },
                            label: {
                                normal: {
                                    formatter: '{c}辆\n{d}%',
                                    textStyle: {
                                        // 提示文字的样式
                                        color: '#595959',
                                        fontSize: 14
                                    }
                                }
                            }
                        }
                    ]
                }
                let tmpDom = document.getElementById('fatigueDrivingTimePie')
                if (tmpDom) {
                    this.fatigueDrivingTimePieChart = echarts.init(tmpDom)
                    this.fatigueDrivingTimePieChart.setOption(option)
                }
            }
        },
        // 各地区疲劳驾驶情况 - 疲劳驾驶次数 饼图
        setfatigueDrivingSumPie(data) {
            let seriesData = []

            if (data && data.length > 0) {
                data.forEach((item) => {
                    seriesData.push({
                        value: item.itemCount ? item.itemCount : 0,
                        name: item.itemName
                    })
                })
                let option = {
                    title: {
                        text: '车辆日均疲劳驾驶次数分布',
                        left: 'center'
                    },
                    // tooltip: {
                    //     trigger: 'item'
                    // },
                    legend: {
                        orient: 'vertical',
                        left: 'left'
                    },
                    series: [
                        {
                            type: 'pie',
                            radius: '50%',
                            data: seriesData,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            },
                            label: {
                                normal: {
                                    formatter: '{c}辆\n{d}%',
                                    textStyle: {
                                        // 提示文字的样式
                                        color: '#595959',
                                        fontSize: 14
                                    }
                                }
                            }
                        }
                    ]
                }
                let tmpDom = document.getElementById('fatigueDrivingSumPie')
                if (tmpDom) {
                    this.fatigueDrivingSumPieChart = echarts.init(tmpDom)
                    this.fatigueDrivingSumPieChart.setOption(option)
                }
            }
        },
        // 车辆单次行程最大疲劳驾驶时长分布 饼图
        setmaximumFatigueDrivingPie(data) {
            let seriesData = []

            if (data && data.length > 0) {
                data.forEach((item) => {
                    seriesData.push({
                        value: item.itemCount ? item.itemCount : 0,
                        name: item.itemName
                    })
                })

                let option = {
                    title: {
                        text: '车辆单次行程最大疲劳驾驶时长',
                        left: 'center'
                    },
                    // tooltip: {
                    //     trigger: 'item'
                    // },
                    legend: {
                        orient: 'vertical',
                        left: 'left'
                    },
                    series: [
                        {
                            type: 'pie',
                            radius: '50%',
                            data: seriesData,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            },
                            label: {
                                normal: {
                                    formatter: '{c}辆\n{d}%',
                                    textStyle: {
                                        // 提示文字的样式
                                        color: '#595959',
                                        fontSize: 14
                                    }
                                }
                            }
                        }
                    ]
                }
                let tmpDom = document.getElementById('maximumFatigueDrivingPie')
                if (tmpDom) {
                    this.maximumFatigueDrivingPieChart = echarts.init(tmpDom)
                    this.maximumFatigueDrivingPieChart.setOption(option)
                }
            }
        },
        // 三急一超情况 柱状图
        setthreeUrgentAndOneSuper(data) {
            let cityNameArr = []
            let sharpUpNum100km = []
            let sharpDownNum100km = []
            let sharpTurnNum100km = []
            let overSpeedNum100km = []

            if (data && data.length > 0) {
                data.forEach((item) => {
                    if (item.cityName != '总数') {
                        cityNameArr.push(item.cityName)
                        sharpUpNum100km.push(item.sharpUpNum100km)
                        sharpDownNum100km.push(item.sharpDownNum100km)
                        sharpTurnNum100km.push(item.sharpTurnNum100km)
                        overSpeedNum100km.push(item.overSpeedNum100km)
                    }
                })

                let option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    legend: {},
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            axisLabel: { interval: 0, rotate: 40 },
                            data: cityNameArr
                        }
                    ],
                    yAxis: {
                        type: 'value',
                        // 去掉网格线
                        splitLine: {
                            show: false
                        }
                    },
                    series: [
                        {
                            name: '百公里急加速',
                            type: 'bar',
                            barWidth: 5,
                            stack: 'Search Engine',
                            emphasis: {
                                focus: 'series'
                            },
                            data: sharpUpNum100km
                        },
                        {
                            name: '百公里急减速',
                            type: 'bar',
                            stack: 'Search Engine',
                            emphasis: {
                                focus: 'series'
                            },
                            data: sharpDownNum100km
                        },
                        {
                            name: '百公里急转弯',
                            type: 'bar',
                            stack: 'Search Engine',
                            emphasis: {
                                focus: 'series'
                            },
                            data: sharpTurnNum100km
                        },
                        {
                            name: '百公里超速',
                            type: 'bar',
                            stack: 'Search Engine',
                            emphasis: {
                                focus: 'series'
                            },
                            data: overSpeedNum100km
                        }
                    ]
                }
                let tmpDom = document.getElementById('threeUrgentAndOneSuper')
                if (tmpDom) {
                    this.threeUrgentAndOneSuperChart = echarts.init(tmpDom)
                    this.threeUrgentAndOneSuperChart.setOption(option)
                }
            }
        },
        // 多个sheet
        exportData1() {
            // 多个sheet，并且可以设置每行背景颜色
            const data = [
                ['姓名', '年龄', '性别'],
                ['张三', 25, '男'],
                ['李四', 30, '女'],
                ['李四', 30, '女'],
                ['李四', 30, '女'],
                ['王五', 28, '男']
            ]

            for (let index = 0; index < 6000; index++) {
                data.push(['王五', 28, '男'])
            }
            const data1 = [
                ['设备号', '车牌号', '驾驶评分'],
                ['16841211619', '川AAK3153', '91'],
                ['16841211620', '川AAK3154', '92'],
                ['16841211620', '川AAK3154', '92'],
                ['16841211620', '川AAK3154', '92'],
                ['16841211621', '川AAK3155', '91']
            ]

            for (let index = 0; index < 10000; index++) {
                data1.push(['16841211621', '川AAK3155', '91'])
            }

            let workbook = XLSXST.utils.book_new()
            const worksheet = XLSXST.utils.aoa_to_sheet(data)
            const worksheet1 = XLSXST.utils.aoa_to_sheet(data1)

            worksheet1['!cols'] = [
                {
                    width: 36
                },
                {
                    width: 36
                },
                {
                    width: 36
                }
            ]

            Object.keys(worksheet1).forEach((key) => {
                // 首行70ad47  奇数行e2efda
                if (key.slice(1, key.length) == '1') {
                    worksheet1[key].s = {
                        fill: { fgColor: { rgb: '70ad47' } }
                    }
                } else if (Number(key.slice(1, key.length)) % 2 == 0) {
                    worksheet1[key].s = {
                        fill: { fgColor: { rgb: 'e2efda' } }
                    }
                }
            })

            let a = XLSXST.utils.book_append_sheet(workbook, worksheet, 'table1')
            let b = XLSXST.utils.book_append_sheet(workbook, worksheet1, 'table2')

            // XLSXST.writeFile(workbook, 'data3.xlsx')
            // let a = XLSXST.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
            // XLSXST.writeFile(workbook, '会议统计表格.xlsx') //表格名称
            let cc = XLSXST.write(workbook, { bookType: 'xlsx', type: 'array' })
            // let abc = XLSXST.write(workbook, '会议统计表格.xlsx') //表格名称
            let blob = new Blob([cc], { type: 'application/octet-stream' })
            // FileSaver.saveAs(blob, 'test.xlsx')
            this.excel = blob
        },
        // 车辆驾驶评分分布 柱状图
        setdrivingRatingColumnar(data) {
            let cityNameArr = []
            let vehNumDrivingScore50To85 = []
            let vehNumDrivingScore85To95 = []
            let vehNumDrivingScore95To100 = []

            if (data && data.length > 0) {
                data.forEach((item) => {
                    if (item.cityName != '总数') {
                        cityNameArr.push(item.cityName)
                        vehNumDrivingScore50To85.push(item.vehNumDrivingScore50To85)
                        vehNumDrivingScore85To95.push(item.vehNumDrivingScore85To95)
                        vehNumDrivingScore95To100.push(item.vehNumDrivingScore95To100)
                    }
                })

                let option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    legend: {},
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            axisLabel: { interval: 0, rotate: 40 },
                            data: cityNameArr
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            splitLine: {
                                show: false
                            }
                        }
                    ],
                    series: [
                        {
                            name: '高风险(50-85)',
                            type: 'bar',
                            barWidth: 5,
                            stack: 'Search Engine',
                            emphasis: {
                                focus: 'series'
                            },
                            data: vehNumDrivingScore50To85
                        },
                        {
                            name: '普通(85-95)',
                            type: 'bar',
                            stack: 'Search Engine',
                            emphasis: {
                                focus: 'series'
                            },
                            data: vehNumDrivingScore85To95
                        },
                        {
                            name: '优良(95-100)',
                            type: 'bar',
                            stack: 'Search Engine',
                            emphasis: {
                                focus: 'series'
                            },
                            data: vehNumDrivingScore95To100
                        }
                    ]
                }
                let tmpDom = document.getElementById('drivingRatingColumnar')
                if (tmpDom) {
                    this.drivingRatingColumnarChart = echarts.init(tmpDom)
                    this.drivingRatingColumnarChart.setOption(option)
                }
            }
        },
        getPdf(domStr) {
            this.exportLoading = true
            html2Canvas(document.querySelector(domStr), {
                allowTaint: false,
                taintTest: false,
                logging: false,
                useCORS: true,
                dpi: window.devicePixelRatio * 5, //将分辨率提高到特定的DPI 提高四倍
                scale: 3 //按比例增加分辨率
            }).then((canvas) => {
                var pdf = new jsPDF('p', 'mm', 'a4', true) //A4纸，纵向
                var ctx = canvas.getContext('2d'),
                    a4w = 190,
                    a4h = 287, //A4大小，210mm x 297mm，四边各保留10mm的边距，显示区域190x277
                    imgHeight = Math.floor((a4h * canvas.width) / a4w), //按A4显示比例换算一页图像的像素高度
                    renderedHeight = 0
                while (renderedHeight < canvas.height) {
                    var page = document.createElement('canvas')
                    page.width = canvas.width
                    page.height = Math.min(imgHeight, canvas.height - renderedHeight) //可能内容不足一页
                    //用 getImageData 剪裁指定区域，并画到前面创建的canvas对象中
                    page.getContext('2d').putImageData(ctx.getImageData(0, renderedHeight, canvas.width, Math.min(imgHeight, canvas.height - renderedHeight)), 0, 0)
                    pdf.addImage(page.toDataURL('image/jpeg', 1.0), 'JPEG', 10, 0, a4w, Math.min(a4h, (a4w * page.height) / page.width)) //添加图像到页面，保留10mm边距

                    renderedHeight += imgHeight
                    if (renderedHeight < canvas.height) {
                        pdf.addPage() //如果后面还有内容，添加一个空页
                    }
                    // delete page;
                }
                this.pdfBlob = pdf.output('blob')
                console.log('pdf转换完成')
                // window.pdfBlob = pdf.output('blob')
                //保存文件
                // pdf.save(title + '.pdf')
            })
        },
        clickPopup(rowItem) {
            this.queryPulloutFromData = {
                periodType: this.searchForm.periodType,
                reportCycle: this.searchForm.reportCycle,
                subTenantId: rowItem.subTenantId
            }
            this.pulloutDialogVisible = true
            this.pulloutDialogLoading = true

            this.instance.post('/ubi/api/report/searchPulloutDeviceList', this.queryPulloutFromData).then((res) => {
                this.pulloutDialogLoading = false
                let data = res.data
                if (data.code == 200) {
                    this.pulloutDialogData = data.data
                }
            })
        },
        downloadPulloutFile() {
            this.instance({
                method: 'post',
                url: '/ubi/api/report/downloadPulloutDeviceList',
                data: this.queryPulloutFromData,
                responseType: 'blob'
            }).then((res) => {
                let url = window.URL.createObjectURL(new Blob([res.data]))
                let link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute('download', this.pulloutDialogTitle + '.xls') //指定下载后的文件名，防跳转
                document.body.appendChild(link)
                link.click()
            })
        }
    },
    watch: {
        pdfBlob: {
            handler(data) {
                if (data) {
                    console.log('watch:')
                    console.log(data)
                    this.tmpPdfBlob = data
                    this.exportLoading && this.downloadFileZip(true)
                }
            },
            immediate: true
        }
    }
}
</script>
<style scoped>
@import '../../assets/css/public.css';
.page_title {
    font-size: 0.24rem;
    font-weight: 500;
    color: #051932;
    margin-bottom: 0.2rem;
}

.page_content {
    width: 100%;
    height: 87%;
    overflow: auto;
}
.chartDialog /deep/ .el-dialog__header {
    font-weight: bold;
}
.chartDialog /deep/ .el-dialog__body {
    padding-top: 0;
}

.echarts_card {
    padding: 10px;
    margin-bottom: 20px;
    background: #fff;
}

.echarts_card .cards_box {
    margin-bottom: 30px;
}

.echarts_card .cards_box:last-child {
    margin-bottom: 0;
}

.echarts_card .card_content .card-content_left,
.echarts_card .card_content .card-content_right {
    width: 55%;
}
.echarts_card .card_content .card-content_left {
    padding-right: 10px;
}
.echarts_card .card_content .card-content_right {
    width: 40%;
}
.report_none /deep/ .el-table__row:last-child {
    font-weight: normal !important;
}

/* 风险分析 */
.abnormal {
    margin-left: 10px;
    margin-right: 20px;
}

.abnormal-txt,
.analysis-txt {
    width: 100%;
    overflow-wrap: break-word;
    max-height: 300px;
    overflow-y: auto;
    padding: 10px;
    margin-top: 10px;
    border-radius: 7px;
    box-shadow: 1px 1px 8px 0 hsla(0, 0%, 87.5%, 0.6);
}
.abnormal-txt {
    padding-left: 25px;
    margin-bottom: 10px;
}

.echarts_category {
    width: 100%;
    height: 250px;
}

.echarts_left,
.echarts_right {
    width: 50%;
    height: 250px;
}

.echarts_30 {
    width: 33.1% !important;
}

.echarts_100 {
    width: 100% !important;
}

.echarts_search .search-form-inline .el-form-item {
    margin-bottom: 0;
}
.card_title {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 16px;
}
.report_table /deep/ .el-table__body .el-table__row:last-child {
    color: #333 !important;
    font-weight: bold;
}
.report_table /deep/ .el-table__body .el-table__row:last-child span {
    color: #333 !important;
}
.report_none /deep/ .el-table__body .el-table__row:last-child .span_txt {
    color: #409eff !important;
    cursor: pointer;
}
.report_table /deep/ thead {
    color: #333;
}
.page_none {
    background: #fff;
}
.page_none .el-empty {
    position: absolute;
    top: 42%;
    left: 43%;
}
.report_table {
    color: #000;
}
.report_table /deep/ th.el-table__cell {
    font-weight: bold;
    font-size: 18px;
    color: #000;
    background: #f5f7fa;
}
</style>