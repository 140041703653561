<template>
	<div v-if="dialogVisible">
		<div class="modal" @click="beforeClose"></div>
		<div class="modal_dialog">
			<div class="dialog_header">
				<!-- <div class="detail_title">用户信息管理/用户详情</div> -->
				<div></div>
				<i class="el-icon-close" @click="beforeClose"></i>
			</div>
			<div class="dialog_section">
				<div class="detail_title" style="margin-top: 0;">用户信息</div>
				<el-form :inline="true"  ref="detailForm" :model="detailForm" class="demo-form-inline" :class="{disabled_null_border:userDisabled}" label-width="120px" >
					<el-form-item label="姓名:" class="width_3" >
						<el-input v-model="detailForm.userName" size="small" :disabled="userDisabled"></el-input>
					</el-form-item>
					<el-form-item label="联系电话:" class="width_3" >
						<el-input v-model="detailForm.userPhone" :disabled="userDisabled" size="small"></el-input>
					</el-form-item>
					<el-form-item label="车牌号:" class="width_3" >
						<el-input v-model="detailForm.plateNo" :disabled="userDisabled" size="small"></el-input>
					</el-form-item>
					<el-form-item label="设备号:" class="width_3" >
						<el-input v-model="detailForm.deviceNumber" :disabled="userDisabled" size="small"></el-input>
					</el-form-item>
					<el-form-item label="小程序注册时间:" class="width_3" >
						<el-input v-model="detailForm.addTime" :disabled="userDisabled" size="small"></el-input>
					</el-form-item>
					<!-- <el-form-item label="最高学历:" class="width_3" >
						<el-input v-model="detailForm.userPhone" :disabled="userDisabled" size="small"></el-input>
					</el-form-item> -->
				</el-form>
				<div class="title_box" style="align-items: center;">
					<div class="detail_title">运维信息</div>
					<el-button type="primary" size="small"  icon="el-icon-circle-plus-outline" @click="handleAddVehicle">添加运维工单</el-button>
				</div>	
				<div v-if="detailForm.operationsWorkOrderVos.length>0">
					<div class="vehicle_box" v-for="(item,index) in detailForm.operationsWorkOrderVos" :key="index">
						<!-- <p class="vehicle_title">运维工单{{numberConversion(index)}}：</p> --> 
						<template v-if="item.workOrderType==3">
							<el-form :inline="true"  :ref="'detailForm'+index" :model="item" class="demo-form-inline" :class="{disabled_null_border:item.sendOut.disabled}" label-width="110px" >
								<div class="form_flex">
									<div>
										<el-form-item label="工单类型:" class="width_3">
											<el-select v-model="item.workOrderType" placeholder="请选择" @change="workOrderTypeChange(item)" :disabled="item.disabled" size="small">
												<el-option :disabled="detailForm.deviceNumber!=''" label="发放设备工单" value="1"></el-option>
												<el-option label="退回设备工单" value="2"></el-option>
												<el-option label="更换设备工单" value="3"></el-option>
											</el-select>
										</el-form-item>
										<el-form-item label="工单状态:" v-if="item.workOrderId" class="width_3 disabled_null_border" :class="{color_active:item.receiveFlag==0}">
											<el-select v-model="item.receiveFlag" disabled size="small">
												<el-option label="待确认" value="0"></el-option>
												<el-option label="已确认" value="1"></el-option>
											</el-select>
										</el-form-item>
									</div>
									<el-button v-if="!item.sendOut.id&&!item.sendBack.id" type="danger" @click="deleteVehicle(index,'sendBack')" size="small">删 除</el-button>
								</div>
							</el-form>
							<el-form :inline="true" :rules="vehicleRules" :ref="'detailForm'+index" :model="item.sendOut" class="demo-form-inline" :class="{disabled_null_border:item.sendOut.disabled}" label-width="110px" >
								<div class="small_title">
									<div><img src="../../public/images/msi/user/give.png" ></div>
									<span>发放设备</span>
								</div>
								<el-form-item label="设备编号:" class="width_3" prop="deviceNumber" :class="{disabled_null_border:item.workOrderType==2||item.operationType==2}">
									<el-input v-model="item.sendOut.deviceNumber" @blur="deviceNumberBlur(item.sendOut.deviceNumber,item.sendOut)" :disabled="item.sendOut.disabled" placeholder="请输入" size="small"></el-input>
									<!-- <el-input v-model="item.sendOut.deviceNumber"  :disabled="item.sendOut.disabled||item.workOrderType==2||item.operationType==2" placeholder="请输入" size="small"></el-input> -->
								</el-form-item>
								<el-form-item label="快递单号:" class="width_3" prop="expressNumber">
									<el-input v-model="item.sendOut.expressNumber" :disabled="item.sendOut.disabled" placeholder="请输入" size="small" clearable></el-input>
								</el-form-item>
								<el-form-item label="快递公司:" class="width_3" prop="expressCompany">
									<el-select v-model="item.sendOut.expressCompany" placeholder="请选择" :disabled="item.sendOut.disabled" size="small">
									    <el-option label="顺丰" value="顺丰"></el-option>
									    <el-option label="申通" value="申通"></el-option>
									    <el-option label="菜鸟" value="菜鸟"></el-option>
									    <el-option label="中通" value="中通"></el-option>
									    <el-option label="京东物流" value="京东物流"></el-option>
									    <el-option label="百世" value="百世"></el-option>
									    <el-option label="宅急送" value="宅急送"></el-option>
									    <el-option label="圆通" value="圆通"></el-option>
									    <el-option label="国通" value="国通"></el-option>
									    <el-option label="跨越速运" value="跨越速运"></el-option>
									    <el-option label="邮政EMS" value="邮政EMS"></el-option>
									    <el-option label="其他" value="其他"></el-option>
									</el-select>
								</el-form-item>
								<el-form-item :label="item.workOrderType==1||item.operationType==1?'收货人姓名:':'发货人姓名:'" class="width_3" prop="senderName">
									<el-input v-model="item.sendOut.senderName" :disabled="item.sendOut.disabled"  :placeholder="item.sendOut.disabled?' ':'请输入'" size="small" clearable></el-input>
								</el-form-item>
								<el-form-item  :label="item.workOrderType==1||item.operationType==1?'收货人手机号:':'发货人手机号:'" class="width_3" prop="phoneNum">
									<el-input v-model="item.sendOut.phoneNum" :disabled="item.sendOut.disabled" maxlength="11" type="tel" placeholder="请输入" size="small" clearable></el-input>
								</el-form-item>
								<el-form-item  :label="item.workOrderType==1||item.operationType==1?'收货地址:':'发货地址:'" class="width_3" prop="address">
									<el-input v-model="item.sendOut.address" :disabled="item.sendOut.disabled" :type="item.workOrderId?'text':'textarea'"  :placeholder="item.disabled?' ':'请输入'" size="small" clearable></el-input>
								</el-form-item>
							</el-form>
							<div class="dialog-footer" style="text-align: right;margin-bottom:20px;">
							  <template v-if="!item.sendOut.id">
								  <!-- <el-button  type="danger" @click="deleteVehicle(index,'sendOut')" size="small">删 除</el-button> -->
								  <el-button type="primary" @click="submitVehicle(index,item.sendOut)" size="mini">确 认</el-button>
							  </template>
							  <el-button v-if="item.sendOut.id&&item.sendOut.receiveFlag==0" type="primary" @click="confirmReceipt(item,item.sendOut)" size="mini">确认收货</el-button>
							</div>
							
							<el-form :inline="true" :rules="vehicleRules" :ref="'detailForm'+index" :model="item.sendBack" class="demo-form-inline" :class="{disabled_null_border:item.disabled}" label-width="110px" >
								<div class="small_title">
									<div><img src="../../public/images/msi/user/replace.png" ></div>
									<span>退回设备</span>
								</div>
								<el-form-item label="设备编号:" class="width_3"  prop="deviceNumber" :class="{disabled_null_border:item.workOrderType==2||item.operationType==2}">
									<el-input v-model="item.sendBack.deviceNumber" :disabled="item.sendBack.disabled" placeholder="请输入" size="small"></el-input>
								</el-form-item>
								<el-form-item label="快递单号:" class="width_3" prop="expressNumber">
									<el-input v-model="item.sendBack.expressNumber" :disabled="item.sendBack.disabled" placeholder="请输入" size="small" clearable></el-input>
								</el-form-item>
								<el-form-item label="快递公司:" class="width_3" prop="expressCompany">
									<el-select v-model="item.sendBack.expressCompany" placeholder="请选择" :disabled="item.sendBack.disabled" size="small">
									    <el-option label="顺丰" value="顺丰"></el-option>
									    <el-option label="申通" value="申通"></el-option>
									    <el-option label="菜鸟" value="菜鸟"></el-option>
									    <el-option label="中通" value="中通"></el-option>
									    <el-option label="京东物流" value="京东物流"></el-option>
									    <el-option label="百世" value="百世"></el-option>
									    <el-option label="宅急送" value="宅急送"></el-option>
									    <el-option label="圆通" value="圆通"></el-option>
									    <el-option label="国通" value="国通"></el-option>
									    <el-option label="跨越速运" value="跨越速运"></el-option>
									    <el-option label="邮政EMS" value="邮政EMS"></el-option>
									    <el-option label="其他" value="其他"></el-option>
									</el-select>
								</el-form-item>
								<el-form-item :label="item.workOrderType==1||item.operationType==1?'收货人姓名:':'发货人姓名:'" class="width_3" prop="senderName">
									<el-input v-model="item.sendBack.senderName" :disabled="item.sendBack.disabled"  :placeholder="item.sendBack.disabled?' ':'请输入'" size="small" clearable></el-input>
								</el-form-item>
								<el-form-item  :label="item.workOrderType==1||item.operationType==1?'收货人手机号:':'发货人手机号:'" class="width_3" prop="phoneNum">
									<el-input v-model="item.sendBack.phoneNum" :disabled="item.sendBack.disabled" maxlength="11" type="tel" placeholder="请输入" size="small" clearable></el-input>
								</el-form-item>
								<el-form-item  :label="item.workOrderType==1||item.operationType==1?'收货地址:':'发货地址:'" class="width_3" prop="address">
									<el-input v-model="item.sendBack.address" :disabled="item.sendBack.disabled" :type="item.workOrderId?'text':'textarea'"  :placeholder="item.disabled?' ':'请输入'" size="small" clearable></el-input>
								</el-form-item>
								<el-form-item label="备注:" class="width_3" >
									<el-input v-model="item.remarks"  :disabled="item.sendBack.disabled"  :placeholder="item.disabled?' ':'请输入'" size="small" clearable></el-input>
								</el-form-item>
							</el-form>
							<div class="dialog-footer" style="text-align: right;margin-bottom:20px;">
							  <template v-if="!item.sendBack.id">
								  
								  <el-button type="primary" :disabled="submitDisabled" @click="submitVehicle(index,item.sendBack)" size="mini">确 认</el-button>
							  </template>
							  <el-button v-if="item.sendBack.id&&item.sendBack.receiveFlag==0" type="primary" @click="confirmReceipt(item,item.sendBack)" size="mini">确认收货</el-button>
							</div>
						</template>
						<template v-else>
							<el-form :inline="true" :rules="vehicleRules" :ref="'detailForm'+index" :model="item" class="demo-form-inline" :class="{disabled_null_border:item.disabled}" label-width="110px" >
								<div class="form_flex">
									<div>
										<el-form-item label="工单类型:" class="width_3">
											<el-select v-model="item.workOrderType" placeholder="请选择" @change="workOrderTypeChange(item)" :disabled="item.disabled" size="small">
												<el-option :disabled="detailForm.deviceNumber!=''" label="发放设备工单" value="1"></el-option>
												<el-option label="退回设备工单" value="2"></el-option>
												<el-option label="更换设备工单" value="3"></el-option>
											</el-select>
										</el-form-item>
										<el-form-item label="工单状态:" v-if="item.workOrderId" class="width_3 disabled_null_border" :class="{color_active:item.receiveFlag==0}">
											<el-select v-model="item.receiveFlag" disabled size="small">
												<el-option label="待确认" value="0"></el-option>
												<el-option label="已确认" value="1"></el-option>
											</el-select>
										</el-form-item>
									</div>
									<el-button  v-if="!item.workOrderId" type="danger" @click="deleteVehicle(index)" size="small">删 除</el-button>
								</div>
								<el-form-item label="设备编号:" class="width_3"  prop="deviceNumber" :class="{disabled_null_border:item.workOrderType==2||item.operationType==2}">
									<el-input v-model="item.deviceNumber"  @blur="deviceNumberBlur(item.deviceNumber,item)" :disabled="item.disabled" placeholder="请输入" size="small"></el-input>
								</el-form-item>
								<el-form-item label="快递单号:" class="width_3" prop="expressNumber">
									<el-input v-model="item.expressNumber" :disabled="item.disabled" placeholder="请输入" size="small" clearable></el-input>
								</el-form-item>
								<el-form-item label="快递公司:" class="width_3" prop="expressCompany">
									<el-select v-model="item.expressCompany" placeholder="请选择" :disabled="item.disabled" size="small">
									    <el-option label="顺丰" value="顺丰"></el-option>
									    <el-option label="申通" value="申通"></el-option>
									    <el-option label="菜鸟" value="菜鸟"></el-option>
									    <el-option label="中通" value="中通"></el-option>
									    <el-option label="京东物流" value="京东物流"></el-option>
									    <el-option label="百世" value="百世"></el-option>
									    <el-option label="宅急送" value="宅急送"></el-option>
									    <el-option label="圆通" value="圆通"></el-option>
									    <el-option label="国通" value="国通"></el-option>
									    <el-option label="跨越速运" value="跨越速运"></el-option>
									    <el-option label="邮政EMS" value="邮政EMS"></el-option>
									    <el-option label="其他" value="其他"></el-option>
									</el-select>
								</el-form-item>
								<el-form-item :label="item.workOrderType==1||item.operationType==1?'收货人姓名:':'发货人姓名:'" class="width_3" prop="senderName">
									<el-input v-model="item.senderName" :disabled="item.disabled"  :placeholder="item.disabled?' ':'请输入'" size="small" clearable></el-input>
								</el-form-item>
								<el-form-item  :label="item.workOrderType==1||item.operationType==1?'收货人手机号:':'发货人手机号:'" class="width_3" prop="phoneNum">
									<el-input v-model="item.phoneNum" :disabled="item.disabled" maxlength="11" type="tel" placeholder="请输入" size="small" clearable></el-input>
								</el-form-item>
								<el-form-item  :label="item.workOrderType==1||item.operationType==1?'收货地址:':'发货地址:'" class="width_3" prop="address">
									<el-input v-model="item.address" :disabled="item.disabled" :type="item.workOrderId?'text':'textarea'"  :placeholder="item.disabled?' ':'请输入'" size="small" clearable></el-input>
								</el-form-item>
								<el-form-item label="备注:" class="width_3" >
									<el-input v-model="item.remarks"  :disabled="item.disabled"  :placeholder="item.disabled?' ':'请输入'" size="small" clearable></el-input>
								</el-form-item>
							</el-form>
							<div class="dialog-footer" style="text-align: right;margin-bottom:20px;">
							  <template v-if="!item.workOrderId">
								  
								  <el-button type="primary" @click="submitVehicle(index,item)" :disabled="submitDisabled" size="small">确 认</el-button>
							  </template>
							  <el-button v-if="item.workOrderId&&item.receiveFlag==0" type="primary" @click="confirmReceipt(item)" size="small">确认收货</el-button>
							</div>
						</template>
						
					</div>
				</div>
				<div class="null_vehicle" v-else>
					<div><img src="../../public/images/msi/user/zanwu.png" ></div>
					<p>未申请设备，暂无运维记录</p>
				</div>
			</div>
		</div>	
	</div>
</template>

<script>
	import {getToken} from '../utils/auth.js'
export default {
  name: 'OperationsDetail',
  props: {
    dialogVisible: Boolean,
    detailForm: Object,
  },
  data(){
	  var checkphone = (rule, value, callback) => {
	  		  if (value == "") {
	  			callback(new Error("请输入手机号"));
	  		  } else if (!this.isCellPhone(value)) {//引入methods中封装的检查手机格式的方法 
	  			callback(new Error("请输入正确的手机号!"));
	  		  } else {
	  			callback();
	  		  }
	  		};
	  return{
		    userDisabled:true,
		    deviceObjOut:{
				  address: '',
				  deviceNumber: '',
				  expressCompany: '',
				  expressNumber: '',
				  operationType:'1',
				  phoneNum:'',
				  disabled:false,
				  receiveFlag:'',
				  receiveTime:'',
				  senderName:'',
				  workOrderType:'3',
				  workOrderId:'',
			},
			deviceObjBack:{
				  address: '',
				  deviceNumber: '',
				  expressCompany: '',
				  expressNumber: '',
				  operationType:'2',
				  phoneNum:'',
				  disabled:false,
				  receiveFlag:'',
				  receiveTime:'',
				  senderName:'',
				  workOrderType:'3',
				  workOrderId:'',
			},
			vehicleRules: {
				expressNumber: [
					{ required: true, message: '请输入快递单号', trigger: 'blur' }
				],
				deviceNumber: [
					{ required: true, message: '请输入设备编号', trigger: 'blur' }
				],  
				phoneNum: [
					{ required: true, validator: checkphone, trigger: 'blur' }
				],
				senderName: [
					{ required: true, message: '请输入姓名', trigger: 'blur' }
				],
				address: [
					{ required: true, message: '请输入地址', trigger: 'blur' }
				]
		    },
			submitDisabled:false
	  }
  },
  mounted() {
  },
  methods:{
	  //设备号失去焦点事件
	  deviceNumberBlur(deviceNumber,item){
		  if(item.workOrderType==2||item.operationType==2){
			  return false
		  }
		  if(!deviceNumber){
			 return false 
		  }
		  this.instance({
		  	  method:'post',
		  	  url:'/user/devops/checkDeviceNumber',
		  	  data:{
		  			deviceNumber:deviceNumber,
		  		},
		  	  headers:{
		  		'Content-Type':"application/json;charset=UTF-8",
		  	  }
		  }).then(res=>{
		  	if(res.data.code==1){
				
		  	}else{
		  		this.$message(res.data.message);
		  	}
		  });
	  },
	  //工单类型change事件
	  workOrderTypeChange(item){
		  if(item.workOrderType==2||item.operationType==2){
		  		item.deviceNumber=this.detailForm.deviceNumber;
		  }else{
			  item.deviceNumber=''
		  }
		  if(item.workOrderType==3){
				 item.sendBack=this.deviceObjBack;
				 item.sendOut=this.deviceObjOut;
				item.sendBack.deviceNumber=this.detailForm.deviceNumber;	 
		  }
	  },
	  //点击运维工单详情的取消按钮
	  editVehicle(sign,index){
		  if(sign=='edit'){
			 this.detailForm.operationsWorkOrderVos[index].disabled=false; 
		  }else{
			 this.detailForm.operationsWorkOrderVos[index].disabled=true; 
			 // console.log(this.$refs.detailForm)
			 this.$refs['detailForm'+index][0].resetFields();
		  }
		   
	  },
	  //点击添加运维工单
	  handleAddVehicle(){
		  if(this.detailForm.operationsWorkOrderVos.length>0){
			  var operationsWorkOrder=this.detailForm.operationsWorkOrderVos[0]
			  if(operationsWorkOrder.workOrderType!=3){
				  if(operationsWorkOrder.receiveFlag!=1){
					  this.$message.error('抱歉，您有未操作完成的运维工单，请操作完成再来添加！');
					  return false
				  }
			  }else{
				  if(operationsWorkOrder.sendBack.receiveFlag!=1||operationsWorkOrder.sendOut.receiveFlag!=1){
					  this.$message.error('抱歉，您有未操作完成的运维工单，请操作完成再来添加！');
					  return false
				  }
			  }
		  }
		  var baseData={
				address: '',
				deviceNumber: '',
				expressCompany: '',
				expressNumber: '',
				operationType:'',
				phoneNum:'',
				receiveFlag:'',
				receiveTime:'',
				senderName:'',
				workOrderType:'1',
				workOrderId:''
			}
		  if(this.detailForm.deviceNumber){
			  baseData.workOrderType='2'
		  }
		  this.detailForm.operationsWorkOrderVos.unshift(
			baseData
		  );
	  },
	  //点击确认收货按钮
	  confirmReceipt(item,detailItem){
		  var deviceNumber=detailItem?detailItem.deviceNumber:item.deviceNumber;
		  var operationType=detailItem?detailItem.operationType:item.operationType;
		  this.instance({
		  	  method:'post',
		  	  url:'/user/devops/confirmReceipt',
		  	  data:{
				  workOrderId:item.workOrderId,
				  deviceNumber:deviceNumber,
				  operationType:operationType,
			  },
		  	  headers:{
		  		'Content-Type':"application/json;charset=UTF-8",
		  	  }
		  }).then(res=>{
		  	if(res.data.code==1){
		  		this.$message.success(res.data.message);
		  				this.$emit('refreshList');
		  				this.$emit('refreshDetail');
		  	}else{
		  		this.$message(res.data.message);
		  	}
		  });
	  },
	  //提交运维工单信息
	  submitVehicle(index,item){
		  if(!item.deviceNumber){
			  this.$message.error('抱歉，设备编号不能为空！')
			  return false
		  }
		  if(!item.expressNumber){
			  this.$message.error('抱歉，快递单号不能为空！')
			  return false
		  }
		  if(!item.senderName){
			  this.$message.error('抱歉，姓名不能为空！')
			  return false
		  }
		  if(!item.phoneNum){
			  this.$message.error('抱歉，手机号不能为空！')
			  return false
		  }else{
			 if(!this.isCellPhone(item.phoneNum)){
				  this.$message.error('抱歉，请输入正确的手机号！')
				  return false
			 }
		  }
		  if(!item.address){
			  this.$message.error('抱歉，地址不能为空！')
			  return false
		  }
		  item.vehicleId=this.detailForm.vehicleId;
		  item.userId=this.detailForm.userId;
		  this.submitDisabled=true;
		 this.instance({
		 	  method:'post',
		 	  url:'/user/devops/addDevOps',
		 	  data:item,
		 	  headers:{
		 		'Content-Type':"application/json;charset=UTF-8",
		 	  }
		 }).then(res=>{
			 this.submitDisabled=false;
		 	if(res.data.code==1){
		 		this.$message.success(res.data.message);
				this.$emit('refreshList');
				this.$emit('refreshDetail');
				Object.assign(this.$data, this.$options.data());
		 	}else{
		 		this.$message(res.data.message);
		 	}
		 });
	  },
	  //点击删除运维工单  
	  deleteVehicle(index,sign){
		  this.detailForm.operationsWorkOrderVos.splice(index,1);
	  },
	  //阿拉伯数字转汉字
	  numberConversion(index){
		  return index==0?'一':index==1?'二':index==2?'三':index==3?'四':index==4?'五':index==5?'六':index==6?'七':index==7?'八':index==8?'九':'十'
	  },
	  //点击添加用户的确定按钮 
	  submitUserForm(formName){
		  this.$refs[formName].validate((valid) => {
			if (valid) {
			  this.instance({
				  method:'post',
				  url:'/user/updateUser',
				  data:this[formName],
				  headers:{
					'Content-Type':"application/json;charset=UTF-8",
				  }
			  }).then(res=>{
				// console.log(res);
				if(res.data.code==1){
					this.$message.success(res.data.message);
					this.$emit('refreshList');
					 this.$emit('refreshDetail');
				}else{
					this.$message(res.data.message);
				}
			  });
			} else {
			  console.log('error submit!!');
			  return false;
			}
		  });
	  },
	  isCellPhone(val) {
	  		  if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(val)) {
	  			return false;
	  		  } else {
	  			return true;
	  		  }
	  		},
	  beforeClose(done ){
		  // console.log(done)
		  Object.assign(this.$data, this.$options.data());
		  this.$emit('beforeClose');
	  }
  }
}
</script>
<style scoped>
	@import "../assets/css/table_white.css";
	.small_title{
		width: 100%;
		display: flex;
		align-items: center;
		color: #05A9C5;
		font-size: 14px;
		padding-left: 40px;
		margin-bottom: 15px;
	}
	.form_flex{
		width:100%;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}
	.small_title div{
		width:24px;
		margin-right: 0.11rem;
	}
	.null_vehicle{
		margin : 1rem auto 0;
	}
	.null_vehicle div{
		width:1.7rem;
		margin: 0 auto;
	}
	.null_vehicle p{
		color: #828893;
		margin-top:0.15rem;
		text-align: center;
	}
	.dialog_section{
		top:0.5rem;
	}
	.uploade_image{
		width: 1.5rem;
		display: inline-block;
	}
	.vehicle_box{
		border-bottom:1px dashed #999;
		margin-bottom:20px;
	}
	.vehicle_box:last-child{
		border-bottom:none;
		margin-bottom:0;
	}
	/deep/.color_active .el-input__inner{
		color:#409EFF !important;
	}
	/* .form_title{
		border-bottom: 1px solid #F0F2F5;
	}
	.blue_bg{
		display: inline-block;
		background: #F3FDFF;
	} */
</style>
