<template>
    <!-- <div> -->
    <!-- <div class="modal_dialog"> -->
    <div class="dialog_section">
        <div class="content_box">
            <div class="detail_title">
                高负荷工作提醒
                <el-tooltip class="item" effect="dark" placement="top-start">
                    <div slot="content" style="line-height: 1.4">
                        当车辆连续行驶4小时以上时，将被判定为高负荷工作,<br />
                        超过4小时的连续行驶时长统计为高负荷工作时长
                    </div>
                    <div class="question_icon"><img src="../../public/images/msi/question_icon.png" /></div>
                </el-tooltip>
            </div>
            <div class="table_box">
                <el-table ref="multipleTable" :data="tableData2" style="width: 100%">
                    <el-table-column label="序号" type="index" width="50"></el-table-column>
                    <el-table-column prop="remindTime" label="提醒时间"></el-table-column>
                    <el-table-column prop="remindType" label="提醒类型"></el-table-column>
                    <el-table-column label="高负荷工作时段">
                        <template slot-scope="scope">
                            <p>{{ scope.row.remindTime }}</p>
                            <p>到</p>
                            <p>{{ scope.row.endTime }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="duration" label="高负荷工作时长（h）"></el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <div class="pointer">
                                <span @click="openNewPage(scope.row)">查看行程</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="content_box">
            <div class="detail_title">
                行驶时段预警
                <el-tooltip class="item" effect="dark" placement="top-start">
                    <div slot="content" style="line-height: 1.4">
                        早高峰驾驶：车辆在7-9时内行驶过<br />
                        晚高峰驾驶：车辆在17-19时内行驶过<br />
                        夜间驾驶：车辆在0-4时内行驶过
                    </div>
                    <div class="question_icon"><img src="../../public/images/msi/question_icon.png" /></div>
                </el-tooltip>
            </div>
            <div class="table_box">
                <el-table ref="multipleTable" :data="tableData3" style="width: 100%">
                    <el-table-column label="序号" type="index" width="50"></el-table-column>
                    <el-table-column prop="remindTime" label="预警时间"></el-table-column>
                    <el-table-column prop="remindType" label="预警类型"></el-table-column>
                    <el-table-column label="驾驶预警时段">
                        <template slot-scope="scope">
                            <p>{{ scope.row.remindTime }}</p>
                            <p>到</p>
                            <p>{{ scope.row.endTime }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="duration" label="驾驶预警时长（h）"></el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <div class="pointer">
                                <span @click="openNewPage(scope.row)">查看行程</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="content_box">
            <div class="detail_title">危险驾驶提醒</div>
            <div class="table_box">
                <el-table ref="multipleTable" :data="tableData1" style="width: 100%">
                    <el-table-column label="序号" type="index"></el-table-column>
                    <el-table-column prop="t" label="危险驾驶时间"></el-table-column>
                    <el-table-column prop="cType" label="危险驾驶类型"></el-table-column>
                </el-table>
            </div>
        </div>
        <!-- <div v-show="!modeForm.deviceNumber" style="margin-top: 0.8rem;">
					<el-empty description="暂无数据"></el-empty>
				</div> -->
    </div>
    <!-- </div> -->
    <!-- </div> -->
</template>

<script>
export default {
    name: 'WarningDetail',
    props: {
        dialogVisible: Boolean,
        deviceNumber: String
    },
    data() {
        return {
            plateno: '',
            isUpdate: false,
            tableData1: [],
            tableData2: [],
            tableData3: [],
            modeForm: {}
        }
    },
    mounted() {
        this.getTbodyData()
    },
    methods: {
        //点击父组件的查看报警时调用的方法
        initData(readFlag) {
            this.isUpdate = false
            this.tableData1 = []
            this.tableData2 = []
            this.tableData3 = []
            return
        },
        beforeClose(done) {
            this.$emit('beforeClose', this.isUpdate)
        },
        //点击查看行程
        openNewPage(row) {
            let routeUrl = this.$router.resolve({
                path: '/vehicleMap',
                query: {
                    deviceNumber: row.deviceNumber,
                    tripUuid: row.tripUuid,
                    startTime: row.startTime,
                    endTime: row.endTime,
                    routeId: row.tripId
                }
            })
            window.open(routeUrl.href, '_blank')
        },
        //获取tbody里的数据
        getTbodyData() {
            // 车辆行驶时段预警
            this.instance
                .get('/ubi/api/vehicle/fetchDrivingTime', {
                    params: { deviceNumber: this.deviceNumber }
                })
                .then((res) => {
                    let data = res.data
                    if (data.code == 200) {
                        data.data.length > 0 &&
                            data.data.forEach((item) => {
                                item.duration = item.duration > 0 ? item.duration.toFixed(2) : item.duration
                            })
                        this.tableData3 = data.data
                    }
                })

            // 车辆高负荷提醒
            this.instance
                .get('/ubi/api/vehicle/fetchFatiguedDriving', {
                    params: { deviceNumber: this.deviceNumber }
                })
                .then((res) => {
                    let data = res.data
                    if (data.code == 200) {
                        data.data.length > 0 &&
                            data.data.forEach((item) => {
                                item.duration = item.duration > 0 ? item.duration.toFixed(2) : item.duration
                            })
                        this.tableData2 = data.data
                    }
                })

            // 危险驾驶类型
            let instance1 = this.instance.get('/ubi/api/enum/getAlarmTypeEnum')
            // 危险驾驶提醒
            let instance2 = this.instance.get('/ubi/api/vehicle/fetchGPSVo', { params: { deviceNumber: this.deviceNumber } })
            this.$axios.all([instance1, instance2]).then(
                this.$axios.spread((res1, res2) => {
                    if (res1.data.code == 200 && res2.data.code == 200) {
                        res2.data.data.length > 0 &&
                            res2.data.data.forEach((item) => {
                                res1.data.data.forEach((items) => {
                                    if (item.c == items.code) {
                                        item.cType = items.desc
                                    }
                                })
                            })
                        this.tableData1 = res2.data.data
                    }
                })
            )
        }
    }
}
</script>
<style scoped>
@import '../assets/css/table_white.css';
.content {
    position: relative;
}
.detail_title {
    margin: 0 auto 0.2rem;
}
.content_box {
    margin-bottom: 0.2rem;
}
.question_icon {
    width: 0.24rem;
    margin-left: 0.2rem;
}
</style>
