<template>
	<!-- <div v-show="dialogVisible">
		<div class="modal" @click="beforeClose"></div>

	</div> -->
	<el-dialog title="批量添加用户" class="detail_dialog more_dialog" :visible.sync="dialogAddUserMoreVisible" :before-close="beforeClose" :modal-append-to-body="false" width="9.5rem" center>
	   <div class="more_user_box">
		   <div class="down_up">
			   <el-upload
			     class="upload_box"
			     accept=".xls,.xlsx"
			     :action="baseURL+'/user/importUserExcel'"
			     :show-file-list="false"
			     :headers="unloaderHeader"
			     :on-success="handleAvatarSuccess"
			     :before-upload="beforeAvatarUpload">
					<template v-if="fileName">
						<div class="upload_icon"><img src="../../public/images/msi/user/wj-wjsc.png" ></div>
						<div class="upload_text upload_text1">
						   <p>{{fileName}}</p>
						   <div>重新上传</div>
						</div>
					</template>
					<template v-else>
						<div class="upload_icon"><img src="../../public/images/msi/user/shangchuan.png" ></div>
						<div class="upload_text">
						   <p>上传文件</p>
						   <div>支持文件类型：xls、xlsl</div>
						</div>
					</template>
			     </el-upload>
			   </el-upload>
			   <div class="download_box" @click="exportTable">
					<div class="download_icon"><img src="../../public/images/msi/user/xiazai.png" ></div>
					<span>下载excel模板</span>
			   </div>
		   </div>
		   <div class="download_text">
			   <ul>
				   <li>1、请上传用户信息的excel表格；</li>
				   <li>2、上传后，信息全部正确将直接导入；</li>
				   <li>3、上传后，若有错误信息，错误信息将以列表形式展示，请回到Excel表格中修改，修改后请重新上传。</li>
			   </ul>
		   </div>
		   <div v-if="tableData.length>0">
			   <div class="detail_title" style="margin-bottom: 0.1rem;">
				   <b>本次导入结果</b> 
				   <span>（错误信息{{tableData.length}}条）</span>
				</div>
			   <!-- <ul class="import_hint">
				   <li>
					   <span>共导入数据：</span>
					   <b>23条</b>
				   </li>
				   <li>
					   <span>成功：</span>
					   <b>23条</b>
				   </li>
				   <li>
					   <span>有误：</span>
					   <b>23条</b>
				   </li>
			   </ul> -->
			   <el-table class="table_dashed" ref="multipleTable" :data="tableData" style="width: 100%" max-height="240">
			       <el-table-column prop="errorIndex" label="报错位置" ></el-table-column>
			       <el-table-column prop="userName" label="姓名" ></el-table-column>
			       <el-table-column label="手机号" >
					   <template slot-scope="scope">
					   		<span>{{scope.row.userPhone}}</span>
					   </template>
				   </el-table-column>
			       <el-table-column prop="errorMessage" label="错误详情" ></el-table-column>
			   </el-table>
			   <!--分页部分-->
			  <!-- <el-pagination
			           style="margin-top:0.2rem;"
			           @current-change="handleCurrentChange"
			           @size-change="handleSizeChange"
			           :current-page="currentPage"
			           layout="total, sizes, prev, pager, next, jumper"
			           :page-sizes="[5, 10]"
			           :page-size="5"
			           :total="totalPage">
			   </el-pagination> -->
		   </div>
	   </div>
	</el-dialog>
</template>

<script>
import { getToken } from '../utils/auth.js'
export default {
    name: 'BatchAddUser',
    props: {
        dialogAddUserMoreVisible: Boolean,
        detailForm: Object
    },
    data() {
        return {
            // dialogVisible:true
            baseURL: '',
            userDisabled: true,
            unloaderHeader: { token: '' }, //上传的请求头
            fullscreenLoading: false,
            uploadFile: {}, //上传成功后的值
            fileName: '',
            currentPage: 1, //当前页
            pageSize: 5, //一页多少条数据
            totalPage: 1, //总页数tableData:[],
            tableData: []
        }
    },
    mounted() {
        this.baseURL = this.$store.getters.baseURL
    },
    methods: {
        //文件上传成功时的钩子
        handleAvatarSuccess(res, file) {
            this.fullscreenLoading.close()
            this.fileName = file.name
            this.uploadFile = res.data
            console.log(res, file)
            if (res.code == 1) {
                this.$message.success(res.message)
                this.$emit('refreshList')
            } else {
                this.$message.error(res.message)
                this.tableData = res.data
            }
            // this.getTbodyData();
        },
        //上传文件之前的钩子
        beforeAvatarUpload(file) {
            // console.log(file.size/1024);
            this.tableData = []
            if (file.size / 1024 > 5000) {
                this.$message.error('抱歉，上传文件不得大于5M！')
                return false
            }
            this.unloaderHeader.token = getToken()
            this.fullscreenLoading = this.$loading({
                lock: true,
                text: '上传中，请稍后',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
        },
        //点击导出
        exportTable() {
            this.instance
                .get(
                    '/user/downLoad', //接口
                    {
                        responseType: 'blob'
                    }
                )
                .then((res) => {
                    let url = window.URL.createObjectURL(new Blob([res.data]))
                    let link = document.createElement('a')
                    link.style.display = 'none'
                    link.href = url
                    link.setAttribute('download', '用户批量导入模板.xlsx') //指定下载后的文件名，防跳转
                    document.body.appendChild(link)
                    link.click()
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        //分页数据多少条change
        handleSizeChange(val) {
            this.pageSize = val
            this.getTbodyData()
        },
        //分页：当前页改变之后的函数
        handleCurrentChange: function (val) {
            // console.log(val);
            this.currentPage = Number(val)
            this.getTbodyData(this.activityState)
        },
        beforeClose(done) {
            this.fileName = ''
            this.tableData = []
            // console.log(done)
            this.$emit('beforeCloseUser')
        }
    }
}
</script>
<style scoped>
@import '../assets/css/table_white.css';
.color_red {
    color: red;
}
.more_user_box {
    width: 100%;
    margin-top: -10px;
}
.import_hint {
    display: flex;
    padding-left: 0.1rem;
}
.import_hint li {
    margin-right: 1rem;
    font-size: 0.16rem;
}
.import_hint li b {
    color: #05a9c5;
}
.down_up {
    display: flex;
    align-items: flex-end;
}
.upload_box {
    width: 3.8rem;
    /* height: 1.45rem; */
    font-size: 0.16rem;
    color: #828893;
}
.upload_box {
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #05a9c5;
    /* display: flex;
		align-items: center; */
    /* padding: 0.3rem; */
}
/deep/ .el-upload {
    display: flex;
    align-items: center;
    padding: 0.3rem;
}
.download_box {
    color: #05a9c5;
    cursor: pointer;
    /* width: 1.56rem; */
    height: 0.46rem;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #05a9c5;
    margin-left: 0.2rem;
    font-size: 0.16rem;
    color: #05a9c5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.1rem;
}
.download_icon {
    width: 0.28rem;
    margin-right: 0.06rem;
}
.upload_icon {
    width: 0.8rem;
    margin-right: 0.2rem;
}
.upload_text p {
    font-size: 0.2rem;
    color: #051932;
    margin-bottom: 0.1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    white-space: nowrap;
    width: 2.1rem;
}
.upload_text {
    color: #828893;
    text-align: left;
}
.upload_text1 div {
    font-size: 0.2rem;
    color: #05a9c5;
}
.download_text {
    background: #f3fdff;
    border-radius: 4px;
    padding: 0.2rem;
    font-size: 0.14rem;
    text-align: justify;
    line-height: 1.5;
    margin-top: 0.24rem;
    color: #828893;
}
.table_dashed {
    margin-top: 0.2rem;
}
/deep/.el-dialog {
    margin-top: 7vh !important;
    margin-bottom: 0;
    /* margin: 1px 0 0 !important;
		position: absolute;
		top:50%;
		left:50%;
		z-index: 999;
		transform: translate(-50%,-50%); */
}
.detail_title span {
    color: #e4393c;
}
</style>
