<template>
    <div class="content" v-cloak>
        <div class="page_title">租金管理</div>
        <div class="content_box">
            <el-tabs v-model="activeName" @tab-click="handleClickTabs">
                <el-tab-pane label="车辆缴费列表" name="first">
                    <div class="search_box">
                        <el-form :inline="true" :model="navForm1" class="demo-form-inline">
                            <el-form-item>
                                <el-button @click="downloadTemplate" type="primary" size="mini" plain><i class="el-icon-download el-icon--left"></i>模板下载</el-button>
                            </el-form-item>
                            <el-form-item>
                                <!-- accept=".xls,.xlsx,.csv" -->
                                <el-upload class="upload_box" accept=".csv" :action="baseURL + '/ubi/api/vehicle/rent/upload'" :show-file-list="false" :headers="unloaderHeader" :on-remove="handleRemove" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                                    <template>
                                        <el-button type="primary" size="mini"><i class="el-icon-upload el-icon--left"></i>上传车辆缴费列表</el-button>
                                    </template>
                                </el-upload>
                            </el-form-item>
                            <br />
                            <el-form-item label="车牌号/VIN码:">
                                <el-input class="width_150" v-model="navForm1.condition.search" size="small" placeholder="请输入车牌号/VIN码" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="下期缴费时间:" class="dateBox">
                                <el-row style="width: 320px">
                                    <el-col :span="11">
                                        <el-date-picker placeholder="请选择日期" v-model="navForm1.condition.nextPaymentTimeMin" type="date" size="small" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                                    </el-col>
                                    <el-col class="line" style="text-align: center" :span="2">至</el-col>
                                    <el-col :span="11">
                                        <el-date-picker placeholder="请选择日期" v-model="navForm1.condition.nextPaymentTimeMax" type="date" size="small" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                                    </el-col>
                                </el-row>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" size="small" @click="searchTable1" style="margin-left: 0.2rem">查询</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="table_box">
                        <!--table表格部分-->
                        <el-table ref="multipleTable" :data="tableData1" :height="tableHeight" style="width: 100%">
                            <el-table-column type="index" label="序号" width="50"></el-table-column>
                            <el-table-column prop="plateNo" label="车牌号"></el-table-column>
                            <el-table-column prop="prePaymentTime" label="上期缴费时间"></el-table-column>
                            <el-table-column prop="nextPaymentTime" label="下期缴费时间"></el-table-column>
                            <el-table-column prop="userEmail" label="通知记录">
                                <template slot-scope="scope">
                                    <div class="color_a">
                                        <span @click="handleLookNotify(scope.row)">查看</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="通知状态" width="130">
                                <template slot-scope="scope">
                                    <div>
                                        <el-switch :value="scope.row.recordStatus" @change="updateState(scope.row, scope.$index)" active-text="启用" inactive-text="停用" active-color="#409EFF" inactive-color="#C0C0C0"> </el-switch>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                    <div class="color_a">
                                        <span @click="handleLookDetail(scope.row)" style="margin-right: 15px">编辑</span>
                                        <span @click="handleSendNotify(scope.row)">通知</span>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <!--分页部分-->
                        <el-pagination style="margin-top: 0.2rem" @current-change="handleCurrentChange1" :current-page="navForm1.pageNo" layout="total, prev, pager, next, jumper" :page-size="navForm1.pageSize" :total="totalPage1"> </el-pagination>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="缴费通知设置" name="second">
                    <div>
                        <div class="detail_title">缴费周期</div>
                        <div class="form_div">
                            <el-radio-group v-model="notifyForm.paymentCycle">
                                <el-radio :label="1">一个月</el-radio>
                                <el-radio :label="3">三个月</el-radio>
                                <el-radio :label="6">六个月</el-radio>
                                <el-radio :label="12">十二个月</el-radio>
                            </el-radio-group>
                        </div>
                        <div class="detail_title">通知规则</div>
                        <div class="form_div">
                            <span>下期缴费时间前</span>
                            <el-select class="width_90" size="mini" v-model="notifyForm.beforeDay" placeholder="请选择">
                                <el-option label="1" value="1"></el-option>
                                <el-option label="2" value="2"></el-option>
                                <el-option label="3" value="3"></el-option>
                            </el-select>
                            <span>天开始通知，</span>
                            <span>连续通知</span>
                            <el-select class="width_90" size="mini" v-model="notifyForm.alarmDays" placeholder="请选择">
                                <el-option label="1" value="1"></el-option>
                                <el-option label="2" value="2"></el-option>
                                <el-option label="3" value="3"></el-option>
                            </el-select>
                            <!-- <el-input class="width_90" size="mini" clearable type="number" v-model="notifyForm.alarmDays"></el-input> -->
                            <span>天。</span>
                        </div>
                        <div class="detail_title">通知对象</div>
                        <div class="form_div">
                            <template v-if="notifyForm.driver.length == 0 && notifyForm.administrators.length == 0 && notifyForm.others.length == 0">
                                <el-button type="primary" size="small" @click="handleRemindObj">选择通知对象</el-button>
                            </template>
                            <el-button v-if="notifyForm.driver.length > 0" size="small" type="primary" @click="handleRemindObj">驾驶员</el-button>
                            <el-button v-if="notifyForm.administrators.length > 0" size="small" type="primary" @click="handleRemindObj">管理员</el-button>
                            <template v-for="item in notifyForm.others">
                                <el-button type="primary" size="small" @click="handleRemindObj">{{ item }}</el-button>
                            </template>
                        </div>
                        <div class="submit_box">
                            <el-button type="primary" @click="handleSubmit">提 交</el-button>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
        <el-dialog title="选择通知对象" center :visible.sync="innerVisible" destroy-on-close append-to-body :modal-append-to-body="false" width="550px">
            <div class="hint_obj_box">
                <el-form ref="staffsForm" :model="staffsForm">
                    <el-form-item label="驾驶员:">
                        <el-checkbox-group v-model="staffsForm.driver">
                            <el-checkbox border label="1">驾驶员</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="车队管理员:">
                        <el-checkbox-group v-model="staffsForm.administrators">
                            <el-checkbox border label="1">车队管理员</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="其他人员:">
                        <el-select v-model="staffsForm.others" multiple placeholder="请选择" class="width_470" clearable>
                            <el-option v-for="(item, index) in tiggerObjectVo.others" :key="index" :label="item.personName" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="innerVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleSelectStaffs">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="修改上期缴费时间" center :visible.sync="timeDialogVisible" destroy-on-close append-to-body :modal-append-to-body="false" width="500px">
            <div class="time_dialog">
                <!--检索部分-->
                <el-form :inline="true" :model="navForm2" class="demo-form-inline">
                    <el-form-item label="当前时间为:" class="border_none" style="margin-bottom: 10px">
                        <el-input v-model="checkedRow.prePaymentTime" disabled></el-input>
                    </el-form-item>
                    <br />
                    <el-form-item label="修改时间为:" class="dateBox">
                        <el-date-picker placeholder="请选择日期" v-model="navForm3.prePaymentTime" type="date" size="mini" value-format="yyyy-MM-dd" style="width: 180px"> </el-date-picker>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="timeDialogVisible = false" size="small">取 消</el-button>
                    <el-button type="primary" @click="handleEditTime" size="small">确 定</el-button>
                </div>
            </div>
        </el-dialog>
        <el-dialog title="通知记录" center :visible.sync="notifyLogDialogVisible" append-to-body :modal-append-to-body="false" width="12rem">
            <div class="search_box">
                <!--检索部分 -->
                <el-form :inline="true" :model="navForm2" class="demo-form-inline">
                    <el-form-item label="车牌号:" class="border_none">
                        <el-input class="width_150" v-model="navForm2.plateNo" disabled size="small"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="table_box bind_vehicle_dialog">
                <!--table表格部分-->
                <el-table ref="multipleTable" :data="tableData2" style="width: 100%">
                    <el-table-column prop="startTime" label="通知时间"></el-table-column>
                    <el-table-column prop="nextPaymentTime" label="待缴费时间"></el-table-column>
                    <el-table-column prop="alarmName" label="通知类型"></el-table-column>
                </el-table>
                <!--分页部分-->
                <el-pagination style="margin-top: 0.2rem" @current-change="handleCurrentChange2" :current-page="currentPage2" layout="total, prev, pager, next, jumper" :page-size="pageSize2" :total="totalPage2"> </el-pagination>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getToken } from '../../utils/auth.js'
import OSS from 'ali-oss'
import qs from 'qs'
var _this = ''
export default {
    data() {
        return {
            tableHeight: window.innerHeight > 700 ? 350 : 330,
            rid: '',
            activeName: 'first',
            loading: false,
            notifyObjDialogVisible: false,
            fullscreenLoading: false,
            innerVisible: false,
            notifyLogDialogVisible: false,
            timeDialogVisible: false,
            hintObjDialogVisible: false,
            caseExcelData: '',
            baseURL: '',
            unloaderHeader: { Authorization: '' }, //上传的请求头
            uploadFile: {}, //上传成功后的值
            fileName: '',
            batchOptions: [],
            staffsForm: {
                driver: [],
                administrators: [],
                others: []
            },
            navForm1: {
                //储存搜索框的检索条件信息
                condition: {
                    nextPaymentTimeMin: null,
                    nextPaymentTimeMax: null,
                    search: null
                },
                pageNo: 1,
                pageSize: 10
            },
            navForm2: {
                plateNo: ''
            },
            navForm3: {
                prePaymentTime: ''
            },
            notifyForm: {
                paymentCycle: 1,
                alarmDays: 1,
                beforeDay: 1,
                administrators: [],
                driver: [],
                others: [],
                remindModeList: [1], // 短信1，默认值
                remindPersonDto: {
                    otherPerson: [],
                    remindManager: null,
                    remindDriver: null,
                    subTenantIdList: []
                }
            },
            tiggerObjectVo: {}, //提醒对象
            checkedRow: {},
            regionsData: [],
            totalPage1: 1, //总页数,
            tableData1: [],
            currentPage2: 1, //当前页
            pageSize2: 10, //一页多少条数据
            totalPage2: 1, //总页数,
            tableData2: [],
            OSSClient: null
        }
    },
    mounted() {
        _this = this
        this.baseURL = this.$store.getters.baseURL
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
        this.navForm1.companyId = this.userInfo.company
        this.getTbodyData1()
        this.getOthers()
    },
    methods: {
        getOthers() {
            this.instance.get('/ubi/api/person/others').then((res) => {
                let data = res.data
                if (data.code == 200) {
                    this.tiggerObjectVo.others = data.data
                    this.othersObj = {}
                    this.tiggerObjectVo.others.forEach((item) => {
                        this.othersObj[item.id] = item.personName
                    })
                }
            })
        },
        downloadTemplate() {
            this.fullscreenLoading = this.$loading({
                lock: true,
                text: '下载中，请稍后',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            // OSS.urllib是SDK内部封装的发送请求的逻辑，开发者可以使用任何发送请求的库向sts-server发送请求。
            this.instance.get(location.protocol + _this.baseURL + '/ubi/api/aliyun/oss/access').then((res) => {
                let data = res.data
                if (data.code == 200) {
                    this.OSSClient = new OSS({
                        accessKeyId: data.data.accessKeyId,
                        accessKeySecret: data.data.accessKeySecret,
                        stsToken: data.data.securityToken,
                        // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                        region: data.data.region,
                        bucket: data.data.bucket
                    })
                    let url = this.OSSClient.signatureUrl(data.data.path + '/车辆缴费信息模板.csv', {
                        response: {
                            'content-disposition': `attachment; filename=车辆缴费信息模板.csv`
                        }
                    })
                    this.fullscreenLoading.close()
                    window.open(url)
                }
            })
        },
        handleClickTabs() {
            if (this.activeName == 'second') {
                this.instance.get('/ubi/api/vehicleRent/fetch').then((res) => {
                    let data = res.data
                    if (data.code == 200) {
                        var response = data.data
                        !response.driver && (response.driver = [])
                        !response.administrators && (response.administrators = [])
                        !response.notifyPeopleList && (response.notifyPeopleList = [])
                        var arr = []
                        let otherArrId = []
                        if (response.notifyPeopleList.length > 0) {
                            response.notifyPeopleList.forEach((val, key) => {
                                this.tiggerObjectVo.others.forEach((value, index) => {
                                    if (val == value.id) {
                                        arr.push(value.username)
                                        otherArrId.push(value.id)
                                    }
                                })
                            })
                        }
                        response.others = arr
                        this.notifyForm.paymentCycle = response.paymentCycle
                        this.notifyForm.alarmDays = response.alarmDays
                        this.notifyForm.beforeDay = response.beforeDay
                        this.notifyForm.administrators = response.administrators
                        this.notifyForm.driver = response.remindPersonDto.remindDriver ? [true] : []
                        this.notifyForm.remindManager = response.remindPersonDto.remindManager ? [true] : []
                        this.notifyForm.others = response.remindPersonDto.otherPerson ? [true] : []
                    }
                })
            }
        },
        //点击提醒对象
        handleRemindObj() {
            this.staffsForm.others = this.notifyForm.others
            this.staffsForm.administrators = this.notifyForm.administrators
            this.staffsForm.driver = this.notifyForm.driver
            this.innerVisible = true
        },
        //点击缴费通知设置处的提交按钮
        handleSubmit() {
            var json = JSON.parse(JSON.stringify(this.notifyForm))
            if (json.others.length > 0) {
                var arr = []
                json.others.forEach((val, key) => {
                    this.tiggerObjectVo.others.forEach((value, index) => {
                        if (val == value.personName) {
                            arr.push(value.id)
                        }
                    })
                })
                json.others = arr
            }
            json.remindPersonDto.remindManager = json.administrators.length > 0
            json.remindPersonDto.remindDriver = json.driver.length > 0
            json.remindPersonDto.otherPerson = json.others
            const { driver, others, ...newSaveData } = this.notifyForm
            this.instance.post('/ubi/api/vehicleRent/save', newSaveData).then((res) => {
                let data = res.data
                if (data.code == 200) {
                    this.$message.success(data.message)
                } else {
                    this.$message(data.message)
                }
            })
        },
        //点击修改上期缴费时间的确定按钮
        handleEditTime() {
            let data = {
                vehicleRentDto: {
                    id: this.navForm2.id,
                    prePaymentTime: this.navForm3.prePaymentTime
                }
            }
            this.instance.post('/ubi/api/vehicleRent/update', data).then((res) => {
                this.timeDialogVisible = false
                if (res.data.code == 200) {
                    this.getTbodyData1()
                    this.$message({
                        type: 'success',
                        message: res.data.message
                    })
                } else {
                    this.$message({
                        type: 'error',
                        message: res.data.message
                    })
                }
            })
        },
        //点击列表的通知记录
        handleLookNotify(row) {
            this.notifyLogDialogVisible = true
            this.navForm2.plateNo = row.plateNo
            this.getTbodyData2(row)
        },
        //点击选择人员的确定按钮
        handleSelectStaffs() {
            this.notifyForm.driver = this.staffsForm.driver
            this.notifyForm.administrators = this.staffsForm.administrators
            this.innerVisible = false
            if (this.tiggerObjectVo.others.length > 0) {
                this.notifyForm.others = []
                this.staffsForm.others.forEach((item) => {
                    this.notifyForm.others.push(this.othersObj[item])
                })
            }
        },
        //删除文件
        handleRemove(file, fileList) {
            this.caseExcelData = ''
        },
        //文件上传成功时的钩子
        handleAvatarSuccess(res, file) {
            this.fullscreenLoading.close()
            this.fileName = file.name
            this.uploadFile = res.data
            if (res.code == 200) {
                this.$message.success(res.message)
                this.caseExcelData = res.data
                this.navForm1.condition.search = ''
            } else {
                this.$message.error(res.message)
            }
        },
        //上传文件之前的钩子
        beforeAvatarUpload(file) {
            if (file.size / 1024 > 5000) {
                this.$message.error('抱歉，上传文件不得大于5M！')
                return false
            }
            this.unloaderHeader.Authorization = 'Bearer ' + getToken()
            this.fullscreenLoading = this.$loading({
                lock: true,
                text: '上传中，请稍后',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
        },
        //点击车辆缴费列表查询按钮
        searchTable1() {
            this.navForm1.pageNo = 1
            this.getTbodyData1()
        },
        //点击报警列表查询按钮
        searchTable2() {
            this.currentPage2 = 1
            this.getTbodyData2()
        },
        //更新通知状态   1启用-0停用
        updateState(row, index) {
            var _statusName = row.messageStatus ? '停用' : '启用'
            this.$confirm('此操作将' + _statusName + '当前车辆缴费通知, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.instance.post(`/ubi/api/vehicle/rent/update/${row.id}`).then((res) => {
                        let data = res.data
                        if (data.code == 200) {
                            row.recordStatus = !row.recordStatus
                            this.$set(this.tableData1, index, row)
                            this.$message({
                                type: 'success',
                                message: res.data.message
                            })
                        }
                    })
                })
                .catch(() => {})
        },
        //点击操作栏的通知按钮
        handleSendNotify(row) {
            this.$confirm('正在给车牌号为' + row.plateNo + '的车主推送租金催缴短信, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.instance
                        .get('/ubi/api/vehicle/rent/sendNotification', {
                            params: { id: row.id }
                        })
                        .then((res) => {
                            if (res.data.code == 200) {
                                this.$message.success(res.data.data)
                            } else {
                                this.$message.error(res.data.message)
                            }
                        })
                })
                .catch(() => {})
        },
        //点击操作栏编辑
        handleLookDetail(row) {
            this.timeDialogVisible = true
            this.navForm3.prePaymentTime = ''
            this.checkedRow = row
        },
        //获取车辆缴费列表tbody里的数据
        getTbodyData1() {
            this.loading = true
            this.tableData1 = [] //  清空table的值
            this.navForm1.condition.nextPaymentTimeMin ? (this.navForm1.condition.nextPaymentTimeMin = this.navForm1.condition.nextPaymentTimeMin + ' 00:00:00') : ''
            this.navForm1.condition.nextPaymentTimeMax ? (this.navForm1.condition.nextPaymentTimeMax = this.navForm1.condition.nextPaymentTimeMax + ' 00:00:00') : ''
            this.instance.post('/ubi/api/vehicleRent/event/page', this.navForm1).then((res) => {
                let data = res.data
                this.loading = false
                if (data.code == 200) {
                    var tableData1 = data.data.list
                    tableData1.forEach((val, key) => {
                        val.recordStatus = val.recordStatus == 1 ? true : false
                    })
                    this.tableData1 = tableData1
                }
                this.totalPage1 = parseInt(res.data.data.total)
            })
        },
        //获取围栏绑定车辆列表tbody里的数据
        getTbodyData2: function (row) {
            this.loading = true
            this.tableData2 = [] //  清空table的值
            var json = {
                plateNo: row.plateNo
            }
            json.pageNo = this.currentPage2
            json.pageSize = this.pageSize2
            this.instance
                .get('/ubi/api/vehicleRentEvent/page', {
                    params: json
                })
                .then((res) => {
                    this.loading = false
                    if (res.data.code == 1 && res.data.data.data) {
                        this.tableData2 = res.data.data.data
                    }
                    this.totalPage2 = parseInt(res.data.data.total)
                })
        },
        //分页：当前页改变之后的函数
        handleCurrentChange1: function (val) {
            this.navForm1.pageNo = Number(val)
            this.getTbodyData1()
        },
        //分页：当前页改变之后的函数
        handleCurrentChange2: function (val) {
            this.currentPage2 = Number(val)
            this.getTbodyData2()
        }
    }
}
</script>
<style scoped>
@import '../../assets/css/table_white.css';
.form_div {
    padding: 0.3rem 0 0.1rem 0.3rem;
}
.dialog-footer {
    text-align: center;
}
.table_box.bind_vehicle_dialog {
    padding-bottom: 20px;
    margin-top: 0;
}
#container {
    width: 4.5rem;
    height: 3.5rem;
}
.time_dialog {
    padding: 0.1rem 0 0.4rem;
}
#container_map {
    width: 8rem;
    height: 4rem;
    margin: 0 auto;
}
.search_box .el-form--inline .el-form-item {
    margin-bottom: 6px;
}
.time_quantum {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.time_quantum li {
    line-height: 1.4;
    padding: 5px 8px;
    border: 1px solid #666;
    border-radius: 5px;
    margin-right: 0.2rem;
    margin-bottom: 0.2rem;
    cursor: pointer;
}
.time_quantum .active {
    background-color: #409eff;
    color: #fff;
    border: 1px solid #409eff;
}
.add_message {
    width: 6rem;
}
.add_message .el-form-item {
    /* margin-bottom: 18px; */
}
.table_box {
    margin-top: 0.2rem;
}
.risk_event {
    display: flex;
    width: 100%;
    padding: 0 0.2rem;
}
.risk_event div:first-child {
    margin-right: 2rem;
}
.event_item p {
    margin: 0.3rem 0 0.2rem;
    font-size: 0.2rem;
}
.detail_title {
    margin-bottom: 0;
}
.content {
    position: relative;
    height: 100%;
}
.hint_obj_box /deep/.el-form-item__label {
    display: block;
    width: 100%;
    text-align: left;
}
.form_box {
    margin-top: 0.2rem;
    width: 100%;
    display: flex;
}
.form_box .el-form-item {
    /* margin-bottom: 0.2rem; */
}
/deep/.el-dialog__body {
    padding: 0 0.5rem;
}
.dialog_box {
    padding: 0 0 0.5rem;
}
.hint_title {
    margin-top: 0.15rem;
    font-size: #000;
}
.hint_info {
    margin-top: 0.2rem;
    border: 1px solid #c0c0c0;
    padding: 0.15rem 0.3rem;
    min-height: 1rem;
}
</style>
