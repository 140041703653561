<template>
    <div class="content" v-cloak>
        <div class="content_box" v-if="!previewVisible">
            <div class="search_box">
                <!--检索部分-->
                <el-form :inline="true" :model="navForm" class="demo-form-inline">
                    <el-form-item label="车队名称：">
                        <el-input class="width_150" v-model="navForm.fleetId" size="medium" placeholder="请输入消息名称" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="报表周期类型：">
                        <el-select class="width_120" v-model="navForm.periodType" placeholder="请选择" clearable>
                            <el-option label="周报" value="0"></el-option>
                            <el-option label="月报" value="2"></el-option>
                            <el-option label="双周报" value="1"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="城市名称：">
                        <el-select class="width_120" v-model="navForm.regionId" filterable placeholder="请选择" clearable>
                            <el-option v-for="item in regionsOption" :key="item.id" :label="item.regionName" :value="item.id"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="审核展示：">
                        <el-select class="width_120" v-model="navForm.reportStatus" placeholder="请选择" clearable>
                            <el-option label="已公开展示" value="1"></el-option>
                            <el-option label="未公开展示" value="0"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="报表类型：">
                        <el-select class="width_120" v-model="navForm.reportType" placeholder="请选择" clearable>
                            <el-option label="总司专用版" value="0"></el-option>
                            <el-option label="车队专用版" value="1"></el-option>
                            <el-option label="城市专用版" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="开始时间:" class="dateBox">
                        <el-row style="width: 420px">
                            <el-col :span="11">
                                <el-date-picker size="medium" placeholder="请选择日期时间" v-model="navForm.reportTimeMin" type="datetime" value-format="yyyy-MM-dd hh:mm:ss" style="width: 100%"> </el-date-picker>
                            </el-col>
                            <el-col class="line" style="text-align: center" :span="2">至</el-col>
                            <el-col :span="11">
                                <el-date-picker size="medium" placeholder="请选择日期时间" v-model="navForm.reportTimeMax" type="datetime" value-format="yyyy-MM-dd hh:mm:ss" style="width: 100%"> </el-date-picker>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="medium" @click="searchTable" style="margin-left: 0.2rem">查询</el-button>
                        <el-button v-if="reportStatus == -1" type="primary" size="medium" @click="uploadFile" style="margin-left: 0.2rem">上传</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="table_box" ref="tableCot">
                <!--table表格部分-->
                <el-table ref="multipleTable" :data="tableData" v-loading="loading" :height="tableHeight">
                    <el-table-column label="运营车队名称" width="110">
                        <template slot-scope="scope">
                            <el-tooltip v-if="scope.row.fleetName && scope.row.fleetName.length > 8" effect="dark" :content="scope.row.fleetName" placement="top-start">
                                <div>{{ scope.row.fleetName && scope.row.fleetName.slice(0, 7) + '...' }}</div>
                            </el-tooltip>
                            <span v-else>{{ scope.row.fleetName ? scope.row.fleetName : '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="运营城市名称">
                        <template slot-scope="scope">
                            <span>{{ scope.row.regionName ? scope.row.regionName : '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="报表名称" width="160">
                        <template slot-scope="scope">
                            <el-tooltip v-if="scope.row.reportName.length > 10" effect="dark" :content="scope.row.reportName" placement="top-start">
                                <div>{{ scope.row.reportName.slice(0, 9) + '...' }}</div>
                            </el-tooltip>
                            <span v-else>{{ scope.row.reportName ? scope.row.reportName : '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="报表周期类型">
                        <template slot-scope="scope">
                            <span>{{ scope.row.periodType == 0 ? '周报' : scope.row.periodType == 1 ? '双周报' : '月报' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="reportType" label="报表类型">
                        <template slot-scope="scope">
                            <span>{{ scope.row.reportType == 0 ? '总司专用版' : scope.row.reportType == 1 ? '车队专用版' : '城市专用' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="审核展示">
                        <template slot-scope="scope">
                            <span>{{ scope.row.reportStatus == 1 ? '已公开展示' : '未公开展示' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="reportTime" label="报表日期">
                        <template slot-scope="scope">
                            <span>{{ scope.row.reportTime ? scope.row.reportTime.slice(0, 10) : '' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="fileSuffix" label="文件后缀" width="80"></el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <div class="operation_btns">
                                <span v-if="scope.row.fileSuffix == '.pdf'" @click="downloadFile(scope.row, true)">预览</span>
                                <span @click="downloadFile(scope.row)">下载</span>
                                <span v-if="reportStatus == -1" @click="releaseFile(scope.row)">发布</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <!--分页部分-->
                <el-pagination style="margin-top: 0.2rem" @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="currentPage" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="10" :total="total"> </el-pagination>
            </div>
        </div>
        <!-- 预览 -->
        <div class="preview-content" v-if="previewVisible">
            <el-page-header @back="goBack" :content="previewTitle"> </el-page-header>
            <iframe v-if="previewUrl" :src="previewUrl"></iframe>
        </div>
        <!-- 上传弹窗 -->
        <el-dialog title="上传报表" :visible.sync="dialogVisible" width="40%" append-to-body center>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForms" label-width="120px" class="demo-ruleForm">
                <el-form-item label="选择车队：" prop="subTenantId">
                    <el-select v-model="ruleForm.subTenantId" placeholder="请选择" class="width_200" clearable>
                        <el-option v-for="(item, index) in batchIdList" :key="index" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="城市名称：" prop="regionId">
                    <el-select v-model="ruleForm.regionId" filterable placeholder="请选择" clearable>
                        <el-option v-for="item in regionsOption" :key="item.id" :label="item.regionName" :value="item.id"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="报表类型：" prop="reportType">
                    <el-select v-model="ruleForm.reportType" placeholder="请选择活动区域">
                        <el-option label="保司专用版" value="0"></el-option>
                        <el-option label="车队专用版" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="报表周期类型：" prop="periodType">
                    <el-select v-model="ruleForm.periodType" placeholder="请选择活动区域">
                        <el-option label="周报" value="0"></el-option>
                        <el-option label="月报" value="2"></el-option>
                        <el-option label="双周报" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="报表日期：" prop="reportTime">
                    <el-col :span="11">
                        <el-form-item prop="date1">
                            <el-date-picker type="date" placeholder="选择日期" v-model="ruleForm.reportTime" value-format="yyyy-MM-dd hh:mm:ss"></el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item label="上传文件：" prop="fileList">
                    <el-upload ref="upload" class="upload-demo" :action="baseURL + '/task/uploadCaseFile'" :on-change="handleChange" :http-request="ossUpload" :on-preview="handlePictureCardPreview" :multiple="false" :auto-upload="false" :file-list="ruleForm.fileList">
                        <el-button size="small" type="primary">点击上传</el-button>
                        <div slot="tip" class="el-upload__tip">请确认上传文件是否正确</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitForm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getToken } from '../utils/auth.js'
import { v4 as uuidv4 } from 'uuid'
export default {
    name: 'NotificationList',
    components: {},
    props: {
        msg: String,
        reportStatus: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            tableHeight: window.innerHeight > 800 ? 500 : 395,
            previewVisible: false,
            previewTitle: '',
            previewUrl: '',
            regionsOption: [],
            loading: false,
            tableData: [],
            navForm: {
                fleetId: '',
                periodType: '',
                regionId: '',
                reportStatus: '',
                reportType: '',
                reportTimeMin: '',
                reportTimeMax: ''
            },
            currentPage: 1, //当前页
            pageSize: 10,
            total: 0,
            batchIdList: [],
            dialogVisible: false,
            ruleForm: {
                regionId: '',
                reportType: '',
                periodType: '',
                reportTime: '',
                fileList: [],
                fileSuffix: '.txt',
                reportName: '新建 文本文档'
            },
            rules: {
                // subTenantId: [{ required: true, message: '请选择车队', trigger: 'blur' }],
                // regionId: [{ required: true, message: '请选择城市', trigger: 'blur' }],
                reportType: [{ required: true, message: '请选择报表类型', trigger: 'blur' }],
                periodType: [{ required: true, message: '报表周期类型', trigger: 'blur' }],
                reportTime: [{ required: true, message: '请选择日期', trigger: 'change' }]
                // fileList: [{ required: true, message: '请选择文件', trigger: 'change' }]
            },
            baseURL: '',
            dialogImageUrl: '',
            dialogVisibleBigImg: false,
            fullscreenLoading: null,
            tmpFile: null
        }
    },
    mounted() {
        this.getTbodyData()
        this.getRegions()
        this.getBatchIdList()
        this.baseURL = this.$store.getters.baseURL
        this.navForm.rootTenantId = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).tenantId : ''
    },
    methods: {
        submitForm() {
            this.$refs.ruleForms.validate((valid) => {
                if (valid) {
                    // 处理文件名 -> 获取md5 -> 上传文件 -> 上传form表单
                    let tmpFileName = this.tmpFile.name
                    let tmpIndex = tmpFileName.lastIndexOf('.')
                    this.ruleForm.reportName = tmpIndex != -1 ? tmpFileName.slice(0, tmpIndex) : ''
                    this.ruleForm.fileSuffix = tmpIndex != -1 ? tmpFileName.slice(tmpIndex, tmpFileName.length) : ''
                    this.getFileMd5()
                }
            })
        },
        handleChange(file) {
            this.tmpFile = file
        },
        uploadFile() {
            this.dialogVisible = true
        },
        getRegions() {
            this.instance.get('/ubi/api/report/regions').then((res) => {
                let data = res.data
                if (data.code == 200) {
                    data.data.forEach((item) => {
                        item.regionName = item.regionName == '福州市' ? '福建市' : item.regionName
                    })
                    this.regionsOption = data.data
                }
            })
        },
        //获取tbody里的数据
        getTbodyData() {
            this.loading = true
            this.tableData = [] //  清空table的值
            let jsonData = {
                condition: JSON.parse(JSON.stringify(this.navForm)),
                pageSize: this.pageSize,
                pageNo: this.currentPage
            }
            jsonData.condition.reportStatus = this.reportStatus

            this.instance.post('/ubi/api/report/page', jsonData).then((res) => {
                this.loading = false
                let data = res.data
                if (data.code == 200) {
                    this.tableData = data.data.list
                    this.total = data.data.total
                    this.tmpFile = null
                }
            })
        },
        //分页数据多少条change
        handleSizeChange(val) {
            this.pageSize = val
            this.currentPage = 1
            this.getTbodyData()
        },
        //分页：当前页改变之后的函数
        handleCurrentChange(val) {
            this.currentPage = Number(val)
            this.getTbodyData(this.activityState)
        },
        downloadFile(row, previewFlag) {
            this.instance
                .get('/ubi/api/report/file?id=' + row.id, {
                    responseType: 'blob'
                })
                .then((res) => {
                    if (previewFlag) {
                        let url1 = window.URL.createObjectURL(res.data)
                        console.log(url1)
                        this.previewUrl = url1
                        this.previewTitle = row.reportName
                        this.previewVisible = true
                    } else {
                        let url = window.URL.createObjectURL(new Blob([res.data]))
                        let link = document.createElement('a')
                        link.style.display = 'none'
                        link.href = url
                        link.setAttribute('download', row.reportName + row.fileSuffix) //指定下载后的文件名，防跳转
                        document.body.appendChild(link)
                        link.click()
                    }
                })
        },
        releaseFile(row) {
            this.instance.post('/ubi/api/report/changeStatus', { id: row.id, reportStatus: 1 }).then((res) => {
                let data = res.data
                if (data.code == 200) {
                    this.$message.success('发布成功')
                }
            })
        },
        goBack() {
            this.previewVisible = false
        },
        //点击删除按钮
        showInfo(row) {
            this.instance
                .get('/ubi/api/report/file?id=' + row.id, {
                    responseType: 'blob'
                })
                .then((res) => {
                    let url1 = window.URL.createObjectURL(res.data)
                    window.open(url1)
                })
        },
        //点击查询
        searchTable() {
            this.currentPage = 1
            this.getTbodyData()
        },
        //索赔资料处照片点击文件列表中已上传的文件时的钩子
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url
            this.dialogVisibleBigImg = true
        },
        //OSS直传
        async ossUpload(file) {
            var _this = this
            const OSS = require('ali-oss')
            let storeAs = 'upload-file'

            this.instance.get('/ubi/api/aliyun/oss/access').then((res) => {
                try {
                    var result = res.data.data
                } catch (e) {
                    console.log('parse sts response info error: ' + e.message)
                }

                let client = new OSS({
                    accessKeyId: result.accessKeyId,
                    accessKeySecret: result.accessKeySecret,
                    stsToken: result.securityToken,
                    // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                    region: result.region,
                    bucket: result.reportBucket
                })

                this.tmpMd5 && client.put(this.tmpMd5, file.raw).then((result) => {})

                return
                client
                    .multipartUpload(storeAs, file)
                    .then(function (result) {
                        // 处理文件名
                        let tmpFileName = file.name
                        let tmpIndex = tmpFileName.lastIndexOf('.')
                        _this.ruleForm.reportName = tmpIndex != -1 ? tmpFileName.slice(0, tmpIndex) : ''
                        _this.ruleForm.fileSuffix = tmpIndex != -1 ? tmpFileName.slice(tmpIndex, tmpFileName.length) : ''

                        _this.fullscreenLoading.close()
                    })
                    .catch(function (err) {
                        console.log(err)
                    })
            })
        },
        //获取车队
        getBatchIdList() {
            this.instance.get('/ubi/api/tenant/fleetList').then((res) => {
                let data = res.data
                if (data.code == 200) {
                    this.batchIdList = data.data
                }
            })
        },
        getFileMd5() {
            this.instance.post('/ubi/api/report/genFileMD5', this.ruleForm).then((res) => {
                let data = res.data
                if (data.code == 200) {
                    this.tmpMd5 = data.data
                    this.ossUpload(this.tmpFile)
                    this.uploadReportFile()
                }
            })
        },
        uploadReportFile() {
            const _this = this
            this.instance.post('/ubi/api/report/upload', this.ruleForm).then((res) => {
                let data = res.data
                if (data.code == 200) {
                    this.$message.success('上传成功')
                    this.getTbodyData()
                    setTimeout(() => {
                        _this.dialogVisible = false
                        _this.$refs.ruleForms.resetFields()
                    }, 1500)
                }
            })
        }
    }
}
</script>
<style scoped>
/* @import '../../assets/css/table_white.css'; */

.content {
    position: relative;
    height: 95%;
}
.content_box {
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(223, 223, 223, 0.5);
    border-radius: 6px;
    border: 1px solid #ececec;
    padding: 0.2rem;
}

.hint_obj_box /deep/ .el-form-item__label {
    display: block;
    width: 100%;
    text-align: left;
}

.form_box {
    margin-top: 0.2rem;
}

.form_box .el-form-item {
    /* margin-bottom: 0.2rem; */
}

/deep/ .el-dialog__body {
    padding: 0 0.5rem;
}

.dialog_box {
    padding: 0 0 0.5rem;
}
.preview-content {
    height: 97%;
}
.preview-content iframe {
    width: 100%;
    height: 100%;
    margin-top: 10px;
}
.operation_btns span {
    color: #409eff;
    margin: 0 5px;
    cursor: pointer;
}
.width_120 {
    width: 120px;
}
</style>