<template >
    <div class="content">
        <div class="page_title">事故车辆跟踪</div>
        <div class="content_box">
            <div class="page_card page-card_bottom">
                <!--检索部分-->
                <div style="margin-bottom: 20px">
                    <el-upload class="upload_box" accept=".csv,.xls,.xlsx" action="/manager/ubi/api/badDriving/accidentRecords/upload" :show-file-list="false" :headers="unloaderHeader" :before-upload="beforeUpload" :on-success="onUploadSuccess">
                        <template>
                            <el-button type="primary" size="large" v-loading="loading"><i class="el-icon-upload el-icon--left"></i>上传保险车辆数据</el-button>
                        </template>
                    </el-upload>
                </div>
                <el-form :inline="true" :model="navForm" class="search-form-inline">
                    <el-form-item label="选择分公司:" prop="subTenantId">
                        <el-select class="width_180" v-model="navForm.condition.subTenantId" placeholder="请选择" size="small" clearable @change="loadFleetList">
                            <el-option v-for="(item, index) in companyList" :key="index" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="选择车队:" prop="fleetId">
                        <el-select class="width_150" v-model="navForm.condition.fleetId" placeholder="请选择" size="small" clearable>
                            <el-option v-for="(item, index) in fleetList" :key="index" :label="item.customerId" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="出险日期：" class="search-form_item">
                        <el-date-picker v-model="rangeDate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"> </el-date-picker>
                    </el-form-item>
                    <el-form-item class="search-form_item">
                        <el-button type="primary" @click="loadAccidentRecords">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="page_card">
                <el-table :data="tableData" style="width: 100%" v-loading="loading">
                    <el-table-column type="index" width="50" label="序号"> </el-table-column>
                    <el-table-column prop="plateNo" label="车牌号" width="80"> </el-table-column>
                    <el-table-column prop="vin" label="车辆VIN码" width="90"> </el-table-column>
                    <el-table-column prop="deviceNumber" label="设备编号"> </el-table-column>
                    <el-table-column prop="companyName" label="所属分公司"> </el-table-column>
                    <el-table-column prop="fleetName" label="所属车队"> </el-table-column>
                    <el-table-column prop="accidentNum" label="出险次数"> </el-table-column>
                    <el-table-column prop="lastAccidentTime" label="最近出险时间">
                        <template slot-scope="scope">
                            <span>{{ (scope.row.lastAccidentTime && moment(scope.row.lastAccidentTime).format('YYYY-MM-DD')) || '--' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="drivingScore" label="驾驶评分"> </el-table-column>
                    <el-table-column prop="tipText" label="危险提示">
                        <template slot-scope="scope">
                            <span style="color: red">{{ scope.row.tipText || '事故车辆' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="endCheckTime" label="解除跟踪日期">
                        <template slot-scope="scope">
                            <span>{{ (scope.row.endCheckTime && moment(scope.row.endCheckTime).format('YYYY-MM-DD')) || '未解除' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="100" style="text-align: center">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="showAccidentDetail(scope.row)">事故详情</el-button><br />
                            <el-button type="text" size="small" @click="handleLookArea(scope.row)">车辆轨迹</el-button><br />
                            <el-button type="text" size="small" @click="link2TrackingAnalysis(scope.row)">驾驶行为跟踪</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <!--分页部分-->
                <el-pagination style="margin-top: 0.2rem" @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="navForm.pageNo" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="navForm.pageSize" :total="total"> </el-pagination>
            </div>
            <!-- 车辆轨迹 -->
            <el-dialog :visible.sync="areaDialogVisible" title="车辆实时位置" :before-close="handleDialogClose" center :modal-append-to-body="false" width="900px">
                <el-descriptions title="">
                    <el-descriptions-item label="设备编号">{{ detailForm.deviceNumber }}</el-descriptions-item>
                    <el-descriptions-item label="车牌号">{{ detailForm.plateNo }}</el-descriptions-item>
                    <el-descriptions-item label="最近定位时间">{{ detailForm.lastDataTime }}</el-descriptions-item>
                    <el-descriptions-item label="设备状态">{{ detailForm.onlineStatus }}</el-descriptions-item>
                    <el-descriptions-item label="插拔状态">{{ detailForm.plugginStatus }}</el-descriptions-item>
                    <el-descriptions-item v-if="detailForm.plugginStatus == '插入'" :label="detailForm.plugginStatus == '插入' ? '插入时间' : '拔出时间'">{{ detailForm.lastInsetTime }}</el-descriptions-item>
                    <el-descriptions-item v-if="detailForm.plugginStatus == '拔出'" :label="detailForm.plugginStatus == '插入' ? '插入时间' : '拔出时间'">{{ detailForm.lastPulloutTime }}</el-descriptions-item>
                </el-descriptions>
                <!-- 地图展示 -->
                <div class="dialog_map" v-loading="polylineLoading">
                    <baidu-map v-if="!polylineLoading" :zoom="zoom" id="container" @ready="handler" :center="center" :max-zoom="22" :mapClick="false" :scroll-wheel-zoom="true">
                        <bm-marker :position="positionPoint" :icon="positionIcon" @click="lookDetail()">
                            <bm-info-window :position="{ lng: 70, lat: 60 }" :show="marker.showFlag" @close="infoWindowClose(marker)" @open="infoWindowOpen(marker)">
                                <p>经度: {{ detailForm.lastDataLongitude }}</p>
                                <p>维度:{{ detailForm.lastDataLatitude }}</p>
                                <p>定位位置:{{ detailForm.lastAddress }}</p>
                                <p>定位时间:{{ detailForm.lastDataTime }}</p>
                            </bm-info-window>
                        </bm-marker>
                    </baidu-map>
                </div>
            </el-dialog>
            <el-dialog class="dialog-title_style" :visible.sync="accidentDetailVisible" width="90%" title="事故详情" :modal-append-to-body="false" v-loading="accidentDetailLoading">
                <el-row>
                    <el-col :span="6" style="text-align: center">
                        <div class="index_style">最近一年出险次数</div>
                        <div class="index_style">
                            <span class="big_num">{{ accidentDetail.accidentNumOfLastYear }}</span
                            ><span>次</span>
                        </div>
                    </el-col>
                    <el-col :span="6" style="text-align: center">
                        <div class="index_style">总出险次数</div>
                        <div class="index_style">
                            <span class="big_num">{{ accidentDetail.accidentSum }}</span
                            ><span>次</span>
                        </div>
                    </el-col>
                    <el-col :span="6" style="text-align: center">
                        <div class="index_style">车龄</div>
                        <div class="index_style">
                            <span class="big_num">{{ accidentDetail.vehicleAge && accidentDetail.vehicleAge.toFixed(2) }}</span
                            ><span>年</span>
                        </div>
                    </el-col>
                    <el-col :span="6" style="text-align: center">
                        <div class="index_style">年均出险次数</div>
                        <div class="index_style">
                            <span class="big_num">{{ accidentDetail.accidentNumOfAvgYear && accidentDetail.accidentNumOfAvgYear.toFixed(2) }}</span
                            ><span>次</span>
                        </div>
                    </el-col>
                </el-row>
                <br /><br />
                <template v-for="(accident, index) in accidentDetail.accidentRecords">
                    <el-row>
                        <el-col :span="6">
                            <div class="title_bar">| 出险时间：{{ (accident.insuranceDate && moment(accident.insuranceDate).format('YYYY.MM.DD')) || '--' }}</div>
                        </el-col>
                        <el-col :span="18">
                            <div class="title_bar">数据导入时间：{{ (accident.createTime && moment(accident.createTime).format('YYYY.MM.DD')) || '--' }}</div>
                        </el-col>
                    </el-row>
                    <el-row class="accident_detail">
                        <el-col :span="6"
                            ><el-row
                                ><el-col :span="8" style="text-align: right">我方车架号：</el-col><el-col :span="16">{{ accident.vin }}</el-col></el-row
                            ></el-col
                        >
                        <el-col :span="6"
                            ><el-row
                                ><el-col :span="14" style="text-align: right">我方车牌号：</el-col><el-col :span="10">{{ accident.plateNo }}</el-col></el-row
                            ></el-col
                        >
                        <el-col :span="6"
                            ><el-row
                                ><el-col :span="12" style="text-align: right">案件类型：</el-col><el-col :span="12">{{ accident.caseType }}</el-col></el-row
                            ></el-col
                        >
                        <el-col :span="6"
                            ><el-row
                                ><el-col :span="12" style="text-align: right">我方责任：</el-col><el-col :span="12">{{ accident.responsibility }}</el-col></el-row
                            ></el-col
                        >
                    </el-row>
                    <el-row class="accident_detail">
                        <el-col :span="6"
                            ><el-row
                                ><el-col :span="8" style="text-align: right">报案号：</el-col><el-col :span="16">{{ accident.caseNo }}</el-col></el-row
                            ></el-col
                        >
                        <el-col :span="6">
                            <el-row>
                                <el-col :span="14" style="text-align: right">出险时间：</el-col>
                                <el-col :span="10">{{ accident.insuranceDate && accident.insuranceDate.substr(0, 10) }}</el-col>
                            </el-row>
                        </el-col>
                        <el-col :span="6">
                            <el-row>
                                <el-col :span="12" style="text-align: right">报案时间：</el-col>
                                <el-col :span="12">{{ accident.reportDate && accident.reportDate.substr(0, 10) }}</el-col>
                            </el-row>
                        </el-col>
                        <el-col :span="6">
                            <el-row>
                                <el-col :span="12" style="text-align: right">延迟报案时间：</el-col>
                                <el-col :span="12">{{ accident.insuranceReportDiffDate || '-' }}</el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                    <el-row class="accident_detail">
                        <el-col :span="6"
                            ><el-row
                                ><el-col :span="8" style="text-align: right">保司名称：</el-col><el-col :span="16">{{ accident.insuranceCompany }}</el-col></el-row
                            ></el-col
                        >
                        <el-col :span="6"
                            ><el-row
                                ><el-col :span="14" style="text-align: right">理赔险种：</el-col><el-col :span="10">{{ accident.claimsInsurance }}</el-col></el-row
                            ></el-col
                        >
                        <el-col :span="6"
                            ><el-row
                                ><el-col :span="12" style="text-align: right">我方驾驶员姓名：</el-col><el-col :span="12">{{ accident.driverName || '-' }}</el-col></el-row
                            ></el-col
                        >
                        <el-col :span="6"
                            ><el-row
                                ><el-col :span="12" style="text-align: right">对方车牌：</el-col><el-col :span="12">{{ accident.otherPlateNo }}</el-col></el-row
                            ></el-col
                        >
                    </el-row>
                    <el-row class="accident_detail">
                        <el-col :span="6"
                            ><el-row
                                ><el-col :span="8" style="text-align: right">交商案件状态：</el-col><el-col :span="16">{{ accident.comprehensiveInsuranceStatus }}</el-col></el-row
                            ></el-col
                        >
                        <el-col :span="6">
                            <el-row>
                                <el-col :span="14" style="text-align: right">交商已决案件结案时间：</el-col>
                                <el-col :span="10">{{ accident.comprehensiveInsuranceEndTime && accident.comprehensiveInsuranceEndTime.substr(0, 10) }}</el-col>
                            </el-row>
                        </el-col>
                        <el-col :span="6">
                            <el-row>
                                <el-col :span="12" style="text-align: right">交商已决总金额：</el-col>
                                <el-col :span="12">{{ accident.comprehensiveInsuranceDecidedAmount || '-' }}</el-col>
                            </el-row>
                        </el-col>
                        <el-col :span="6">
                            <el-row>
                                <el-col :span="12" style="text-align: right">交商未决总金额：</el-col>
                                <el-col :span="12">{{ accident.comprehensiveInsuranceUndecidedAmount || '-' }}</el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                    <el-row class="accident_detail">
                        <el-col :span="6">
                            <el-row>
                                <el-col :span="8" style="text-align: right">交强险状态：</el-col>
                                <el-col :span="16">{{ accident.compulsoryInsuranceStatus }}</el-col>
                            </el-row>
                        </el-col>
                        <el-col :span="6">
                            <el-row>
                                <el-col :span="14" style="text-align: right">交强险已决案件结案时间：</el-col>
                                <el-col :span="10">{{ accident.compulsoryInsuranceEndTime && accident.compulsoryInsuranceEndTime.substr(0, 10) }}</el-col>
                            </el-row>
                        </el-col>
                        <el-col :span="6">
                            <el-row>
                                <el-col :span="12" style="text-align: right">交强险已决总金额：</el-col>
                                <el-col :span="12">{{ accident.compulsoryInsuranceDecidedAmount || '-' }}</el-col>
                            </el-row>
                        </el-col>
                        <el-col :span="6">
                            <el-row>
                                <el-col :span="12" style="text-align: right">交强险未决总金额：</el-col>
                                <el-col :span="12">{{ accident.compulsoryInsuranceUndecidedAmount || '-' }}</el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                    <el-row class="accident_detail">
                        <el-col :span="6">
                            <el-row>
                                <el-col :span="8" style="text-align: right">商业险状态：</el-col>
                                <el-col :span="16">{{ accident.vehicleInsuranceStatus }}</el-col>
                            </el-row>
                        </el-col>
                        <el-col :span="6">
                            <el-row>
                                <el-col :span="14" style="text-align: right">商业险已决案件结案时间：</el-col>
                                <el-col :span="10">{{ accident.vehicleInsuranceEndTime && accident.vehicleInsuranceEndTime.substr(0, 10) }}</el-col>
                            </el-row>
                        </el-col>
                        <el-col :span="6">
                            <el-row>
                                <el-col :span="12" style="text-align: right">商业险已决总金额：</el-col>
                                <el-col :span="12">{{ accident.vehicleInsuranceDecidedAmount || '-' }}</el-col>
                            </el-row>
                        </el-col>
                        <el-col :span="6">
                            <el-row>
                                <el-col :span="12" style="text-align: right">商业险未决总金额：</el-col>
                                <el-col :span="12">{{ accident.vehicleInsuranceUndecidedAmount || '-' }}</el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                    <el-row class="accident_detail">
                        <el-col :span="24">
                            <el-row>
                                <el-col :span="2" style="text-align: right">事故地点：</el-col>
                                <el-col :span="22">{{ accident.accidentAddress || '-' }}</el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                    <el-row class="accident_detail">
                        <el-col :span="24">
                            <el-row>
                                <el-col :span="2" style="text-align: right">事故经过：</el-col>
                                <el-col :span="22">
                                    <div class="accident_content">{{ accident.accidentDetail || '-' }}</div>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                    <br />
                </template>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import { getToken } from '../../utils/auth.js';

export default {
    data() {
        return {
            loading: false,
            accidentDetailVisible: false,
            accidentDetailLoading: false,
            areaDialogVisible: false,
            polylineLoading: false,
            center: { lng: 116.404269, lat: 39.915378 },
            zoom: 18,
            BDmap: '',
            BMap: '',
            detailForm: {},
            positionPoint: {},
            positionIcon: {},
            marker: { showFlag: false },
            navForm: {
                //储存搜索框的检索条件信息
                condition: {
                    subTenantId: null,
                    fleetId: null,
                    startAccidentTime: null,
                    endAccidentTime: null,
                    recordType: 1
                },
                orders: [],
                pageNo: 1,
                pageSize: 20
            },
            rangeDate: [],
            companyList: [],
            fleetList: [],
            tableData: [],
            total: 0,
            currentUser: {
                userTeantType: 2
            },
            accidentDetail: {
                accidentNumOfLastYear: 0,
                accidentSum: 0,
                vehicleAge: 0,
                accidentNumOfAvgYear: 0,
                accidentRecords: []
            },
            unloaderHeader: {
                Authorization: ''
            },
            userInfo: null
        };
    },
    mounted() {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.loadCompanyList();
        this.getCurrentUser();
        this.loadAccidentRecords();
    },
    methods: {
        link2TrackingAnalysis(row) {
            this.$router.push({
                path: '/application/trackingAnalysis',
                query: {
                    search: row.vin,
                    ruleType: 1
                }
            });
        },
        showAccidentDetail(row) {
            this.accidentDetailVisible = true;
            this.accidentDetailLoading = true;
            this.accidentDetail.accidentNumOfLastYear = 0;
            this.accidentDetail.accidentSum = 0;
            this.accidentDetail.accidentNumOfAvgYear = 0;
            this.accidentDetail.vehicleAge = 0;
            var _this = this;
            this.instance.get('/ubi/api/badDriving/accidentsDetail?vin=' + row.vin).then(({ data }) => {
                console.log(data);
                _this.accidentDetailLoading = false;
                _this.accidentDetail = { ...data.data };
            });
        },
        beforeUpload() {
            this.unloaderHeader.Authorization = 'Bearer ' + getToken();
            this.fullscreenLoading = this.$loading({
                lock: true,
                text: '上传中，请稍后',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            //this.loading = true;
        },
        onUploadSuccess(res) {
            this.fullscreenLoading.close();
            setTimeout(() => this.loadAccidentRecords(), 100);
            if (res.code == 200) {
                if (res.data.resultCode == 0) {
                    this.$message({ message: res.data.message, type: 'success' });
                }
                if (res.data.resultCode == 1) {
                    const h = this.$createElement;
                    const items = new Array();
                    items.push(h('p', { style: 'color:red' }, '以下纪录与系统中已有信息重复，请核实!'));
                    for (var i = 0; i < res.data.repeatList.length; i++) {
                        const record = res.data.repeatList[i];
                        var text = '车架号：' + record.vin + '，出险日期：' + this.moment(record.insuranceDate).format('YYYY/MM/DD');
                        items.push(h('p', null, text));
                    }

                    items.push(h('p', null, ''));
                    this.$msgbox({
                        message: h('p', null, items),
                        showCancelButton: true,
                        confirmButtonText: '覆盖已有记录',
                        cancelButtonText: '取消导入',
                        beforeClose: (action, instance, done) => {
                            if (action == 'cancel') {
                                this.$message({ message: '已取消导入重复的记录！', type: 'warn' });
                                done();
                            } else {
                                instance.confirmButtonLoading = true;
                                instance.cancelButtonLoading = true;
                                this.instance.post('/ubi/api/badDriving/repeatAccidentRecords/overwrite', res.data.repeatList).then(({ data }) => {
                                    if (data.code == 200) {
                                        this.$message({ message: '导入成功！原有记录已覆盖！', type: 'success' });
                                        instance.confirmButtonLoading = false;
                                        instance.cancelButtonLoading = false;
                                        setTimeout(() => this.loadAccidentRecords(), 100);
                                        done();
                                    }
                                });
                            }
                        }
                    });
                }
            } else {
                this.$alert('您上传的车辆保险数据文件格式不正确，无法解析！请重新上传！', '错误', { confirmButtonText: '确定' });
            }
        },
        loadAccidentRecords() {
            this.loading = true;
            const queryParams = JSON.parse(JSON.stringify(this.navForm));
            if (this.rangeDate && this.rangeDate.length > 0) {
                queryParams.condition.startAccidentTime = this.rangeDate[0];
                queryParams.condition.endAccidentTime = this.rangeDate[1];
            }
            var _this = this;
            this.instance.post('/ubi/api/badDriving/searchVehicleRecord', queryParams).then(({ data }) => {
                this.loading = false;
                if (data.code == 200) {
                    _this.tableData = data.data.list;
                    _this.total = data.data.total;
                } else {
                    _this.total = 0;
                }
            });
        },
        handleCurrentChange(val) {
            this.navForm.pageNo = val;
            this.loadAccidentRecords();
        },
        handleSizeChange(val) {
            this.navForm.pageSize = val;
            this.loadAccidentRecords();
        },
        //获取车队
        loadFleetList(id) {
            this.navForm.condition.fleetId = null;
            if (id) {
                this.instance.get('/ubi/api/tenant/fleetList?companyId=' + id).then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.fleetList = data.data;
                    }
                });
            } else {
                this.fleetList = [];
            }
        },
        //获取分公司
        loadCompanyList() {
            this.instance.get('/ubi/api/tenant/companyList').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.companyList = data.data;
                }
            });
        },
        getCurrentUser() {
            this.instance.get('/ubi/api/tenant/currentUser').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.currentUser = res.data;
                }
            });
        },
        // 点击车辆轨迹
        handleLookArea(row) {
            this.areaDialogVisible = true;
            // this.areaForm.condition.deviceNumber = row.deviceNumber
            // this.areaForm.condition.platNo = row.platNo
            this.getVehicleInfo(row.deviceNumber);
        },
        // 查看区域dialog关闭时的事件
        handleDialogClose() {
            this.areaDialogVisible = false;
        },
        // 点击点坐标赋值
        lookDetail() {
            this.marker.showFlag = true;
        },
        // 关闭弹窗
        infoWindowClose() {
            this.marker.showFlag = false;
        },
        // 打开弹窗
        infoWindowOpen() {
            this.marker.showFlag = true;
        },
        handler({ BMap, map }) {
            this.BMap = BMap;
            this.BDmap = map;
        },
        // 车辆实时位置
        getVehicleInfo(rowDeviceNumber) {
            this.polylineLoading = true;
            this.instance.get(`/ubi/api/device/location?deviceNumber=${rowDeviceNumber}`).then((res) => {
                this.polylineLoading = false;
                let data = res.data;
                if (data.code == 200) {
                    this.detailForm = data.data;
                    // 测试数据，测试环境没有这些字段，没啥强迫症别删
                    // this.detailForm.lastDataLongitude = 103.860691
                    // this.detailForm.lastDataLatitude = 30.646956
                    // this.detailForm.lastAddress = '四川省成都市双流区彭柑路'
                    // this.detailForm.lastDataTime = '2023-04-19 17:58:20'
                    // 车辆图标
                    this.positionIcon = {
                        url: require('../../../public/images/msi/online/car_icon.png'),
                        size: {
                            width: 24,
                            height: 46
                        },
                        opts: {
                            anchor: {
                                width: 24,
                                height: 46
                            }
                        }
                    };
                    // 车辆坐标
                    this.positionPoint = {
                        lng: data.data.bdLon,
                        lat: data.data.bdLat
                    };
                    // 地图中心点
                    this.center.lng = data.data.bdLon;
                    this.center.lat = data.data.bdLat;
                }
            });
        }
    }
};
</script>
<style scoped>
@import '../../assets/css/table_white.css';
@import '../../assets/css/public.css';

.page_content {
    width: 100%;
    height: 100%;
    overflow: auto;
}
.content {
    position: relative;
    height: 100%;
}
.content_box {
    height: 96%;
    overflow-y: scroll;
}
.page_none {
    background: #fff;
}
.big_num {
    font-size: 48px;
    font-weight: bold;
    color: darkturquoise;
}
.index_style {
    font-size: 24px;
    font-weight: bold;
    height: 60px;
    text-align: center;
    vertical-align: middle;
}
.title_bar {
    padding-left: 20px;
    line-height: 40px;
    font-size: 16px;
    font-weight: bold;
    background-color: #e5e9f2;
}
.accident_detail {
    line-height: 40px;
    font-size: small;
}
.accident_content {
    width: 70%;
    margin-top: 10px;
    padding: 10px 10px 20px 30px;
    border-style: solid;
    border-radius: 4px;
    border-width: 1px;
    border-color: rgba(156, 15, 15, 0.1);
    line-height: normal;
}
.dialog_map {
    height: 500px;
}
.dialog_map > p {
    font-size: 16px;
    margin-bottom: 10px;
}
#container,
#conta {
    width: 100%;
    height: 500px;
}
#container /deep/ .BMap_pop {
    left: 264px !important;
    top: 90px !important;
}
#container /deep/ .BMap_pop p {
    line-height: 18px;
}
#container /deep/ .BMap_pop p:nth-child(3) {
    display: inline-block;
    width: 300px;
}
</style>