<template>
    <div class="dialog_section detail-content">
        <div class="detail_title" style="margin-top: 0.1rem">基础详情</div>
        <ul class="detail_form">
            <li>
                <span>设备号：</span>
                <b>{{ detailForm.deviceNumber }}</b>
            </li>
            <li>
                <span>绑定状态：</span>
                <b>{{ detailForm.bindStatus == 1 ? '已绑定' : '未绑定' }}</b>
            </li>
            <li>
                <span>激活状态：</span>
                <b>{{ detailForm.activationStatus == 1 ? '已激活' : '未激活' }}</b>
            </li>
            <li>
                <span>设备状态：</span>
                <b>{{ detailForm.onlineStatus == 1 ? '在线' : detailForm.onlineStatus == 2 ? '离线' : '--' }}</b>
            </li>
            <!-- <li>
						<span>插拔状态：</span>
						<b>{{detailForm.plugStatus==0?'插入':'拔出'}}</b>
					</li> -->
        </ul>
        <div class="detail_title">绑定车辆信息</div>
        <ul class="detail_form">
            <li>
                <span>车牌号：</span>
                <b>{{ detailForm.plateNo }}</b>
            </li>
            <li style="min-width: 25%">
                <span>车架号：</span>
                <b>{{ detailForm.vin }}</b>
            </li>
            <li>
                <span>设备绑定时间：</span>
                <b>{{ detailForm.activationTime }}</b>
            </li>
        </ul>
        <div class="detail_title">设备发放机构</div>
        <ul class="detail_form">
            <li>
                <b>{{ detailForm.sendDeviceOrg }}</b>
            </li>
        </ul>
        <div class="detail_title">行程数据</div>
        <ul class="detail_form">
            <li>
                <span>行程数量：</span>
                <b>{{ detailForm.totalTripNum }}次</b>
            </li>
            <li>
                <span>行程总里程：</span>
                <b>{{ detailForm.totalMileage }}km</b>
            </li>
            <li>
                <span>行程总时长：</span>
                <b>{{ detailForm.totalDuration }}h</b>
            </li>
            <li>
                <span>总报警次数（三急一超）：</span>
                <b>{{ Math.ceil(detailForm.totalAlarm / 1) }}次</b>
            </li>
        </ul>
        <div class="detail_title">
            插拔记录
            <span>（仅展示最近十条记录）</span>
        </div>
        <div class="table_box">
            <el-table ref="multipleTable" :data="detailForm.alarmRecords" style="width: 100%">
                <el-table-column label="序号" type="index" width="50"></el-table-column>
                <el-table-column prop="alarmName" label="插拔操作"></el-table-column>
                <el-table-column prop="time" label="插拔时间"></el-table-column>
                <!-- <el-table-column prop="lon" label="参考经度（东经）"></el-table-column> -->
                <!-- <el-table-column prop="lat" label="参考纬度（北纬）"></el-table-column> -->
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DeviceDetail',
    props: {
        detailForm: Object
    },
    data() {
        return {
            tableData: []
        }
    },
    mounted() {},
    methods: {
        beforeClose(done) {
            this.$emit('beforeClose')
        }
    }
}
</script>
<style scoped>
@import '../assets/css/table_white.css';
.detail-content {
    height: 400px;
}
</style>
