<template>
    <div class="content" v-cloak>
        <div class="page_title">消息设置</div>
        <div class="content_box overlength_content">
            <el-tabs v-model="activeName">
                <!--                <el-tab-pane label="提醒内容管理" name="first">-->
                <!-- <div class="risk_event">
                      <div>
                        <div class="detail_title" style="margin-top: 0.1rem;">高危风险事件</div>
                        <div class="event_item">
                          <p>疲劳驾驶</p>
                          <el-row>
                            <el-button type="primary" size="small">短信模板设置</el-button>
                            <el-button type="primary" size="small">AI电话模板设置</el-button>
                          </el-row>
                        </div>
                        <div class="event_item">
                          <p>急变速</p>
                          <el-row>
                            <el-button type="primary" size="small">短信模板设置</el-button>
                            <el-button type="primary" size="small">AI电话模板设置</el-button>
                          </el-row>
                        </div>
                      </div>
                      <div>
                        <div class="detail_title" style="margin-top: 0.1rem;">次级风险事件</div>
                      </div>
                    </div> -->
                <!--                </el-tab-pane>-->
                <el-tab-pane label="提醒对象管理" name="second">
                    <div>
                        <el-button type="primary" size="medium" @click="handleShowDialog">添加提醒对象</el-button>
                    </div>
                    <div class="detail_title">驾驶员</div>
                    <div style="margin-top: 0.2rem; font-size: 0.18rem; margin-left: 0.2rem">车辆所绑定的驾驶员</div>
                    <div class="detail_title">车队管理员</div>
                    <div class="table_box">
                        <!--table表格部分-->
                        <el-table ref="multipleTable" :data="managerRemindPersonList" style="width: 100%">
                            <el-table-column prop="personName" label="姓名"></el-table-column>
                            <el-table-column prop="phone" label="手机号"></el-table-column>
                            <el-table-column prop="email" label="邮箱"></el-table-column>
                            <!--                            <el-table-column prop="batchName" label="所属车队"></el-table-column>-->
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                    <div class="color_a">
                                        <span @click="handleLookDetail(scope.row)">编辑</span>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <!--分页部分-->
                        <el-pagination style="margin-top: 0.2rem" @current-change="handleCurrentChangeFromManager" :current-page="this.managerRemindPageParam.pageNo" layout="total, prev, pager, next, jumper" :page-size="this.managerRemindPageParam.pageSize" :total="this.managerRemindTotal">
                        </el-pagination>
                    </div>
                    <div class="detail_title">其他人员</div>
                    <div class="table_box">
                        <!--table表格部分-->
                        <el-table ref="multipleTable" :data="otherRemindPersonList" style="width: 100%">
                            <el-table-column prop="personName" label="姓名"></el-table-column>
                            <el-table-column prop="phone" label="手机号"></el-table-column>
                            <el-table-column prop="email" label="邮箱"></el-table-column>
                            <!--                            <el-table-column prop="wechat" label="微信号"></el-table-column>-->
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                    <div class="color_a">
                                        <span @click="handleLookDetail(scope.row)">编辑</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <!-- <el-table-column prop="reminderModeName" label="所属车队"></el-table-column> -->
                        </el-table>
                        <!--分页部分-->
                        <el-pagination style="margin-top: 0.2rem" @current-change="handleCurrentChangeFromOther" :current-page="this.otherRemindPageParam.pageNo" layout="total, prev, pager, next, jumper" :page-size="this.otherRemindPageParam.pageSize" :total="this.otherRemindTotal"> </el-pagination>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
        <el-dialog :visible.sync="hintObjDialogVisible" :modal-append-to-body="false" width="650px" center title="添加提醒对象">
            <el-form :rules="rules" ref="addMessageForm" :model="addMessageForm" label-width="120px" class="add_message">
                <el-form-item label="姓名:" prop="personName">
                    <el-input v-model="addMessageForm.personName" size="small" placeholder="请输入姓名" clearable></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                    <el-input v-model="addMessageForm.phone" size="small" type="tel" maxlength="11" placeholder="请输入手机号" clearable></el-input>
                </el-form-item>
                <el-form-item label="邮箱:" prop="email">
                    <el-input v-model="addMessageForm.email" size="small" placeholder="请输入邮箱" clearable></el-input>
                </el-form-item>
                <!--                <el-form-item label="微信号:" prop="wechat">-->
                <!--                    <el-input v-model="addMessageForm.wechat" size="small" placeholder="请输入微信号"-->
                <!--                              clearable></el-input>-->
                <!--                </el-form-item>-->
                <el-form-item label="身份:" prop="personType">
                    <el-select v-model="addMessageForm.personType" size="small" style="width: 100%" clearable placeholder="请选择" onchange="personTypeChange">
                        <el-option v-for="item in personTypeOption" :key="item.code" :label="item.desc" :value="item.code"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属分公司:" prop="tenantIdList" v-if="addMessageForm.personType !== 2">
                    <el-select v-model="addMessageForm.personConfigDto.tenantIdList" multiple size="small" placeholder="请选择" style="width: 100%">
                        <el-option v-for="item in companyList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="免打扰日期:" prop="code">
                    <el-checkbox-group v-model="addMessageForm.personConfigDto.notDisturbDate">
                        <el-checkbox v-for="item in disturbDateTypeOption" :key="item.code" :label="item.code">{{ item.desc }}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="免打扰时间段:" prop="notDisturbTime">
                    <ul class="time_quantum">
                        <li v-for="item in timeQuantum" :key="item" @click="handleTimeQuantum(item)" :class="{ active: addMessageForm.personConfigDto.notDisturbTime.includes(item) }">{{ item }}</li>
                    </ul>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancelAddMessageForm">取 消</el-button>
                <el-button type="primary" @click="addRemindPerson">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'NotificationRecord',
    components: {},
    props: {
        msg: String
    },
    data() {
        return {
            activeName: 'second',
            loading: false,
            hintObjDialogVisible: false,
            addMessageForm: {
                personName: '',
                phone: '',
                email: '',
                personType: '',
                personConfigDto: {
                    notDisturbDate: [],
                    notDisturbTime: [],
                    tenantIdList: []
                }
            },
            rules: {
                personName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
                phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
                personType: [{ required: true, message: '请至少选择一个身份', trigger: 'change' }]
            },

            personTypeOption: [],
            disturbDateTypeOption: [],
            selectedOptions: [],
            timeQuantum: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
            tenantListOption: [],

            otherRemindPageParam: {
                pageNo: 1,
                pageSize: 5,
                condition: {}
            },
            otherRemindTotal: 0,
            otherRemindPersonList: [],

            managerRemindPageParam: {
                pageNo: 1,
                pageSize: 5,
                condition: {}
            },
            managerRemindTotal: 0,
            managerRemindPersonList: [],
            companyList: []
        }
    },
    mounted() {
        this.getPersonTypeEnum()
        this.getDisturbDateTypeEnum()
        this.getTenantList()

        this.getRemindPersonList(1)
        this.getRemindPersonList(2)
        this.getCompanyList()
    },
    methods: {
        //获取分公司
        getCompanyList() {
            this.instance.get('/ubi/api/tenant/companyList').then((res) => {
                let data = res.data
                if (data.code == 200) {
                    this.companyList = data.data
                }
            })
        },
        //添加提醒对象时的身份change事件
        personTypeChange(val) {
            if (val === 2) {
                this.addMessageForm.personConfigDto.tenantIdList = []
            }
        },
        // 获取人员类型
        getPersonTypeEnum() {
            this.instance
                .get('/ubi/api/enum/getPersonTypeEnum')
                .then((res) => {
                    let data = res.data
                    if (data.code === 200) {
                        this.personTypeOption = data.data
                    } else {
                        console.log(res)
                    }
                })
                .catch((res) => {
                    console.log(res)
                })
        },
        // 获取免打扰日期
        getDisturbDateTypeEnum() {
            this.instance
                .get('/ubi/api/enum/getDisturbDateTypeEnum')
                .then((res) => {
                    let data = res.data
                    if (data.code === 200) {
                        this.disturbDateTypeOption = data.data
                    } else {
                        console.log(res)
                    }
                })
                .catch((res) => {
                    console.log(res)
                })
        },

        // 获取租户
        getTenantList() {
            this.instance
                .get('/ubi/api/tenant/fleetList')
                .then((res) => {
                    let data = res.data
                    if (data.code === 200) {
                        this.tenantListOption = data.data
                    } else {
                        console.log(res)
                    }
                })
                .catch((res) => {
                    console.log(res)
                })
        },

        //点击时间段
        handleTimeQuantum(item) {
            if (this.addMessageForm.personConfigDto.notDisturbTime.includes(item)) {
                this.addMessageForm.personConfigDto.notDisturbTime.remove(item)
            } else {
                this.addMessageForm.personConfigDto.notDisturbTime.push(item)
            }
        },
        //点击添加提醒对象按钮，显示Dialog
        handleShowDialog() {
            this.clearAddMessageForm()
            this.hintObjDialogVisible = true
        },
        //添加消息提醒
        addRemindPerson() {
            this.$refs.addMessageForm
                .validate((valid) => {
                    if (valid) {
                        let tmpNotDisturbTimeArr = this.addMessageForm.personConfigDto.notDisturbTime
                        tmpNotDisturbTimeArr.forEach((item, index) => {
                            tmpNotDisturbTimeArr[index] = Number(tmpNotDisturbTimeArr[index])
                        })
                        let json = JSON.parse(JSON.stringify(this.addMessageForm))
                        this.instance
                            .post('/ubi/api/person/addRemindPerson', json)
                            .then((res) => {
                                let data = res.data
                                if (data.code === 200) {
                                    this.$message.success(data.message)
                                } else {
                                    this.$message.error(data.message)
                                }
                                this.cancelAddMessageForm()
                                this.getRemindPersonList(1)
                                this.getRemindPersonList(2)
                            })
                            .catch((res) => {
                                console.log(res)
                                this.$message.error('添加失败! 请稍后重试')
                            })
                    }
                })
                .catch((res) => {
                    console.log(res)
                })
        },

        // 取消按钮
        cancelAddMessageForm() {
            this.clearAddMessageForm()
            this.hintObjDialogVisible = false
        },

        // 清除弹窗数据
        clearAddMessageForm() {
            this.addMessageForm = {
                personName: '',
                phone: '',
                email: '',
                personType: '',
                personConfigDto: {
                    notDisturbDate: [],
                    notDisturbTime: [],
                    tenantIdList: []
                }
            }
        },

        // 获取消息提醒对象列表
        getRemindPersonList(personType) {
            this.otherRemindPageParam.condition = {
                personType: personType
            }

            let param = {}
            if (personType === 2) {
                this.otherRemindPageParam.condition = {
                    personType: personType
                }
                param = this.otherRemindPageParam
            }

            if (personType === 1) {
                this.managerRemindPageParam.condition = {
                    personType: personType
                }
                param = this.managerRemindPageParam
            }

            this.instance
                .post('/ubi/api/person/paging', param)
                .then((res) => {
                    let data = res.data
                    if (data.code === 200 && personType === 2) {
                        this.otherRemindPersonList = data.data.list
                        this.otherRemindTotal = parseInt(data.data.total)
                    }
                    if (data.code === 200 && personType === 1) {
                        this.managerRemindPersonList = data.data.list
                        this.managerRemindTotal = parseInt(data.data.total)
                    }
                })
                .catch((res) => {
                    console.log(res)
                    this.$message.error('查询数据失败! 请稍后重试')
                })
        },

        //分页：当前页改变之后的函数
        handleCurrentChangeFromOther: function (val) {
            this.otherRemindPageParam.pageNo = Number(val)
            this.getRemindPersonList(2)
        },

        handleCurrentChangeFromManager: function (val) {
            this.managerRemindPageParam.pageNo = Number(val)
            this.getRemindPersonList(1)
        },

        //跳转到行程轨迹页
        openNewPage(row) {
            let routeUrl = this.$router.resolve({
                path: '/vehicleMap',
                query: {
                    deviceNumber: row.deviceNumber,
                    startTime: row.startTime,
                    endTime: row.endTime,
                    routeId: row.id
                }
            })
            window.open(routeUrl.href, '_blank')
        },
        //关闭模态框
        beforeClose() {
            this.$emit('update:messageParent', false)
            this.dialogVisible = false
        },
        linkHeat() {
            let routeUrl = this.$router.resolve({
                path: '/vehicleMapHeat'
            })
            window.open(routeUrl.href, '_blank')
        },
        //点击查看详情
        handleLookDetail(row) {
            this.hintObjDialogVisible = true
            this.addMessageForm = JSON.parse(JSON.stringify(row))
            let tmpNotDisturbTimeArr = this.addMessageForm.personConfigDto.notDisturbTime
            tmpNotDisturbTimeArr.forEach((item, index) => {
                tmpNotDisturbTimeArr[index] = String(tmpNotDisturbTimeArr[index]).length < 2 ? '0' + tmpNotDisturbTimeArr[index] : String(tmpNotDisturbTimeArr[index])
            })
        }
    }
}
</script>
<style scoped>
@import '../../assets/css/table_white.css';

.time_quantum {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.time_quantum li {
    line-height: 1.4;
    padding: 5px 8px;
    border: 1px solid #666;
    border-radius: 5px;
    margin-right: 0.2rem;
    margin-bottom: 0.2rem;
    cursor: pointer;
}

.time_quantum .active {
    background-color: #409eff;
    color: #fff;
    border: 1px solid #409eff;
}

.add_message .el-form-item {
    /* margin-bottom: 18px; */
}

.table_box {
    margin-top: 0.2rem;
}

.risk_event {
    display: flex;
    width: 100%;
    padding: 0 0.2rem;
}

.risk_event div:first-child {
    margin-right: 2rem;
}

.event_item p {
    margin: 0.3rem 0 0.2rem;
    font-size: 0.2rem;
}

.detail_title {
    margin-bottom: 0;
}

.content {
    position: relative;
    height: 100%;
}

.hint_obj_box /deep/ .el-form-item__label {
    display: block;
    width: 100%;
    text-align: left;
}

.form_box {
    margin-top: 0.2rem;
}

.form_box .el-form-item {
    /* margin-bottom: 0.2rem; */
}

/deep/ .el-dialog__body {
    padding: 0 0.5rem;
}

.dialog_box {
    padding: 0 0 0.5rem;
}

.hint_title {
    margin-top: 0.15rem;
    font-size: #000;
}

.hint_info {
    margin-top: 0.2rem;
    border: 1px solid #c0c0c0;
    padding: 0.15rem 0.3rem;
    min-height: 1rem;
}
</style>
