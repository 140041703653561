<template>
    <div class="content" v-cloak>
        <div class="page_title">消息提醒历史</div>
        <div class="content_box">
            <div class="search_box">
                <!--检索部分-->
                <el-form :inline="true" :model="navForm" class="demo-form-inline">
                    <el-form-item label="消息名称:">
                        <el-input class="width_150" v-model="navForm.remindName" size="small" placeholder="请输入消息名称" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="提醒事件类型:">
                        <el-select v-model="navForm.remindType" placeholder="请选择" size="small" clearable>
                            <el-option v-for="item in remindTypeOptions" :key="item.code" :label="item.desc" :value="item.code"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="通知方式:">
                        <el-select v-model="navForm.remindMode" placeholder="请选择" size="small" clearable>
                            <el-option v-for="item in remindModeOptions" :key="item.code" :label="item.desc" :value="item.code"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="提醒对象:">
                        <el-input v-model="navForm.remindPersonName" placeholder="请输入提醒对象姓名/电话" size="small" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="行驶时间:" class="dateBox">
                        <el-row style="width: 420px">
                            <el-col :span="11">
                                <el-date-picker size="small" placeholder="请选择日期时间" v-model="navForm.startTripTime" type="datetime" value-format="yyyy-MM-dd hh:mm:ss" style="width: 100%"> </el-date-picker>
                            </el-col>
                            <el-col class="line" style="text-align: center" :span="2">至</el-col>
                            <el-col :span="11">
                                <el-date-picker size="small" placeholder="请选择日期时间" v-model="navForm.endTripTime" type="datetime" value-format="yyyy-MM-dd hh:mm:ss" style="width: 100%"> </el-date-picker>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="small" @click="searchTable" style="margin-left: 0.2rem">查询 </el-button>
                    </el-form-item>
                </el-form>
            </div>

            <div class="table_box">
                <!--table表格部分-->
                <el-table ref="multipleTable" :data="tableData" v-loading="loading" :height="tableHeight" style="width: 100%">
                    <el-table-column label="序号" type="index" width="80"></el-table-column>
                    <el-table-column prop="startTime" label="提醒时间"></el-table-column>
                    <el-table-column prop="remindName" label="消息名称"></el-table-column>
                    <el-table-column prop="remindTypeDesc" label="提醒事件类型"></el-table-column>
                    <el-table-column prop="remindModeDesc" label="提醒方式"></el-table-column>
                    <el-table-column prop="plateNo" label="触发车辆车牌号"></el-table-column>
                    <el-table-column prop="triggerCondition" label="触发原因">
                        <template slot-scope="scope">
                            <div>{{ scope.row.condition ? scope.row.condition.condition + scope.row.condition.unit : '' }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="remindPersonName" label="提醒对象"></el-table-column>
                    <el-table-column label="操作" width="100px">
                        <template slot-scope="scope">
                            <!-- <div class="operation_box">
                              <span @click="openNewPage(scope.row)">查看行程</span>
                              <span @click="handleLookDetail(scope.row)">提醒内容</span>
                            </div> -->
                            <div>
                                <span class="color_a" @click="handleLookDetail(scope.row)">提醒内容</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <!--分页部分-->
                <el-pagination style="margin-top: 0.2rem" @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="currentPage" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="10" :total="totalPage"> </el-pagination>
            </div>
        </div>
        <el-dialog :visible.sync="hintDialogVisible" :modal-append-to-body="false" width="60%">
            <div class="dialog_box">
                <el-tabs v-model="dialogActiveName">
                    <el-tab-pane label="驾驶员" name="first">
                        <el-table :data="messageModelInfo.driver" style="width: 100%">
                            <el-table-column prop="username" label="姓名"></el-table-column>
                            <el-table-column prop="userPhone" label="电话"></el-table-column>
                            <el-table-column prop="userEmail" label="邮箱"></el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="车队管理员" name="second">
                        <el-table :data="messageModelInfo.administrators" style="width: 100%">
                            <el-table-column prop="username" label="姓名"></el-table-column>
                            <el-table-column prop="userPhone" label="电话"></el-table-column>
                            <el-table-column prop="userEmail" label="邮箱"></el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="其他人员" name="third">
                        <el-table :data="messageModelInfo.others" style="width: 100%">
                            <el-table-column prop="username" label="姓名"></el-table-column>
                            <el-table-column prop="userPhone" label="电话"></el-table-column>
                            <el-table-column prop="userEmail" label="邮箱"></el-table-column>
                        </el-table>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </el-dialog>
        <!-- 提醒内容 -->
        <el-dialog :visible.sync="hintContentDialogVisible" :modal-append-to-body="false" width="60%">
            <div class="dialog_box">
                <el-tabs v-model="dialogActiveName">
                    <el-tab-pane :label="tabsObj[logContents.remindMode]" name="first"></el-tab-pane>
                </el-tabs>
                <div class="hint_content">
                    <div class="hint_title">提醒内容：</div>
                    <div class="hint_info">
                        <!-- <p v-for="item in logContent" :key="item.id">{{ item.logContent }}</p> -->
                        <p>{{ logContents.remindContent }}</p>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'NotificationRecord',
    components: {},
    props: {
        msg: String
    },
    data() {
        return {
            tableHeight: window.innerHeight > 700 ? 395 : 350,
            rid: '',
            dialogActiveName: 'first',
            loading: false,
            innerVisible: false,
            hintDialogVisible: false,
            fullscreenLoading: false,
            detailPageVisible: false,
            hintContentDialogVisible: false,
            dialogAddUserMoreVisible: false,
            tabsObj: {
                1: '短信',
                2: 'AI电话',
                3: '邮件'
            },
            imageUrl: '',
            checkedRow: '',
            formType: '', //页面类型
            startTime: '',
            endTime: '', //添加用户form
            detailForm: {},
            logContent: '', //消息提醒内容
            logContents: '', //消息提醒内容
            tRemindereventType: [], //事件类型
            tiggerObjectVo: [], //提醒对象
            messageModelInfo: {}, //消息提醒对象详情
            tableDataObj: [],

            remindModeOptions: [],
            remindTypeOptions: [],
            navForm: {
                //储存搜索框的检索条件信息
                remindName: '',
                remindPersonName: '',
                remindType: '',
                remindMode: '',
                startTripTime: '',
                endTripTime: '',
                remindPersonPhone: ''
            },

            pageParam: {
                pageNo: 1,
                pageSize: 20,
                condition: {
                    remindMode: undefined,
                    remindType: undefined,
                    remindName: ''
                }
            },
            currentPage: 1, //当前页
            pageSize: 10,
            tableData: [],
            totalPage: 0
        }
    },
    mounted() {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
        this.navForm.companyId = this.userInfo.company
        this.selectDate(7)
        this.getTbodyData()

        this.getRemindModeEnum()
        this.getRemindTypeEnum()
    },
    methods: {
        // 请求通知方式
        getRemindModeEnum: function () {
            this.instance
                .get('/ubi/api/enum/getRemindModeEnum')
                .then((res) => {
                    let data = res.data
                    if (data.code === 200) {
                        this.remindModeOptions = data.data
                    } else {
                        console.log(res)
                        this.$message.error(data.message)
                    }
                })
                .catch((res) => {
                    console.log(res)
                    this.$message.error('请求通知类型失败!')
                })
        },

        // 获取消息提醒类型
        getRemindTypeEnum: function () {
            this.instance
                .get('/ubi/api/enum/getRemindTypeEnum')
                .then((res) => {
                    let data = res.data
                    if (data.code === 200) {
                        this.remindTypeOptions = data.data
                    } else {
                        console.log(res)
                        this.$message.error(data.message)
                    }
                })
                .catch((res) => {
                    console.log(res)
                    this.$message.error('请求通知类型失败!')
                })
        },

        //获取tbody里的数据
        getTbodyData: function () {
            this.loading = true
            this.tableData = [] //  清空table的值
            let json = JSON.parse(JSON.stringify(this.navForm))
            json.remindPersonPhone = json.remindPersonName
            let param = this.pageParam
            param.condition = json
            param.pageNo = this.currentPage
            param.pageSize = this.pageSize
            this.instance
                .post('/ubi/api/remind/record/paging', param)
                .then((res) => {
                    this.loading = false
                    if (res.data.code === 200) {
                        this.tableData = res.data.data.list
                        this.totalPage = parseInt(res.data.data.total)
                    } else {
                        console.log(res)
                        this.$message.error(res.data.message)
                    }
                })
                .catch((res) => {
                    console.log(res)
                    this.$message.error('请求失败! 请稍后重试')
                })
        },
        //分页数据多少条change
        handleSizeChange(val) {
            this.pageSize = val
            this.getTbodyData()
        },
        //分页：当前页改变之后的函数
        handleCurrentChange: function (val) {
            // console.log(val);
            this.currentPage = Number(val)
            this.getTbodyData(this.activityState)
        },

        //选择日期
        selectDate(sign) {
            const start = new Date()
            const end = new Date()
            if (sign == 'today') {
                this.navForm.startTripTime = this.dateFormatting(start, 'yyyy-MM-dd hh:mm:ss')
                this.navForm.endTripTime = this.dateFormatting(end, 'yyyy-MM-dd hh:mm:ss')
            } else {
                this.navForm.startTripTime = this.dateFormatting(new Date(start.getTime() - 3600 * 1000 * 24 * sign), 'yyyy-MM-dd hh:mm:ss')
                this.navForm.endTripTime = this.dateFormatting(end, 'yyyy-MM-dd hh:mm:ss')
            }
        },
        //跳转到行程轨迹页
        openNewPage(row) {
            if (row.tripId) {
                let routeUrl = this.$router.resolve({
                    path: '/VehicleMap',
                    query: {
                        deviceNumber: row.deviceNumber,
                        startTime: row.startTime,
                        endTime: row.endTime,
                        routeId: row.id
                    }
                })
                window.open(routeUrl.href, '_blank')
            } else {
                this.$message('抱歉，当前行程暂未生成！')
            }
        },

        //点击提醒对象的文字
        handleHintObj(row) {
            this.hintDialogVisible = true
            this.instance
                .get('/message/findTiggerObjectVo', {
                    params: { messageCode: row.messageCode }
                })
                .then((res) => {
                    if (res.data.code == 1) {
                        this.messageModelInfo = res.data.data
                    }
                })
        },
        //关闭模态框
        beforeClose() {
            this.$emit('update:messageParent', false)
            this.dialogVisible = false
        },
        linkHeat() {
            let routeUrl = this.$router.resolve({
                path: '/VehicleMapHeat'
            })
            window.open(routeUrl.href, '_blank')
        },
        //点击查看详情
        handleLookDetail(row) {
            this.hintContentDialogVisible = true
            this.logContents = row
            return
            // 下面暂时不用，2023-4-22 16:55 旧系统这个功能坏的，后台看不到内容
            this.instance
                .get('/getTMessageLogList', {
                    params: {
                        messageCode: row.messageCode,
                        messageTime: row.messageTime,
                        reminderMode: row.reminderMode
                    }
                })
                .then((res) => {
                    if (res.data.code == 1) {
                        this.dialogAddUserVisible = true
                        var response = res.data.data
                        this.logContent = response
                    }
                })
        },
        //点击查询
        searchTable() {
            this.currentPage = 1
            this.getTbodyData()
        }
    }
}
</script>
<style scoped>
@import '../../assets/css/table_white.css';

.content {
    position: relative;
    height: 100%;
}

.hint_obj_box /deep/ .el-form-item__label {
    display: block;
    width: 100%;
    text-align: left;
}

.form_box {
    margin-top: 0.2rem;
}

.form_box .el-form-item {
    /* margin-bottom: 0.2rem; */
}

/deep/ .el-dialog__body {
    padding: 0 0.5rem;
}

.dialog_box {
    padding: 0 0 0.5rem;
}

.hint_title {
    margin-top: 0.15rem;
    font-size: #000;
}

.hint_info {
    margin-top: 0.2rem;
    border: 1px solid #c0c0c0;
    padding: 0.2rem 0.3rem;
    min-height: 1rem;
}

.hint_info p {
    line-height: 1.4;
    margin-bottom: 0.1rem;
}
</style>
