<template >
    <div class="content">
        <div class="page_title">危险驾驶车辆跟踪</div>
        <div class="content_box">
            <div class="page_card page-card_bottom">
                <!--检索部分-->
                <el-form :inline="true" :model="navForm" class="search-form-inline">
                    <el-form-item label="选择分公司:" prop="subTenantId">
                        <el-select class="width_180" v-model="navForm.condition.subTenantId" placeholder="请选择" size="small" clearable @change="loadFleetList">
                            <el-option v-for="(item, index) in companyList" :key="index" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="选择车队:" prop="fleetId">
                        <el-select class="width_150" v-model="navForm.condition.fleetId" placeholder="请选择" size="small" clearable>
                            <el-option v-for="(item, index) in fleetList" :key="index" :label="item.customerId" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="跟踪时间：" class="search-form_item">
                        <el-date-picker v-model="rangeDate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"> </el-date-picker>
                    </el-form-item>
                    <el-form-item class="search-form_item">
                        <el-button type="primary" @click="loadRecords">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="page_card">
                <el-table :data="tableData" style="width: 100%" v-loading="loading">
                    <el-table-column type="index" width="50" label="序号"> </el-table-column>
                    <el-table-column prop="plateNo" label="车牌号" width="80"> </el-table-column>
                    <el-table-column prop="vin" label="车辆VIN码" width="90"> </el-table-column>
                    <el-table-column prop="deviceNumber" label="设备编号"> </el-table-column>
                    <el-table-column prop="companyName" label="所属分公司"> </el-table-column>
                    <el-table-column prop="fleetName" label="所属车队"> </el-table-column>
                    <el-table-column prop="fatiguedDrivingDurationAvgDay" label="本月日均疲劳驾驶时长数">
                        <template slot-scope="scope">
                            <span>{{ (scope.row.fatiguedDrivingDurationAvgDay / 60.0).toFixed(2) + 'h' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="sharpAlarmNum100km" label="本月百公里三急次数"> </el-table-column>
                    <el-table-column prop="overSpeedNum100km" label="本月百公里超速次"> </el-table-column>
                    <el-table-column prop="lastCheckTime" label="跟踪时间">
                        <template slot-scope="scope">
                            <span>{{ scope.row.lastCheckTime || '--' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="drivingScore" label="驾驶评分"> </el-table-column>
                    <el-table-column prop="tipText" label="危险提示">
                        <template slot-scope="scope">
                            <span style="color: red">{{ scope.row.tipText || '危险驾驶' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="endCheckTime" label="解除跟踪日期">
                        <template slot-scope="scope">
                            <span>{{ (scope.row.endCheckTime && moment(scope.row.endCheckTime).format('YYYY-MM-DD')) || '未解除' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="link2TrackingAnalysis(scope.row)">驾驶行为跟踪</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <!--分页部分-->
                <el-pagination style="margin-top: 0.2rem" @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="navForm.pageNo" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="navForm.pageSize" :total="total"> </el-pagination>
            </div>
        </div>
    </div>
</template>
  <script>
export default {
    data() {
        return {
            loading: false,
            navForm: {
                //储存搜索框的检索条件信息
                condition: {
                    subTenantId: null,
                    fleetId: null,
                    startCheckTime: null,
                    endCheckTime: null,
                    recordType: 0
                },
                orders: [],
                pageNo: 1,
                pageSize: 20
            },
            rangeDate: [],
            companyList: [],
            fleetList: [],
            tableData: [],
            total: 0,
            currentUser: {
                userTeantType: 2
            },
            userInfo: null
        };
    },
    mounted() {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.loadCompanyList();
        this.getCurrentUser();
        this.loadRecords();
    },
    methods: {
        link2TrackingAnalysis(row) {
            this.$router.push({
                path: '/application/trackingAnalysis',
                query: {
                    search: row.vin,
                    ruleType: 0
                }
            });
        },
        loadRecords() {
            this.loading = true;
            const queryParams = JSON.parse(JSON.stringify(this.navForm));
            if (this.rangeDate && this.rangeDate.length > 0) {
                queryParams.condition.startCheckTime = this.rangeDate[0];
                queryParams.condition.endCheckTime = this.rangeDate[1];
            }
            var _this = this;
            this.instance.post('/ubi/api/badDriving/searchVehicleRecord', queryParams).then(({ data }) => {
                this.loading = false;
                if (data.code == 200) {
                    _this.tableData = data.data.list;
                    _this.total = data.data.total;
                } else {
                    _this.total = 0;
                }
            });
        },
        handleCurrentChange(val) {
            this.navForm.pageNo = val;
            this.loadRecords();
        },
        handleSizeChange(val) {
            this.navForm.pageSize = val;
            this.loadRecords();
        },
        //获取车队
        loadFleetList(id) {
            this.navForm.condition.fleetId = null;
            if (id) {
                this.instance.get('/ubi/api/tenant/fleetList?companyId=' + id).then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.fleetList = data.data;
                    }
                });
            } else {
                this.fleetList = [];
            }
        },
        //获取分公司
        loadCompanyList() {
            this.instance.get('/ubi/api/tenant/companyList').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.companyList = data.data;
                }
            });
        },
        getCurrentUser() {
            this.instance.get('/ubi/api/tenant/currentUser').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.currentUser = res.data;
                }
            });
        }
    }
};
</script>
  <style scoped>
@import '../../assets/css/table_white.css';
@import '../../assets/css/public.css';

.page_content {
    width: 100%;
    height: 100%;
    overflow: auto;
}
.content {
    position: relative;
    height: 100%;
}
.content_box {
    height: 96%;
    overflow-y: scroll;
}
.page_none {
    background: #fff;
}
.big_num {
    font-size: 48px;
    font-weight: bold;
    color: darkturquoise;
}
.index_style {
    font-size: 24px;
    font-weight: bold;
    height: 60px;
    text-align: center;
    vertical-align: middle;
}
.title_bar {
    padding-left: 20px;
    line-height: 40px;
    font-size: 16px;
    font-weight: bold;
    background-color: #e5e9f2;
}
.accident_detail {
    line-height: 40px;
    font-size: medium;
}
.accident_content {
    width: 70%;
    margin-top: 10px;
    padding: 10px 10px 20px 30px;
    border-style: solid;
    border-radius: 4px;
    border-width: 1px;
    border-color: rgba(156, 15, 15, 0.1);
    line-height: normal;
}
</style>