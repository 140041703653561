<template>
    <div class="content" v-cloak>
        <div class="page_title">电子围栏</div>
        <div class="content_box">
            <el-tabs v-model="activeName">
                <el-tab-pane label="电子围栏列表" name="first">
                    <div class="search_box flex">
                        <!--检索部分-->
                        <el-form :inline="true" :model="navForm1" class="demo-form-inline">
                            <el-form-item label="围栏名称:">
                                <el-input class="width_150" v-model="navForm1.condition.remindName" size="small" placeholder="请输入围栏名称" clearable></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" size="small" @click="searchTable1" style="margin-left: 0.2rem">查询</el-button>
                            </el-form-item>
                        </el-form>
                        <el-button type="primary" size="small" @click="handleGeoFencing('add')">添加新围栏</el-button>
                    </div>
                    <div class="table_box">
                        <!--table表格部分-->
                        <el-table ref="multipleTable" :data="tableData1" v-loading="tableData1Loading" :height="tableHeight" style="width: 100%">
                            <el-table-column type="index" label="序号" width="50"></el-table-column>
                            <el-table-column prop="remindName" label="围栏名称"></el-table-column>
                            <el-table-column prop="triggerDesc" label="围栏区域"></el-table-column>
                            <el-table-column prop="userEmail" label="绑定车辆">
                                <template slot-scope="scope">
                                    <div class="color_a">
                                        <span @click="handleLookVehicle(scope.row)">车队（{{ scope.row.bindFleetNum }}）车辆（{{ scope.row.bindVehicleNum }}）</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="remindEventDesc" label="报警类型"></el-table-column>
                            <el-table-column prop="batchName" label="报警记录">
                                <template slot-scope="scope">
                                    <div class="color_a">
                                        <span @click="handleLookLog(scope.row)">查看</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="报警状态" width="130">
                                <template slot-scope="scope">
                                    <div>
                                        <el-switch :value="scope.row.messageStatus" @change="updateState(scope.row, scope.$index)" active-text="启用" inactive-text="停用" active-color="#409EFF" inactive-color="#C0C0C0"> </el-switch>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                    <div class="color_a">
                                        <span @click="handleLookDetail(scope.row)">编辑</span>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <!--分页部分-->
                        <el-pagination style="margin-top: 0.2rem" @current-change="handleCurrentChange1" :current-page="navForm1.pageNo" layout="total, prev, pager, next, jumper" :page-size="navForm1.pageSize" :total="totalPage1"> </el-pagination>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="报警列表" name="second">
                    <div class="search_box">
                        <!--检索部分-->
                        <el-form :inline="true" :model="navForm2" class="demo-form-inline">
                            <el-form-item label="围栏名称:">
                                <el-input class="width_150" v-model="navForm2.condition.remindName" size="small" placeholder="请输入围栏名称" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="车牌号/VIN码:">
                                <el-input class="width_150" v-model="navForm2.condition.search" size="small" placeholder="请输入车牌号/VIN码" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="报警开始时间:" class="dateBox">
                                <el-row style="width: 320px">
                                    <el-col :span="11">
                                        <el-date-picker placeholder="请选择日期" v-model="navForm2.startTime" type="date" size="small" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                                    </el-col>
                                    <el-col class="line" style="text-align: center" :span="2">至</el-col>
                                    <el-col :span="11">
                                        <el-date-picker placeholder="请选择日期" v-model="navForm2.endTime" type="date" size="small" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                                    </el-col>
                                </el-row>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" size="small" @click="searchTable2" style="margin-left: 0.2rem">查询</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="table_box">
                        <!--table表格部分-->
                        <el-table ref="multipleTable" :data="tableData2" :height="tableHeight1" style="width: 100%">
                            <el-table-column type="index" label="序号" width="50"></el-table-column>
                            <el-table-column prop="remindName" label="围栏名称" width="150"></el-table-column>
                            <el-table-column prop="regionName" label="围栏区域" width="100"></el-table-column>
                            <el-table-column label="报警车辆">
                                <template slot-scope="scope">
                                    <div>
                                        <div>车牌号：{{ scope.row.plateNo }}</div>
                                        <div>VIN码：{{ scope.row.vin }}</div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="报警时间" width="170">
                                <template slot-scope="scope">
                                    <div>
                                        <div>开始：{{ scope.row.startTime }}</div>
                                        <div>结束：{{ scope.row.endTime }}</div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="alarmName" label="报警类型" width="150"></el-table-column>
                            <el-table-column label="报警位置">
                                <template slot-scope="scope">
                                    <div>
                                        <div>开始：{{ scope.row.startLocation }}</div>
                                        <div>结束：{{ scope.row.endLocation }}</div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" width="110px">
                                <template slot-scope="scope">
                                    <div class="color_a" v-if="scope.row.eventStatus != 99">
                                        <span v-if="scope.row.eventStatus == 0" @click="handleOver(scope.row)" style="margin-right: 15px">结束报警</span>
                                        <span @click="handleLookMap(scope.row)">查看</span>
                                    </div>
                                    <div v-else>--</div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <!--分页部分-->
                        <el-pagination style="margin-top: 0.2rem" @current-change="handleCurrentChange2" :current-page="navForm2.pageN" layout="total, prev, pager, next, jumper" :page-size="navForm2.pageSize" :total="totalPage2"> </el-pagination>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
        <el-dialog title="查看围栏" center :visible.sync="mapDialogVisible" @close="handleDialogClose" destroy-on-close append-to-body :modal-append-to-body="false" width="70%">
            <div class="map_dialog">
                <map-fence v-if="mapDialogVisible" :regionObj="regionObj"></map-fence>
            </div>
        </el-dialog>
        <el-dialog title="围栏绑定车辆列表" center :visible.sync="bindVehicleDialogVisible" append-to-body :close-on-click-modal="false" :modal-append-to-body="false" width="12rem">
            <div class="search_box">
                <!--检索部分-->
                <el-form :inline="true" :model="navForm3" class="demo-form-inline">
                    <el-form-item label="围栏名称:" class="border_none">
                        <el-input class="width_150" v-model="navForm3.remindName" disabled size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="车队:">
                        <el-select v-model="navForm3.groupId" size="small" placeholder="请选择" clearable>
                            <el-option v-for="item in batchIdList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="small" @click="searchTable3" style="margin-left: 0.2rem">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="table_box bind_vehicle_dialog">
                <!--table表格部分-->
                <el-table ref="multipleTable" :data="tableData3" height="477" style="width: 100%">
                    <el-table-column prop="subTenantName" label="所属车队"></el-table-column>
                    <el-table-column prop="plateNo" label="车牌号"></el-table-column>
                    <el-table-column prop="deviceNumber" label="车辆设备号"></el-table-column>
                    <el-table-column prop="vin" label="车辆VIN码"></el-table-column>
                </el-table>
                <!--分页部分-->
                <el-pagination style="margin-top: 0.2rem" @current-change="handleCurrentChange3" :current-page="currentPage3" layout="total, prev, pager, next, jumper" :page-size="pageSize3" :total="totalPage3"> </el-pagination>
            </div>
        </el-dialog>
        <el-dialog :title="geoFencingTitle" center :visible.sync="geoFencingDialogVisible" destroy-on-close append-to-body :modal-append-to-body="false" width="70%">
            <div class="form_box">
                <el-form ref="addMessageForm" :model="addMessageForm" label-width="100px" class="add_message">
                    <el-form-item label="围栏名称:">
                        <el-input class="width_200" v-model="addMessageForm.remindName" size="small" placeholder="请输入围栏名称" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="报警类型:">
                        <el-select v-model="addMessageForm.remindEventType" size="small" placeholder="请选择">
                            <el-option v-for="item in remindTypes" :label="item.desc" :value="item.code" :key="item.code"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="围栏区域:" prop="region">
                        <el-select ref="mySelected" v-model="addMessageForm.triggerRules" filterable placeholder="请选择围栏区域" @change="selectValue">
                            <el-option v-for="item in regions" :key="item.id" :label="item.regionName" :value="item.id"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="提醒方式:" prop="remindModeList">
                        <el-checkbox-group v-model="addMessageForm.remindModeList">
                            <el-checkbox v-for="item in remindModes" :label="item.code" :key="item.code">{{ item.desc }}</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <!-- <el-form-item :label="userInfo.tenantId == '10004' ? '绑定分公司' : '绑定车队'"> -->
                    <el-form-item label="绑定分公司">
                        <el-select v-model="addMessageForm.remindPersonDto.subTenantIdList" multiple placeholder="请选择" class="width_430" clearable>
                            <el-option v-for="(item, index) in companyList" :key="index" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="提醒对象:" prop="notifyPeopleList">
                        <template v-if="addMessageForm.driver.length == 0 && addMessageForm.administrators.length == 0 && addMessageForm.others.length == 0">
                            <el-button type="primary" size="small" @click="handleRemindObj">选择提醒对象</el-button>
                        </template>
                        <el-button v-if="addMessageForm.driver.length > 0" size="small" type="primary" @click="handleRemindObj">驾驶员</el-button>
                        <el-button v-if="addMessageForm.administrators.length > 0" size="small" type="primary" @click="handleRemindObj">管理员</el-button>
                        <template v-for="(item, index) in addMessageForm.others">
                            <el-button type="primary" size="small" @click="handleRemindObj" :key="index">{{ item }}</el-button>
                        </template>
                    </el-form-item>
                </el-form>
                <!-- 地图区域 -->
                <map-fence v-if="geoFencingDialogVisible" :regionObj="regionObj"></map-fence>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="geoFencingDialogVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="addTPushUserVo" size="small">确 定</el-button>
            </div>
            <el-dialog width="600px" :visible.sync="innerVisible" center append-to-body>
                <div class="hint_obj_box">
                    <el-form ref="staffsForm" :model="addMessageForm.remindPersonDto">
                        <el-form-item label="驾驶员:">
                            <el-checkbox-group v-model="addMessageForm.remindPersonDto.remindDriver">
                                <el-checkbox border :label="true">驾驶员</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                        <el-form-item label="车队管理员:">
                            <el-checkbox-group v-model="addMessageForm.remindPersonDto.remindManager">
                                <el-checkbox border :label="true">车队管理员</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                        <el-form-item label="其他人员:">
                            <el-select v-model="addMessageForm.remindPersonDto.otherPerson" multiple placeholder="请选择" class="width_470" clearable>
                                <el-option v-for="(item, index) in tiggerObjectVo.others" :key="index" :label="item.personName" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </div>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="handleSelectStaffs(false)">取 消</el-button>
                    <el-button type="primary" @click="handleSelectStaffs(true)">确 定</el-button>
                </div>
            </el-dialog>
        </el-dialog>
    </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';
import { BmlMarkerClusterer } from 'vue-baidu-map';
import MapFence from '@/components/mapFence.vue';
import qs from 'qs';
var _this = '';
export default {
    name: 'GeoFencing',
    components: {
        BmlMarkerClusterer,
        MapFence
    },
    props: {
        msg: String
    },
    data() {
        return {
            tableHeight: window.innerHeight > 700 ? 410 : 381,
            tableHeight1: window.innerHeight > 700 ? 410 : 327,
            rid: '',
            activeName: 'first',
            tableData1Loading: false,
            innerVisible: false,
            geoFencingDialogVisible: false,
            bindVehicleDialogVisible: false,
            mapDialogVisible: false,
            hintObjDialogVisible: false,
            geoFencingTitle: '添加电子围栏',
            restaurants: [],
            remindTypes: [],
            remindModes: [],
            regions: [],
            navForm1: {
                //储存搜索框的检索条件信息
                condition: {
                    remindName: ''
                },
                pageNo: 1,
                pageSize: 10
            },
            navForm2: {
                condition: {
                    remindName: null,
                    remindId: null
                },
                pageNo: 1,
                pageSize: 10
            },
            navForm3: {
                remindName: null,
                groupId: null
            },
            batchIdList: [],
            addMessageForm: {
                remindEventType: '',
                remindName: '',
                id: '',
                remindModeList: [],
                triggerRules: null,
                driver: [],
                administrators: [],
                others: [],
                remindPersonDto: {
                    subTenantIdList: [],
                    remindManager: [],
                    remindDriver: [],
                    otherPerson: []
                }
            },
            GDmap: '',
            GDmap2: '',
            polyline: '',
            polyline2: '',
            rules: {
                username: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
                userPhone: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
                remindModeList: [{ required: true, message: '请至少选择一个身份', trigger: 'change' }],
                uid: [{ required: true, message: '请至少选择一个所属车队', trigger: 'change' }]
            },
            regionsData: [],
            tiggerObjectVo: {}, //提醒对象
            currentPage1: 1, //当前页
            pageSize1: 10, //一页多少条数据
            totalPage1: 1, //总页数,
            tableData1: [],
            totalPage2: 1, //总页数,
            tableData2: [],
            currentPage3: 1, //当前页
            pageSize3: 10, //一页多少条数据
            totalPage3: 1, //总页数,
            tableData3: [],
            othersObj: {},
            regionObj: {},
            companyList: []
        };
    },
    mounted() {
        _this = this;
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.navForm1.companyId = this.userInfo.company;
        this.getBatchIdList();
        this.getTbodyData1();
        this.getTbodyData2();
        this.getEnumerationData();
        this.getCompanyList();
    },
    methods: {
        selectValue(val) {
            let _this = this;
            setTimeout(() => {
                _this.regionObj = { value: _this.$refs.mySelected.selected.value, label: _this.$refs.mySelected.selected.label };
            }, 100);
        },
        // 获取枚举数据
        getEnumerationData() {
            // 获取事件类型
            this.instance.get('/ubi/api/enum/getFenceRemindTypeEnum').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.remindTypes = data.data;
                }
            });
            // 获取提醒方式
            this.instance.get('/ubi/api/enum/getRemindModeEnum').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.remindModes = data.data;
                }
            });
            // 获取行政区域
            this.instance.get('/ubi/api/fence/regions').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.regions = data.data;
                    return;
                    var regionsData = [];
                    res.data.data.forEach((val, key) => {
                        var obj = {
                            value: val.regionName,
                            label: val.id
                        };
                        regionsData.push(obj);
                        val.children.forEach((value, index) => {
                            var obj1 = {
                                value: value.regionName,
                                label: value.id
                            };
                            regionsData.push(obj1);
                        });
                    });
                    this.restaurants = regionsData;
                }
            });
            // 获取其他人员
            this.instance.get('/ubi/api/person/others').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.tiggerObjectVo.others = data.data;
                    this.othersObj = {};
                    this.tiggerObjectVo.others.forEach((item) => {
                        this.othersObj[item.id] = item.personName;
                    });
                }
            });
        },
        //查看围栏dialog关闭时的事件
        handleDialogClose() {
            this.GDmap2.destroy();
        },
        //点击结束报警
        handleOver(row) {
            this.$confirm('正在结束当前围栏报警, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.instance
                        .get('/ubi/api/fence/event/end', {
                            params: { eventId: row.id }
                        })
                        .then((res) => {
                            if (res.data.code == 200) {
                                this.$message.success(res.data.data);
                                this.searchTable2();
                            } else {
                                this.$message.error(res.data.message);
                            }
                        });
                })
                .catch(() => {});
        },
        //点击查看报警记录
        handleLookLog(row) {
            this.activeName = 'second';
            this.navForm2.condition.remindName = row.remindName;
            this.navForm2.condition.remindId = row.id;
            this.getTbodyData2();
        },
        //点击列表的绑定车辆
        handleLookVehicle(row) {
            this.bindVehicleDialogVisible = true;
            this.navForm3 = {
                condition: row.remindPersonDto.subTenantIdList,
                remindName: row.remindName
            };
            this.getTbodyData3();
        },
        //点击选择人员的确定按钮
        handleSelectStaffs(btnStatus) {
            if (btnStatus) {
                this.addMessageForm.driver = this.addMessageForm.remindPersonDto.remindDriver;
                this.addMessageForm.administrators = this.addMessageForm.remindPersonDto.remindManager;
                if (this.addMessageForm.remindPersonDto.otherPerson.length > 0) {
                    this.addMessageForm.others = [];
                    this.addMessageForm.remindPersonDto.otherPerson.forEach((item) => {
                        this.addMessageForm.others.push(this.othersObj[item]);
                    });
                }
            }
            this.innerVisible = false;
        },
        //加载高德地图
        loadAmap() {
            AMapLoader.load({
                key: 'dabcacd9e784df189b7f69e994d4793e', // 申请好的Web端开发者Key，首次调用 load 时必填
                version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                plugins: ['AMap.Scale'] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
            })
                .then((AMap) => {
                    this.GDmap = new AMap.Map('container', {
                        mapStyle: 'amap://styles/light', //设置地图的显示样式
                        zoom: 8, //级别
                        zooms: [3, 11],
                        resizeEnable: true,
                        zoomEnable: true
                        // center: [gpsData[0].lng, gpsData[0].lat],//中心点坐标
                        // viewMode:'3D'//使用3D视图
                    });
                    // //增加比例尺控件
                    // var scale = new AMap.Scale({
                    // 	position :'RT'
                    // })
                    // this.GDmap.addControl(scale);
                    //绘制行政区域边界
                    // this.loadMapDistrict();
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        //绘制地图边界
        loadMapDistrict() {
            this.instance
                .get('/saas/fence/region_boundary', {
                    params: { regionId: this.addMessageForm.regionId }
                })
                .then((res) => {
                    if (res.data.code == 1) {
                        this.GDmap.clearMap(); //每次加载路线时,清除地图上所有覆盖物,防止覆盖物重复
                        var path = res.data.data[0][0];
                        var newPath = [];
                        path.forEach((val, key) => {
                            let obj = this.transformFromWGSToGCJ(val[1], val[0]);
                            newPath.push(new AMap.LngLat(obj.lng, obj.lat));
                        });
                        this.polyline = new AMap.Polyline({
                            path: newPath,
                            strokeWeight: 3,
                            //isOutline: true,	// 是否描边
                            //borderWeight: 2, // 描边宽度，默认为 1
                            //outlineColor: 'red', // 描边颜色
                            lineCap: 'round',
                            strokeColor: '#0766FE', // 线条颜色
                            lineJoin: 'round', // 折线拐点连接处样式
                            strokeOpacity: 1 //线透明度
                        });
                        // 将折线添加至地图实例
                        this.GDmap.add(this.polyline);
                        this.GDmap.setFitView(this.polyline);
                    }
                });
        },
        //点击报警列表操作的查看按钮
        handleLookMap(row) {
            this.regionObj.label = row.regionName;
            this.mapDialogVisible = true;
        },
        //加载报警列表查看处的地图电子围栏
        loadMapDistrict2(row) {
            this.instance
                .get('/saas/fence/region_boundary', {
                    params: { regionId: row.regionId }
                })
                .then((res) => {
                    if (res.data.code == 1) {
                        this.GDmap2.clearMap(); //每次加载路线时,清除地图上所有覆盖物,防止覆盖物重复
                        var path = res.data.data[0][0];
                        var newPath = [];
                        path.forEach((val, key) => {
                            let obj = this.transformFromWGSToGCJ(val[1], val[0]);
                            newPath.push(new AMap.LngLat(obj.lng, obj.lat));
                        });
                        this.polyline2 = new AMap.Polyline({
                            path: newPath,
                            strokeWeight: 3,
                            lineCap: 'round',
                            strokeColor: '#0766FE', // 线条颜色
                            lineJoin: 'round', // 折线拐点连接处样式
                            strokeOpacity: 1 //线透明度
                        });
                        // 将折线添加至地图实例
                        this.GDmap2.add(this.polyline2);
                        this.GDmap2.setFitView(this.polyline2);
                        let obj = this.transformFromWGSToGCJ(row.latitude, row.longitude);
                        var circleMarker = new AMap.CircleMarker({
                            center: new AMap.LngLat(obj.lng, obj.lat),
                            radius: 3, //3D视图下，CircleMarker半径不要超过64px
                            zIndex: 100,
                            cursor: 'pointer',
                            fillOpacity: 1,
                            strokeColor: '#e4393c',
                            fillColor: '#e4393c',
                            extData: row
                        });
                        circleMarker.setMap(this.GDmap2);
                        circleMarker.on('click', (e) => {
                            var extData = e.target._opts.extData;
                            //创建信息窗口
                            var content =
                                `<div class="info_box">
									<p>设备报警时GPS点</p>
									<p>经度（东经）：` +
                                extData.longitude +
                                `</p>
									<p>纬度（北纬）：` +
                                extData.latitude +
                                `</p>
									<p>GPS点时间：` +
                                extData.startTime +
                                `</p>
									<p>GPS点地图位置：` +
                                extData.startLocation +
                                `</p>
								</div>`;
                            var infoWindow = new AMap.InfoWindow({
                                // isCustom: true,  //使用自定义窗体
                                content: content,
                                offset: new AMap.Pixel(-1, -10)
                            });
                            //打开信息窗口
                            infoWindow.open(_this.GDmap2, e.lnglat); //后面的参数指的是经纬度，在此显示窗口
                        });
                        if (row.lastLatitude) {
                            this.loadLastMarker(row);
                        }
                    }
                });
        },
        //加载最后的gps点
        loadLastMarker(row) {
            let obj = this.transformFromWGSToGCJ(row.lastLatitude, row.lastLongitude);
            var circleMarker = new AMap.CircleMarker({
                center: new AMap.LngLat(obj.lng, obj.lat),
                radius: 3, //3D视图下，CircleMarker半径不要超过64px
                zIndex: 100,
                cursor: 'pointer',
                fillOpacity: 1,
                strokeColor: '#e4393c',
                fillColor: '#e4393c',
                extData: row
            });
            circleMarker.setMap(this.GDmap2);
            circleMarker.on('click', (e) => {
                var extData = e.target._opts.extData;
                //创建信息窗口
                var content =
                    `<div class="info_box">
							<p>设备最后GPS点</p>
							<p>经度（东经）：` +
                    extData.lastLongitude +
                    `</p>
							<p>纬度（北纬）：` +
                    extData.lastLatitude +
                    `</p>
							<p>GPS点时间：` +
                    extData.lastUpdateTime +
                    `</p>
							<p>GPS点地图位置：` +
                    extData.lastLocation +
                    `</p>
						</div>`;
                var infoWindow = new AMap.InfoWindow({
                    // isCustom: true,  //使用自定义窗体
                    content: content,
                    offset: new AMap.Pixel(-1, -10)
                });
                //打开信息窗口
                infoWindow.open(_this.GDmap2, e.lnglat); //后面的参数指的是经纬度，在此显示窗口
            });
        },
        //操作添加围栏
        handleGeoFencing(sign) {
            this.geoFencingTitle = '添加电子围栏';
            this.addMessageForm = {
                remindEventType: '',
                remindName: '',
                id: '',
                remindModeList: [],
                triggerRules: null,
                driver: [],
                administrators: [],
                others: [],
                remindPersonDto: {
                    subTenantIdList: [],
                    remindManager: [],
                    remindDriver: [],
                    otherPerson: []
                }
            };
            this.geoFencingDialogVisible = true;
            // this.loadAmap()
        },
        //点击提醒对象
        handleRemindObj() {
            // this.addMessageForm.remindPersonDto.otherPerson = this.addMessageForm.others
            this.addMessageForm.remindPersonDto.remindManager = this.addMessageForm.administrators;
            this.addMessageForm.remindPersonDto.remindDriver = this.addMessageForm.driver;
            this.innerVisible = true;
        },
        //点击电子围栏列表查询按钮
        searchTable1() {
            this.navForm1.pageNo = 1;
            this.getTbodyData1();
        },
        //点击报警列表查询按钮
        searchTable2() {
            this.navForm2.pageNo = 1;
            this.getTbodyData2();
        },
        //点击围栏绑定车辆列表查询按钮
        searchTable3() {
            this.currentPage3 = 1;
            this.getTbodyData3();
        },
        //获取分公司
        getCompanyList() {
            this.instance.get('/ubi/api/tenant/companyList').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.companyList = data.data;
                }
            });
        },
        //更新报警状态   1启用-0停用
        updateState(row, index) {
            var _statusName = row.messageStatus ? '停用' : '启用';
            this.$confirm('此操作将' + _statusName + '当前电子围栏报警, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    row.messageStatus = row.messageStatus ? 0 : 1;
                    this.instance.post(`/ubi/api/fence/updateStatus?id=${row.id}&status=${row.messageStatus}`).then((res) => {
                        if (res.data.code == 200) {
                            this.getTbodyData1();
                            row.messageStatus = !row.messageStatus;
                            this.$set(this.tableData1, index, row);
                            this.$message({
                                type: 'success',
                                message: res.data.message
                            });
                        }
                    });
                })
                .catch(() => {});
        },
        //添加提醒对象时的身份change事件
        userClassChange(val) {
            if (val == 3) {
                this.addMessageForm.subTenantIdList = [];
            }
        },
        //点击添加提醒对象按钮，显示Dialog
        handleShowDialog() {
            this.hintObjDialogVisible = true;
            if (this.$refs.addMessageForm !== undefined) {
                this.$refs.addMessageForm.resetFields();
            }
        },
        //添加电子围栏
        addTPushUserVo() {
            this.$refs.addMessageForm.validate((valid) => {
                if (valid) {
                    const { others, driver, id, administrators, ...newFenceData } = this.addMessageForm;
                    newFenceData.remindPersonDto.remindManager = newFenceData.remindPersonDto.remindManager.length > 0 ? true : false;
                    newFenceData.remindPersonDto.remindDriver = newFenceData.remindPersonDto.remindDriver.length > 0 ? true : false;
                    if (this.geoFencingTitle == '编辑电子围栏') {
                        newFenceData.id = id;
                        this.instance.post('/ubi/api/remind/event/definition/edit', newFenceData).then((res) => {
                            let data = res.data;
                            if (data.code == 200) {
                                this.$message.success(data.message);
                                this.geoFencingDialogVisible = false;
                                this.getTbodyData1();
                            } else {
                                this.$message(data.message);
                            }
                        });
                    } else {
                        this.instance.post('/ubi/api/remind/event/definition/add', newFenceData).then((res) => {
                            let data = res.data;
                            if (data.code == 200) {
                                this.$message.success(data.message);
                                this.geoFencingDialogVisible = false;
                                this.getTbodyData1();
                            } else {
                                this.$message(data.message);
                            }
                        });
                    }
                }
            });
        },
        querySearch(queryString, cb) {
            var restaurants = this.restaurants;
            var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilter(queryString) {
            return (restaurant) => {
                return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) != -1;
            };
        },
        //选定围栏区域
        handleSelectArea(item) {
            this.addMessageForm.regionId = item.label;
            this.loadMapDistrict();
        },
        //获取车队
        getBatchIdList() {
            this.instance.get('/ubi/api/tenant/fleetList').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.batchIdList = data.data;
                    // this.batchIdList.unshift({ name: '全部车队', id: -1 })
                }
            });
        },
        //关闭模态框
        beforeClose() {
            this.$emit('update:messageParent', false);
            this.dialogVisible = false;
        },
        //点击查看详情
        handleLookDetail(row) {
            const tmpRow = row;
            this.geoFencingDialogVisible = true;
            this.geoFencingTitle = '编辑电子围栏';
            this.addMessageForm = row;

            // 回显提醒对象-其他人员
            this.addMessageForm.others = [];
            this.addMessageForm.remindPersonDto.otherPerson.forEach((val, key) => {
                this.tiggerObjectVo.others.forEach((value, index) => {
                    if (val == value.id) {
                        this.addMessageForm.others.push(value.personName);
                    }
                });
            });

            // 回显提醒对象-驾驶员
            this.addMessageForm.driver = this.addMessageForm.remindPersonDto.remindDriver ? [1] : [];
            // 回显提醒对象-车队管理员
            this.addMessageForm.administrators = this.addMessageForm.remindPersonDto.remindManager ? [1] : [];

            // 回显电子围栏
            this.regionObj.label = this.addMessageForm.triggerDesc;

            // this.loadAmap()
            // this.loadMapDistrict()
        },
        //获取电子围栏列表tbody里的数据
        getTbodyData1() {
            this.tableData1Loading = true;
            this.tableData1 = []; //  清空table的值
            var json = JSON.parse(JSON.stringify(this.navForm1));
            this.instance.post('/ubi/api/fence/page', json).then((res) => {
                this.tableData1Loading = false;
                let data = res.data;
                if (data.code == 200) {
                    var tableData1 = res.data.data.list;
                    tableData1.length > 0 &&
                        tableData1.forEach((val, key) => {
                            val.messageStatus = Boolean(val.messageStatus);
                        });
                    this.tableData1 = tableData1;
                    this.totalPage1 = parseInt(res.data.data.total);
                }
            });
        },
        //获取报警列表tbody里的数据
        getTbodyData2() {
            this.loading = true;
            this.tableData2 = []; //  清空table的值
            var json = JSON.parse(JSON.stringify(this.navForm2));
            json.condition.eventTimeMin = json.startTime;
            json.condition.eventTimeMax = json.endTime;
            this.instance.post('/ubi/api/fence/event/page', json).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.loading = false;
                    this.tableData2 = data.data.list;
                    this.totalPage2 = parseInt(data.data.total);
                } else {
                    this.$message(res.data.message);
                }
            });
        },
        //获取围栏绑定车辆列表tbody里的数据
        getTbodyData3() {
            this.loading = true;
            this.tableData3 = []; //  清空table的值
            var json = JSON.parse(JSON.stringify(this.navForm3));
            json.pageNo = this.currentPage3;
            json.pageSize = this.pageSize3;

            if (json.groupId) {
                const tmpData = json.groupId;
                json.groupId = json.condition;
                json.condition = [tmpData];
            }
            this.instance.post('/ubi/api/fence/fetchFenceVehicle', json).then((res) => {
                this.loading = false;
                let data = res.data;
                if (data.code == 200) {
                    this.tableData3 = data.data.list;
                    this.totalPage3 = parseInt(data.data.total);
                }
            });
        },
        //分页：当前页改变之后的函数
        handleCurrentChange1: function (val) {
            this.navForm1.pageNo = Number(val);
            this.getTbodyData1();
        },
        //分页：当前页改变之后的函数
        handleCurrentChange2(val) {
            this.navForm2.pageNo = Number(val);
            this.getTbodyData2();
        },
        //分页：当前页改变之后的函数
        handleCurrentChange3: function (val) {
            this.currentPage3 = Number(val);
            this.getTbodyData3();
        }
    }
};
</script>
<style scoped>
@import '../../assets/css/table_white.css';
.info_box {
    /* width: 500px; */
}
.bind_vehicle_dialog {
    padding-bottom: 20px;
}
#container {
    width: 4.5rem;
    height: 3.5rem;
}
.map_dialog {
    padding: 0.1rem 0 0.4rem;
}
#container_map {
    width: 8rem;
    height: 4rem;
    margin: 0 auto;
}
.search_box .el-form--inline .el-form-item {
    margin-bottom: 0;
}
.time_quantum {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.time_quantum li {
    line-height: 1.4;
    padding: 5px 8px;
    border: 1px solid #666;
    border-radius: 5px;
    margin-right: 0.2rem;
    margin-bottom: 0.2rem;
    cursor: pointer;
}
.time_quantum .active {
    background-color: #409eff;
    color: #fff;
    border: 1px solid #409eff;
}
.add_message {
    width: 6rem;
    margin-right: 15px;
}
.add_message .el-form-item {
    margin-bottom: 10px;
}
.table_box {
    margin-top: 0.2rem;
}
.risk_event {
    display: flex;
    width: 100%;
    padding: 0 0.2rem;
}
.risk_event div:first-child {
    margin-right: 2rem;
}
.event_item p {
    margin: 0.3rem 0 0.2rem;
    font-size: 0.2rem;
}
.detail_title {
    margin-bottom: 0;
}
.content {
    position: relative;
    height: 100%;
}
.hint_obj_box /deep/.el-form-item__label {
    display: block;
    width: 100%;
    text-align: left;
}
.form_box {
    margin-top: 0.2rem;
    width: 100%;
    display: flex;
}
.form_box .el-form-item {
    /* margin-bottom: 0.2rem; */
}
/deep/.el-dialog__body {
    padding: 0 0.5rem;
}
.dialog_box {
    padding: 0 0 0.5rem;
}
.hint_title {
    margin-top: 0.15rem;
    font-size: #000;
}
.hint_info {
    margin-top: 0.2rem;
    border: 1px solid #c0c0c0;
    padding: 0.15rem 0.3rem;
    min-height: 1rem;
}
</style>
