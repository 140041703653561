<template>
    <div class="dialog_section">
        <div class="content_box">
            <div class="search_box">
                <!--检索部分-->
                <el-form :inline="true" :model="navForm" class="demo-form-inline">
                    <!-- <el-form-item label="设备编号/车架号/车牌号:">
								<el-input class="width_150" v-model="navForm.search"  size="medium" placeholder="请输入" clearable></el-input>
							</el-form-item> -->
                    <el-form-item label="行驶时间:" class="dateBox">
                        <el-row style="width: 320px">
                            <el-col :span="11">
                                <el-date-picker size="medium" placeholder="请选择月份" v-model="navForm.startTime" type="month" value-format="yyyy-MM" style="width: 100%"> </el-date-picker>
                            </el-col>
                            <el-col class="line" style="text-align: center" :span="2">至</el-col>
                            <el-col :span="11">
                                <el-date-picker size="medium" placeholder="请选择月份" v-model="navForm.endTime" type="month" value-format="yyyy-MM" style="width: 100%"> </el-date-picker>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item>
                        <el-button-group>
                            <el-button type="primary" plain :autofocus="true" size="small" @click="selectMonth(3)">前三月</el-button>
                            <el-button type="primary" plain size="small" @click="selectMonth(6)">前半年</el-button>
                        </el-button-group>
                        <el-button type="primary" @click="searchTable" size="medium" style="margin-left: 0.2rem">查询</el-button>
                        <el-button v-if="modeForm.totalMileage >= 100 && 0" type="success" @click="handleDownload" size="medium" style="margin-left: 0.2rem">下载报告</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <template v-if="platNo">
                <div class="detail_title">基础信息</div>
                <ul class="detail_form">
                    <li>
                        <span>车牌号：</span>
                        <b>{{ platNo }}</b>
                    </li>
                    <li>
                        <span>查询时段：</span>
                        <b v-if="modeForm.tripTimeMin">{{ modeForm.tripTimeMin + ' 至 ' + modeForm.tripTimeMax }}</b>
                        <!-- <b v-if="!mileageLoading && !modeForm.tripTimeMin">--</b> -->
                    </li>
                </ul>
            </template>
        </div>
        <div class="statistic_data" v-show="modeForm.totalMileage">
            <div class="content_box statistic_left">
                <div class="detail_title">
                    <b>统计数据</b>
                    <span v-if="modeForm.totalMileage < 100">该车辆行驶里程过短，统计数据仅供参考</span>
                </div>
                <div class="score_box">
                    <p>驾驶评分</p>
                    <template v-if="modeForm.score > 0">
                        <div class="score">
                            <b>{{ modeForm.score }}</b>
                            <span>分</span>
                        </div>
                        <el-progress :show-text="false" :stroke-width="8" :percentage="modeForm.score" color="#05A9C5"></el-progress>
                    </template>
                    <template v-else>
                        <div class="null_score">
                            <!-- <span>Hi, 行程太少啦，暂无评分哦~</span> -->
                            <!-- <div><img src="../../public/images/msi/null_score.png" alt=""></div> -->
                            <img src="../../public/images/msi/null_score1.png" alt="" />
                        </div>
                    </template>
                </div>
                <div id="radar_chart"></div>
            </div>
            <div class="statistic_right">
                <ul class="flex">
                    <li>
                        <div class="item_top">
                            <div class="item_img"><img src="../../public/images/msi/licheng-3.png" /></div>
                            <div class="item_text">
                                <p>日均行驶里程</p>
                                <div>
                                    <span>优于</span>
                                    <b>{{ modeForm.avgMileBetterPerc }}%</b>
                                    <span>车辆</span>
                                </div>
                            </div>
                        </div>
                        <div class="item_num">
                            <b>{{ modeForm.avgMile }}</b>
                            <span>km</span>
                        </div>
                    </li>
                    <li>
                        <div class="item_top">
                            <div class="item_img"><img src="../../public/images/msi/hangshishichang.png" /></div>
                            <div class="item_text">
                                <p>日均行驶时长</p>
                                <div>
                                    <span>优于</span>
                                    <b>{{ modeForm.avgDurationBetterPerc }}%</b>
                                    <span>车辆</span>
                                </div>
                            </div>
                        </div>
                        <div class="item_num">
                            <b>{{ modeForm.avgDuration }}</b>
                            <span>h</span>
                        </div>
                    </li>
                    <li>
                        <div class="item_top">
                            <div class="item_img"><img src="../../public/images/msi/zaogaofeng.png" /></div>
                            <div class="item_text">
                                <p>早晚高峰日均行驶时长</p>
                                <div>
                                    <span>优于</span>
                                    <b>{{ modeForm.avgRushDrivingHourBetterPerc }}%</b>
                                    <span>车辆</span>
                                </div>
                            </div>
                        </div>
                        <div class="item_num">
                            <b>{{ modeForm.avgRushDrivingHour }}</b>
                            <span>h</span>
                        </div>
                    </li>
                </ul>
                <ul class="flex" style="margin-top: 1.5%">
                    <li>
                        <div class="item_top">
                            <div class="item_img"><img src="../../public/images/msi/fatigue-early-warning_line.png" /></div>
                            <div class="item_text">
                                <p>日均疲劳驾驶时长</p>
                                <div>
                                    <span>优于</span>
                                    <b>{{ modeForm.avgFatiguedDrivingHourBetterPerc }}%</b>
                                    <span>车辆</span>
                                </div>
                            </div>
                        </div>
                        <div class="item_num">
                            <b>{{ modeForm.avgFatiguedDrivingHour }}</b>
                            <span>h</span>
                        </div>
                    </li>
                    <li>
                        <div class="item_top">
                            <div class="item_img"><img src="../../public/images/msi/cheliangzhongdian-chaosu.png" /></div>
                            <div class="item_text">
                                <p>百公里三急次数</p>
                                <div>
                                    <template v-if="modeForm.totalMileage >= 100">
                                        <span>优于</span>
                                        <b>{{ modeForm.alarm100KMBetterPerc }}%</b>
                                        <span>车辆</span>
                                    </template>
                                    <span v-else>暂无数据</span>
                                </div>
                            </div>
                        </div>
                        <div class="item_num">
                            <template v-if="modeForm.totalMileage >= 100">
                                <b>{{ Math.ceil(modeForm.alarm100KM / 1) }}</b>
                                <span>次</span>
                            </template>
                            <span v-else>暂无数据</span>
                        </div>
                    </li>
                    <li>
                        <div class="item_top">
                            <div class="item_img"><img src="../../public/images/msi/icon-jijiasu.png" /></div>
                            <div class="item_text">
                                <p>百公里超速次数</p>
                                <div>
                                    <template v-if="modeForm.totalMileage >= 100">
                                        <span>优于</span>
                                        <b>{{ modeForm.overSpeed100KMBetterPerc }}%</b>
                                        <span>车辆</span>
                                    </template>
                                    <span v-else>暂无数据</span>
                                </div>
                            </div>
                        </div>
                        <div class="item_num">
                            <template v-if="modeForm.totalMileage >= 100">
                                <b>{{ modeForm.overSpeed100KM }}</b>
                                <span>次</span>
                            </template>
                            <span v-else>暂无数据</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div v-if="!modeForm.score" v-loading="mileageLoading" style="margin-top: 2.1rem"></div>
        <div v-show="!modeForm.score && !mileageLoading" style="margin-top: 0.8rem">
            <el-empty description="暂无数据"></el-empty>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
    name: 'BehaviouralDetail',
    props: {
        dialogVisible: Boolean,
        platNo: {
            type: String,
            default: ''
        },
        queryForm: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            plateno: '',
            myChart: '',
            loading: false,
            percentage: 89,
            navForm: {
                //储存搜索框的检索条件信息
                startTime: '',
                endTime: ''
            },
            modeForm: {
                totalMileage: null
            },
            mileageLoading: true
        }
    },
    mounted() {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
        this.navForm.companyId = this.userInfo.company
        window.addEventListener('resize', () => {
            if (this.myChart) {
                setTimeout(() => {
                    this.myChart.dispose()
                    this.loadCharts()
                }, 500)
            }
        })

        if (this.platNo) {
            this.navForm = {
                startTime: this.queryForm ? this.queryForm.startTime : '',
                endTime: this.queryForm ? this.queryForm.endTime : ''
            }
            this.navForm.platNo = this.platNo ? this.platNo : null
            this.initData(this.navForm)
        }
    },
    methods: {
        //点击父组件的查看报警时调用的方法
        initData(navForm) {
            if (navForm.startTime && navForm.endTime) {
                this.navForm = JSON.parse(JSON.stringify(navForm))
            } else {
                this.selectMonth(3)
            }
            this.getTbodyData()
        },
        beforeClose(done) {
            this.navForm = {
                startTime: '',
                endTime: ''
            }
            this.$emit('beforeClose')
        },
        loadCharts() {
            var chartDom = document.getElementById('radar_chart')
            this.myChart = echarts.init(chartDom, null, { renderer: 'svg' })
            var modeForm = this.modeForm
            var seriesData = [modeForm.avgMileBetterPerc, modeForm.avgDurationBetterPerc, modeForm.avgFatiguedDrivingHourBetterPerc, modeForm.alarm100KMBetterPerc, modeForm.overSpeed100KMBetterPerc]
            var option = {
                title: {
                    text: '驾驶行为因子评分统计',
                    textStyle: {
                        color: '#05A9C5',
                        fontSize: '0.24rem',
                        fontWeight: '300'
                    },
                    bottom: 30,
                    left: 'center'
                },
                radar: {
                    // shape: 'circle',
                    radius: '40%',
                    center: ['50%', '45%'],
                    axisName: {
                        show: true,
                        // width:40,
                        lineHeight: 12,
                        overflow: 'break',
                        fontSize: '0.14rem'
                    },
                    nameGap: 8,
                    indicator: [
                        { name: '日均行驶里程因子', max: 100, color: '#051932' },
                        { name: '日均行驶时长因子', max: 100, color: '#051932' },
                        { name: '日均疲劳驾驶因子', max: 100, color: '#051932' },
                        { name: '百公里三急因子', max: 100, color: '#051932' },
                        { name: '百公里超速因子', max: 100, color: '#051932' }
                    ],
                    splitArea: {
                        show: false
                    },
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(207, 229, 243, 1)'
                        }
                    }
                },
                tooltip: {
                    show: false
                },
                series: [
                    {
                        name: '驾驶评分因素',
                        type: 'radar',
                        lineStyle: {
                            color: '#CFE5F3'
                        },
                        symbol: 'none',
                        areaStyle: {
                            color: 'rgba(0, 192, 224, 0.2)'
                        },
                        lineStyle: {
                            color: 'rgba(0, 192, 224, 0.2)',
                            width: 3
                        },
                        data: [
                            {
                                value: seriesData
                            }
                        ]
                    }
                ]
            }
            this.myChart.setOption(option)
        },
        //选择日期
        selectDate(sign) {
            const start = new Date()
            const end = new Date()
            if (sign == 'today') {
                this.navForm.startTime = this.dateFormatting(start, 'yyyy-MM-dd')
                this.navForm.endTime = this.dateFormatting(end, 'yyyy-MM-dd')
            } else {
                var startTime = this.dateFormatting(new Date(start.getTime() - 3600 * 1000 * 24 * sign), 'yyyy-MM-dd')
                var endTime = this.dateFormatting(new Date(start.getTime() - 3600 * 1000 * 24 * 30), 'yyyy-MM-dd')
                this.navForm.startTime = startTime.slice(0, 7)
                this.navForm.endTime = endTime.slice(0, 7)
                // this.navForm.endTime=this.dateFormatting(end,'yyyy-MM-dd');
            }
        },
        //点击查询
        searchTable() {
            this.getTbodyData('search')
            // this.navForm.startTime && this.navForm.endTime && this.getTbodyData('search')
        },
        //下载
        handleDownload() {
            this.navForm.search = this.platNo
            this.instance
                .get(
                    '/screen/exportDrivingBehavior', //接口
                    {
                        params: this.navForm,
                        responseType: 'blob'
                    }
                )
                .then((res) => {
                    if (res.data.size < 100) {
                        this.$message.error('无数据')
                        // console.log('无数据');
                    } else {
                        var keyword = this.plateno ? this.plateno : this.platNo
                        // this.deviceNumber+
                        let url = window.URL.createObjectURL(new Blob([res.data]))
                        let link = document.createElement('a')
                        link.style.display = 'none'
                        link.href = url
                        link.setAttribute('download', 'UBI车联网分析报告-' + keyword + '.pdf') //指定下载后的文件名，防跳转
                        document.body.appendChild(link)
                        link.click()
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        //获取tbody里的数据
        getTbodyData(sign) {
            this.mileageLoading = true
            let tmpData = {
                tripTimeMin: this.navForm.startTime ? this.navForm.startTime + '-01 00:00:00' : null,
                tripTimeMax: this.navForm.endTime ? this.navForm.endTime + '-01 00:00:00' : '',
                platNo: this.platNo
            }
            this.instance.post('/ubi/api/vehicle/drivingBehaviorAnalysis', tmpData).then((res) => {
                this.mileageLoading = false
                let data = res.data
                if (data.code == 200) {
                    for (const key in data.data) {
                        data[key] = String(data.data[key]).indexOf('.') > -1 ? Number(data.data[key].toFixed(2)) : data.data[key]
                    }
                    this.modeForm = data
                    setTimeout(() => {
                        this.loadCharts()
                    }, 300)
                } else {
                    sign == 'search' && this.$message.error(res.data.message)
                }
            })
        }
    }
}
</script>
<style scoped>
@import '../assets/css/table_white.css';
.content {
    position: relative;
}
.detail_title {
    margin: 0 auto 0.2rem;
}
.statistic_left .detail_title {
    padding-left: 0.2rem;
}
.detail_title span {
    color: #e4393c;
    font-size: 12px;
    margin-left: 0.1rem;
    width: 68%;
    line-height: 1.3;
}
.detail_form {
    border: 0;
    padding-bottom: 0.1rem;
}
.statistic_data {
    margin-top: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.statistic_left {
    width: 27.9%;
    height: 6.02rem;
    padding: 0.2rem 0;
}
.statistic_right {
    width: 70.53%;
    height: 6.02rem;
    margin-left: 0.2rem;
}
.score_box {
    width: 92%;
    min-height: 1.3rem;
    border-radius: 0.06rem;
    border: 1px solid #b6e3ea;
    padding: 0.2rem 0.3rem;
    font-size: 0.18rem;
    color: #051932;
    margin: 0 auto;
}
.score {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    color: #05a9c5;
    margin-bottom: 0.12rem;
}
.score b {
    font-size: 0.46rem;
    font-family: DINAlternate-Bold, DINAlternate;
    /* font-weight: bold; */
}
.null_score {
    width: 100%;
    display: flex;
    align-items: center;
    /* padding-left:0.7rem; */
    color: #828893;
    font-size: 0.14rem;
}
.null_score div {
    width: 1rem;
    margin-left: 0.2rem;
}
.statistic_right ul {
    width: 100%;
}
.statistic_right li {
    width: 32%;
    height: 2.9rem;
    background: #ffffff;
    box-shadow: 0px 2px 14px 0px rgba(222, 222, 222, 0.5);
    border-radius: 0.06rem;
    padding-top: 0.4rem;
}
.item_top {
    display: flex;
    width: 100%;
    justify-content: center;
}
.item_img {
    width: 0.5rem;
    margin-right: 0.14rem;
}
.item_text p {
    font-size: 0.24rem;
    margin-bottom: 0.1rem;
}
.item_text span {
    font-size: 0.2rem;
}
.item_text b {
    color: #05a9c5;
    font-size: 0.24rem;
}
.item_num {
    margin-top: 0.6rem;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: baseline;
    font-size: 0.2rem;
    font-family: DINAlternate-Bold, DINAlternate;
    font-weight: 600;
    color: #000000;
}
.item_num b {
    font-size: 0.36rem;
}
#radar_chart {
    width: 100%;
    height: 3.9rem;
    margin-top: 0.1rem;
}
</style>
