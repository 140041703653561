<template>
    <div class="content" v-cloak>
        <div class="page_title">驾驶行为分析</div>
        <div class="content_box">
            <div class="search_box">
                <!--检索部分-->
                <el-form :inline="true" :model="navForm" class="demo-form-inline">
                    <el-form-item label="行驶时间:" class="dateBox">
                        <el-row style="width: 480px">
                            <el-col :span="7">
                                <el-date-picker size="small" placeholder="请选择月份" v-model="navForm.startTime" type="month" value-format="yyyy-MM" style="width: 100%"> </el-date-picker>
                            </el-col>
                            <el-col class="line" style="text-align: center" :span="2">至</el-col>
                            <el-col :span="7">
                                <el-date-picker size="small" placeholder="请选择月份" v-model="navForm.endTime" type="month" value-format="yyyy-MM" style="width: 100%"> </el-date-picker>
                            </el-col>
                            <el-col :span="1" style="height: 20px"></el-col>
                            <el-col :span="7">
                                <el-button-group>
                                    <el-button type="primary" plain size="small" @click="selectMonths(3)">前三月</el-button>
                                    <el-button type="primary" plain size="small" @click="selectMonths(6)">前半年</el-button>
                                </el-button-group>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item label="设备编号/车架号/车牌号:">
                        <el-input class="width_150" v-model="navForm.condition.search" size="small" placeholder="请输入" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="设备发放机构:" v-if="!navForm.companyId && 0">
                        <el-input class="width_150" v-model="navForm.company" size="small" placeholder="请输入" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="使用性质:">
                        <el-select class="width_150" v-model="navForm.condition.vehicleUseType" placeholder="请选择" size="small" clearable>
                            <el-option label="全部" value=""></el-option>
                            <el-option label="营运" value="2"></el-option>
                            <el-option label="非营运" value="1"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="选择分公司:" prop="companyId">
                        <el-select class="width_180" v-model="navForm.condition.companyId" placeholder="请选择" size="small" clearable @change="getBatchIdList">
                            <el-option v-for="(item, index) in companyList" :key="index" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="选择车队:" prop="fleetId">
                        <el-select class="width_150" v-model="navForm.condition.fleetId" placeholder="请选择" size="small" clearable>
                            <el-option v-for="(item, index) in batchIdList" :key="index" :label="item.customerId" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="searchTable" size="small" style="margin-left: 0.2rem">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="table_box">
                <!--table表格部分-->
                <el-table ref="multipleTable" :data="tableData" v-loading="loading" :height="tableHeight" @sort-change="sortChange">
                    <el-table-column label="序号" type="index" width="80" :index="hIndex"></el-table-column>
                    <el-table-column prop="deviceNumber" label="设备编号"></el-table-column>
                    <el-table-column prop="platNo" label="绑定车牌号"></el-table-column>
                    <!-- <el-table-column prop="score" label="驾驶评分" ></el-table-column> -->
                    <el-table-column prop="totalMileage" label="驾驶总里程（km）" sortable="custom"></el-table-column>
                    <el-table-column prop="totalDuration" label="驾驶总时长（h）" sortable="custom"></el-table-column>
                    <el-table-column prop="totalAlarmNum" label="三急一超报警总次数" sortable="custom" width="170"></el-table-column>
                    <el-table-column label="报警" v-if="!userInfo.batchId">
                        <template slot-scope="scope">
                            <div class="warning_icon">
                                <img v-if="scope.row.totalAlarmNum > 0" src="../../../public/images/msi/wraning_icon.png" alt="" />
                                <img v-else src="../../../public/images/msi/success_icon.png" alt="" />
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="150">
                        <template slot-scope="scope">
                            <div class="pointer">
                                <span @click="handleLookDetail(scope.row, '')">分析详情</span>
                                <template v-if="!userInfo.batchId">
                                    <span v-if="_versions != 'tuanche'" @click="handleLookArea(scope.row)" class="last_span">车辆轨迹</span>
                                    <span class="last_span" @click="handleLookDetail(scope.row, 'warning')">查看报警</span>
                                </template>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <!--分页部分-->
                <el-pagination style="margin-top: 0.2rem" @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="currentPage" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="10" :total="totalPage"> </el-pagination>
            </div>
        </div>
        <el-dialog :visible.sync="areaDialogVisible" title="车辆轨迹监控" :before-close="handleDialogClose" center :modal-append-to-body="false" width="900px">
            <el-tabs v-model="activeName" @tab-click="handleClickTab">
                <el-tab-pane label="车辆实时位置" name="first">
                    <el-descriptions title="">
                        <el-descriptions-item label="设备编号">{{ detailForm.deviceNumber }}</el-descriptions-item>
                        <el-descriptions-item label="车牌号">{{ detailForm.plateNo }}</el-descriptions-item>
                        <el-descriptions-item label="最近定位时间">{{ detailForm.lastDataTime }}</el-descriptions-item>
                        <el-descriptions-item label="设备状态">{{ detailForm.onlineStatus }}</el-descriptions-item>
                        <el-descriptions-item label="插拔状态">{{ detailForm.plugginStatus }}</el-descriptions-item>
                        <el-descriptions-item v-if="detailForm.plugginStatus == '插入'" :label="detailForm.plugginStatus == '插入' ? '插入时间' : '拔出时间'">{{ detailForm.lastInsetTime }}</el-descriptions-item>
                        <el-descriptions-item v-if="detailForm.plugginStatus == '拔出'" :label="detailForm.plugginStatus == '插入' ? '插入时间' : '拔出时间'">{{ detailForm.lastPulloutTime }}</el-descriptions-item>
                    </el-descriptions>
                </el-tab-pane>
                <el-tab-pane label="车辆轨迹区域" name="second">
                    <div class="dialog_box" v-loading="areaLoading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
                        <!--检索部分-->
                        <el-form :model="areaForm" :inline="true" class="demo-form-inline" style="margin-top: 10px">
                            <el-form-item label="设备编号:" style="margin-right: 30px">
                                <span>{{ areaForm.condition.deviceNumber }}</span>
                            </el-form-item>
                            <el-form-item label="车牌号:" style="margin-right: 30px">
                                <span>{{ areaForm.condition.platNo }}</span>
                            </el-form-item>
                            <el-form-item label="行驶时间:">
                                <el-row style="width: 320px">
                                    <el-col :span="11">
                                        <el-date-picker placeholder="请选择日期" v-model="areaForm.startTime" type="date" size="small" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                                    </el-col>
                                    <el-col class="line" style="text-align: center" :span="2">至</el-col>
                                    <el-col :span="11">
                                        <el-date-picker placeholder="请选择日期" v-model="areaForm.endTime" type="date" size="small" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                                    </el-col>
                                </el-row>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" size="medium" @click="handleSearchAreaMap" style="margin-left: 0.2rem">查询</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </el-tab-pane>
            </el-tabs>
            <!-- 地图展示 -->
            <div class="dialog_map" v-loading="polylineLoading">
                <baidu-map v-if="!polylineLoading" v-loading="mapLoading" :zoom="zoom" id="container" @ready="handler" :center="center" :max-zoom="22" :mapClick="false" :scroll-wheel-zoom="true">
                    <bm-scale v-if="activeName == 'second'" anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-scale>
                    <bm-marker v-if="activeName == 'second'" :position="startPoint" :icon="startIcon"></bm-marker>
                    <bm-marker v-if="activeName == 'second'" :position="endPoint" :icon="endIcon"></bm-marker>
                    <bm-polyline v-if="activeName == 'second'" :path="polylinePath" stroke-color="#0766FE" :stroke-weight="5" :strokeOpacity="1"></bm-polyline>
                    <bm-marker v-if="activeName == 'first' && positionIconFlag" :position="positionPoint" :icon="positionIcon" @click="lookDetail()">
                        <bm-info-window :position="{ lng: 70, lat: 60 }" :show="marker.showFlag" @close="infoWindowClose(marker)" @open="infoWindowOpen(marker)">
                            <p>经度: {{ detailForm.lastDataLongitude }}</p>
                            <p>维度:{{ detailForm.lastDataLatitude }}</p>
                            <p>定位位置:{{ detailForm.lastAddress }}</p>
                            <p>定位时间:{{ detailForm.lastDataTime }}</p>
                        </bm-info-window>
                    </bm-marker>
                </baidu-map>
            </div>
        </el-dialog>
        <!-- 分析详情 -->
        <el-dialog class="dialog-title_style" :visible.sync="dialogVisible" title="驾驶行为分析" width="90%" :modal-append-to-body="false">
            <behavioural-detail ref="behaviouralChild" v-if="dialogVisible" :platNo="platNo" :queryForm="navForm"></behavioural-detail>
        </el-dialog>
        <!-- 查看报警 -->
        <el-dialog class="dialog-title_style" :visible.sync="dialogWarningVisible" title="驾驶行为分析/报警提示详情" width="90%" :modal-append-to-body="false">
            <warning-detail ref="warningChild" v-if="dialogWarningVisible" :deviceNumber="deviceNumber"></warning-detail>
        </el-dialog>
    </div>
</template>

<script>
import BehaviouralDetail from '@/components/BehaviouralDetail.vue';
import WarningDetail from '@/components/WarningDetail.vue';
import AMapLoader from '@amap/amap-jsapi-loader';
import { BmlMarkerClusterer } from 'vue-baidu-map';
export default {
    name: 'BehaviouralAnalysis',
    props: {
        msg: String
    },
    components: {
        BmlMarkerClusterer,
        BehaviouralDetail,
        WarningDetail
    },
    data() {
        return {
            tableHeight: window.innerHeight > 700 ? 395 : 364,
            tmpGpsMap: [],
            startIcon: '',
            startPoint: '',
            positionPoint: '',
            positionIcon: '',
            positionIconFlag: false,
            mapLoading: false,
            endIcon: '',
            endPoint: '',
            circlePath: {
                center: {
                    lng: 116.404,
                    lat: 39.915
                },
                radius: 1000,
                editing: false
            }, //百度地图电子围栏
            center: { lng: 116.404269, lat: 39.915378 },
            zoom: 16,
            BDmap: '',
            BMap: '',
            version: '',
            loading: false,
            dialogVisible: false,
            areaDialogVisible: false,
            dialogWarningVisible: false,
            fullscreenLoading: false,
            detailPageVisible: false,
            areaLoading: false,
            deviceNumber: '',
            GDmap: '',
            formType: '', //页面类型
            navForm: {
                //储存搜索框的检索条件信息
                condition: {
                    tripTimeMin: null,
                    tripTimeMax: null,
                    companyId: null,
                    vehicleUseType: null,
                    fleetId: null,
                    search: null
                },
                startTime: null,
                endTime: null,
                orders: [],
                pageNo: 1,
                pageSize: 20
            },
            areaForm: {
                condition: {
                    deviceNumber: null,
                    platNo: null,
                    vehicleUseType: null,
                    search: null,
                    tripTimeMin: null,
                    tripTimeMax: null
                },
                startTime: null,
                endTime: null,
                pageNo: 1,
                pageSize: 10
            },
            polyline: '',
            heatmap: '',
            batchIdList: [], //车队
            currentPage: 1, //当前页
            pageSize: 10, //一页多少条数据
            totalPage: 0, //总页数tableData:[],
            tableData: [],
            checkedMap: '百度',
            platNo: '',
            polylinePath: [],
            lastDataTime: '',
            activeName: 'first',
            detailForm: {},
            marker: { showFlag: false },
            polylineLoading: false,
            companyList: []
        };
    },
    mounted() {
        this.version = this.$store.state.version;
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.navForm.companyId = this.userInfo.company;

        // this.selectMonths(3, 'defalut')
        this.getCompanyList();
        this.getData();
    },
    methods: {
        handleClickTab() {
            this.marker.showFlag = false;

            if (this.activeName == 'second' && !this.startPoint) {
                this.selectDate(7);
                this.getRouteInfo();
                this.center = { lng: 116.404269, lat: 39.915378 };
            } else if (this.activeName != 'first') {
                this.center = { lng: this.detailForm.lastDataLongitude, lat: this.detailForm.lastDataLatitude };
                setTimeout(() => {
                    this.BDmap.setViewport(this.polylinePath);
                }, 100);
            }

            if (this.activeName == 'first') {
                this.getVehicleInfo(this.areaForm.condition.deviceNumber);
            }
        },
        selectMonths(num, sign) {
            var nowDate = new Date();
            var year = nowDate.getFullYear();
            var month = nowDate.getMonth() + 1; //0-11表示1-12月
            var startMonth = '',
                endMonth = '';
            if (month - num <= 0) {
                startMonth = year - 1 + '-' + (12 - (num - month)).toString().padStart(2, '0');
            } else {
                startMonth = year + '-' + (month - num).toString().padStart(2, '0');
            }
            let _num = sign == 'defalut' ? 0 : 1;

            if (month - 1 == 0) {
                if (sign == 'defalut') {
                    endMonth = year + '-' + month.toString().padStart(2, '0');
                } else {
                    endMonth = year - 1 + '-' + (12 - (_num - month)).toString().padStart(2, '0');
                }
            } else {
                endMonth = year + '-' + (month - _num).toString().padStart(2, '0');
            }
            this.navForm.startTime = startMonth;
            this.navForm.endTime = endMonth;
            this.$forceUpdate();
        },
        handler({ BMap, map }) {
            this.BMap = BMap;
            this.BDmap = map;
        },
        // 查看区域dialog关闭时的事件
        handleDialogClose() {
            this.areaDialogVisible = false;
            this.activeName = '';
            // 关闭弹窗初始化，展示逻辑需要用
            this.startIcon = '';
            this.startPoint = '';
            this.positionPoint = '';
            this.positionIcon = '';
            this.endIcon = '';
            this.endPoint = '';
        },
        // 点击车辆轨迹
        handleLookArea(row) {
            this.areaDialogVisible = true;
            this.areaForm.condition.deviceNumber = row.deviceNumber;
            this.areaForm.condition.platNo = row.platNo;
            this.activeName = 'first';
            this.getVehicleInfo(row.deviceNumber);
        },
        // 车辆实时位置
        getVehicleInfo(rowDeviceNumber) {
            this.positionIconFlag = false;
            this.instance.get(`/ubi/api/device/location?deviceNumber=${rowDeviceNumber}`).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.positionIconFlag = true;
                    this.detailForm = data.data;

                    // 测试数据，测试环境没有这些字段，没啥强迫症别删
                    // this.detailForm.lastDataLongitude = 103.860691
                    // this.detailForm.lastDataLatitude = 30.646956
                    // this.detailForm.lastAddress = '四川省成都市双流区彭柑路'
                    // this.detailForm.lastDataTime = '2023-04-19 17:58:20'

                    // 车辆图标
                    this.positionIcon = {
                        url: require('../../../public/images/msi/online/car_icon.png'),
                        size: {
                            width: 24,
                            height: 46
                        },
                        opts: {
                            anchor: {
                                width: 24,
                                height: 46
                            }
                        }
                    };

                    // 车辆坐标
                    this.positionPoint = {
                        lng: data.data.bdLon,
                        lat: data.data.bdLat
                    };

                    // 地图中心点
                    this.center.lng = data.data.bdLon;
                    this.center.lat = data.data.bdLat;
                }
            });
        },
        // 点击点坐标赋值
        lookDetail() {
            this.marker.showFlag = true;
        },
        // 关闭弹窗
        infoWindowClose() {
            this.marker.showFlag = false;
        },
        // 打开弹窗
        infoWindowOpen() {
            this.marker.showFlag = true;
        },
        getRouteInfo(row) {
            var _this = this;
            this.polyline && this.GDmap.remove(this.polyline);
            // this.areaLoading = true
            this.areaForm.condition.platNo = row ? row.platNo : this.areaForm.condition.platNo;
            this.areaForm.condition.deviceNumber = row ? row.deviceNumber : this.areaForm.condition.deviceNumber;
            const { startTime, endTime, ...newAreaForm } = this.areaForm;
            newAreaForm.condition.tripTimeMin = startTime + ' 00:00:00';
            newAreaForm.condition.tripTimeMax = endTime + ' 00:00:00';
            this.polylineLoading = true;
            this.instance.post('/ubi/api/trip/page', newAreaForm).then((res) => {
                this.polylineLoading = false;
                if (res.data.code == 200) {
                    let tmpdata = res.data.data.list;
                    if (tmpdata.length == 0) {
                        // this.areaLoading = false
                        this.polylinePath = [];
                        this.startIcon = '';
                        this.endIcon = '';
                        this.center = { lng: 116.404269, lat: 39.915378 };
                        this.BDmap.clearOverlays();
                        this.$message.error('抱歉，当前无行程！');
                        return false;
                    }

                    let uuidArr = [];
                    tmpdata.forEach((item) => {
                        uuidArr.push(item.tripUuid);
                    });
                    this.initGPSData(uuidArr);
                }
            });
        },
        //处理GPS数据
        initGPSData(uuidArr) {
            this.AMapCircleRadio = '关闭编辑电子围栏';
            let gpsData = [];
            let gpsMap;
            this.mapLoading = true;
            this.instance.post('/ubi/api/trajectory/tracking/gps', uuidArr).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    gpsMap = data.data;
                    // 合并gps行程并按照时间顺序排序
                    let tmpGpsMapArr = [];
                    for (const key in gpsMap) {
                        if (gpsMap[key] && key != 'accidentGPS') {
                            gpsMap[key].forEach((item) => {
                                tmpGpsMapArr.push(item);
                            });
                        }
                    }
                    tmpGpsMapArr.sort((a, b) => {
                        return new Date(a.t).getTime() - new Date(b.t).getTime();
                    });
                    gpsData = tmpGpsMapArr;
                    if (gpsData.length > 0) {
                        switch (this.checkedMap) {
                            case '高德':
                                gpsData.forEach((val, key) => {
                                    let obj = this.BMaptoAMap(val.lon, val.lat);
                                    val.lng = obj.lng;
                                    val.lat = obj.lat;
                                });
                                this.loadAmap(gpsData);

                                break;
                            case '百度':
                                this.tmpGpsMap = gpsData;
                                this.setLineMarker(gpsData);
                                // this.setBMapCircle(gpsData)
                                break;

                            default:
                                break;
                        }
                    }
                }
            });
        },
        //百度地图描绘地图的点和线
        setLineMarker(gpsData) {
            let _this = this;
            var checkedRoute = this.checkedRoute;
            var _len = gpsData.length;
            var path = [];
            gpsData.forEach((val, key) => {
                path.push({
                    lng: val.lon,
                    lat: val.lat
                });
            });
            this.polylinePath = path;
            // 轨迹出来以后关闭loading
            this.areaLoading = false;
            this.mapLoading = false;
            this.center.lng = gpsData[0].lon;
            this.center.lat = gpsData[0].lat;
            let iconSize = {
                width: 38,
                height: 49
            };
            this.startIcon = {
                url: require('../../../public/images/depicon.png'),
                size: iconSize,
                opts: {
                    anchor: {
                        width: 19,
                        height: 49
                    }
                }
            };
            this.endIcon = {
                url: require('../../../public/images/desicon.png'),
                size: iconSize,
                opts: {
                    imageSize: iconSize,
                    anchor: {
                        width: 19,
                        height: 49
                    }
                }
            };
            this.startPoint = {
                lng: gpsData[0].lon,
                lat: gpsData[0].lat
            };
            this.endPoint = {
                lng: gpsData[_len - 1].lon,
                lat: gpsData[_len - 1].lat
            };

            setTimeout(() => {
                this.BDmap.setViewport(path);
            }, 100);
        },
        abc() {
            var newPath = [];
            data.forEach((val, key) => {
                if (val.latitude != 0) {
                    let obj = this.transformFromWGSToGCJ(val.latitude, val.longitude);
                    newPath.push(new AMap.LngLat(obj.lng, obj.lat));
                }
            });
            this.polyline = new AMap.Polyline({
                path: newPath,
                strokeWeight: 3,
                lineCap: 'round',
                strokeColor: '#0766FE', // 线条颜色
                lineJoin: 'round', // 折线拐点连接处样式
                strokeOpacity: 1 //线透明度
            });
            this.polyline.setMap(this.GDmap);
            this.GDmap.setFitView(this.polyline);
        },
        //点击查看行驶区域dialog的查询按钮
        handleSearchAreaMap() {
            let startTime = this.areaForm.startTime;
            let endTime = this.areaForm.endTime;
            if (!startTime) {
                this.$message.error('抱歉，行驶开始时间不能为空！');
                return false;
            }
            if (!endTime) {
                this.$message.error('抱歉，行驶结束时间不能为空！');
                return false;
            }
            let timeDifference = (new Date(endTime).getTime() - new Date(startTime).getTime()) / 1000 / 60 / 60 / 24;
            if (timeDifference > 30) {
                this.$message.error('抱歉，行驶时间不能超过30天！');
                return false;
            }
            //置空热力图
            // this.heatmap.setDataSet({data: []});
            this.getRouteInfo();
        },
        //获取车队
        getBatchIdList(id) {
            this.navForm.condition.fleetId = '';
            if (id) {
                this.instance.get('/ubi/api/tenant/fleetList?companyId=' + id).then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.batchIdList = data.data;
                    }
                });
            } else {
                this.batchIdList = [];
            }
        },
        //获取分公司
        getCompanyList() {
            this.instance.get('/ubi/api/tenant/companyList').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.companyList = data.data;
                }
            });
        },
        //排序
        sortChange(column) {
            let tmpIndex = this.navForm.orders.findIndex((res) => {
                return res.column == column.prop;
            });
            let tmpOrderItem = {
                asc: column.order == 'ascending' ? true : false,
                column: column.prop
            };
            this.navForm.orders = [];
            column.order && this.navForm.orders.push(tmpOrderItem);
            this.getTbodyData();
        },
        getData() {
            var vehicleNavForm = sessionStorage.getItem('vehicleNavForm');
            if (vehicleNavForm) {
                vehicleNavForm = JSON.parse(vehicleNavForm);
                this.navForm = vehicleNavForm;
                sessionStorage.removeItem('vehicleNavForm');
            }
            let tmpShowDetail = false;
            if (this.$route.query.search) {
                let tmpQueryData = this.$route.query;
                this.navForm.condition.search = tmpQueryData.search;
                tmpShowDetail = true;
            }
            this.getTbodyData(tmpShowDetail);
        },
        //关闭模态框
        beforeClose() {
            this.$emit('update:messageParent', false);
            this.dialogVisible = false;
        },
        //关闭模态框
        beforeWarningClose(sign) {
            sign && this.getTbodyData();
            this.dialogWarningVisible = false;
        },
        //重新计算index序号
        hIndex(index) {
            // index索引从零开始，index +1即为当前数据序号
            this.currentPage <= 0 ? (this.currentPage = 1) : this.currentPage;
            // 如果当前不是第一页数据
            if (this.currentPage != 1) {
                // index + 1 + (( 当前页 - 1) * 每页展示条数)
                // 比如是第二页数据 1 + ((2 - 1)*5) = 6,第二页数据也就是从序号6开始
                return index + 1 + (this.currentPage - 1) * this.pageSize;
            }
            // 否则直接返回索引+1作为序号
            return index + 1;
        },
        //选择日期
        selectDate(sign) {
            const start = new Date();
            const end = new Date();
            if (sign == 'today') {
                this.areaForm.startTime = this.dateFormatting(start, 'yyyy-MM-dd');
                this.areaForm.endTime = this.dateFormatting(end, 'yyyy-MM-dd');
            } else {
                var startTime = this.dateFormatting(new Date(start.getTime() - 3600 * 1000 * 24 * sign), 'yyyy-MM-dd');
                var endTime = this.dateFormatting(new Date(start.getTime() - 3600 * 1000 * 24), 'yyyy-MM-dd');
                this.areaForm.startTime = startTime;
                this.areaForm.endTime = endTime;
            }
        },
        //点击查看详情
        handleLookDetail(row, sign) {
            console.log(sign);
            switch (sign) {
                case 'warning':
                    this.deviceNumber = row.deviceNumber;
                    this.dialogWarningVisible = true;
                    break;
                case '':
                    this.platNo = row.platNo;
                    this.dialogVisible = true;
                    break;

                default:
                    break;
            }
        },
        //点击查询
        searchTable() {
            if (this.navForm.startTime && this.navForm.endTime && this.calcMonthInterval(this.navForm.startTime, this.navForm.endTime) > 12) {
                this.$message.error('抱歉，仅支持查询12个月及以内的数据！');
                return false;
            }
            this.currentPage = 1;
            this.getTbodyData();
        },
        //获取tbody里的数据
        getTbodyData(showDetailFlag) {
            this.loading = true;
            var _this = this;
            _this.tableData = []; //  清空table的值
            const { startTime, endTime, ...newNavForm } = this.navForm;
            newNavForm.pageNo = _this.currentPage;
            newNavForm.pageSize = _this.pageSize;
            newNavForm.condition.tripTimeMin = startTime ? startTime + '-01 00:00:00' : null;
            newNavForm.condition.tripTimeMax = endTime ? endTime + '-01 00:00:00' : null;
            this.instance.post('/ubi/api/vehicle/stat', newNavForm).then((res) => {
                let data = res.data;
                this.loading = false;
                if (data.code == 200) {
                    data.data.list.length > 0 &&
                        data.data.list.forEach((item) => {
                            item.totalMileage = item.totalMileage > 0 ? item.totalMileage.toFixed(2) : item.totalMileage;
                            item.totalDuration = item.totalDuration > 0 ? item.totalDuration.toFixed(2) : item.totalDuration;
                        });
                    _this.tableData = data.data.list;
                    _this.totalPage = parseInt(data.data.total);
                    if (showDetailFlag) {
                        this.handleLookDetail(this.tableData[0], '');
                    }
                } else {
                    _this.totalPage = 0;
                }
            });
        },
        //分页数据多少条change
        handleSizeChange(val) {
            this.pageSize = val;
            this.getTbodyData();
        },
        //分页：当前页改变之后的函数
        handleCurrentChange: function (val) {
            this.currentPage = Number(val);
            this.getTbodyData(this.activityState);
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../../assets/css/table_white.css';
.content {
    position: relative;
    height: 100%;
}
.warning_icon {
    width: 0.24rem;
    margin: 0 auto;
}
.pointer span {
    display: inline-block;
}
.last_span {
    margin-left: 0.1rem;
}
.dialog_box {
    margin-top: -10px;
}
.dialog_map {
    height: 500px;
}
.dialog_map > p {
    font-size: 16px;
    margin-bottom: 10px;
}
#container_map {
    width: 760px;
    height: 380px;
    margin: 0 auto;
}
#container,
#conta {
    width: 100%;
    height: 500px;
}
#container /deep/ .BMap_pop {
    left: 264px !important;
    top: 90px !important;
}
#container /deep/ .BMap_pop p {
    line-height: 18px;
}
#container /deep/ .BMap_pop p:nth-child(3) {
    display: inline-block;
    width: 300px;
}
</style>
