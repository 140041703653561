<template>
    <div class="content" v-cloak v-loading.fullscreen.lock="fullscreenLoading" element-loading-text="正在加载中，请稍后！">
        <div class="page_title">车辆轨迹回溯</div>
        <div class="overlength_content">
            <div class="content_box" style="padding-bottom: 0">
                <div class="search_box">
                    <!--检索部分-->
                    <el-form :inline="true" :model="navForm" class="demo-form-inline" :rules="searchRules">
                        <el-form-item label="车牌号/车架号/设备号:" prop="search">
                            <el-input class="width_150" v-model="navForm.search" size="medium" placeholder="请输入" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="事故发生时间:" class="dateBox">
                            <el-date-picker size="medium" placeholder="请选择时间" v-model="navForm.accidentTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" style="width: 100%"> </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="getVehicleInfo" size="medium">查询</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div class="content_box" style="padding-bottom: 0">
                <div class="detail_title" style="margin-top: 0.1rem">基础信息</div>
                <ul class="detail_form">
                    <li>
                        <span>车牌号：</span>
                        <b>{{ vehicleInfo.plateNo || '--' }}</b>
                    </li>
                    <li>
                        <span>车辆VIN码：</span>
                        <b>{{ vehicleInfo.vin || '--' }}</b>
                    </li>
                    <li>
                        <span>绑定设备号：</span>
                        <b>{{ vehicleInfo.deviceNumber || '--' }}</b>
                    </li>
                    <li>
                        <span>商业险保单号：</span>
                        <b>{{ vehicleInfo.policyNo || '--' }}</b>
                    </li>
                    <li>
                        <span>使用性质：</span>
                        <b>{{ vehicleInfo.useNature || '--' }}</b>
                    </li>
                    <li>
                        <span>车辆驾驶行为分析：</span>
                        <b v-if="vehicleInfo.plateNo" class="link" @click="handleLookDetail">查看详情</b>
                        <b v-else>--</b>
                    </li>
                </ul>
            </div>
            <div class="content_box">
                <div class="detail_title" style="margin: 0.1rem 0 0.25rem">事故前后车辆轨迹回溯</div>
                <div class="map_box" v-loading="mapLoading" element-loading-text="拼命加载中">
                    <div style="display: flex; justify-content: space-between">
                        <div v-show="checkedMap == '高德'" id="container" tabindex="0"></div>
                        <baidu-map v-show="checkedMap == '百度'" :center="center" :zoom="zoom" :max-zoom="16" id="container" @ready="handler" :mapClick="false" :scroll-wheel-zoom="isZoomEnable">
                            <bml-lushu @stop="reset" :autoView="true" :path="lushuData.polylinePath" :icon="lushuData.carIcon" :speed="lushuData.speed" :play="lushuData.play" :rotation="true"> </bml-lushu>
                            <bm-marker :position="startPoint" :icon="startIcon"></bm-marker>
                            <bm-marker :position="endPoint" :icon="endIcon"></bm-marker>
                            <bm-marker :position="qizhiPoint" :icon="qizhiIcon" @click="handleQizhi">
                                <bm-info-window :show="infoWindowQizhi.show" @close="infoWindowQizhiClose">
                                    <div class="info_window">
                                        <p v-show="infoWindowQizhi.deviceTime">事故时间：{{ infoWindowQizhi.deviceTime }}</p>
                                        <p v-show="infoWindowQizhi.speed">速度：{{ infoWindowQizhi.speed }}</p>
                                        <p>事故地点：{{ infoWindowQizhi.addr }}</p>
                                        <p class="color_red">位置偏移量：{{ infoWindowQizhi.distance }}</p>
                                    </div>
                                </bm-info-window>
                            </bm-marker>
                            <bm-marker :position="shiguPoint" :icon="shiguIcon" @click="handleShigu">
                                <bm-info-window :show="infoWindowShigu.show" @close="infoWindowShiguClose">
                                    <div class="info_window">
                                        <p v-show="infoWindowShigu.deviceTime">事故时间：{{ infoWindowShigu.deviceTime }}</p>
                                        <p v-show="infoWindowShigu.speed">速度：{{ infoWindowShigu.speed }}</p>
                                        <p>事故地点-：{{ infoWindowShigu.addr }}</p>
                                        <p class="color_red" v-show="infoWindowShigu.distance">位置偏移量：{{ infoWindowShigu.distance }}</p>
                                    </div>
                                </bm-info-window>
                            </bm-marker>
                            <bm-marker :position="collisionPoint" :icon="collisionIcon" @click="handleCollision">
                                <bm-info-window :show="infoWindowCollision.show" :offset="{ width: 0, height: -21 }" @close="infoWindowCollisionClose">
                                    <div class="info_window">
                                        <p>{{ infoWindowCollision.name }}</p>
                                        <p>{{ infoWindowCollision.deviceTime }}</p>
                                        <p>速度：{{ infoWindowCollision.speed }}</p>
                                        <div class="info_btn" @click="initData(infoWindowCollision.deviceTime)">设为事故点</div>
                                    </div>
                                </bm-info-window>
                            </bm-marker>
                            <bm-polyline :path="lushuData.polylinePath" stroke-color="rgba(52, 191, 90, 1)" :stroke-weight="7" :strokeOpacity="1"></bm-polyline>
                            <bm-polyline :path="fatiguePoints" stroke-color="#8E0000" :stroke-weight="9" :strokeOpacity="1"></bm-polyline>
                            <!-- 异常点 -->
                            <bm-point-collection v-if="recallTravelData.sharpSpeedUpPoints.length > 0" :points="circlePath.sharpSpeedUpPoints" color="rgba(31, 117, 255, 1)" size="BMAP_POINT_SIZE_BIG" @click="handleCollection"></bm-point-collection>
                            <bm-point-collection v-if="recallTravelData.sharpSpeedDownPoints.length > 0" :points="circlePath.sharpSpeedDownPoints" color="rgba(163, 79, 224, 1)" size="BMAP_POINT_SIZE_BIG" @click="handleCollection"></bm-point-collection>
                            <bm-point-collection v-if="recallTravelData.sharpTurnPoints.length > 0" :points="circlePath.sharpTurnPoints" color="rgba(5, 169, 197, 1)" size="BMAP_POINT_SIZE_BIG" @click="handleCollection"></bm-point-collection>
                            <bm-point-collection v-if="recallTravelData.collisionPoints.length > 0" :points="circlePath.collisionPoints" color="rgba(255, 199, 60, 1)" size="BMAP_POINT_SIZE_BIG" @click="handleCollection"></bm-point-collection>
                            <bm-point-collection v-if="recallTravelData.rolloverPoints.length > 0" :points="circlePath.rolloverPoints" color="rgba(228, 112, 24, 1)" size="BMAP_POINT_SIZE_BIG" @click="handleCollection"></bm-point-collection>
                            <bm-point-collection v-if="recallTravelData.overSpeedPoints.length > 0" :points="circlePath.overSpeedPoints" color="rgba(247, 72, 130, 1)" size="BMAP_POINT_SIZE_BIG" @click="handleCollection"></bm-point-collection>
                            <!-- 信息窗体 -->
                            <bm-info-window :position="infoWindow" :show="infoWindow.show" @close="infoWindoClose">
                                <div class="info_window">
                                    <p>{{ infoWindow.name }}</p>
                                    <p>{{ infoWindow.deviceTime }}</p>
                                    <p>速度：{{ infoWindow.speed }}</p>
                                    <div class="info_btn" @click="initData(infoWindow.deviceTime)">设为事故点</div>
                                </div>
                            </bm-info-window>
                        </baidu-map>
                        <div class="map_right">
                            <div class="accident content_box">
                                <div>
                                    <div class="flex">
                                        <img src="../../../public/images/msi/online/qizhi.png" />
                                        <span>事故发生的地点</span>
                                    </div>
                                    <p>(用户提供的参考地点)</p>
                                </div>
                                <div>
                                    <div class="flex">
                                        <img src="../../../public/images/msi/online/shigu.png" />
                                        <span>事故发生的时间</span>
                                    </div>
                                    <p>(用户提供的参考时间)</p>
                                </div>
                            </div>
                            <ul class="dot_hint content_box">
                                <li><b></b><span>急加速报警</span></li>
                                <li><b></b><span>急减速报警</span></li>
                                <li><b></b><span>急转弯报警</span></li>
                                <li><b></b><span>碰撞报警</span></li>
                                <li><b></b><span>翻转报警</span></li>
                                <li><b></b><span>超速报警</span></li>
                            </ul>
                        </div>
                    </div>
                    <div class="form_box flex">
                        <div style="display: flex">
                            <div class="select_map">
                                <el-select v-model="checkedMap" @change="checkedMapChange" size="mini" style="width: 100px">
                                    <el-option label="百度" value="百度"></el-option>
                                    <!-- <el-option label="高德" value="高德"></el-option> -->
                                </el-select>
                            </div>
                            <div class="switch_box">
                                <el-switch v-model="isZoomEnable" @change="handleZoomEnable" active-color="#05A9C5" inactive-color="#C0C0C0"> </el-switch>
                                <span class="switch_text">地图缩放</span>
                            </div>
                        </div>
                        <div class="flex">
                            <div class="group_btn">
                                <el-button size="small" icon="el-icon-refresh-right" @click="startAnimation">开始</el-button>
                                <el-button v-if="checkedMap == '百度' || (checkedMap == '高德' && !isPause)" size="small" icon="el-icon-video-pause" @click="pauseAnimation">暂停</el-button>
                                <el-button v-if="checkedMap == '高德' && isPause" size="small" icon="el-icon-video-play" @click="resumeAnimation">继续</el-button>
                            </div>
                            <el-autocomplete class="poi_address" suffix-icon="el-icon-search" popper-class="my-autocomplete" placeholder="事故地点（用户参考的地点）" v-model="modeForm.POIname" :fetch-suggestions="querySearch" @select="handleSelect">
                                <template slot-scope="{ item }">
                                    <div class="name">{{ item.value }}</div>
                                    <span class="addr">{{ item.address }}</span>
                                </template>
                            </el-autocomplete>
                        </div>
                    </div>
                </div>
                <div class="slider_box">
                    <div class="block flex">
                        <div class="slider_item">
                            <div class="slider_icon"><img src="../../../public/images/msi/online/time_dot.png" /></div>
                            <el-slider v-model="sliderValue" range :max="80" :show-tooltip="false" style="width: 100%" :step="10" :marks="marks"> </el-slider>
                        </div>
                        <el-button type="primary" size="medium" @click="handleSlideBtn">确定</el-button>
                    </div>
                    <div class="slider_title">事故前后时间范围选择</div>
                </div>
            </div>
            <div class="content_box">
                <div class="detail_title" style="margin: 0.1rem 0 0.25rem">事故行程数据</div>
                <div class="data_box">
                    <div class="data_box_left">
                        <div><img src="../../../public/images/msi/online/yingyupingjunfen.png" /></div>
                        <span>行程数据</span>
                    </div>
                    <ul class="data_box_right">
                        <li>
                            <div>行程开始时间</div>
                            <p>{{ accidentTrip.startTime || '--' }}</p>
                        </li>
                        <li>
                            <div>行程结束时间</div>
                            <p>{{ accidentTrip.endTime || '--' }}</p>
                        </li>
                        <li>
                            <div>行程起点</div>
                            <p>{{ accidentTrip.startAddress || '--' }}</p>
                        </li>
                        <li>
                            <div>行程终点</div>
                            <p>{{ accidentTrip.endAddress || '--' }}</p>
                        </li>
                        <li>
                            <div>行驶时长</div>
                            <p v-if="accidentTrip.drivingDurationMinutes != null">{{ accidentTrip.drivingDurationMinutes || '0' }} 分钟</p>
                            <p v-else>--</p>
                        </li>
                        <li>
                            <div>行驶里程</div>
                            <p v-if="accidentTrip.mileage != null">{{ accidentTrip.mileage || '0' }} km</p>
                            <p v-else>--</p>
                        </li>
                        <li>
                            <div>最高速度</div>
                            <p v-if="accidentTrip.maxSpeed != null">{{ accidentTrip.maxSpeed || '0' }} km/h</p>
                            <p v-else>--</p>
                        </li>
                        <li>
                            <div>平均速度</div>
                            <p v-if="accidentTrip.avgSpeedPerHour != null">{{ accidentTrip.avgSpeedPerHour ? accidentTrip.avgSpeedPerHour.toFixed(2) : '0' }} km/h</p>
                            <p v-else>--</p>
                        </li>
                        <li>
                            <div>与下一段行程间隔时长</div>
                            <p>{{ accidentTrip.durationText || '--' }}</p>
                        </li>
                        <li>
                            <div>是否疲劳驾驶</div>
                            <p v-if="accidentTrip.isFatigueDriving != null">{{ accidentTrip.isFatigueDriving ? '是' : '否' }}</p>
                            <p v-else>--</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="content_box">
                <div class="data_box yellow">
                    <div class="data_box_left">
                        <div><img src="../../../public/images/msi/online/he_37datifenlei.png" /></div>
                        <span>报警数据</span>
                    </div>
                    <ul class="data_box_right">
                        <li>
                            <div>急加速次数</div>
                            <p v-if="accidentTrip.sharpSpeedUpAlarmNum != null">{{ accidentTrip.sharpSpeedUpAlarmNum || '0' }} 次</p>
                            <p v-else>--</p>
                        </li>
                        <li>
                            <div>急减速次数</div>
                            <p v-if="accidentTrip.sharpSpeedDownAlarmNum != null">{{ accidentTrip.sharpSpeedDownAlarmNum || '0' }} 次</p>
                            <p v-else>--</p>
                        </li>
                        <li>
                            <div>急转弯次数</div>
                            <p v-if="accidentTrip.sharpTurnAlarmNum != null">{{ accidentTrip.sharpTurnAlarmNum || '0' }} 次</p>
                            <p v-else>--</p>
                        </li>
                        <li>
                            <div>超速次数</div>
                            <p v-if="accidentTrip.overSpeedGpsNum != null">{{ accidentTrip.overSpeedGpsNum || '0' }} 次</p>
                            <p v-else>--</p>
                        </li>
                        <li>
                            <div>碰撞报警次数</div>
                            <p v-if="accidentTrip.collision != null">{{ accidentTrip.collision || '0' }} 次</p>
                            <p v-else>--</p>
                        </li>
                        <li>
                            <div>翻转报警次数</div>
                            <p v-if="accidentTrip.overturn != null">{{ accidentTrip.overturn || '0' }} 次</p>
                            <p v-else>--</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="content_box">
                <div class="detail_title" style="margin: 0.1rem 0 0.25rem">与事故相关的其他数据</div>
                <div class="data_box blue">
                    <div class="data_box_left">
                        <div><img src="../../../public/images/msi/online/line.png" /></div>
                        <span>参考数据</span>
                    </div>
                    <ul class="data_box_right">
                        <li>
                            <div>事故发生时车辆状态</div>
                            <p v-if="vehicleInfo.onlineStatus === 0">{{ '未知' }}</p>
                            <p v-else-if="vehicleInfo.onlineStatus === 1">{{ '行驶' }}</p>
                            <p v-else-if="vehicleInfo.onlineStatus === 2">{{ '停驶' }}</p>
                            <p v-else>--</p>
                        </li>
                        <li>
                            <div>事故发生时设备插拔状态</div>
                            <p v-if="vehicleInfo.pluggingStatus === 0">{{ '未知' }}</p>
                            <p v-else-if="vehicleInfo.pluggingStatus === 1">{{ '插入' }}</p>
                            <p v-else-if="vehicleInfo.pluggingStatus === 2">{{ '拔出' }}</p>
                            <p v-else>--</p>
                        </li>
                        <li>
                            <div>事故前24小时驾驶时长</div>
                            <p>{{ accidentTrip.drivingDurationFormat || '--' }}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="content_box">
                <div class="detail_title" style="margin: 0.1rem 0 0.25rem">事故前后24小时车辆行程信息</div>
                <el-table class="table_dashed" ref="multipleTable" :data="tripPlus24HourList" style="width: 100%">
                    <el-table-column label="序号" type="index" width="50"></el-table-column>
                    <el-table-column prop="platNo" label="车牌号"></el-table-column>
                    <el-table-column prop="startTime" label="开始时间"></el-table-column>
                    <el-table-column prop="endTime" label="结束时间"></el-table-column>
                    <el-table-column prop="drivingDurationHours" label="行驶时长(h)"></el-table-column>
                    <el-table-column prop="mileage" label="行驶里程(km)"></el-table-column>
                    <el-table-column prop="maxSpeed" label="最高速度(km/h)"></el-table-column>
                    <el-table-column prop="totalAlarmNum" label="三急一超次数"></el-table-column>
                    <el-table-column prop="endAddress" label="终点"></el-table-column>
                    <el-table-column prop="commercialVehicleScore" label="行程评分"></el-table-column>
                    <el-table-column label="操作" width="80px">
                        <template slot-scope="scope">
                            <div class="operation_box">
                                <span @click="openNewPage(scope.row)">查看轨迹</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <!--分页部分-->
                <el-pagination
                    style="margin-top: 0.2rem"
                    @current-change="handleCurrentChange"
                    @size-change="handleSizeChange"
                    :current-page="this.queryParams.pageNo"
                    layout="total, sizes, prev, pager, next, jumper"
                    :page.sync="queryParams.pageNo"
                    :limit.sync="queryParams.pageSize"
                    :page-sizes="[5, 10]"
                    :page-size="5"
                    :total="total"
                >
                </el-pagination>
            </div>
        </div>
        <el-dialog class="dialog-title_style" :visible.sync="dialogVisible" width="80%" title="车辆驾驶行为分析" :modal-append-to-body="false">
            <behavioural-detail v-if="dialogVisible" :dialogVisible="dialogVisible" :platNo="platNo" @beforeClose="beforeClose"></behavioural-detail>
        </el-dialog>
    </div>
</template>
<script>
window._AMapSecurityConfig = {
    securityJsCode: '600ed9a76e2ed9438261d3a2736a2d57'
}
// import { EventBus } from "../assets/js/EventBus.js";
// import * as echarts from 'echarts';
let colormap = require('colormap')
import AMapLoader from '@amap/amap-jsapi-loader'
import BehaviouralDetail from '@/components/BehaviouralDetail.vue'
import { BmlLushu } from 'vue-baidu-map'
import moment from 'moment'

export default {
    name: 'OnlineSurvey',
    props: {
        msg: String
    },
    components: {
        BehaviouralDetail,
        BmlLushu
    },
    data() {
        return {
            isPause: false,
            checkedMap: '百度',
            BMap: '',
            map: '',
            GDmap: '',
            infoWindowShow: false,
            version: '',
            rid: '',
            loading: false,
            mapLoading: false,
            dialogVisible: false,
            fullscreenLoading: false,
            isZoomEnable: false,
            deviceNumber: '',
            polyline: '',
            marker: '', //小车icon
            markerArr: [], //点集合
            lineArr: [],
            startMarker: '',
            endMarker: '',
            pathParam: [], //轨迹
            sliderValue: [0, 80],
            marks: {},
            navForm: {
                //储存搜索框的检索条件信息
                accidentTime: '',
                search: ''
                // accidentTime: '2023-04-17 06:49:18',
                // search: '沪AAK5825'
            },

            vehicleInfo: {
                plateNo: null,
                vin: '--',
                deviceNumber: '--',
                policyNo: '--',
                useNature: '--',
                onlineStatus: '', // 在离线状态
                activationStatus: '', // 激活状态
                pluggingStatus: '' // 插拔状态
            },

            tripVoList: [],
            accidentTrip: {},

            queryParams: {
                pageNo: 1,
                pageSize: 5
            },
            tripPlus24HourList: [],
            total: 0,

            gpsMap: {},

            recallTravelData: {
                accidentPoint: {},
                gpsList: [],
                sharpSpeedUpPoints: [],
                sharpSpeedDownPoints: [],
                sharpTurnPoints: [],
                collisionPoints: [],
                rolloverPoints: [],
                overSpeedPoints: []
            },

            searchRules: {
                search: [{ required: true, message: '车牌号/车架号/设备号不能为空', trigger: 'blur' }]
            },

            modeForm: {
                POIname: '',
                Address: ''
            },
            center: { lng: 109.45744048529967, lat: 36.49771311230842 },
            zoom: 13,
            lushuData: {
                carIcon: {
                    url: require('../../../public/images/msi/online/baidu_car_icon.png'),
                    size: {
                        width: 67,
                        height: 37
                    },
                    opts: {
                        anchor: {
                            width: 33,
                            height: 18
                        }
                    }
                },
                play: false,
                speed: 100,
                polylinePath: []
            },
            startIcon: '',
            startPoint: '',
            endIcon: '',
            endPoint: '',
            qizhiPoint: '',
            shiguPoint: '',
            qizhiIcon: {
                url: require('../../../public/images/msi/online/qizhi.png'),
                size: { width: 42, height: 42 },
                opts: {
                    anchor: { width: 21, height: 42 }
                }
            },
            shiguIcon: {
                url: require('../../../public/images/msi/online/shigu.png'),
                size: { width: 42, height: 42 },
                imageSize: { width: 42, height: 42 },
                opts: {
                    anchor: { width: 21, height: 42 }
                }
            },
            collisionPoint: {},
            collisionIcon: {
                url: require('../../../public/images/msi/online/pengzhuang.png'),
                size: { width: 42, height: 42 },
                imageSize: { width: 42, height: 42 },
                opts: {
                    anchor: { width: 21, height: 42 }
                }
            },
            isLoadMap: true,
            platNo: '',
            infoWindowCollision: { show: false },
            infoWindow: { show: false },
            infoWindowShigu: { show: false },
            infoWindowQizhi: { show: false },
            fatiguePoints: [], //疲劳驾驶点集合
            circlePath: {
                sharpSpeedUpPoints: [],
                sharpSpeedDownPoints: [],
                sharpTurnPoints: [],
                collisionPoints: [],
                rolloverPoints: [],
                overSpeedPoints: []
            },
            animationIndex: 1,
            alarmTypeEnum: [], // 告警类型
            alarmTypeEnumObj: {},
            accidentUuid: null
        }
    },
    mounted() {
        this.version = this.$store.state.version
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
        // this.selectDate(60);
        this.getData()
        this.getPointType()
    },
    methods: {
        //百度地图信息窗口关闭
        infoWindoClose() {
            this.infoWindow.show = false
        },
        infoWindowShiguClose() {
            this.infoWindowShigu.show = false
        },
        infoWindowQizhiClose() {
            this.infoWindowQizhi.show = false
        },
        infoWindowCollisionClose() {
            this.infoWindowCollision.show = false
        },
        // 获取报警类型
        getPointType() {
            this.instance.get('/ubi/api/enum/getAlarmTypeEnum').then((res) => {
                let data = res.data
                if (data.code == 200 && data.data.length > 0) {
                    this.alarmTypeEnumObj = {}
                    this.alarmTypeEnum = data.data
                    this.alarmTypeEnum.length > 0 &&
                        this.alarmTypeEnum.forEach((item) => {
                            this.alarmTypeEnumObj[item.desc] = item.code
                        })
                }
            })
        },
        // 获取当前事故设备及对应行程
        getVehicleInfo() {
            if (!this.navForm.search) {
                this.$message.error('抱歉，请输入车牌号/车架号/设备号')
                return false
            }
            if (!this.navForm.accidentTime) {
                this.$message.error('抱歉，请选择事故发生时间')
                return false
            }
            var checkedTime = this.navForm.accidentTime
            checkedTime = checkedTime.replace(/-/g, '/')
            this.setMarks(new Date(checkedTime))
            let param = JSON.parse(JSON.stringify(this.navForm))
            let accidentTime = new Date(param.accidentTime)
            param.startTime = moment(new Date(accidentTime.getTime() - 2 * 60 * 60 * 1000)).format('YYYY-MM-DD HH:mm:ss')
            param.endTime = moment(new Date(accidentTime.getTime() + 2 * 60 * 60 * 1000)).format('YYYY-MM-DD HH:mm:ss')
            this.mapLoading = true
            this.instance
                .post('/ubi/api/trajectory/tracking', param)
                .then((res) => {
                    let data = res.data
                    if (data.code == 200) {
                        this.vehicleInfo = data.data.deviceVo
                        this.vehicleInfo.useNature = '营运'
                        this.tripVoList = data.data.tripVoList
                        this.tripVoList.forEach((item) => {
                            if (item.isAccident == true) {
                                this.accidentUuid = item.tripUuid
                            }
                        })
                        this.getAccidentPlus24HourTrip()
                        this.getCurrentAccidentTrip()
                        this.getCurrentAccidentGps()
                    }
                })
                .catch((res) => {
                    console.log(res)
                    this.$message.error('查询失败! 请稍后重试')
                })
        },

        // 获取事故前后24小时车辆行程信息
        getAccidentPlus24HourTrip() {
            let json = {
                condition: {
                    accidentTime: this.navForm.accidentTime,
                    deviceNumber: this.vehicleInfo.deviceNumber
                },
                pageNo: this.queryParams.pageNo,
                pageSize: this.queryParams.pageSize
            }
            this.instance
                .post('/ubi/api/trajectory/tracking/plus12Hours', json)
                .then((res) => {
                    let data = res.data
                    if (data.code === 200) {
                        this.tripPlus24HourList = data.data.list
                        this.tripPlus24HourList.length > 0 &&
                            this.tripPlus24HourList.forEach((item) => {
                                item.commercialVehicleScore = String(item.commercialVehicleScore).indexOf('.') != -1 ? Number(item.commercialVehicleScore).toFixed(0) : item.commercialVehicleScore
                            })
                        this.total = parseInt(res.data.data.total)
                    } else {
                        console.log(res)
                    }
                })
                .catch((res) => {
                    console.log(res)
                })
        },

        // 获取事故行程信息
        getCurrentAccidentTrip() {
            this.instance
                .get(`/ubi/api/trajectory/tracking/accident?tripId=${this.accidentUuid ? this.accidentUuid : ''}&accidentTime=${this.navForm.accidentTime}`)
                .then((res) => {
                    let data = res.data
                    if (data.code == 200) {
                        this.accidentUuid = null
                        this.accidentTrip = data.data
                        this.drawAccidentPoint(1)
                    } else {
                        console.log('接口事故行程信息有误：')
                        console.log(res)
                    }
                })
                .catch((res) => {
                    console.log(res)
                })
        },

        // 查询事故时间段范围内 gps 点位
        getCurrentAccidentGps() {
            let param = []
            this.tripVoList.forEach((item) => {
                param.push(item.tripUuid)
            })
            this.instance
                .post(`/ubi/api/trajectory/tracking/gps?accidentTime=${this.navForm.accidentTime}`, param)
                .then((res) => {
                    this.mapLoading = false
                    let data = res.data
                    if (data.code == 200) {
                        this.gpsMap = data.data
                        let tmpGpsMapArr = []
                        for (const key in this.gpsMap) {
                            if (this.gpsMap[key] && key != 'accidentGPS') {
                                this.gpsMap[key].forEach((item) => {
                                    tmpGpsMapArr.push(item)
                                })
                            } else if (key == 'accidentGPS') {
                                this.recallTravelData.accidentPoint = this.gpsMap[key][0]
                            }
                        }
                        tmpGpsMapArr.sort((a, b) => {
                            return new Date(a.t).getTime() - new Date(b.t).getTime()
                        })
                        this.recallTravelData.gpsList = tmpGpsMapArr

                        // 处理报警类型坐标
                        if (Array.isArray(this.recallTravelData.gpsList)) {
                            this.gpsForEach(this.recallTravelData.gpsList)
                            // 绘制地图点位
                            this.checkedMap == '百度' ? this.loadBMapPath(this.recallTravelData.gpsList) : ''
                        }
                    } else {
                        this.$message.error(data.message)
                    }
                })
                .catch((res) => {
                    console.log(res)
                })
        },
        gpsForEach(gpsDataList) {
            this.recallTravelData.sharpSpeedUpPoints = []
            this.recallTravelData.sharpSpeedDownPoints = []
            this.recallTravelData.sharpTurnPoints = []
            this.recallTravelData.collisionPoints = []
            this.recallTravelData.rolloverPoints = []
            this.recallTravelData.overSpeedPoints = []
            gpsDataList.forEach((item) => {
                switch (item.c) {
                    // 急加速 311
                    case this.alarmTypeEnumObj['急加速报警']:
                        this.recallTravelData.sharpSpeedUpPoints.push(item)
                        break
                    // 急减速 312
                    case this.alarmTypeEnumObj['急减速报警']:
                        this.recallTravelData.sharpSpeedDownPoints.push(item)
                        break
                    // 急转弯 319
                    case this.alarmTypeEnumObj['急转弯报警']:
                        this.recallTravelData.sharpTurnPoints.push(item)
                        break
                    // 碰撞点 12
                    case this.alarmTypeEnumObj['碰撞报警']:
                        this.recallTravelData.collisionPoints.push(item)
                        break
                    // 翻转点 78
                    case this.alarmTypeEnumObj['翻转报警']:
                        this.recallTravelData.rolloverPoints.push(item)
                        break
                    // 超速点 14
                    case this.alarmTypeEnumObj['超速报警']:
                        this.recallTravelData.overSpeedPoints.push(item)
                        break

                    default:
                        break
                }
            })
        },
        //选择地图change事件
        checkedMapChange() {
            this.pauseAnimation()
            this.setMap()
        },
        //点击事故点时触发
        handleShigu(type, target) {
            this.infoWindowShigu = {
                lng: this.shiguPoint.lng,
                lat: this.shiguPoint.lat,
                deviceTime: this.shiguPoint.deviceTime,
                speed: this.shiguPoint.speed,
                addr: this.shiguPoint.addr,
                show: true
            }
            return
            type.domEvent.stopPropagation()
            var _this = this
            var myGeo = new this.BMap.Geocoder()
            myGeo.getLocation(type.point, function (result) {
                _this.infoWindowShigu.lng = _this.shiguPoint.lng
                _this.infoWindowShigu.lat = _this.shiguPoint.lat
                _this.infoWindowShigu.deviceTime = _this.shiguPoint.deviceTime
                _this.infoWindowShigu.speed = _this.shiguPoint.speed
                _this.infoWindowShigu.addr = result.address
                _this.infoWindowShigu.show = true
            })
        },
        //点击旗帜时触发
        handleQizhi(type, target) {
            type.domEvent.stopPropagation()
            this.infoWindowQizhi.lng = this.qizhiPoint.lon
            this.infoWindowQizhi.lat = this.qizhiPoint.lat
            this.infoWindowQizhi.addr = this.modeForm.Address
            this.infoWindowQizhi.show = true
            let qizhiPoint = new this.BMap.Point(this.qizhiPoint.lng, this.qizhiPoint.lat)
            let shiguPoint = new this.BMap.Point(this.shiguPoint.lng, this.shiguPoint.lat)
            let distance = (this.map.getDistance(qizhiPoint, shiguPoint) / 1000).toFixed(2)
            this.infoWindowQizhi.distance = distance + 'km'
        },
        //点击事故图标时触发
        handleCollision(type, target) {
            type.domEvent.stopPropagation()
            this.infoWindowCollision.lng = this.collisionPoint.lon
            this.infoWindowCollision.lat = this.collisionPoint.lat
            this.infoWindowCollision.name = this.collisionPoint.name
            this.infoWindowCollision.deviceTime = this.collisionPoint.deviceTime
            this.infoWindowCollision.speed = this.collisionPoint.speed
            this.infoWindowCollision.show = true
        },
        //点击异常点时触发
        handleCollection(currentTarget, point) {
            this.infoWindow.lng = point.point.lng
            this.infoWindow.lat = point.point.lat
            this.infoWindow.name = point.point.name
            this.infoWindow.deviceTime = point.point.deviceTime
            this.infoWindow.speed = point.point.speed
            this.infoWindow.show = true
            // 手动设置事故点
            let tmpIndex = point.point.speed.indexOf('k')
            this.recallTravelData.accidentPoint = {
                endLongitude: point.point.lng,
                endLatitude: point.point.lat,
                endTime: point.point.deviceTime,
                avgSpeedPerHour: point.point.speed.slice(0, tmpIndex)
            }
        },
        //地图加载完触发
        handler({ BMap, map }) {
            this.BMap = BMap
            this.map = map
        },
        getData() {
            this.setMap('null')
        },
        //开启关闭地图缩放
        handleZoomEnable() {
            if (this.checkedMap == '高德') {
                if (this.isZoomEnable) {
                    this.GDmap.setStatus({
                        scrollWheel: true
                    })
                } else {
                    this.GDmap.setStatus({
                        scrollWheel: false
                    })
                }
            }
        },
        //点击滑块旁边的确定按钮
        handleSlideBtn() {
            this.pauseAnimation()
            this.setMap('null')
            this.getRecallTravel()
        },

        //事故前后车辆轨迹回溯
        getRecallTravel(tripList) {
            if (tripList !== undefined) {
                this.getCurrentAccidentGps()
            }
            let tmpGpsMapArr = []
            for (const key in this.gpsMap) {
                if (key && key != 'accidentGPS') {
                    this.gpsMap[key].forEach((item) => {
                        tmpGpsMapArr.push(item)
                    })
                }
            }
            this.recallTravelData.gpsList = tmpGpsMapArr

            var json = JSON.parse(JSON.stringify(this.navForm))
            json.startTime = this.sliderValue[0] != 40 ? this.dateTimeString(this.navForm.accidentTime, true) + this.marks[this.sliderValue[0]] + ':00' : this.navForm.accidentTime
            json.endTime = this.sliderValue[1] != 40 ? this.dateTimeString(this.navForm.accidentTime, false) + this.marks[this.sliderValue[1]] + ':00' : this.navForm.accidentTime
            json.deviceNumber = this.vehicleInfo.deviceNumber
            let tmpGpsData = []
            if (this.recallTravelData.gpsList.length > 0) {
                this.mapLoading = true
                this.recallTravelData.gpsList.forEach((item) => {
                    if (json.startTime <= item.t && item.t <= json.endTime) {
                        tmpGpsData.push(item)
                    }
                })
                tmpGpsData.length > 0 && this.gpsForEach(tmpGpsData)
            }

            if (this.checkedMap == '百度' && tmpGpsData.length > 0) {
                this.loadBMapPath(tmpGpsData)
            } else if (this.checkedMap === '高德') {
                //轨迹百度转高德
                this.initGPSData('gpsList')
                //事故点百度转高德
                this.initGPSData('sharpSpeedUpPoints')
                this.initGPSData('sharpSpeedDownPoints')
                this.initGPSData('sharpTurnPoints')
                this.initGPSData('collisionPoints')
                this.initGPSData('rolloverPoints')
                this.initGPSData('overSpeedPoints')
                this.initGPSData('accidentPoint')
                //绘制地图轨迹
                this.loadAMapPath()
            } else {
                this.mapLoading = false
                this.$message.warning('当前所选时间范围，未查询到坐标信息！')
            }
        },
        //处理GPS数据
        initGPSData(keyName) {
            if (keyName == 'accidentPoint') {
                let obj = this.BMaptoAMap(this.recallTravelData[keyName].longitude, this.recallTravelData[keyName].latitude)
                this.recallTravelData[keyName].longitude = obj.lng
                this.recallTravelData[keyName].latitude = obj.lat
            } else {
                this.recallTravelData[keyName].length > 0 &&
                    this.recallTravelData[keyName].forEach((val, key) => {
                        let obj = this.BMaptoAMap(val.longitude, val.latitude)
                        val.longitude = obj.lng
                        val.latitude = obj.lat
                    })
            }
        },
        // 格式化时间前/后2小时
        dateTimeString(timeStr, flag) {
            // 创建一个 Date 对象并设置当前时间
            let date = new Date(timeStr)

            // 往前推 2 小时
            flag ? date.setHours(date.getHours() - 2) : date.setHours(date.getHours() + 2)

            // 格式化日期时间
            let year = date.getFullYear().toString().padStart(4, '0')
            let month = (date.getMonth() + 1).toString().padStart(2, '0')
            let day = date.getDate().toString().padStart(2, '0')
            let hours = date.getHours().toString().padStart(2, '0')
            let minutes = date.getMinutes().toString().padStart(2, '0')
            let seconds = date.getSeconds().toString().padStart(2, '0')

            // let dateTimeString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
            let dateTimeString = `${year}-${month}-${day} `
            return dateTimeString
        },
        //关闭模态框
        beforeClose() {
            this.$emit('update:messageParent', false)
            this.dialogVisible = false
        },
        //点击查看详情
        handleLookDetail() {
            this.platNo = this.vehicleInfo.plateNo
            this.dialogVisible = this.platNo ? true : false
        },
        //设置滑块marks
        setMarks(checkedTime) {
            this.marks = {
                0: this.returnTime(checkedTime, -2),
                10: this.returnTime(checkedTime, -1.5),
                20: this.returnTime(checkedTime, -1),
                30: this.returnTime(checkedTime, -0.5),
                40: this.returnTime(checkedTime, 0),
                // 40:{
                // style: {
                //   color: '#0D5C6A'
                // },
                // label: this.$createElement('strong', this.returnTime(checkedTime,0))
                // },
                50: this.returnTime(checkedTime, 0.5),
                60: this.returnTime(checkedTime, 1),
                70: this.returnTime(checkedTime, 1.5),
                80: this.returnTime(checkedTime, 2)
            }
        },
        returnTime(checkedTime, interval) {
            return this.dateFormatting(new Date(checkedTime.getTime() + interval * 60 * 60 * 1000), 'hh:mm')
        },
        //开始动画
        startAnimation() {
            var mileage = this.recallTravelData.mileage
            if (this.checkedMap == '百度') {
                if (mileage) {
                    this.lushuData.speed = parseInt(mileage / 8)
                }
                this.lushuData.play = true
            } else if (this.checkedMap == '高德') {
                var _this = this
                var speed = 2000
                if (mileage) {
                    speed = parseInt(mileage / 12)
                }
                if (this.animationIndex == 1) {
                    this.animationIndex++
                    AMap.plugin('AMap.MoveAnimation', function () {
                        _this.marker.moveAlong(_this.lineArr, {
                            // duration:5000,
                            autoRotation: true
                        })
                    })
                } else {
                    _this.marker.moveAlong(_this.lineArr, {
                        // duration:5,
                        autoRotation: true
                    })
                }
            }
        },
        reset() {
            this.lushuData.play = false
        },
        //暂停动画
        pauseAnimation() {
            if (this.checkedMap == '百度') {
                this.lushuData.play = false
            } else if (this.checkedMap == '高德') {
                this.isPause = true
                this.marker && this.marker.pauseMove()
            }
        },
        //继续动画
        resumeAnimation() {
            if (this.checkedMap == '百度') {
                this.lushuData.play = true
            } else if (this.checkedMap == '高德') {
                this.isPause = false
                this.marker.resumeMove()
            }
        },
        //停止动画
        stopAnimation() {
            this.marker.stopMove()
        },
        //搜索处Input提示框选择之后的函数
        handleSelect: function (item) {
            this.modeForm.Address = item.address
            this.modeForm.POIpoint = item.POIpoint
            this.drawAccidentPoint(2)
        },
        //input模糊查询部分
        querySearch: function (queryString, cb) {
            var val = this.modeForm.POIname
            if (this.checkedMap == '百度') {
                var options = {
                    onSearchComplete: (results) => {
                        if (local.getStatus() == 0) {
                            var arr = []
                            var len = results.getCurrentNumPois()
                            for (var i = 0; i < len; i++) {
                                var obj = {
                                    value: '',
                                    address: '',
                                    POIpoint: ''
                                }
                                obj.value = results.getPoi(i).title
                                obj.address = results.getPoi(i).address || results.getPoi(i).city
                                obj.POIpoint = results.getPoi(i).point
                                arr.push(obj)
                            }
                            this.restaurants = arr
                            // 调用 callback 返回建议列表的数据
                            var restaurants = this.restaurants
                            var results = val ? restaurants.filter(this.acreateFilter(val)) : restaurants
                            // 调用 callback 返回建议列表的数据
                            cb(results)
                        }
                    }
                }
                var local = new this.BMap.LocalSearch(this.map, options)
                local.search(val)
            } else if (this.checkedMap == '高德') {
                var _this = this
                AMap.plugin('AMap.AutoComplete', function () {
                    // 实例化Autocomplete
                    var autoOptions = {
                        city: '全国'
                    }
                    var autoComplete = new AMap.AutoComplete(autoOptions)
                    val &&
                        autoComplete.search(val, function (status, result) {
                            // 搜索成功时，result即是对应的匹配数据
                            var len = result.tips.length
                            if (len > 0) {
                                var arr = []
                                for (var i = 0; i < len; i++) {
                                    var obj = {
                                        value: '',
                                        address: '',
                                        POIpoint: ''
                                    }
                                    obj.value = result.tips[i].name
                                    obj.address = result.tips[i].district + result.tips[i].address
                                    result.tips[i].location && (obj.POIpoint = result.tips[i].location.lat + ',' + result.tips[i].location.lng)
                                    arr.push(obj)
                                }
                                _this.restaurants = arr
                                // 调用 callback 返回建议列表的数据
                                var restaurants = _this.restaurants
                                var results = val ? restaurants.filter(_this.acreateFilter(val)) : restaurants
                                // 调用 callback 返回建议列表的数据
                                cb(results)
                            }
                        })
                })
            }
        },
        acreateFilter: function () {
            return function (restaurant) {
                return restaurant
            }
        },
        //设置地图
        setMap(sign) {
            if (this.checkedMap == '百度') {
                sign != 'null' && this.getVehicleInfo() //获取车辆基本信息
            } else if (this.checkedMap == '高德') {
                AMapLoader.load({
                    key: 'dabcacd9e784df189b7f69e994d4793e', // 申请好的Web端开发者Key，首次调用 load 时必填
                    version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                    plugins: ['AMap.Scale', 'AMap.AutoComplete']
                })
                    .then((AMap) => {
                        this.GDmap = new AMap.Map('container', {
                            mapStyle: 'amap://styles/light', //设置地图的显示样式
                            resizeEnable: true,
                            zoomEnable: true,
                            zooms: [5, 16],
                            scrollWheel: false,
                            center: [116.397428, 39.90923],
                            zoom: 10
                            // viewMode:'3D'//使用3D视图
                        })
                        //增加比例尺控件
                        var scale = new AMap.Scale({
                            position: 'LB'
                        })
                        this.GDmap.addControl(scale)
                        sign != 'null' && this.getVehicleInformation() //获取车辆基本信息
                    })
                    .catch((e) => {
                        console.log(e)
                    })
            }
        },
        //加载百度地图轨迹
        loadBMapPath(gpsDataList) {
            if (gpsDataList.length > 0) {
                var path = []
                gpsDataList.forEach((val, key) => {
                    path.push(new this.BMap.Point(val.lon, val.lat))
                })
                this.lushuData.polylinePath = path
            } else {
                this.map.clearOverlays()
                return false
            }
            //疲劳驾驶数据处理
            if (this.recallTravelData.fatiguePoints && this.recallTravelData.fatiguePoints.length > 0) {
                let fatiguePath = []
                this.recallTravelData.fatiguePoints.forEach((val, key) => {
                    fatiguePath.push(new this.BMap.Point(val.longitude, val.latitude))
                })
                this.fatiguePoints = fatiguePath
            }
            //疲劳驾驶数据处理
            var _len = path.length
            this.center = path[0]
            this.carPoint = path[0]
            let iconSize = {
                width: 38,
                height: 49
            }
            this.startPoint = path[0]
            this.endPoint = path[_len - 1]
            this.startIcon = {
                url: require('../../../public/images/depicon.png'),
                size: iconSize,
                opts: {
                    anchor: {
                        width: 19,
                        height: 49
                    }
                }
            }
            this.endIcon = {
                url: require('../../../public/images/desicon.png'),
                size: iconSize,
                opts: {
                    imageSize: iconSize,
                    anchor: {
                        width: 19,
                        height: 49
                    }
                }
            }
            setTimeout(() => {
                this.map.setViewport(path)
            }, 100)
            //绘制圆点-三急一超点
            this.recallTravelData.sharpSpeedUpPoints.length > 0 && this.drawOutliersBmap(this.recallTravelData.sharpSpeedUpPoints, 'sharpSpeedUpPoints') //急加速
            this.recallTravelData.sharpSpeedDownPoints.length > 0 && this.drawOutliersBmap(this.recallTravelData.sharpSpeedDownPoints, 'sharpSpeedDownPoints') //急减速
            this.recallTravelData.sharpTurnPoints.length > 0 && this.drawOutliersBmap(this.recallTravelData.sharpTurnPoints, 'sharpTurnPoints') //急转弯
            this.recallTravelData.collisionPoints.length > 0 && this.drawOutliersBmap(this.recallTravelData.collisionPoints, 'collisionPoints') //碰撞点
            this.recallTravelData.rolloverPoints.length > 0 && this.drawOutliersBmap(this.recallTravelData.rolloverPoints, 'rolloverPoints') //翻转点
            this.recallTravelData.overSpeedPoints.length > 0 && this.drawOutliersBmap(this.recallTravelData.overSpeedPoints, 'overSpeedPoints') //超速点
            this.mapLoading = false
            //绘制事故点
            this.drawAccidentPoint(1)
        },
        //加载高德地图轨迹、异常点
        loadAMapPath() {
            var _this = this
            if (this.recallTravelData.gpsList.length > 0) {
                var path = []
                this.recallTravelData.gpsList.forEach((val, key) => {
                    // path.push(new AMap.LngLat(val.longitude,val.latitude));
                    path.push([val.longitude, val.latitude])
                })
                this.lineArr = path
            } else {
                return false
            }
            // this.lineArr = this.recallTravelData.gpsList;
            var carIcon = new AMap.Icon({
                size: new AMap.Size(37, 67), // 图标尺寸
                image: require('../../../public/images/msi/online/car_icon.png'), // Icon的图像
                // image:"http://webapi.amap.com/images/car.png",
                imageSize: new AMap.Size(37, 67) // 根据所设置的大小拉伸或压缩图片
            })
            //绘制小车
            this.marker = new AMap.Marker({
                map: this.GDmap,
                position: this.lineArr[0],
                icon: carIcon,
                offset: new AMap.Pixel(-18, -37),
                // autoRotation: true,  //车辆是否自动调头
                zIndex: 1200
                // angle:-90,
            })
            //绘制开始结束图标
            this.drawAMapStartEnd('start')
            this.drawAMapStartEnd('end')
            //绘制圆点-三急一超点
            this.recallTravelData.sharpSpeedUpPoints.length > 0 && this.drawAMapOutliers(this.recallTravelData.sharpSpeedUpPoints, 'sharpSpeedUpPoints') //急加速
            this.recallTravelData.sharpSpeedDownPoints.length > 0 && this.drawAMapOutliers(this.recallTravelData.sharpSpeedDownPoints, 'sharpSpeedDownPoints') //急减速
            this.recallTravelData.sharpTurnPoints.length > 0 && this.drawAMapOutliers(this.recallTravelData.sharpTurnPoints, 'sharpTurnPoints') //急转弯
            this.recallTravelData.collisionPoints.length > 0 && this.drawAMapOutliers(this.recallTravelData.collisionPoints, 'collisionPoints') //碰撞点
            this.recallTravelData.rolloverPoints.length > 0 && this.drawAMapOutliers(this.recallTravelData.rolloverPoints, 'rolloverPoints') //翻转点
            this.recallTravelData.overSpeedPoints.length > 0 && this.drawAMapOutliers(this.recallTravelData.overSpeedPoints, 'overSpeedPoints') //超速点
            //绘制事故地点
            AMap.plugin('AMap.Geocoder', function () {
                let geocoder = new AMap.Geocoder()
                var accidentPoint = _this.recallTravelData.accidentPoint
                geocoder.getAddress([accidentPoint.longitude, accidentPoint.latitude], function (status, result) {
                    if (status === 'complete' && result.regeocode) {
                        var address = result.regeocode.formattedAddress
                        _this.drawAccidentPoint(1, address)
                    }
                })
            })
            // 加载速度分色
            if (this.version == 'manager') {
                let colors = colormap({
                    colormap: 'jet',
                    nshades: 120,
                    format: 'hex',
                    alpha: 1
                })
                var gpsList = this.recallTravelData.gpsList
                var lineArr = this.lineArr
                for (var i = 0, len = lineArr.length; i < len - 1; i++) {
                    var speed = gpsList[i].gpsSpeed >= 0 && parseInt(gpsList[i].gpsSpeed)
                    speed = speed >= 120 ? 119 : speed
                    var _color = colors[speed]
                    var polyline = new AMap.Polyline({
                        map: _this.GDmap,
                        path: [lineArr[i], lineArr[i + 1]],
                        showDir: true,
                        isOutline: true,
                        outlineColor: 'rgba(15, 106, 51, 1)',
                        strokeColor: _color, //线颜色
                        // strokeOpacity: 1,     //线透明度
                        strokeWeight: 6, //线宽
                        lineJoin: 'round',
                        lineCap: 'butt',
                        extData: {
                            speed: speed + 'km/h',
                            time: gpsList[i].deviceTime
                        }
                        // strokeStyle: "solid"  //线样式
                    })
                    polyline.on('click', (e) => {
                        var extData = e.target._opts.extData
                        //创建信息窗口
                        var content =
                            `<div class="info_box">
											  <p>速度：` +
                            extData.speed +
                            `</p>
											  <p>时间：` +
                            extData.time +
                            `</p>
										  </div>`
                        var infoWindow = new AMap.InfoWindow({
                            // isCustom: true,  //使用自定义窗体
                            content: content,
                            offset: new AMap.Pixel(-3, -5)
                        })
                        //打开信息窗口
                        infoWindow.open(this.GDmap, e.lnglat) //后面的参数指的是经纬度，在此显示窗口
                    })
                }
            } else {
                // 绘制轨迹
                var polyline = new AMap.Polyline({
                    map: this.GDmap,
                    path: this.lineArr,
                    showDir: true,
                    isOutline: true,
                    outlineColor: 'rgba(15, 106, 51, 1)',
                    strokeColor: 'rgba(52, 191, 90, 1)', //线颜色
                    // strokeOpacity: 1,     //线透明度
                    strokeWeight: 6, //线宽
                    lineJoin: 'round',
                    lineCap: 'round'
                    // strokeStyle: "solid"  //线样式
                })
            }
            //绘制行驶过的轨迹
            var passedPolyline = new AMap.Polyline({
                map: this.GDmap,
                // path: lineArr,
                showDir: true,
                isOutline: true,
                outlineColor: 'rgba(71, 147, 121, 1)',
                lineJoin: 'round',
                lineCap: 'round',
                strokeColor: 'rgba(153, 255, 59, 1)', //线颜色
                // strokeOpacity: 1,     //线透明度
                strokeWeight: 6 //线宽
                // strokeStyle: "solid"  //线样式
            })
            this.marker.on('moving', function (e) {
                passedPolyline.setPath(e.passedPath)
                // this.GDmap.setCenter(e.target.getPosition(),true)
            })

            this.GDmap.add(this.markerArr)
            this.GDmap.setFitView(polyline)
        },
        //绘制高德地图起点终点 start==起点  end==终点
        drawAMapStartEnd(sign) {
            var _src = '',
                poiPoint = [],
                speed = '',
                time = ''
            var gpsList = this.recallTravelData.gpsList
            var len = gpsList.length
            if (sign == 'start') {
                _src = 'start_icon'
                poiPoint = this.lineArr[0]
                speed = gpsList[0].gpsSpeed
                time = gpsList[0].deviceTime.replace('T', ' ')
            } else {
                _src = 'end_icon'
                poiPoint = this.lineArr[this.lineArr.length - 1]
                speed = gpsList[len - 1].gpsSpeed
                time = gpsList[len - 1].deviceTime.replace('T', ' ')
            }
            const Icon = new AMap.Icon({
                size: new AMap.Size(50, 50), // 图标尺寸
                image: require('../../../public/images/msi/online/' + _src + '.png'),
                imageSize: new AMap.Size(50, 50) // 根据所设置的大小拉伸或压缩图片
            })
            var Marker = new AMap.Marker({
                map: this.GDmap,
                position: poiPoint, // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
                offset: new AMap.Pixel(-25, -50),
                icon: Icon,
                extData: {
                    name: sign == 'start' ? '起点' : '终点',
                    speed: speed + 'km/h',
                    time: time
                }
            })
            Marker.on('click', (e) => {
                var extData = e.target._opts.extData
                //创建信息窗口
                var content =
                    `<div class="info_box">
								  <p>` +
                    extData.name +
                    `</p>
								  <p>` +
                    extData.time +
                    `</p>
								  <p>速度：` +
                    extData.speed +
                    `</p>
							  </div>`
                var infoWindow = new AMap.InfoWindow({
                    // isCustom: true,  //使用自定义窗体
                    content: content,
                    offset: new AMap.Pixel(-1, -10)
                })
                //打开信息窗口
                infoWindow.open(this.GDmap, e.lnglat) //后面的参数指的是经纬度，在此显示窗口
            })
        },
        //绘制事故点 1==事故时间  2==事故地点
        drawAccidentPoint(sign, address) {
            if (this.checkedMap == '百度') {
                var accidentPoint = this.recallTravelData.accidentPoint
                if (sign == 1 && accidentPoint) {
                    this.shiguPoint = {
                        lng: accidentPoint.lon,
                        lat: accidentPoint.lat,
                        deviceTime: accidentPoint.t,
                        speed: accidentPoint.s + ' km/h',
                        addr: accidentPoint.location
                    }
                } else {
                    this.qizhiPoint = this.modeForm.POIpoint
                    if (this.qizhiPoint.lng) {
                        let qizhiPoint = new this.BMap.Point(this.qizhiPoint.lng, this.qizhiPoint.lat)
                        let shiguPoint = new this.BMap.Point(this.shiguPoint.lng, this.shiguPoint.lat)
                        let distance = (this.map.getDistance(qizhiPoint, shiguPoint) / 1000).toFixed(2)
                        this.infoWindowShigu.distance = distance + 'km'
                    }
                }
            } else if (this.checkedMap == '高德') {
                var _src = '',
                    poiPoint = [],
                    content = '',
                    addr = ''
                var accidentPoint = this.recallTravelData.accidentPoint
                if (sign == 1) {
                    _src = 'shigu'
                    addr = address
                    poiPoint = [accidentPoint.latitude, accidentPoint.longitude]
                } else {
                    _src = 'qizhi'
                    poiPoint = this.modeForm.POIpoint.split(',')
                    addr = this.modeForm.POIname
                }
                const Icon = new AMap.Icon({
                    size: new AMap.Size(42, 42), // 图标尺寸
                    image: require('../../../public/images/msi/online/' + _src + '.png'),
                    imageSize: new AMap.Size(42, 42) // 根据所设置的大小拉伸或压缩图片
                })
                var Marker = new AMap.Marker({
                    map: this.GDmap,
                    position: new AMap.LngLat(poiPoint[1], poiPoint[0]), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
                    offset: new AMap.Pixel(-21, -42),
                    icon: Icon,
                    extData: {
                        time: accidentPoint.deviceTime.replace('T', ' '),
                        speed: accidentPoint.gpsSpeed + 'km/h',
                        addr: addr
                    }
                })
                Marker.on('click', (e) => {
                    var extData = e.target._opts.extData
                    //创建信息窗口
                    if (sign == 1) {
                        content =
                            `<div class="info_box">
										  <p>事故时间：` +
                            extData.time +
                            `</p>
										  <p>速度：` +
                            extData.speed +
                            `</p>
										  <p>` +
                            extData.addr +
                            `</p>
									  </div>`
                    } else {
                        var dis = AMap.GeometryUtil.distance([poiPoint[1], poiPoint[0]], [accidentPoint.longitude, accidentPoint.latitude])
                        dis = (dis / 1000).toFixed(2)
                        content =
                            `<div class="info_box">
										  <p>事故地点：` +
                            extData.addr +
                            `</p>
										  <p style="color:#e4393c">位置偏移量：` +
                            dis +
                            `km</p>
									  </div>`
                    }
                    var infoWindow = new AMap.InfoWindow({
                        // isCustom: true,  //使用自定义窗体
                        content: content,
                        offset: new AMap.Pixel(-1, -10)
                    })
                    //打开信息窗口
                    infoWindow.open(this.GDmap, e.lnglat) //后面的参数指的是经纬度，在此显示窗口
                })
            }
        },
        //Draw outliers 绘制百度地图异常点-三急一超
        drawOutliersBmap(data, sign) {
            var circlePath = []
            var strokeColor = '',
                fillColor = '',
                extDataName = ''
            if (sign === 'sharpSpeedUpPoints') {
                strokeColor = 'rgba(255, 255, 255, 1)'
                fillColor = 'rgba(31, 117, 255, 1)'
                extDataName = '急加速报警'
            } else if (sign == 'sharpSpeedDownPoints') {
                strokeColor = 'rgba(255, 255, 255, 1)'
                fillColor = 'rgba(163, 79, 224, 1)'
                extDataName = '急减速报警'
            } else if (sign == 'sharpTurnPoints') {
                strokeColor = 'rgba(255, 255, 255, 1)'
                fillColor = 'rgba(5, 169, 197, 1)'
                extDataName = '急转弯报警'
            } else if (sign == 'collisionPoints') {
                strokeColor = 'rgba(255, 255, 255, 1)'
                fillColor = 'rgba(255, 199, 60, 1)'
                extDataName = '碰撞报警'
            } else if (sign == 'rolloverPoints') {
                strokeColor = 'rgba(255, 255, 255, 1)'
                fillColor = 'rgba(228, 112, 24, 1)'
                extDataName = '翻转报警'
            } else if (sign == 'overSpeedPoints') {
                strokeColor = 'rgba(255, 255, 255, 1)'
                fillColor = 'rgba(247, 72, 130, 1)'
                extDataName = '超速报警'
            }
            data.forEach((val, key) => {
                var obj = {
                    collision: val.collision,
                    lng: val.lon,
                    lat: val.lat,
                    // deviceTime: val.deviceTime.replace('T', ' '),
                    deviceTime: val.t,
                    name: extDataName,
                    speed: val.s + 'km/h',
                    // show:false,
                    strokeColor: strokeColor,
                    fillColor: fillColor
                }
                if (sign == 'collisionPoints' && val.collision) {
                    this.collisionPoint = obj
                } else {
                    circlePath.push(obj)
                }
            })
            this.circlePath[sign] = circlePath
        },
        //绘制高德事故点
        drawAMapOutliers(data, sign) {
            var _this = this
            var strokeColor = '',
                fillColor = '',
                extDataName = ''
            if (sign == 'sharpSpeedUpPoints') {
                strokeColor = 'rgba(255, 255, 255, 1)'
                fillColor = 'rgba(31, 117, 255, 1)'
                extDataName = '急加速报警'
            } else if (sign == 'sharpSpeedDownPoints') {
                strokeColor = 'rgba(255, 255, 255, 1)'
                fillColor = 'rgba(163, 79, 224, 1)'
                extDataName = '急减速报警'
            } else if (sign == 'sharpTurnPoints') {
                strokeColor = 'rgba(255, 255, 255, 1)'
                fillColor = 'rgba(5, 169, 197, 1)'
                extDataName = '急转弯报警'
            } else if (sign == 'collisionPoints') {
                strokeColor = 'rgba(255, 255, 255, 1)'
                fillColor = 'rgba(255, 199, 60, 1)'
                extDataName = '碰撞报警'
            } else if (sign == 'rolloverPoints') {
                strokeColor = 'rgba(255, 255, 255, 1)'
                fillColor = 'rgba(228, 112, 24, 1)'
                extDataName = '翻转报警'
            } else if (sign == 'overSpeedPoints') {
                strokeColor = 'rgba(255, 255, 255, 1)'
                fillColor = 'rgba(247, 72, 130, 1)'
                extDataName = '超速报警'
            }
            data.forEach((val, key) => {
                var circleMarker = new AMap.CircleMarker({
                    center: new AMap.LngLat(val.longitude, val.latitude),
                    radius: 7, //3D视图下，CircleMarker半径不要超过64px
                    zIndex: 100,
                    cursor: 'pointer',
                    strokeColor: strokeColor,
                    fillColor: fillColor,
                    extData: {
                        deviceTime: val.deviceTime.replace('T', ' '),
                        name: extDataName,
                        speed: val.gpsSpeed + 'km/h'
                    }
                })
                circleMarker.setMap(this.GDmap)
                circleMarker.on('click', (e) => {
                    var extData = e.target._opts.extData
                    //创建信息窗口
                    var content =
                        `<div class="info_box">
									  <p>` +
                        extData.name +
                        `</p>
									  <p>` +
                        extData.deviceTime +
                        `</p>
									  <p>速度：` +
                        extData.speed +
                        `</p>
									  <div class="info_btn">设为事故点</div>
								  </div>`
                    var infoWindow = new AMap.InfoWindow({
                        // isCustom: true,  //使用自定义窗体
                        content: content,
                        offset: new AMap.Pixel(-1, -10)
                    })
                    //打开信息窗口
                    infoWindow.open(this.GDmap, e.lnglat) //后面的参数指的是经纬度，在此显示窗口
                    setTimeout(() => {
                        var oBox = document.getElementsByClassName('info_btn')
                        oBox[0].addEventListener('click', () => {
                            _this.initData(extData.deviceTime)
                        })
                    }, 500)
                })
            })
        },
        //点击设为事故点，初始化数据
        initData(deviceTime) {
            // this.fullscreenLoading = true
            this.navForm.accidentTime = deviceTime
            this.currentPage = 1
            this.map.reset()
            this.infoWindow.show = false
            this.setMap()
            return
            this.loadBMapPath(this.recallTravelData.gpsList)
        },
        //选择日期
        selectDate(sign) {
            const start = new Date()
            const end = new Date()
            if (sign == 'today') {
                this.navForm.accidentTime = this.dateFormatting(start, 'yyyy-MM-dd')
                this.navForm.endTime = this.dateFormatting(end, 'yyyy-MM-dd')
            } else {
                var accidentTime = this.dateFormatting(new Date(start.getTime() - 3600 * 1000 * 24 * sign), 'yyyy-MM-dd')
                var endTime = this.dateFormatting(new Date(start.getTime() - 3600 * 1000 * 24), 'yyyy-MM-dd')
                this.navForm.accidentTime = accidentTime.slice(0, 7)
                this.navForm.endTime = endTime.slice(0, 7)
                // this.navForm.endTime=this.dateFormatting(end,'yyyy-MM-dd');
            }
        },
        openNewPage(row) {
            let routeUrl = this.$router.resolve({
                path: '/vehicleMap',
                query: {
                    deviceNumber: row.deviceNumber,
                    rowStartTime: row.startTime,
                    tripUuid: row.tripUuid
                }
            })
            window.open(routeUrl.href, '_blank')
        },
        //分页数据多少条change
        handleSizeChange(val) {
            this.queryParams.pageSize = val
            this.getAccidentPlus24HourTrip()
        },
        //分页：当前页改变之后的函数
        handleCurrentChange: function (val) {
            this.queryParams.pageNo = Number(val)
            this.getAccidentPlus24HourTrip()
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../../assets/css/table_white.css';
.select_map {
    margin-right: 0.2rem;
}
.data_box {
    display: flex;
    justify-content: space-between;
}
.data_box_left {
    width: 12%;
    height: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.22rem;
    border: 1px dashed #05a9c5;
    background: #f3fdff;
}
.yellow .data_box_left {
    border: 1px dashed #ff9a02;
    background: #fffaf6;
    height: 1.09rem;
}
.blue .data_box_left {
    border: 1px dashed #697eff;
    background: #f8f9ff;
    height: 1.09rem;
}
.data_box_left div {
    width: 0.44rem;
    margin-right: 0.1rem;
}
.data_box_right {
    width: 86.8%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-content: space-between;
}
.data_box_right li {
    width: 19%;
    /* margin-bottom:0.22rem; */
    height: 1.09rem;
    border: 1px dashed #05a9c5;
    text-align: center;
    font-size: 0.18rem;
    font-weight: 500;
}
.yellow .data_box_right li {
    border: 1px dashed #ff9a02;
    width: 15.6%;
}
.blue .data_box_right li {
    border: 1px dashed #697eff;
    width: 32.4%;
}
.data_box_right li div {
    height: 0.52rem;
    line-height: 0.52rem;
    background: #f3fdff;
    color: #05a9c5;
}
.yellow .data_box_right li div {
    background: #fffaf6;
    color: #b95b2b;
}
.blue .data_box_right li div {
    background: #f8f9ff;
    color: #7186ff;
}
.data_box_right li p {
    height: 0.55rem;
    line-height: 0.55rem;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}
.content {
    position: relative;
    height: 100%;
}
.content_box {
    margin-bottom: 0.18rem;
    position: relative;
}
.map_box {
    position: relative;
}
.slider_item {
    width: 88%;
    position: relative;
}
.slider_icon {
    width: 0.72rem;
    height: 0.72rem;
    margin: 0 auto;
}
#container {
    width: 85%;
    height: 8.53rem;
}
.map_right {
    width: 14%;
    height: 8rem;
}
.detail_form {
    border: none;
}
.form_box {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: 85%;
    height: 66px;
    background: rgba(0, 0, 0, 0.6);
    padding: 0 15px;
}
.detail_form li {
    min-width: 25%;
}
.poi_address {
    width: 200px;
}
.group_btn {
    text-align: center;
    margin-right: 0.2rem;
}
.switch_box {
    padding: 0.06rem;
    background: #ffffff;
    border-radius: 6px;
    display: flex;
    align-items: center;
}
.switch_text {
    display: inline-block;
    margin: 0 0.12rem;
}
.accident {
    width: 100%;
    height: 2.96rem;
    margin-bottom: 0.26rem;
    padding: 0;
}
.accident > div {
    height: 1.47rem;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}
.accident > div:first-child {
    border-bottom: 1px dashed #e8e8e8;
}
.accident img {
    width: 0.36rem;
    height: 0.36rem;
    display: inline-block;
    margin-right: 0.03rem;
}
.accident p {
    width: 100%;
    margin-top: 0.1rem;
}
.dot_hint {
    display: flex;
    width: 100%;
    height: 5.3rem;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: center;
    padding: 0.5rem 0;
}
.dot_hint li {
    font-size: 0.18rem;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    /* color: #FFFFFF; */
    /* width: 150px; */
    display: flex;
    align-items: center;
    width: 1.5rem;
    /* margin:0 auto 0.56rem; */
}
.dot_hint b {
    width: 0.24rem;
    height: 0.24rem;
    border: 3px solid rgba(255, 255, 255, 1);
    background-color: rgba(31, 117, 255, 1);
    border-radius: 50%;
    margin-right: 0.15rem;
}
.dot_hint li:nth-child(2) b {
    border: 3px solid rgba(255, 255, 255, 1);
    background-color: rgba(163, 79, 224, 1);
}
.dot_hint li:nth-child(3) b {
    border: 3px solid rgba(255, 255, 255, 1);
    background-color: rgba(5, 169, 197, 1);
}
.dot_hint li:nth-child(4) b {
    border: 3px solid rgba(255, 255, 255, 1);
    background-color: rgba(255, 199, 60, 1);
}
.dot_hint li:nth-child(5) b {
    border: 3px solid rgba(255, 255, 255, 1);
    background-color: rgba(228, 112, 24, 1);
}
.dot_hint li:nth-child(6) b {
    border: 3px solid rgba(255, 255, 255, 1);
    background-color: rgba(247, 72, 130, 1);
}
.slider_box {
    width: 97%;
    margin: 0.2rem auto 0.1rem;
    position: relative;
}
.slider_title {
    color: #000000;
    font-size: 0.18rem;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0.08rem;
    right: 0;
    z-index: 100;
}
.slider_title::before {
    width: 0.1rem;
    height: 0.16rem;
    display: inline-block;
    content: '';
    background: url(../../../public/images/msi/online/xiangzuo.png) no-repeat;
    background-size: 100% 100%;
    margin-right: 6px;
}
</style>
