<template>
    <div class="content" v-cloak v-loading.fullscreen.lock="fullscreenLoading" element-loading-text="正在加载中，请稍后！">
        <div class="page_title">行程分析</div>
        <div class="content_box">
            <div class="search_box">
                <!--检索部分-->
                <el-form :inline="true" :model="navForm" class="demo-form-inline">
                    <el-form-item label="行驶时间:" class="dateBox">
                        <el-row style="width: 320px">
                            <el-col :span="11">
                                <el-date-picker size="small" placeholder="请选择日期" v-model="navForm.startTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                            </el-col>
                            <el-col class="line" style="text-align: center" :span="2">至</el-col>
                            <el-col :span="11">
                                <el-date-picker size="small" placeholder="请选择日期" v-model="navForm.endTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item>
                        <el-button-group>
                            <el-button type="primary" plain size="small" @click="selectDate('today')">今天</el-button>
                            <el-button type="primary" plain size="small" autofocus @click="selectDate('7')">近7天</el-button>
                            <el-button type="primary" plain size="small" @click="selectDate('30')">近30天</el-button>
                        </el-button-group>
                    </el-form-item>
                    <el-form-item label="设备编号/车架号/车牌号:">
                        <el-input class="width_150" v-model="navForm.condition.search" size="small" placeholder="请输入" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="选择分公司:" prop="companyId">
                        <el-select class="width_180" v-model="navForm.condition.companyId" placeholder="请选择" size="small" clearable @change="getBatchIdList">
                            <el-option v-for="(item, index) in companyList" :key="index" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="选择车队:" prop="fleetId">
                        <el-select class="width_150" v-model="navForm.condition.fleetId" placeholder="请选择" size="small" clearable>
                            <el-option v-for="(item, index) in batchIdList" :key="index" :label="item.customerId" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="searchTable" size="small" style="margin-left: 0.2rem">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="table_box">
                <!--table表格部分-->
                <el-table ref="multipleTable" v-loading="loading" :data="tableData" style="width: 100%" :height="tableHeight" @sort-change="sortChange">
                    <el-table-column label="序号" type="index" width="80" :index="hIndex"></el-table-column>
                    <el-table-column prop="platNo" label="绑定车牌号"></el-table-column>
                    <el-table-column prop="deviceNumber" label="设备编号"></el-table-column>
                    <!-- <el-table-column prop="vin" label="绑定车架号" ></el-table-column> -->
                    <el-table-column prop="startTime" label="开始时间" width="156" sortable="custom"></el-table-column>
                    <el-table-column prop="endTime" label="结束时间" width="156" sortable="custom"></el-table-column>
                    <el-table-column prop="commercialVehicleScore" label="驾驶评分" sortable="custom"></el-table-column>
                    <el-table-column prop="drivingDurationMinutes" label="行驶时长（h）" sortable="custom">
                        <template slot-scope="scope">
                            <span>{{ scope.row.drivingDurationHours }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="mileage" label="行驶里程（km）" sortable="custom"></el-table-column>
                    <!-- <el-table-column prop="maxSpeed" label="最大速度（km/h）" ></el-table-column> -->
                    <!-- <el-table-column prop="avgSpeed" label="平均速度（km/h）" ></el-table-column> -->
                    <el-table-column label="操作" width="80" v-if="!userInfo.batchId">
                        <template slot-scope="scope">
                            <div class="operation_box">
                                <span @click="openNewPage(scope.row)">查看轨迹</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <!--分页部分-->
                <el-pagination style="margin-top: 0.2rem" @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="currentPage" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="10" :total="totalPage"> </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DrivingScore',
    props: {
        msg: String
    },
    data() {
        return {
            tableHeight: window.innerHeight > 700 ? 395 : 368,
            rid: '',
            loading: false,
            fullscreenLoading: false,
            detailPageVisible: false,
            formType: '', //页面类型
            navForm: {
                //储存搜索框的检索条件信息
                condition: {
                    companyId: null,
                    search: null,
                    fleetId: null,
                    deviceNumber: null,
                    tripTimeMin: null,
                    tripTimeMax: null
                },
                startTime: null,
                endTime: null,
                orders: [],
                pageNo: 1,
                pageSize: 20
            },
            modeForm: {
                name: ''
            },
            batchIdList: [], //车队
            currentPage: 1, //当前页
            pageSize: 10, //一页多少条数据
            totalPage: 1, //总页数tableData:[],
            tableData: [],
            companyList: []
        };
    },
    mounted() {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.navForm.companyId = this.userInfo.company;
        this.selectDate(7);
        this.getCompanyList();
        this.getTbodyData();
    },
    methods: {
        //获取车队
        getBatchIdList(id) {
            this.navForm.condition.fleetId = '';
            if (id) {
                this.instance.get('/ubi/api/tenant/fleetList?companyId=' + id).then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.batchIdList = data.data;
                    }
                });
            } else {
                this.batchIdList = [];
            }
        },
        //获取分公司
        getCompanyList() {
            this.instance.get('/ubi/api/tenant/companyList').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.companyList = data.data;
                }
            });
        },
        //排序
        sortChange(column) {
            let tmpIndex = this.navForm.orders.findIndex((res) => {
                return res.column == column.prop;
            });
            let tmpOrderItem = {
                asc: column.order == 'ascending' ? true : false,
                column: column.prop
            };
            this.navForm.orders = [];
            column.order && this.navForm.orders.push(tmpOrderItem);
            this.getTbodyData();
        },
        //重新计算index序号
        hIndex(index) {
            // index索引从零开始，index +1即为当前数据序号
            this.currentPage <= 0 ? (this.currentPage = 1) : this.currentPage;
            // 如果当前不是第一页数据
            if (this.currentPage != 1) {
                // index + 1 + (( 当前页 - 1) * 每页展示条数)
                // 比如是第二页数据 1 + ((2 - 1)*5) = 6,第二页数据也就是从序号6开始
                return index + 1 + (this.currentPage - 1) * this.pageSize;
            }
            // 否则直接返回索引+1作为序号
            return index + 1;
        },
        openNewPage(row) {
            let routeUrl = this.$router.resolve({
                path: '/vehicleMap',
                query: {
                    deviceNumber: row.deviceNumber,
                    rowStartTime: row.startTime,
                    tripUuid: row.tripUuid
                }
            });
            window.open(routeUrl.href, '_blank');
        },
        //选择日期
        selectDate(sign) {
            const start = new Date();
            const end = new Date();
            if (sign == 'today') {
                this.navForm.startTime = this.dateFormatting(start, 'yyyy-MM-dd');
                this.navForm.endTime = this.dateFormatting(end, 'yyyy-MM-dd');
            } else {
                this.navForm.startTime = this.dateFormatting(new Date(start.getTime() - 3600 * 1000 * 24 * sign), 'yyyy-MM-dd');
                this.navForm.endTime = this.dateFormatting(end, 'yyyy-MM-dd');
            }
        },
        //点击查询
        searchTable() {
            this.currentPage = 1;
            this.getTbodyData();
        },
        //获取tbody里的数据
        getTbodyData() {
            var _this = this;
            this.loading = true;
            _this.tableData = []; //  清空table的值
            const { startTime, endTime, ...newNavForm } = this.navForm;
            newNavForm.condition.tripTimeMin = startTime ? startTime + ' 00:00:00' : null;
            newNavForm.condition.tripTimeMax = endTime ? endTime + ' 00:00:00' : null;
            newNavForm.pageNo = _this.currentPage;
            newNavForm.pageSize = _this.pageSize;
            this.instance.post('/ubi/api/trip/page', newNavForm).then((res) => {
                this.loading = false;
                let data = res.data;
                if (data.code == 200) {
                    _this.tableData = data.data.list;
                    _this.totalPage = parseInt(data.data.total);
                } else {
                    _this.totalPage = 0;
                }
            });
        },
        toHourMinute(minutes) {
            return Math.floor(minutes / 60) + '小时' + (minutes % 60).toFixed(2) + '分';
        },
        //分页数据多少条change
        handleSizeChange(val) {
            this.pageSize = val;
            this.getTbodyData();
        },
        //分页：当前页改变之后的函数
        handleCurrentChange: function (val) {
            // console.log(val);
            this.currentPage = Number(val);
            this.getTbodyData(this.activityState);
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../../assets/css/table_white.css';
.content {
    height: 100%;
}
</style>
