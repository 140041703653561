<template>
    <!-- 地图区域 -->
    <baidu-map @ready="initMap" :zoom="5" center="四川省" style="height: 60vh; width: 100%" :scroll-wheel-zoom="false" :double-click-zoom="false">
        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT" />
    </baidu-map>
</template>
<script>
export default {
    name: 'MapFence',
    props: {
        regionObj: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            map: null
        }
    },
    watch: {
        regionObj(val) {
            if (val) {
                this.drawPolygon(val.label)
            }
        }
    },
    methods: {
        initMap({ BMap, map }) {
            this.map = map
            this.regionObj.label && this.drawPolygon(this.regionObj.label)
        },
        // 绘制行政区划轮廓
        drawPolygon(regionName) {
            this.map && this.map.clearOverlays()
            let that = this
            let bdary = new BMap.Boundary()
            bdary.get(regionName, function (rs) {
                //轮廓的总数 —— 有的区由多个封闭区域组成
                let count = rs.boundaries.length
                if (!count) {
                    console.log('未能获取到当前输入的行政区域')
                    return
                }
                for (let i = 0; i < count; i++) {
                    //创建多边形覆盖物
                    let ply = new BMap.Polygon(rs.boundaries[i], {
                        strokeWeight: 3,
                        strokeColor: 'red',
                        fillOpacity: 0.3,
                        strokeStyle: 'dashed'
                    })
                    // 给覆盖物添加点击事件
                    ply.addEventListener('click', function () {
                        console.log('当前区域：' + regionName)
                        // 改变自己的透明度
                        this.setFillOpacity(1)
                    })
                    //添加覆盖物
                    that.map.addOverlay(ply)
                }
            })
        }
    }
}
</script>