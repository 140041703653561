<template>
    <div :class="sumFlag ? 'preview_ontent' : ''">
        <div class="preview_zhe"></div>
        <div id="pdfContent" class="pdf_content">
            <!-- 第0页 -->
            <div class="pdf_page">
                <img class="imgTop" src="../../public/logo.jpg" />
                <img class="imgBottom" src="../../public/logo.jpg" />
                <div class="page_header">环球车享车队运营报告</div>
                <div class="page_title" @click="clickEle">环球车享车队运营报告</div>
                <div class="page_title page_titles" style="margin-bottom: 20px">
                    ——{{ reportData.searchForm ? String(reportData.searchForm.reportCycle).slice(0, 4) : '-' }} 年 {{ reportData.searchForm ? String(reportData.searchForm.reportCycle).slice(5, reportData.searchForm.reportCycle.length) : '-' }} 月
                </div>
                <div class="txt_title page_txt" style="margin-top: 0px">设备拔出率排名</div>
                <el-table class="report_table report_none" :data="deviceExtractData" border style="width: 100%">
                    <el-table-column prop="sort" label="拔出率排名" width="120"></el-table-column>
                    <el-table-column prop="region" label="地区"></el-table-column>
                    <el-table-column prop="currentRatio" label="拔出率">
                        <template slot-scope="scope">
                            <span>{{ scope.row.currentRatio ? scope.row.currentRatio + '%' : '' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="currentPullNum" label="拔出数量"></el-table-column>
                    <el-table-column prop="lastRatio" :label="datasTitle1 + '月拔出率'">
                        <template slot-scope="scope">
                            <span>{{ scope.row.lastRatio ? scope.row.lastRatio + '%' : '' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="previousRatio" :label="datasTitle2 + '月拔出率'">
                        <template slot-scope="scope">
                            <span>{{ scope.row.previousRatio ? scope.row.previousRatio + '%' : '' }}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="txt_title page_txt" style="margin-top: 20px">风险分析</div>
                <div class="subhead_txt" style="margin-bottom: 10px">
                    <template v-if="deviceExtractObj.textArr.length > 0">
                        <p v-for="(item, index) in deviceExtractObj.textArr" :key="index" v-html="item"></p>
                    </template>
                </div>
                <div class="txt_title page_txt" style="margin-top: 20px; margin-bottom: 0px">危险驾驶车辆跟踪</div>
                <!-- 危险驾驶车辆跟踪 折柱图 -->
                <div id="badDrivingVehiclePdfChat" class="echarts_category"></div>
                <div class="txt_title page_txt" style="margin-top: -20px">风险分析</div>
                <div class="subhead_txt" style="margin-bottom: 10px">
                    <template v-if="drivingVehicleData.textArr.length > 0">
                        <p v-for="(item, index) in drivingVehicleData.textArr" :key="index" v-html="item"></p>
                    </template>
                </div>
                <div class="page_footer">1</div>
            </div>
            <!-- 第1页 -->
            <div class="pdf_page">
                <img class="imgTop" src="../../public/logo.jpg" />
                <img class="imgBottom" src="../../public/logo.jpg" />
                <div class="page_header">环球车享车队运营报告</div>
                <!-- <div class="page_title" @click="clickEle">环球车享车队运营报告</div>
                <div class="page_title page_titles">——{{ reportData.searchForm ? String(reportData.searchForm.reportCycle).slice(0, 4) : '-' }} 年 {{ reportData.searchForm ? String(reportData.searchForm.reportCycle).slice(5, reportData.searchForm.reportCycle.length) : '-' }} 月</div> -->

                <div class="txt_title page_txt" style="margin-top: 20px; margin-bottom: 0px">疲劳驾驶指数排名</div>
                <!-- 危险驾驶车辆跟踪 柱状图 -->
                <div id="fatiguedDrivingIndexSorPdfChat" class="echarts_category"></div>
                <div class="txt_title page_txt" style="margin-top: -20px">风险分析</div>
                <div class="subhead_txt" style="margin-bottom: 10px">
                    <template v-if="drivingIndexSortData.textArr.length > 0">
                        <p v-for="(item, index) in drivingIndexSortData.textArr" :key="index" v-html="item"></p>
                    </template>
                </div>
                <div class="subheading">一、设备运行情况</div>
                <div class="subhead_txt" style="margin-bottom: 10px">
                    环球车享创新服务项目于 2022 年 06 月启动，截至 {{ reportData.searchForm ? String(reportData.searchForm.reportCycle).slice(0, 4) : '-' }} 年
                    {{ reportData.searchForm ? String(reportData.searchForm.reportCycle).slice(5, reportData.searchForm.reportCycle.length) : '-' }} 月，累计发货设 备 {{ tableData1[tableData1.length - 1] ? tableData1[tableData1.length - 1].deliverGoodsNum : '-' }} 台，安装设备
                    {{ tableData1[tableData1.length - 1] ? tableData1[tableData1.length - 1].bindDeviceNum : '-' }} 台，设备激活率 {{ tableData1[tableData1.length - 1] ? tableData1[tableData1.length - 1].activationDeviceRatio : '-' }}%，设备在线率
                    {{ tableData1[tableData1.length - 1] ? tableData1[tableData1.length - 1].onlineDeviceRatio : '-' }}%，设备 拔出率 {{ tableData1[tableData1.length - 1] ? tableData1[tableData1.length - 1].pullDeviceRatio : '-' }}%，各地区详细情况如下
                    <span style="color: red">（设备激活率小于 100%，设备在线率小于 80%，设备拔出率大于 10%的地区标红）：</span>
                </div>
                <!-- 表格 -->
                <el-table class="report_table" :data="tableData1" border>
                    <el-table-column prop="cityName" label="地区"></el-table-column>
                    <el-table-column prop="deliverGoodsNum" label="发货数量"></el-table-column>
                    <el-table-column prop="bindDeviceNum" label="安装数量"></el-table-column>
                    <el-table-column prop="activationDeviceNum" label="激活数量"></el-table-column>
                    <el-table-column prop="activationDeviceRatio" label="激活率">
                        <template slot-scope="scope">
                            <div>
                                <span style="color: red" v-if="Number(scope.row.activationDeviceRatio) < 100">{{ scope.row.activationDeviceRatio + '%' }}</span>
                                <span v-else>{{ scope.row.activationDeviceRatio + '%' }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="onlineDeviceNum" label="在线数量"></el-table-column>
                    <el-table-column prop="onlineDeviceRatio" label="在线率">
                        <template slot-scope="scope">
                            <div>
                                <span style="color: red" v-if="Number(scope.row.onlineDeviceRatio) < 80">{{ scope.row.onlineDeviceRatio + '%' }}</span>
                                <span v-else>{{ scope.row.onlineDeviceRatio + '%' }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="pullDeviceNum" label="拔出数量"></el-table-column>
                    <el-table-column prop="pullDeviceRatio" label="拔出率">
                        <template slot-scope="scope">
                            <div>
                                <span style="color: red" v-if="Number(scope.row.pullDeviceRatio) > 10">{{ scope.row.pullDeviceRatio + '%' }}</span>
                                <span v-else>{{ scope.row.pullDeviceRatio + '%' }}</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="txt_title page_txt" style="margin-top: 20px">数据统计规则：</div>
                <div class="page_txt">安装：设备装车后，已提供设备与车辆匹配信息并绑定到平台</div>
                <div class="page_txt">激活：设备在车辆上安装成功后，成功上报过数据则为已激活设备</div>
                <div class="page_txt">在线：统计周期内，有数据上报则为在线设备</div>
                <div class="page_txt">拔出：统计数据时，设备为拔出状态</div>
                <div class="page_footer">2</div>
            </div>
            <!-- 第2页 -->
            <div class="pdf_page">
                <img class="imgTop" src="../../public/logo.jpg" />
                <img class="imgBottom" src="../../public/logo.jpg" />
                <div class="page_header">环球车享创新服务项目运营报告</div>
                <div class="page_txt">设备激活率：设备激活数量/设备安装数量*100%</div>
                <div class="page_txt">设备在线率：在线设备数量/设备激活总数*100%</div>
                <div class="page_txt">设备拔出率：拔出设备数量/设备激活总数*100%</div>
                <div class="subheading">二、车辆运行情况</div>
                <div class="txt_title page_txt">1、行驶里程情况</div>
                <div class="page_txt">1）各地区车辆行驶里程</div>
                <div class="page_txt txt_indent" style="margin-bottom: 40px">本周期内，各地区在线车辆运营里程情况如下：</div>
                <!-- 各地区车辆行驶里程 - 柱状图 -->
                <div id="drivingMileageLine1" class="echarts_category"></div>
                <!-- 各地区车辆行驶里程 - 表格 -->
                <el-table class="report_table" :data="drivingMileageData1" style="margin-top: -10px">
                    <el-table-column prop="cityName" label="地区"> </el-table-column>
                    <el-table-column label="总里程(Km)">
                        <el-table-column prop="mileage" label="本月" width="110"> </el-table-column>
                        <el-table-column prop="mileageRingRatio" label="环比">
                            <template slot-scope="scope">
                                <span>{{ scope.row.mileageRingRatio ? scope.row.mileageRingRatio + '%' : '-' }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="有行程车辆数">
                        <el-table-column prop="drivingVehNum" label="本月"> </el-table-column>
                        <el-table-column prop="drivingVehNumRingRatio" label="环比">
                            <template slot-scope="scope">
                                <span>{{ scope.row.drivingVehNumRingRatio ? scope.row.drivingVehNumRingRatio + '%' : '-' }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="车均里程(Km)">
                        <el-table-column prop="mileageAvgVeh" label="本月"> </el-table-column>
                        <el-table-column prop="mileageAvgVehRingRatio" label="环比">
                            <template slot-scope="scope">
                                <span>{{ scope.row.mileageAvgVehRingRatio ? scope.row.mileageAvgVehRingRatio + '%' : '-' }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="日均里程(Km)">
                        <el-table-column prop="mileageAvgDay" label="本月"> </el-table-column>
                        <el-table-column prop="mileageAvgDayRingRatio" label="环比">
                            <template slot-scope="scope">
                                <span>{{ scope.row.mileageAvgDayRingRatio ? scope.row.mileageAvgDayRingRatio + '%' : '-' }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column prop="mileageSum" label="累计里程(km)" width="140"> </el-table-column>
                </el-table>
                <div class="txt_title page_txt">数据统计规则：</div>
                <div class="page_txt">车均里程：统计期内总里程/有行程车辆数</div>
                <div class="page_txt">日均里程：统计期内总里程/在线车辆有行程的天数之和</div>
                <div class="page_txt">累计里程：从激活时间起，车辆总行驶里程</div>
                <div class="page_txt">2）月均里程及单车里程分析</div>
                <div class="page_txt txt_indent">本周期内，各地区在线车辆运营里程情况如下（里程>10000km、里程<1000km 车辆清单详见附件）：</div>
                <div class="page_footer footer2">3</div>
            </div>
            <!-- 第3页 -->
            <div class="pdf_page">
                <img class="imgTop" src="../../public/logo.jpg" />
                <img class="imgBottom" src="../../public/logo.jpg" />
                <div class="page_header">环球车享创新服务项目运营报告</div>
                <!-- 里程>10000 - 表格 -->
                <el-table class="report_table" :data="mileageData1" style="width: 100%; margin-top: 50px" border>
                    <el-table-column prop="cityName" label="地区"> </el-table-column>
                    <el-table-column prop="drivingVehNum" label="有行程车辆数"> </el-table-column>
                    <el-table-column prop="vehNumMileage10000ToUp" label="里程>10000km 车辆数"> </el-table-column>
                    <el-table-column prop="vehNumMileage0To1000" label="里程<1000km 车辆数"> </el-table-column>
                    <el-table-column prop="mileageSumAvgMonth" label="月均里程(km)" width="140"> </el-table-column>
                </el-table>
                <div class="txt_title page_txt" style="margin-top: 20px">数据统计规则：</div>
                <div class="page_txt" style="margin-top: 20px">月均里程：车辆累计里程/(自有行程之日起的天数/30)</div>
                <div class="page_txt" style="margin-bottom: 60px; margin-top: 30px">3）车辆累计里程分布</div>
                <!-- 车辆累计里程分布 饼图 -->
                <div id="accumulatePie1" class="echarts_category echarts_60 echarts_center"></div>
                <div class="page_txt" style="margin-bottom: 40px">4）车辆单次行程最大里程分布</div>
                <!-- 车辆单次行程最大里程分布 饼图 -->
                <div id="singlePie1" class="echarts_category echarts_60 echarts_center"></div>
                <div class="page_footer">4</div>
            </div>
            <!-- 第4页 -->
            <div class="pdf_page">
                <img class="imgTop" src="../../public/logo.jpg" />
                <img class="imgBottom" src="../../public/logo.jpg" />
                <div class="page_header">环球车享创新服务项目运营报告</div>
                <div class="txt_title page_txt">2、行驶时长情况</div>
                <div class="page_txt">1）各地区车辆行驶时长</div>
                <div class="page_txt txt_indent" style="margin-bottom: 40px">本周期内，各地区在线车辆运营时长情况如下：</div>
                <!-- 各地区车辆行驶时长 柱状图-->
                <div id="eachRegionCategory1" class="echarts_category"></div>
                <!-- 各地区车辆行驶时长 表格 -->
                <el-table class="report_table" :data="eachRegionData1">
                    <el-table-column prop="cityName" label="地区"> </el-table-column>
                    <el-table-column label="总时长(H)">
                        <el-table-column prop="drivingDuration" label="本月"> </el-table-column>
                        <el-table-column prop="drivingDurationRingRatio" label="环比">
                            <template slot-scope="scope">
                                <span>{{ scope.row.drivingDurationRingRatio ? scope.row.drivingDurationRingRatio + '%' : '-' }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="有行程车辆数">
                        <el-table-column prop="drivingVehNum" label="本月"> </el-table-column>
                        <el-table-column prop="drivingVehNumRingRatio" label="环比">
                            <template slot-scope="scope">
                                <span>{{ scope.row.drivingVehNumRingRatio ? scope.row.drivingVehNumRingRatio + '%' : '-' }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="车均时长(H)">
                        <el-table-column prop="drivingDurationAvgVeh" label="本月"> </el-table-column>
                        <el-table-column prop="drivingDurationAvgVehRingRatio" label="环比">
                            <template slot-scope="scope">
                                <span>{{ scope.row.drivingDurationAvgVehRingRatio ? scope.row.drivingDurationAvgVehRingRatio + '%' : '-' }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="日均时长(H)">
                        <el-table-column prop="drivingDurationAvgDay" label="本月"> </el-table-column>
                        <el-table-column prop="drivingDurationAvgDayRingRatio" label="环比">
                            <template slot-scope="scope">
                                <span>{{ scope.row.drivingDurationAvgDayRingRatio ? scope.row.drivingDurationAvgDayRingRatio + '%' : '-' }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column prop="drivingDurationSum" label="累计时长(H)" width="120"> </el-table-column>
                </el-table>
                <div class="txt_title page_txt" style="margin-top: 30px; margin-bottom: 20px">数据统计规则：</div>
                <div class="page_txt">车均时长：统计期内总时长/有行程车辆</div>
                <div class="page_txt">日均时长：统计期内总时长/在线车辆有行程的天数之和</div>
                <div class="page_txt">累计时长：从激活时间起，车辆总行驶时长</div>
                <div class="page_footer">5</div>
            </div>
            <!-- 第5页 -->
            <div class="pdf_page">
                <img class="imgTop" src="../../public/logo.jpg" />
                <img class="imgBottom" src="../../public/logo.jpg" />
                <div class="page_header">环球车享创新服务项目运营报告</div>
                <div class="page_txt" style="margin-bottom: 90px; margin-top: 30px">2）车辆累计时长分布</div>
                <!-- 车辆累计时长分布 -->
                <div id="accumulatedDurationPie1" class="echarts_category echarts_60 echarts_center"></div>
                <div class="page_txt" style="margin-bottom: 90px">3）车辆单次行程最大时长分布</div>
                <!-- 车辆单次行程最大时长分布 -->
                <div id="maximumDurationPie1" class="echarts_category echarts_60 echarts_center"></div>
                <div class="subheading">三、驾驶行为分析</div>
                <div class="txt_title page_txt" style="margin: 20px 0">1、疲劳驾驶情况</div>
                <div class="page_txt">1）各地区车辆疲劳驾驶情况</div>
                <div class="page_txt txt_indent" style="margin-bottom: 60px">本周期内，各地区疲劳驾驶情况如下：</div>
                <!-- 各地区疲劳驾驶情况  折/柱图 -->
                <div id="fatigueDrivingLine1" class="echarts_category" style="height: 350px"></div>
                <div class="page_footer">6</div>
            </div>
            <!-- 第6页 -->
            <div class="pdf_page">
                <img class="imgTop" src="../../public/logo.jpg" />
                <img class="imgBottom" src="../../public/logo.jpg" />
                <div class="page_header">环球车享创新服务项目运营报告</div>
                <!-- 各地区疲劳驾驶情况 表格 -->
                <el-table class="report_table" :data="fatigueDrivingData1" style="margin-bottom: 30px; margin-top: 30px">
                    <el-table-column prop="cityName" label="地区"> </el-table-column>
                    <el-table-column prop="drivingVehNum" label="有行程车辆数"> </el-table-column>
                    <el-table-column prop="fatiguedDrivingVehRatio" label="疲劳驾驶车辆占比" width="170">
                        <template slot-scope="scope">
                            <span>{{ scope.row.fatiguedDrivingVehRatio ? scope.row.fatiguedDrivingVehRatio + '%' : '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="日均疲劳驾驶时长(h)">
                        <el-table-column prop="fatiguedDrivingDurationAvgDay" label="本月"> </el-table-column>
                        <el-table-column prop="fatiguedDrivingDurationAvgDayRingRatio" label="环比">
                            <template slot-scope="scope">
                                <span>{{ scope.row.fatiguedDrivingDurationAvgDayRingRatio ? scope.row.fatiguedDrivingDurationAvgDayRingRatio + '%' : '-' }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="日均疲劳驾驶次数">
                        <el-table-column prop="fatiguedDrivingNumAvgDay" label="本月"> </el-table-column>
                        <el-table-column prop="fatiguedDrivingNumAvgDayRingRatio" label="环比">
                            <template slot-scope="scope">
                                <span>{{ scope.row.fatiguedDrivingNumAvgDayRingRatio ? scope.row.fatiguedDrivingNumAvgDayRingRatio + '%' : '-' }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                </el-table>
                <div class="txt_title page_txt" style="margin-bottom: 10px">数据统计规则：</div>
                <div class="page_txt">疲劳驾驶：单次行程驾驶时长超过 4 小时</div>
                <div class="page_txt">日均疲劳驾驶时长：总疲劳驾驶时长/在线车辆有行程的天数之和</div>
                <div class="page_txt">日均疲劳驾驶次数：总疲劳驾驶次数/在线车辆有行程的天数之和</div>
                <div class="page_txt" style="margin-bottom: 40px; margin-top: 10px">2）车辆日均疲劳驾驶时长分布</div>
                <!-- 车辆日均疲劳驾驶时长分布 -->
                <div id="fatigueDrivingTimePie1" class="echarts_category echarts_60 echarts_center"></div>
                <div class="page_txt" style="margin-bottom: 40px">3）车辆日均疲劳驾驶次数分布</div>
                <!-- 车辆日均疲劳驾驶次数分布 -->
                <div id="fatigueDrivingSumPie1" class="echarts_category echarts_60 echarts_center"></div>
                <div class="page_footer">7</div>
            </div>
            <!-- 第7页 -->
            <div class="pdf_page">
                <img class="imgTop" src="../../public/logo.jpg" />
                <img class="imgBottom" src="../../public/logo.jpg" />
                <div class="page_header">环球车享创新服务项目运营报告</div>
                <div class="page_txt" style="margin-bottom: 40px; margin-top: 20px">4）车辆单次行程最大疲劳驾驶时长分布</div>
                <!-- 车辆单次行程最大疲劳驾驶时长分布 饼图 -->
                <div id="maximumFatigueDrivingPie1" class="echarts_category echarts_60 echarts_center"></div>
                <div class="txt_title page_txt" style="margin-bottom: 10px; margin-top: -10px">2、三急一超情况</div>
                <div class="page_txt txt_indent" style="margin-bottom: 40px">本周期内，各地区三急一超情况如下：</div>
                <!-- 三急一超情况 柱状图 -->
                <div id="threeUrgentAndOneSuper1" class="echarts_category"></div>
                <!-- 三急一超情况 表格 -->
                <el-table class="report_table" :data="threeUrgentAndOneSuperData1" style="margin-top: 20px">
                    <el-table-column prop="cityName" label="地区"> </el-table-column>
                    <el-table-column prop="drivingVehNum" label="有行程车辆数" width="140"> </el-table-column>
                    <el-table-column label="百公里急加速次数">
                        <el-table-column prop="sharpUpNum100km" label="本月"> </el-table-column>
                        <el-table-column prop="sharpUpNum100kmRingRatio" label="环比">
                            <template slot-scope="scope">
                                <span>{{ scope.row.sharpUpNum100kmRingRatio ? scope.row.sharpUpNum100kmRingRatio + '%' : '-' }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="百公里急减速次数">
                        <el-table-column prop="sharpDownNum100km" label="本月"> </el-table-column>
                        <el-table-column prop="sharpDownNum100kmRingRatio" label="环比">
                            <template slot-scope="scope">
                                <span>{{ scope.row.sharpDownNum100kmRingRatio ? scope.row.sharpDownNum100kmRingRatio + '%' : '-' }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="百公里急加转弯次数">
                        <el-table-column prop="sharpTurnNum100km" label="本月"> </el-table-column>
                        <el-table-column prop="sharpTurnNum100kmRingRatio" label="环比">
                            <template slot-scope="scope">
                                <span>{{ scope.row.sharpTurnNum100kmRingRatio ? scope.row.sharpTurnNum100kmRingRatio + '%' : '-' }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="百公里超速次数">
                        <el-table-column prop="overSpeedNum100km" label="本月"> </el-table-column>
                        <el-table-column prop="overSpeedNum100kmRingRatio" label="环比">
                            <template slot-scope="scope">
                                <span>{{ scope.row.overSpeedNum100kmRingRatio ? scope.row.overSpeedNum100kmRingRatio + '%' : '-' }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                </el-table>
                <div class="page_footer">8</div>
            </div>
            <!-- 第8页 -->
            <div class="pdf_page">
                <img class="imgTop" src="../../public/logo.jpg" />
                <img class="imgBottom" src="../../public/logo.jpg" />
                <div class="page_header">环球车享创新服务项目运营报告</div>
                <div class="txt_title page_txt" style="margin-top: 20px">3、车辆驾驶评分</div>
                <div class="page_txt">1）各地区驾驶评分情</div>
                <div class="page_txt txt_indent" style="margin-bottom: 40px">本周期内，环球车享各地区车辆驾驶评分情况如下：</div>
                <!-- 车辆驾驶评分分布 柱状图 -->
                <div id="drivingRatingColumnar1" class="echarts_category"></div>
                <!-- 车辆驾驶评分情况 表格 -->
                <el-table class="report_table" :data="drivingRatingData1" style="margin-top: 60px">
                    <el-table-column prop="cityName" label="地区"> </el-table-column>
                    <el-table-column prop="drivingVehNum" label="有行程车辆数"> </el-table-column>
                    <el-table-column label="驾驶评分">
                        <el-table-column prop="drivingScoreAvgVeh" label="本月"> </el-table-column>
                        <el-table-column prop="drivingScoreAvgVehRingRatio" label="环比">
                            <template slot-scope="scope">
                                <span>{{ scope.row.drivingScoreAvgVehRingRatio ? scope.row.drivingScoreAvgVehRingRatio + '%' : '-' }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                </el-table>
                <!-- <div class="page_txt">2）驾驶评分分布</div> -->
                <!-- <div class="page_txt">本周期内，环球车享项目车辆驾驶评分分布情况如下：</div> -->
                <!-- 柱状图123 -->
                <div class="subheading" style="margin-top: 40px; margin-bottom: 20px">四、运营及理赔管理建议</div>
                <div class="page_txt txt_indent">通过事故预防、理赔全流程管理、奖惩机制等手段，形成闭环管理，最终实 现降低赔付率的效果。建议采取的运营及理赔管理手段如下：</div>
                <div class="txt_title page_txt">1、事故预防机制</div>
                <div class="page_txt txt_indent">1）针对驾驶行为较差的司机，判定为出事故风险较高进入管控清单，每月 对进入管控清单的司机进行至少 1 次线上安全培训；</div>
                <div class="page_footer">9</div>
            </div>
            <!-- 第9页 -->
            <div class="pdf_page">
                <img class="imgTop" src="../../public/logo.jpg" />
                <img class="imgBottom" src="../../public/logo.jpg" />
                <div class="page_header">环球车享创新服务项目运营报告</div>
                <div class="page_txt txt_indent">2）对不同签约时间的司机进行驾驶行为分类管理，短期签约（如半年）的司机如判定为危险驾驶行为较多的高风险司机，可以通过罚款等手段进行管理，长期签约（如一年及以上）的司机如判定为危险驾驶行为较多的高风险司机，可以进行引导运营、派单倾斜；</div>
                <div class="page_txt txt_indent">3）危险驾驶管理：针对危险驾驶行为（三急一超、疲劳驾驶等），进行不干扰驾驶的微信、短信及 AI 电话实时提醒；</div>
                <div class="page_txt txt_indent">4）对于有出险记录且驾驶行为判定为高风险的司机，每日单独进行驾驶行 为跟踪监督。对于全年未出险且驾驶行为较好的司机，给予现金或其他物品奖励。</div>
                <div class="txt_title page_txt">2、理赔管理建议</div>
                <div class="page_txt txt_indent">1）对于一般案件（部分车损或无人伤案件），进行简易报案；对于较为严 重的事故（如人伤、车辆全损等），车管或司管到现场取证，事故现场还原，确 认事故真实性与合理性，调查司机驾驶行为记录并分析，跟进案件进展；</div>
                <div class="page_txt txt_indent">2）免赔费用追偿：车辆事故维修费用超过 2000 元时，通过保险进行理赔， 2000 元以内的维修费用由司机自行承担（维修金额以保险公司定损为准）；</div>
                <div class="page_txt txt_indent">
                    3）事故维修追偿：车辆事故维修费用低于 2000 元的，司机无需支付加速折 旧费；维修费用超过 2000 元的，司机在交通事故中负全责的或主要责任的，需 向公司支付 2000 元以上部分的维修费 20%的加速折旧费；司机在交通事故中负 同等责任、次要责任的，司机需向公司支付 2000 元以上部分的维修费 10%的加
                    速折旧费；
                </div>
                <div class="page_txt txt_indent">4）车辆维修管理：建议出险车辆必须回到指定修理厂，否则不予理赔；维 修必须使用原厂件，保证维修质量。如发现未使用原厂件，按配件金额 10 倍罚款。</div>
                <div class="txt_title page_txt">3、回收奖惩机制</div>
                <div class="page_txt txt_indent">对在运营车辆强制回收：租期 6 个月内，商业险出险且产生理赔金额 4 次及 以上；或租期 6 个月以上，12 个月内商业险有出险记录且产生理赔金额 5 次及 以上的，进行车辆强制回收，具体条款写入承租合同。</div>
                <div class="page_footer">10</div>
            </div>
        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts'
export default {
    props: {
        reportData: {
            typeof: Object,
            default: {}
        }
    },
    data() {
        return {
            eles: [],
            tableData1: [],
            drivingMileageData1: [],
            eachRegionData1: [],
            eachRegionData1: [],
            fatigueDrivingData1: [],
            threeUrgentAndOneSuperData1: [],
            drivingRatingData1: [],
            drivingMileageLine1Chart: null,
            accumulatePie1Chart: null,
            singlePie1Chart: null,
            eachRegionCategory1Chart: null,
            accumulatedDurationPie1Chart: null,
            maximumDurationPie1Chart: null,
            fatigueDrivingLine1Chart: null,
            fatigueDrivingTimePie1Chart: null,
            fatigueDrivingSumPie1Chart: null,
            maximumFatigueDrivingPie1Chart: null,
            threeUrgentAndOneSuper1Chart: null,
            drivingRatingColumnar1Chart: null,
            yue: '',
            mileageData1: [],
            sumFlag: true,
            deviceExtractData: [],
            deviceExtractObj: {
                textArr: []
            },
            drivingVehicleData: {
                textArr: []
            },
            drivingIndexSortData: {
                textArr: []
            },
            datasTitle1: '',
            datasTitle2: ''
        }
    },
    mounted() {
        // 每屏高度要控制在1631
        // console.log(document.querySelectorAll('.pdf_page')[0].clientHeight)
        this.eles = document.querySelectorAll('.pdf_page')
    },
    methods: {
        clickSum(flag) {
            this.sumFlag = flag
        },
        clickEle() {
            this.eles = document.querySelectorAll('.pdf_page')
        },
        renderedData(data) {
            this.yue = String(this.reportData.searchForm.reportCycle).slice(5, this.reportData.searchForm.reportCycle.length)
            this.yue = Number(this.yue) - 1 < 10 ? '0' + (Number(this.yue) - 1) : Number(this.yue) - 1

            this.$nextTick(() => {
                // 总览
                // 设备拔出率排名 上月拔出率 上上月拔出率
                let tmpDateArr = this.reportData.searchForm.reportCycle.split('-')
                this.datasTitle1 = Number(tmpDateArr[1]) - 1
                this.datasTitle2 = Number(tmpDateArr[1]) - 2

                // 设备拔出率排名
                this.deviceExtractData = data.pulloutRatioSortList
                this.deviceExtractObj.textArr = data.pulloutRatioSortText
                // 危险驾驶车辆跟踪 折柱图
                this.setBadDrivingVehicleChat(data.badDrivingVehicleReportList)
                this.drivingVehicleData.textArr = data.badDrivingVehicleReportText
                // 疲劳驾驶指数排名 柱状图
                this.setFatiguedDrivingIndexSorChat(data.fatiguedDrivingIndexSortList)
                this.drivingIndexSortData.textArr = data.fatiguedDrivingIndexSortText

                this.tableData1 = data.deviceRunSituationList
                // 各地区车辆行驶里程 柱状图
                this.setdrivingMileageLine1(data.vehicleRunMileageSituations)
                // 各地区车辆行驶里程 表格
                this.drivingMileageData1 = data.vehicleRunMileageSituations
                // 里程>10000表格
                this.mileageData1 = data.vehicleMileageAnalysis.vehicleMileageCount
                // 各地区车辆行驶时长 表格
                this.eachRegionData1 = data.vehicleRunDurationSituations
                // 车辆累计里程分布 饼图
                this.setaccumulatePie1(data.vehicleMileageAnalysis.mileageSumDistribution)
                // 车辆单次行程最大里程分布 饼图
                this.setsinglePie1(data.vehicleMileageAnalysis.maxMileageDistribution)
                // 各地区车辆行驶时长 柱状图
                this.seteachRegionCategory1(data.vehicleRunDurationSituations)
                // 各地区车辆行驶时长 表格
                this.eachRegionData1 = data.vehicleRunDurationSituations
                // 车辆累计时长分布 饼图
                this.setaccumulatedDurationPie1(data.vehicleDurationAnalysis.durationSumDistribution)
                // 车辆单次行程最大时长分布 饼图
                this.setmaximumDurationPie1(data.vehicleDurationAnalysis.maxDurationDistribution)
                // 各地区疲劳驾驶情况  折/柱图
                this.setfatigueDrivingLine1(data.reportList)
                // 各地区疲劳驾驶情况 表格
                this.fatigueDrivingData1 = data.reportList
                // 各地区疲劳驾驶情况 - 疲劳驾驶时长
                this.setfatigueDrivingTimePie1(data.drivingBehaviorFatigued.drivingBehaviorDurationDistribution)
                // 各地区疲劳驾驶情况 - 疲劳驾驶次数
                this.setfatigueDrivingSumPie1(data.drivingBehaviorFatigued.drivingBehaviorCountDistribution)
                // 车辆单次行程最大疲劳驾驶时长分布 饼图
                this.setmaximumFatigueDrivingPie1(data.drivingBehaviorFatigued.drivingBehaviorMaxDurationDistribution)
                // 三急一超情况 柱状图
                this.setthreeUrgentAndOneSuper1(data.drivingBehaviorOverSpeed)
                // 三急一超情况 表格
                this.threeUrgentAndOneSuperData1 = data.drivingBehaviorOverSpeed
                // 车辆驾驶评分情况 表格
                this.drivingRatingData1 = data.drivingBehaviorDriveScore
                // 车辆驾驶评分分布 柱状图
                this.setdrivingRatingColumnar1(data.drivingBehaviorDriveScore)
            })
        },
        // 各地区车辆行驶里程 柱状图
        setdrivingMileageLine1(data) {
            let cityNameArr = []
            let mileageAvgDayArr = []
            if (data && data.length > 0) {
                this.drivingMileageLine1Chart && this.drivingMileageLine1Chart.dispose()
                data.forEach((item) => {
                    if (item.cityName != '总数') {
                        cityNameArr.push(item.cityName)
                        mileageAvgDayArr.push(item.mileageAvgDay)
                    }
                })
                let option = {
                    title: {
                        text: '各地区日均里程情况(km)',
                        left: 'center'
                    },
                    xAxis: {
                        type: 'category',
                        data: cityNameArr
                    },
                    yAxis: {
                        type: 'value',
                        // 去掉网格线
                        splitLine: {
                            show: false
                        }
                    },
                    series: [
                        {
                            data: mileageAvgDayArr,
                            type: 'bar'
                            //显示数值
                            // itemStyle: {
                            //     normal: {
                            //         label: {
                            //             show: true, //开启显示
                            //             position: 'top', //在上方显示
                            //             textStyle: {
                            //                 //数值样式
                            //                 color: 'black',
                            //                 fontSize: 12
                            //             }
                            //         }
                            //     }
                            // }
                        }
                    ]
                }

                let tmpDom = document.getElementById('drivingMileageLine1')
                if (tmpDom) {
                    this.drivingMileageLine1Chart = echarts.init(tmpDom)
                    this.drivingMileageLine1Chart.setOption(option)
                }
            }
        },
        // 车辆累计里程分布 饼图
        setaccumulatePie1(data) {
            let seriesData = []

            if (data && data.length > 0) {
                this.accumulatePie1Chart && this.accumulatePie1Chart.dispose()
                data.forEach((item) => {
                    seriesData.push({
                        value: item.itemCount,
                        name: item.itemName
                    })
                })
                let option = {
                    title: {
                        text: '车辆累计里程分布',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        orient: 'vertical',
                        left: 'left'
                    },
                    series: [
                        {
                            type: 'pie',
                            radius: '50%',
                            data: seriesData,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            },
                            label: {
                                normal: {
                                    formatter: '{c}辆\n{d}%',
                                    textStyle: {
                                        // 提示文字的样式
                                        color: '#595959',
                                        fontSize: 14
                                    }
                                }
                            }
                        }
                    ]
                }

                let tmpDom = document.getElementById('accumulatePie1')
                if (tmpDom) {
                    this.accumulatePie1Chart = echarts.init(tmpDom)
                    this.accumulatePie1Chart.setOption(option)
                }
            }
        },
        // 车辆单次行程最大里程分布 饼图
        setsinglePie1(data) {
            let seriesData = []

            if (data && data.length > 0) {
                this.singlePie1Chart && this.singlePie1Chart.dispose()
                data.forEach((item) => {
                    seriesData.push({
                        value: item.itemCount,
                        name: item.itemName
                    })
                })
                let option = {
                    title: {
                        text: '车辆单次行程最大里程分布',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        orient: 'vertical',
                        left: 'left'
                    },
                    series: [
                        {
                            type: 'pie',
                            radius: '50%',
                            data: seriesData,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            },
                            label: {
                                normal: {
                                    formatter: '{c}辆\n{d}%',
                                    textStyle: {
                                        // 提示文字的样式
                                        color: '#595959',
                                        fontSize: 14
                                    }
                                }
                            }
                        }
                    ]
                }
                let tmpDom = document.getElementById('singlePie1')
                if (tmpDom) {
                    this.singlePie1Chart = echarts.init(tmpDom)
                    this.singlePie1Chart.setOption(option)
                }
            }
        },
        // 各地区车辆行驶里程 柱状图
        seteachRegionCategory1(data) {
            let cityNameArr = []
            let mileageAvgDayArr = []
            if (data && data.length > 0) {
                this.eachRegionCategory1Chart && this.eachRegionCategory1Chart.dispose()
                data.forEach((item) => {
                    if (item.cityName != '总数') {
                        cityNameArr.push(item.cityName)
                        mileageAvgDayArr.push(item.drivingDurationAvgDay)
                    }
                })
                let option = {
                    title: {
                        text: '各地区日均时长情况(h)',
                        left: 'center'
                    },
                    xAxis: {
                        type: 'category',
                        data: cityNameArr
                    },
                    yAxis: {
                        type: 'value',
                        // 去掉网格线
                        splitLine: {
                            show: false
                        }
                    },
                    series: [
                        {
                            data: mileageAvgDayArr,
                            type: 'bar'
                            //显示数值
                            // itemStyle: {
                            //     normal: {
                            //         label: {
                            //             show: true, //开启显示
                            //             position: 'top', //在上方显示
                            //             textStyle: {
                            //                 //数值样式
                            //                 color: 'black',
                            //                 fontSize: 12
                            //             }
                            //         }
                            //     }
                            // }
                        }
                    ]
                }
                let tmpDom = document.getElementById('eachRegionCategory1')
                if (tmpDom) {
                    this.eachRegionCategory1Chart = echarts.init(tmpDom)
                    this.eachRegionCategory1Chart.setOption(option)
                }
            }
        },
        // 车辆累计时长分布 饼图
        setaccumulatedDurationPie1(data) {
            let seriesData = []

            if (data && data.length > 0) {
                this.accumulatedDurationPie1Chart && this.accumulatedDurationPie1Chart.dispose()
                data.forEach((item) => {
                    seriesData.push({
                        value: item.itemCount ? item.itemCount : 0,
                        name: item.itemName
                    })
                })
                let option = {
                    title: {
                        text: '车辆累计时长分布',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        orient: 'vertical',
                        left: 'left'
                    },
                    series: [
                        {
                            type: 'pie',
                            radius: '50%',
                            data: seriesData,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            },
                            label: {
                                normal: {
                                    formatter: '{c}辆\n{d}%',
                                    textStyle: {
                                        // 提示文字的样式
                                        color: '#595959',
                                        fontSize: 14
                                    }
                                }
                            }
                        }
                    ]
                }
                let tmpDom = document.getElementById('accumulatedDurationPie1')
                if (tmpDom) {
                    this.accumulatedDurationPie1Chart = echarts.init(tmpDom)
                    this.accumulatedDurationPie1Chart.setOption(option)
                }
            }
        },
        // 车辆单次行程最大时长分布 饼图
        setmaximumDurationPie1(data) {
            let seriesData = []

            if (data && data.length > 0) {
                this.maximumDurationPie1Chart && this.maximumDurationPie1Chart.dispose()
                data.forEach((item) => {
                    seriesData.push({
                        value: item.itemCount ? item.itemCount : 0,
                        name: item.itemName
                    })
                })
                let option = {
                    title: {
                        text: '车辆单次行程最大时长分布',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        orient: 'vertical',
                        left: 'left'
                    },
                    series: [
                        {
                            type: 'pie',
                            radius: '50%',
                            data: seriesData,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            },
                            label: {
                                normal: {
                                    formatter: '{c}辆\n{d}%',
                                    textStyle: {
                                        // 提示文字的样式
                                        color: '#595959',
                                        fontSize: 14
                                    }
                                }
                            }
                        }
                    ]
                }
                let tmpDom = document.getElementById('maximumDurationPie1')
                if (tmpDom) {
                    this.maximumDurationPie1Chart = echarts.init(tmpDom)
                    this.maximumDurationPie1Chart.setOption(option)
                }
            }
        },
        // 各地区疲劳驾驶情况  折/柱图
        setfatigueDrivingLine1(data) {
            let cityNameArr = []
            let dataArr1 = []
            let dataArr2 = []

            if (data && data.length > 0) {
                this.fatigueDrivingLine1Chart && this.fatigueDrivingLine1Chart.dispose()
                data.forEach((item) => {
                    if (item.cityName != '总数') {
                        cityNameArr.push(item.cityName)
                        // 日均疲劳驾驶时长
                        dataArr1.push(item.fatiguedDrivingDurationAvgDay)
                        // 日均疲劳驾驶次数
                        dataArr2.push(item.fatiguedDrivingNumAvgDay)
                    }
                })

                let option = {
                    title: {
                        text: '各地区疲劳驾驶情况',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999'
                            }
                        }
                    },
                    legend: {
                        data: ['日均疲劳驾驶时长', '日均疲劳驾驶次数'],
                        top: '10%'
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: cityNameArr,
                            axisPointer: {
                                type: 'shadow'
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            name: '日均疲劳驾驶时长',
                            // min: 0,
                            // max: 250,
                            // interval: 50,
                            // 去掉网格线
                            splitLine: {
                                show: false
                            },
                            axisLabel: {
                                formatter: '{value} h'
                            }
                        },
                        {
                            type: 'value',
                            name: '日均疲劳驾驶次数',
                            // min: 0,
                            // max: 25,
                            // interval: 5,
                            // 去掉网格线
                            splitLine: {
                                show: false
                            },
                            axisLabel: {
                                formatter: '{value} 次'
                            }
                        }
                    ],
                    series: [
                        {
                            name: '日均疲劳驾驶时长',
                            type: 'bar',
                            //显示数值
                            // itemStyle: {
                            //     normal: {
                            //         label: {
                            //             show: true, //开启显示
                            //             position: 'top', //在上方显示
                            //             textStyle: {
                            //                 //数值样式
                            //                 color: 'black',
                            //                 fontSize: 12
                            //             }
                            //         }
                            //     }
                            // },
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + ' h'
                                }
                            },
                            data: dataArr1
                        },
                        {
                            name: '日均疲劳驾驶次数',
                            type: 'line',
                            //显示数值
                            // itemStyle: {
                            //     normal: {
                            //         label: {
                            //             show: true, //开启显示
                            //             position: 'top', //在上方显示
                            //             textStyle: {
                            //                 //数值样式
                            //                 color: 'black',
                            //                 fontSize: 12
                            //             }
                            //         }
                            //     }
                            // },
                            yAxisIndex: 1,
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + ' 次'
                                }
                            },
                            data: dataArr2
                        }
                    ]
                }
                let tmpDom = document.getElementById('fatigueDrivingLine1')
                if (tmpDom) {
                    this.fatigueDrivingLine1Chart = echarts.init(tmpDom)
                    this.fatigueDrivingLine1Chart.setOption(option)
                }
            }
        },
        // 各地区疲劳驾驶情况 - 疲劳驾驶时长
        setfatigueDrivingTimePie1(data) {
            let seriesData = []

            if (data && data.length > 0) {
                this.fatigueDrivingTimePie1Chart && this.fatigueDrivingTimePie1Chart.dispose()
                data.forEach((item) => {
                    seriesData.push({
                        value: item.itemCount ? item.itemCount : 0,
                        name: item.itemName
                    })
                })
                let option = {
                    title: {
                        text: '车辆日均疲劳驾驶时长分布',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        orient: 'vertical',
                        left: 'left'
                    },
                    series: [
                        {
                            type: 'pie',
                            radius: '50%',
                            data: seriesData,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            },
                            label: {
                                normal: {
                                    formatter: '{c}辆\n{d}%',
                                    textStyle: {
                                        // 提示文字的样式
                                        color: '#595959',
                                        fontSize: 14
                                    }
                                }
                            }
                        }
                    ]
                }
                let tmpDom = document.getElementById('fatigueDrivingTimePie1')
                if (tmpDom) {
                    this.fatigueDrivingTimePie1Chart = echarts.init(tmpDom)
                    this.fatigueDrivingTimePie1Chart.setOption(option)
                }
            }
        },
        // 各地区疲劳驾驶情况 - 疲劳驾驶次数
        setfatigueDrivingSumPie1(data) {
            let seriesData = []

            if (data && data.length > 0) {
                this.fatigueDrivingSumPie1Chart && this.fatigueDrivingSumPie1Chart.dispose()
                data.forEach((item) => {
                    seriesData.push({
                        value: item.itemCount ? item.itemCount : 0,
                        name: item.itemName
                    })
                })
                let option = {
                    title: {
                        text: '车辆日均疲劳驾驶次数分布',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        orient: 'vertical',
                        left: 'left'
                    },
                    series: [
                        {
                            type: 'pie',
                            radius: '50%',
                            data: seriesData,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            },
                            label: {
                                normal: {
                                    formatter: '{c}辆\n{d}%',
                                    textStyle: {
                                        // 提示文字的样式
                                        color: '#595959',
                                        fontSize: 14
                                    }
                                }
                            }
                        }
                    ]
                }
                let tmpDom = document.getElementById('fatigueDrivingSumPie1')
                if (tmpDom) {
                    this.fatigueDrivingSumPie1Chart = echarts.init(tmpDom)
                    this.fatigueDrivingSumPie1Chart.setOption(option)
                }
            }
        },
        // 车辆单次行程最大疲劳驾驶时长分布 饼图
        setmaximumFatigueDrivingPie1(data) {
            let seriesData = []

            if (data && data.length > 0) {
                this.maximumFatigueDrivingPie1Chart && this.maximumFatigueDrivingPie1Chart.dispose()
                data.forEach((item) => {
                    seriesData.push({
                        value: item.itemCount ? item.itemCount : 0,
                        name: item.itemName
                    })
                })

                let option = {
                    title: {
                        text: '车辆单次行程最大疲劳驾驶时长',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        orient: 'vertical',
                        left: 'left'
                    },
                    series: [
                        {
                            type: 'pie',
                            radius: '50%',
                            data: seriesData,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            },
                            label: {
                                normal: {
                                    formatter: '{c}辆\n{d}%',
                                    textStyle: {
                                        // 提示文字的样式
                                        color: '#595959',
                                        fontSize: 14
                                    }
                                }
                            }
                        }
                    ]
                }
                let tmpDom = document.getElementById('maximumFatigueDrivingPie1')
                if (tmpDom) {
                    this.maximumFatigueDrivingPie1Chart = echarts.init(tmpDom)
                    this.maximumFatigueDrivingPie1Chart.setOption(option)
                }
            }
        },
        // 三急一超情况 柱状图
        setthreeUrgentAndOneSuper1(data) {
            let cityNameArr = []
            let sharpUpNum100km = []
            let sharpDownNum100km = []
            let sharpTurnNum100km = []
            let overSpeedNum100km = []

            if (data && data.length > 0) {
                this.threeUrgentAndOneSuper1Chart && this.threeUrgentAndOneSuper1Chart.dispose()
                data.forEach((item) => {
                    if (item.cityName != '总数') {
                        cityNameArr.push(item.cityName)
                        sharpUpNum100km.push(item.sharpUpNum100km)
                        sharpDownNum100km.push(item.sharpDownNum100km)
                        sharpTurnNum100km.push(item.sharpTurnNum100km)
                        overSpeedNum100km.push(item.overSpeedNum100km)
                    }
                })

                let option = {
                    title: {
                        text: '各地区百公里三急一超次数',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    legend: {
                        top: '10%'
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            axisLabel: { interval: 0, rotate: 40 },
                            data: cityNameArr
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            // 去掉网格线
                            splitLine: {
                                show: false
                            }
                        }
                    ],
                    series: [
                        {
                            name: '百公里急加速',
                            type: 'bar',
                            stack: 'Search Engine',
                            emphasis: {
                                focus: 'series'
                            },
                            barWidth: '50%',
                            data: sharpUpNum100km
                        },
                        {
                            name: '百公里急减速',
                            type: 'bar',
                            stack: 'Search Engine',
                            emphasis: {
                                focus: 'series'
                            },
                            data: sharpDownNum100km
                        },
                        {
                            name: '百公里急转弯',
                            type: 'bar',
                            stack: 'Search Engine',
                            emphasis: {
                                focus: 'series'
                            },
                            data: sharpTurnNum100km
                        },
                        {
                            name: '百公里超速',
                            type: 'bar',
                            stack: 'Search Engine',
                            emphasis: {
                                focus: 'series'
                            },
                            data: overSpeedNum100km
                        }
                    ]
                }
                let tmpDom = document.getElementById('threeUrgentAndOneSuper1')
                if (tmpDom) {
                    this.threeUrgentAndOneSuper1Chart = echarts.init(tmpDom)
                    this.threeUrgentAndOneSuper1Chart.setOption(option)
                }
            }
        },
        // 车辆驾驶评分分布 柱状图
        setdrivingRatingColumnar1(data) {
            let cityNameArr = []
            let vehNumDrivingScore50To85 = []
            let vehNumDrivingScore85To95 = []
            let vehNumDrivingScore95To100 = []

            if (data && data.length > 0) {
                this.drivingRatingColumnar1Chart && this.drivingRatingColumnar1Chart.dispose()
                data.forEach((item) => {
                    if (item.cityName != '总数') {
                        cityNameArr.push(item.cityName)
                        vehNumDrivingScore50To85.push(item.vehNumDrivingScore50To85)
                        vehNumDrivingScore85To95.push(item.vehNumDrivingScore85To95)
                        vehNumDrivingScore95To100.push(item.vehNumDrivingScore95To100)
                    }
                })

                let option = {
                    title: {
                        text: '驾驶评分分布图',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    legend: {
                        top: '10%'
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            axisLabel: { interval: 0, rotate: 40 },
                            data: cityNameArr
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            // 去掉网格线
                            splitLine: {
                                show: false
                            }
                        }
                    ],
                    series: [
                        {
                            name: '高风险(50-85)',
                            type: 'bar',
                            stack: 'Search Engine',
                            emphasis: {
                                focus: 'series'
                            },
                            barWidth: '50%',
                            data: vehNumDrivingScore50To85
                        },
                        {
                            name: '普通(85-95)',
                            type: 'bar',
                            stack: 'Search Engine',
                            emphasis: {
                                focus: 'series'
                            },
                            data: vehNumDrivingScore85To95
                        },
                        {
                            name: '优良(95-100)',
                            type: 'bar',
                            stack: 'Search Engine',
                            emphasis: {
                                focus: 'series'
                            },
                            data: vehNumDrivingScore95To100
                        }
                    ]
                }
                let tmpDom = document.getElementById('drivingRatingColumnar1')
                if (tmpDom) {
                    this.drivingRatingColumnar1Chart = echarts.init(tmpDom)
                    this.drivingRatingColumnar1Chart.setOption(option)
                }
            }
        },
        // 危险驾驶车辆跟踪 折柱图
        setBadDrivingVehicleChat(data) {
            let xAxisData = []
            let data1 = []
            let data2 = []
            let data3 = []
            let cityIdObj = {}
            if (data && data.length > 0) {
                data.forEach((item) => {
                    cityIdObj[item.cityName] = item.subTenantId
                    xAxisData.push(item.cityName)
                    data1.push(item.dangerousDrivingVehicleCount)
                    data2.push(item.accidentCount)
                    data3.push(item.accidentRatio)
                })
                let tmpData2 = JSON.parse(JSON.stringify(data2))
                let sortArr2 = tmpData2.sort((a, b) => a - b)
                let tmpData3 = JSON.parse(JSON.stringify(data3))
                let sortArr3 = tmpData3.sort((a, b) => a - b)

                let option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999'
                            }
                        }
                    },
                    legend: {
                        data: ['危险驾驶车辆', '其中近6月出险车辆', '半年出险率']
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: xAxisData,
                            triggerEvent: true,
                            axisLabel: {
                                //x轴文字的配置
                                show: true,
                                textStyle: {
                                    color: '#409EFF'
                                }
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            min: sortArr2[0],
                            max: sortArr2[sortArr2.length - 1]
                        },
                        {
                            type: 'value',
                            min: sortArr3[0],
                            max: sortArr3[sortArr3.length - 1],
                            splitLine: { show: false },
                            axisLabel: {
                                formatter: '{value} %'
                            }
                        }
                    ],
                    series: [
                        {
                            name: '危险驾驶车辆',
                            type: 'bar',
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value
                                }
                            },
                            data: data1
                        },
                        {
                            name: '其中近6月出险车辆',
                            type: 'bar',
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value
                                }
                            },
                            data: data2
                        },
                        {
                            name: '半年出险率',
                            type: 'line',
                            yAxisIndex: 1,
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value
                                }
                            },
                            data: data3
                        }
                    ]
                }

                let tmpDom = document.getElementById('badDrivingVehiclePdfChat')
                if (tmpDom) {
                    let _this = this
                    this.badDrivingVehicleChatChart = echarts.init(tmpDom)
                    this.badDrivingVehicleChatChart.setOption(option)
                    this.badDrivingVehicleChatChart.on('click', function (params) {
                        if (params.componentType == 'xAxis' && params.value != 'null') {
                            _this.chartDialogVisible = true
                            _this.chartDialogTitle = params.value + '危险驾驶且出险车辆明细'

                            _this.queryChartFromData.periodType = _this.searchForm.periodType
                            _this.queryChartFromData.reportCycle = _this.searchForm.reportCycle
                            _this.queryChartFromData.subTenantId = cityIdObj[params.value]
                            _this.queryDrivingVehicleData()
                        }
                    })
                }
            }
        },
        // 疲劳驾驶车辆跟踪 柱状图
        setFatiguedDrivingIndexSorChat(data) {
            let xAxisData = []
            let data1 = []
            let cityIdObj = {}
            if (data && data.length > 0) {
                data.forEach((item) => {
                    cityIdObj[item.region] = item.subTenantId
                    xAxisData.push(item.region)
                    data1.push(item.fatiguedDrivingIndex)
                })

                let option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999'
                            }
                        }
                    },
                    xAxis: {
                        type: 'category',
                        data: xAxisData,
                        triggerEvent: true,
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLabel: {
                            //x轴文字的配置
                            show: true,
                            textStyle: {
                                color: '#409EFF'
                            }
                        }
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            data: data1,
                            type: 'bar'
                        }
                    ]
                }

                let tmpDom = document.getElementById('fatiguedDrivingIndexSorPdfChat')
                if (tmpDom) {
                    let _this = this
                    this.drivingMileageLineChart = echarts.init(tmpDom)
                    this.drivingMileageLineChart.setOption(option)
                    this.drivingMileageLineChart.on('click', function (params, charts, event) {
                        if (params.componentType == 'xAxis' && params.value != 'null') {
                            _this.tiredDialogVisible = true
                            _this.tiredDialogTitle = params.value + '疲劳驾驶排名'

                            _this.queryTriedFromData.periodType = _this.searchForm.periodType
                            _this.queryTriedFromData.reportCycle = _this.searchForm.reportCycle
                            _this.queryTriedFromData.subTenantId = cityIdObj[params.value]
                            _this.queryTiredDrivingVehicleData()
                        }
                    })
                }
            }
        }
    },
    destroyed() {
        this.drivingMileageLine1Chart && this.drivingMileageLine1Chart.dispose()
        this.accumulatePie1Chart && this.accumulatePie1Chart.dispose()
        this.singlePie1Chart && this.singlePie1Chart.dispose()
        this.eachRegionCategory1Chart && this.eachRegionCategory1Chart.dispose()
        this.accumulatedDurationPie1Chart && this.accumulatedDurationPie1Chart.dispose()
        this.maximumDurationPie1Chart && this.maximumDurationPie1Chart.dispose()
        this.fatigueDrivingLine1Chart && this.fatigueDrivingLine1Chart.dispose()
        this.fatigueDrivingTimePie1Chart && this.fatigueDrivingTimePie1Chart.dispose()
        this.fatigueDrivingSumPie1Chart && this.fatigueDrivingSumPie1Chart.dispose()
        this.maximumFatigueDrivingPie1Chart && this.maximumFatigueDrivingPie1Chart.dispose()
        this.threeUrgentAndOneSuper1Chart && this.threeUrgentAndOneSuper1Chart.dispose()
        this.drivingRatingColumnar1Chart && this.drivingRatingColumnar1Chart.dispose()
    },
    watch: {
        reportData: {
            handler(data) {
                data.searchReportData && this.renderedData(data.searchReportData)
            },
            immediate: true
        }
    }
}
</script>
<style scoped>
/* 隐藏滚动条 */
::-webkit-scrollbar {
    display: none !important;
}
.preview_ontent {
    width: 10px;
    height: 10px;
    overflow: auto;
    position: relative;
}
.preview_zhe {
    position: absolute;
    width: 10px;
    height: 10px;
    background: #f2f8fc;
    z-index: 2;
}
.pdf_content {
    width: 1080px;
    font-family: arial, 'times new roman', '宋体', '微软雅黑';
}
.pdf_page {
    background: #fff;
    color: #000;
    height: 1631px;
    /* border-bottom: 1px solid red; */
    /* border-top: 1px solid blue; */
    overflow: hidden;
    position: relative;
}
.pdf_page img {
    position: absolute;
    width: 80%;
    opacity: 0.1;
    transform: rotate(-25deg);
    left: 8%;
    z-index: 9;
}
.pdf_page .imgTop {
    top: 22%;
}
.pdf_page .imgBottom {
    bottom: 20%;
}
.page_header {
    border-bottom: 1px solid #000;
    padding-bottom: 5px;
    margin-block: 20px;
    font-size: 18px;
    margin-top: 20px;
}
.page_title {
    text-align: center;
    font-weight: bold;
    margin-top: 30px;
    font-size: 40px;
}
.page_titles {
    margin-top: 15px;
    text-align: right !important;
    margin-bottom: 30px;
}
.txt_indent {
    text-indent: 40px;
}
.subheading {
    margin-top: 10px;
    font-size: 30px;
    font-weight: bold;
}
.subhead_txt {
    text-indent: 56px;
    margin-top: 20px;
    line-height: 40px;
    font-size: 23px;
}
.txt_title {
    font-weight: bold;
    font-size: 16px;
    margin-top: 15px;
}
.page_txt {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 30px;
    text-align: justify;
}
.page_footer {
    text-align: center;
    font-size: 18px;
    padding-bottom: 5px;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
}
.footer1 {
    margin-top: 60px;
}
.footer2 {
    margin-top: 40px;
}

.table_80 {
    width: 80% !important;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}
.report_table /deep/ .el-table__body .el-table__row:last-child {
    color: #333 !important;
    font-weight: bold;
}
.report_table /deep/ .el-table__body .el-table__row:last-child span {
    color: #333 !important;
}
.report_table /deep/ thead {
    color: #333;
}
.report_table /deep/ td {
    /* padding: 6px 0; */
}
.echarts_category {
    width: 100%;
    height: 250px;
}
.echarts_60 {
    width: 60% !important;
}
.echarts_center {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

/* 表格border */
.report_table /deep/ .el-table--border .el-table__cell,
.report_table /deep/ .el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
    border-right: 1px solid #000;
}
.report_table /deep/ .el-table td.el-table__cell,
.report_table /deep/ .el-table th.el-table__cell.is-leaf {
    border-bottom: 1px solid #000;
}
.report_table /deep/ .el-table__cell {
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
}
.report_table /deep/ th.el-table__cell.is-leaf,
.report_table /deep/ td.el-table__cell {
    border-bottom: 1px solid #000;
}

/* 最外层边框 */
.el-table td,
.el-table th.is-leaf,
.el-table--border,
.el-table--group {
    border-color: black;
}

.el-table--border::after,
.el-table--group::after,
.el-table::before {
    background-color: black;
}
.report_table {
    color: #000;
}
.report_table /deep/ th.el-table__cell {
    font-weight: bold;
    font-size: 18px;
    color: #000;
    background: #f5f7fa;
}
</style>