<template>
    <div class="content">
        <div class="page_title">
            <b>风险车辆筛查</b>
        </div>
        <div :class="activeName == 'first' ? 'content_box' : 'content_box overlength_content'">
            <div class="search_box public_box">
                <el-tabs v-model="activeName" @tab-click="handleClickTabs">
                    <el-tab-pane label="我的规则" name="first"></el-tab-pane>
                    <el-tab-pane label="自定义规则" name="second"></el-tab-pane>
                </el-tabs>
                <!--检索部分-->
                <el-form :inline="true" :model="navForm" class="demo-form-inline">
                    <div class="flex">
                        <div>
                            <el-form-item label="数据筛查时间：" class="dateBox">
                                <el-row style="width: 370px">
                                    <el-col :span="11">
                                        <el-date-picker placeholder="请选择日期" clearable v-model="navForm.startTime" type="datetime" value-format="yyyy-MM-dd hh:mm:ss" style="width: 100%"> </el-date-picker>
                                    </el-col>
                                    <el-col class="line" style="text-align: center" :span="2">至</el-col>
                                    <el-col :span="11">
                                        <el-date-picker placeholder="请选择日期" clearable v-model="navForm.endTime" type="datetime" value-format="yyyy-MM-dd hh:mm:ss" style="width: 100%"> </el-date-picker>
                                    </el-col>
                                </el-row>
                            </el-form-item>
                        </div>
                        <!-- <div v-if="activeName=='second'">
                          <el-form-item>
                            <el-button type="primary" plain icon="el-icon-plus" @click="addMyRule">添加为我的规则</el-button>
                          </el-form-item>
                        </div> -->
                    </div>

                    <template v-if="activeName === 'first'">
                        <el-form-item label="风险点组合名称：">
                            <el-select v-model="navForm.value" placeholder="请选择" style="width: 450px" clearable>
                                <el-option v-for="item in commonRiskOptions" :key="item.id" :label="item.filterName" :value="item.id"> </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="searchTable(1)" style="margin-left: 0.2rem">查询 </el-button>
                        </el-form-item>
                    </template>
                    <template v-else>
                        <div class="custom_filtering">
                            <div class="condition_box">
                                <ul>
                                    <li v-for="(items, index) in customFiltering">
                                        <div class="and_or" v-if="index !== 0">
                                            <b :class="{ active: customFiltering[index - 1].logicOperation === '且' }" @click="customFiltering[index - 1].logicOperation = '且'">且</b>
                                            <b :class="{ active: customFiltering[index - 1].logicOperation === '或' }" @click="customFiltering[index - 1].logicOperation = '或'">或</b>
                                            <span></span>
                                        </div>
                                        <div class="condition_item">
                                            <div class="condition_lebal">
                                                <span>风险筛选项：</span>
                                                <b @click="handleClose(items, index)"></b>
                                            </div>
                                            <div class="condition_form">
                                                <el-select v-model="items.riskCode" size="small" @change="riskItemChange(items)" style="font-size: 0.14rem" class="width_250" placeholder="请选择">
                                                    <el-option v-for="item in riskOptions" :key="item.code" :label="item.desc" :value="item.code"> </el-option>
                                                </el-select>
                                                <el-select v-model="items.riskConditionCode" size="small" @change="riskFilterChange(items)" class="width_250" placeholder="请选择">
                                                    <el-option v-for="item in riskFilterOptions" :key="item.code" :label="item.desc" :value="item.code"> </el-option>
                                                </el-select>
                                                <el-select v-model="items.judgeOption" size="small" class="width_170" placeholder="请选择">
                                                    <el-option v-for="item in operatorOptions" :key="item" :label="item" :value="item"> </el-option>
                                                </el-select>
                                                <!-- <el-form-item> -->
                                                <el-input v-model="items.value" size="small" type="number" class="width_170" placeholder="请输入"></el-input>
                                                <span>{{ items.unit }}</span>
                                                <!-- </el-form-item> -->
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="add_condition">
                                <div @click="addCondition"><img src="../../../public/images/msi/plus_icon.png" alt="" /></div>
                                <span @click="addCondition">添加条件</span>
                            </div>
                        </div>
                        <div style="margin-top: 0.2rem; text-align: right">
                            <el-button type="primary" @click="searchTable(2)" style="margin-bottom: 0.2rem">查询 </el-button>
                        </div>
                    </template>
                </el-form>
            </div>
            <div class="main">
                <div class="table_box public_box">
                    <!-- <div class="table_title">
                      <el-button @click="handleDownload" v-if="activeName=='first'&&tableData1.length>0" class="download" type="text"><i class="el-icon-download el-icon--left"></i>下载风险车辆表单</el-button>
                      <el-button @click="handleDownload" v-if="activeName=='second'&&tableData2.length>0" class="download" type="text"><i class="el-icon-download el-icon--left"></i>下载风险车辆表单</el-button>
                    </div> -->
                    <!--table表格部分-->
                    <el-table ref="multipleTable" :data="tableData" v-loading="loading" height="320" style="width: 100%">
                        <el-table-column width="180" prop="platNo" label="车牌号"></el-table-column>
                        <el-table-column width="180" prop="vin" label="车辆VIN码"></el-table-column>
                        <el-table-column width="180" prop="deviceNumber" label="绑定设备号"></el-table-column>
                        <el-table-column prop="risk" label="车辆风险"></el-table-column>
                        <!-- <el-table-column label="操作" >
                          <template slot-scope="scope">
                            <span class="a_color" @click="lookDetails(scope.row)">查看详情</span>
                          </template>
                        </el-table-column> -->
                    </el-table>
                    <!--分页部分-->
                    <el-pagination style="margin-top: 0.2rem" @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="currentPage" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="10" :total="total"> </el-pagination>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false" width="65%">
            <div class="dialog_box"></div>
        </el-dialog>
    </div>
</template>

<script>
// @ is an alias to /src
// import {getToken} from '../../../utils/auth.js'
export default {
    data() {
        return {
            activeName: 'first',
            loading: false,

            dialogVisible: false,

            riskOptions: [], //我的规则 风险点组合名称
            riskFilterOptions: [], //风险条件
            customFiltering: [
                //自定义筛选条件
            ],
            customFilter: {
                riskCode: undefined,
                riskConditionCode: undefined,
                value: undefined,
                logicOperation: '且',
                judgeOption: undefined,
                unit: undefined
            },
            operatorOptions: [],
            pageParam: {
                pageNo: 1,
                pageSize: 10,
                condition: {}
            },
            currentPage: 1, //当前页
            pageSize: 10, //一页多少条数据
            total: 1, //总页数tableData:[],
            tableData: [],
            navForm: {
                //储存搜索框的检索条件信息
                startTime: '',
                endTime: '',
                value: ''
            },
            commonRiskOptions: []
        }
    },

    mounted() {
        //
        this.getRiskOptionsEnum()
        this.getCommonRiskOptions()
        // this.getTbodyData();
    },
    methods: {
        getCommonRiskOptions: function () {
            this.instance
                .get('/ubi/api/risk/definition/fetch')
                .then((res) => {
                    if (res.data.code === 200) {
                        this.commonRiskOptions = res.data.data
                    } else {
                        this.$message.error(res.data.message)
                    }
                })
                .catch((res) => {
                    console.log(res)
                    this.$message.error('请求失败! 请稍后重试')
                })
        },

        getRiskOptionsEnum: function () {
            this.instance
                .get('/ubi/api/enum/getRiskOptionsEnum')
                .then((res) => {
                    let data = res.data
                    if (data.code === 200) {
                        this.riskOptions = data.data
                    } else {
                        this.$message.error(data.message)
                    }
                })
                .catch((res) => {
                    console.log(res)
                })
        },

        //风险特征change事件
        riskItemChange(items) {
            this.riskOptions.forEach((item) => {
                if (item.code === items.riskCode) {
                    this.riskFilterOptions = item.optionsList
                }
            })
        },

        riskFilterChange(items) {
            this.riskFilterOptions.forEach((item) => {
                if (item.code === items.riskConditionCode) {
                    this.operatorOptions = item.operator
                    items.unit = item.unit
                }
            })
        },

        //检测自定义筛选
        detectCustomFilter() {
            let customFiltering = this.customFiltering
            let isSubmit = true,
                hintMessage = ''
            let len = customFiltering.length
            if (len > 0) {
                let filterData = customFiltering[len - 1]
                if (!filterData.riskCode) {
                    isSubmit = false
                    hintMessage = '抱歉，请选择风险特征！'
                } else if (!filterData.riskConditionCode) {
                    isSubmit = false
                    hintMessage = '抱歉，请选择筛选维度！'
                } else if (!filterData.judgeOption) {
                    isSubmit = false
                    hintMessage = '抱歉，请选择判断选项！'
                } else if (!filterData.value) {
                    isSubmit = false
                    hintMessage = '抱歉，请输入筛选值！'
                }
            }
            if (!isSubmit) {
                this.$message.error(hintMessage)
            }
            return isSubmit
        },

        //添加条件
        addCondition() {
            if (this.customFiltering.length >= 18) {
                this.$message({
                    type: 'wraning',
                    message: '抱歉，最多只能添加18条风险筛选项！'
                })
            } else {
                //判断上一条有没有填完
                if (!this.detectCustomFilter()) {
                    return false
                }
                let obj = JSON.parse(JSON.stringify(this.customFilter))
                this.customFiltering.push(obj)
            }
        },

        //点击查询
        searchTable(sign) {
            if (this.navForm.startTime && this.navForm.endTime) {
                if (sign === 1 && !this.navForm.value) {
                    this.$message.error('请选择风险点组合名称之后再查询！')
                    return false
                }
                if (sign === 2) {
                    if (this.customFiltering.length === 0) {
                        this.$message.error('抱歉，请添加自定义筛选项！')
                        return false
                    }
                    if (!this.detectCustomFilter()) {
                        return false
                    }
                }
                this.getTbodyData()
            } else {
                this.$message.error('请选择数据筛查时间之后再查询！')
            }
        },

        //获取tbody里的数据
        getTbodyData: function () {
            this.loading = true
            this.tableData = [] //  清空table的值
            let json = JSON.parse(JSON.stringify(this.navForm))
            let param = this.pageParam
            param.condition.startTime = json.startTime
            param.condition.endTime = json.endTime
            param.pageNo = this.currentPage
            param.pageSize = this.pageSize
            if (this.activeName === 'first') {
                param.condition.id = this.navForm.value
            } else {
                param.condition.id = null
                param.condition.definitionDtoList = this.customFiltering
            }
            this.instance.post('/ubi/api/risk/definition', param).then((res) => {
                this.loading = false
                if (res.data.code === 200) {
                    this.tableData = res.data.data.list
                    this.total = parseInt(res.data.data.total)
                }
            })
        },
        //分页数据多少条change
        handleSizeChange(val) {
            this.pageSize = val
            this.getTbodyData()
        },
        //分页：当前页改变之后的函数
        handleCurrentChange: function (val) {
            // console.log(val);
            this.currentPage = Number(val)
            this.getTbodyData()
        },

        //点击自定义筛选的close图标
        handleClose(item, index) {
            this.customFiltering.splice(index, 1)
        },
        //点击查看详情
        lookDetails(row) {
            this.checkedRowDetail = row
            this.dialogVisible = true
        },
        handleClickTabs() {
            // if (this.activeName === 'first') {
            //     this.currentPageName = 'currentPage1';
            //     this.pageSizeName = 'pageSize1';
            //     this.tableDataName = 'tableData1';
            //     this.totalPageName = 'totalPage1';
            // } else {
            //     this.currentPageName = 'currentPage2';
            //     this.pageSizeName = 'pageSize2';
            //     this.tableDataName = 'tableData2';
            //     this.totalPageName = 'totalPage2';
            // }
            this.tableData = [] //  清空table的值
            this.customFiltering = []
            this.navForm = {
                //储存搜索框的检索条件信息
                startTime: '',
                endTime: '',
                value: ''
            }
        }
    }
}
</script>
<style scoped>
@import '../../assets/css/table_white.css';
.content {
    height: 100%;
}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
}

::v-deep input[type='‘number’'] {
    -moz-appearance: textfield !important;
}

.condition_form /deep/ .el-input__inner,
.condition_form /deep/ .el-select-dropdown__item {
    font-size: 0.14rem;
}

.condition_form {
    margin-top: 0.15rem;
}

.condition_form .el-form-item--mini.el-form-item,
.condition_form .el-form-item--small.el-form-item {
    margin-bottom: 0;
}

.condition_form .el-select,
.condition_form .el-input {
    margin-right: 0.2rem;
}

.condition_form /deep/ .el-form-item__label {
    padding: 0;
}

.condition_lebal {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.18rem;
}

.condition_lebal b {
    width: 0.2rem;
    height: 0.2rem;
    background: url(../../../public/images/msi/close_icon.png) no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
}

.condition_item {
    width: 97%;
    min-height: 1.2rem;
    background: #f2f8fc;
    border-radius: 0.04rem;
    padding: 0.2rem 0.3rem;
}

.condition_box li {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    min-height: 1.2rem;
    margin-bottom: 0.3rem;
}

.condition_box li:nth-child(2) .condition_item {
    width: 96%;
}

.condition_box li:nth-child(3) .and_or {
    left: 1.5%;
}

.condition_box li:nth-child(3) .condition_item {
    width: 95%;
}

.condition_box li:nth-child(4) .and_or {
    left: 2.5%;
}

.condition_box li:nth-child(4) .condition_item {
    width: 94%;
}

.condition_box li:nth-child(5) .and_or {
    left: 3.5%;
}

.condition_box li:nth-child(5) .condition_item {
    width: 93%;
}

.condition_box li:nth-child(6) .and_or {
    left: 4.5%;
}

.condition_box li:nth-child(6) .condition_item {
    width: 92%;
}

.condition_box li:nth-child(7) .and_or {
    left: 5.5%;
}

.condition_box li:nth-child(7) .condition_item {
    width: 91%;
}

.condition_box li:nth-child(8) .and_or {
    left: 6.5%;
}

.condition_box li:nth-child(8) .condition_item {
    width: 90%;
}

.condition_box li:nth-child(9) .and_or {
    left: 7.5%;
}

.condition_box li:nth-child(9) .condition_item {
    width: 89%;
}

.condition_box li:nth-child(10) .and_or {
    left: 8.5%;
}

.condition_box li:nth-child(10) .condition_item {
    width: 88%;
}

.condition_box li:nth-child(11) .and_or {
    left: 9.5%;
}

.condition_box li:nth-child(11) .condition_item {
    width: 87%;
}

.condition_box li:nth-child(12) .and_or {
    left: 10.5%;
}

.condition_box li:nth-child(12) .condition_item {
    width: 86%;
}

.condition_box li:nth-child(13) .and_or {
    left: 11.5%;
}

.condition_box li:nth-child(13) .condition_item {
    width: 85%;
}

.condition_box li:nth-child(14) .and_or {
    left: 12.5%;
}

.condition_box li:nth-child(14) .condition_item {
    width: 84%;
}

.condition_box li:nth-child(15) .and_or {
    left: 13.5%;
}

.condition_box li:nth-child(15) .condition_item {
    width: 83%;
}

.condition_box li:nth-child(16) .and_or {
    left: 14.5%;
}

.condition_box li:nth-child(16) .condition_item {
    width: 82%;
}

.condition_box li:nth-child(17) .and_or {
    left: 15.5%;
}

.condition_box li:nth-child(17) .condition_item {
    width: 81%;
}

.condition_box li:nth-child(18) .and_or {
    left: 16.5%;
}

.condition_box li:nth-child(18) .condition_item {
    width: 80%;
}

.and_or {
    position: absolute;
    bottom: 0.4rem;
    left: 0;
    z-index: 20;
    width: 0.26rem;
    height: 1.7rem;
    padding-top: 0.5rem;
}

.and_or span {
    width: 1px;
    height: 1.7rem;
    background-color: #c1e0ff;
    position: absolute;
    top: 0;
    left: 0.12rem;
    z-index: -1;
}

.and_or b {
    display: inline-block;
    background: #ffffff;
    border: 1px solid #1989fa;
    font-size: 0.15rem;
    width: 0.26rem;
    height: 0.26rem;
    line-height: 0.24rem;
    text-align: center;
    color: #1989fa;
    margin-bottom: 0.1rem;
    cursor: pointer;
}

.and_or .active {
    color: #fff;
    background: #1989fa;
}

.custom_filtering {
    padding: 0 0 0.3rem;
    border-bottom: 1px solid #ebeef5;
}

.add_condition {
    display: flex;
    align-items: center;
    font-size: 0.18rem;
}

.add_condition div {
    width: 0.24rem;
    margin-right: 0.1rem;
    cursor: pointer;
}

.add_condition span {
    cursor: pointer;
}

.adjustment_detail {
    width: 100%;
    display: flex;
}

.dialog_col li,
.adjustment_detail > div {
    font-size: 0.18rem;
    color: #606266;
    margin-top: 0.2rem;
}

.adjustment_detail li span:first-child {
    display: inline-block;
    min-width: 3rem;
}

.dialog_row {
    margin-top: 0.4rem;
    display: flex;
}

.dialog_row > div {
    width: 50%;
}

.dialog_header {
    display: flex;
    height: 0.68rem;
    border-bottom: 1px solid #ebeef5;
    font-size: 0.24rem;
    font-weight: 600;
}

.dialog_header div:first-child {
    margin-right: 1.2rem;
}

/deep/ .el-dialog__header {
    padding: 0;
}

/deep/ .el-dialog__body {
    padding: 0.5rem;
    color: #333;
}

.table_title {
    display: flex;
    margin-bottom: 0.3rem;
    align-items: center;
}

.table_title b {
    font-size: 0.24rem;
    font-weight: 600;
}

.hint_text {
    color: #909399;
    font-size: 0.16rem;
}

/deep/ .el-tabs__item {
    height: 0.4rem;
    font-size: 0.22rem;
    line-height: 1;
}

/deep/ .el-tabs__header {
    margin-bottom: 0.38rem;
}

.warning_icon {
    width: 0.14rem;
    margin-right: 0.18rem;
}

.download {
    margin-left: 0.5rem;
    font-size: 0.18rem;
}
</style>
