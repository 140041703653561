<template>
    <div class="dialog_section dialog_height">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClickTabs">
            <el-tab-pane v-for="(item, index) in tabPane" :key="index" :label="item.label" :name="item.name"></el-tab-pane>
        </el-tabs>
        <div class="table_box">
            <div class="round_box" :class="{ under: !isUnder }">
                <div class="round_top">
                    <div class="round_text">
                        <b>高 </b>
                        <span>{{ detailData.highN }}辆，占比{{ detailData.highPerc }}%</span>
                    </div>
                </div>
                <div class="round_middle">
                    <p>平均值：{{ detailData.avgVal }}</p>
                </div>
                <div class="round_bottom">
                    <div class="round_text">
                        <b>低 </b>
                        <span>{{ detailData.lowN }}辆，占比{{ detailData.lowPerc }}%</span>
                    </div>
                </div>
            </div>
            <div class="table">
                <!--table表格部分 -->
                <el-table ref="multipleTable" :data="tableData" height="480" border v-loading="loading">
                    <el-table-column label="序号" type="index" width="50"></el-table-column>
                    <el-table-column prop="deviceNumber" label="设备号"></el-table-column>
                    <el-table-column prop="platNo" label="车牌号"></el-table-column>
                    <el-table-column prop="value" :label="labelName"></el-table-column>
                    <el-table-column v-if="riskLevelVisible" prop="riskLevel" label="风险级别">
                        <template slot-scope="scope">
                            <span>{{ scope.row.riskLevel || '--' }}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <!--分页部分-->
                <el-pagination style="margin-top: 0.2rem" @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="currentPage" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="10" :total="totalPage"> </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VehicleDetail',
    props: {
        dialogVisible: Boolean,
        detailForm: Object
    },
    data() {
        return {
            // dialogVisible:true,
            detailTitle: '车辆行驶里程分布',
            tabPane: [],
            isUnder: false, //是否低于平均值
            activeName: '1',
            riskLevelVisible: false,
            detailData: {}, //所有的车辆详情数据
            currentPage: 1, //当前页
            pageSize: 10, //一页多少条数据
            totalPage: 1, //总页数tableData:[],
            tableData: [],
            labelName: '总行驶里程(km)',
            loading: true,
            tabPaneObj: {
                1: [
                    {
                        label: '总行驶里程',
                        name: '1'
                    },
                    {
                        label: '日均行驶里程',
                        name: '2'
                    }
                ],
                3: [
                    {
                        label: '总行驶时长',
                        name: '3'
                    },
                    {
                        label: '日均行驶时长',
                        name: '4'
                    }
                ],
                5: [
                    {
                        label: '总行程数量',
                        name: '5'
                    },
                    {
                        label: '日均行程数量',
                        name: '6'
                    }
                ],
                7: [
                    {
                        label: '月均出行天数',
                        name: '7'
                    }
                ],
                8: [
                    {
                        label: '平均速度',
                        name: '8'
                    },
                    {
                        label: '最高速度',
                        name: '9'
                    }
                ],
                10: [
                    {
                        label: '百公里三急一超数量',
                        name: '10'
                    }
                ]
            },
            activeNameObj: {
                1: {
                    jogglePath: 'totalMile',
                    labelName: '总行驶里程(km)'
                },
                2: {
                    jogglePath: 'avgMile',
                    labelName: '日均行驶里程(km)'
                },
                3: {
                    jogglePath: 'totalDuration',
                    labelName: '总行驶时长(h)'
                },
                4: {
                    jogglePath: 'avgDuration',
                    labelName: '日均行驶时长(h)'
                },
                5: {
                    jogglePath: 'totalTripNum',
                    labelName: '总行程数量(条)'
                },
                6: {
                    jogglePath: 'avgTripNum',
                    labelName: '日均行程数量(条)'
                },
                7: {
                    jogglePath: 'drivingDay',
                    labelName: '月均出行天数(天)'
                },
                8: {
                    jogglePath: 'avgSpeed',
                    labelName: '平均速度(km/h)'
                },
                9: {
                    jogglePath: 'maxSpeed',
                    labelName: '最高速度(km/h)'
                },
                10: {
                    jogglePath: 'alarmNum100KM',
                    labelName: '百公里三急一超数量'
                }
            }
        }
    },
    watch: {
        // detailForm() {
        //     this.loadTabPane()
        //     this.handleClickTabs()
        // },
        activeName(val) {
            if (val == 4 || val == 8 || val == 9 || val == 10) {
                this.riskLevelVisible = true
            } else {
                this.riskLevelVisible = false
            }
        }
    },
    mounted() {
        this.loadTabPane()
        this.handleClickTabs()
    },
    methods: {
        loadTabPane() {
            this.tabPane = this.tabPaneObj[this.detailForm.label]
            this.activeName = this.tabPane[0].name
        },
        handleClickTabs() {
            this.currentPage = 1
            this.pageSize = 10
            this.totalPage = 0
            this.getTbodyData()
        },
        beforeClose(done) {
            this.$emit('beforeClose')
        },
        //获取tbody里的数据
        getTbodyData() {
            this.loading = true
            this.detailData = {}
            this.tableData = []
            let jogglePath = 'avgMile'
            let joggleData = {
                condition: {
                    search: this.detailForm.search,
                    companyId: this.detailForm.companyId,
                    fleetId: this.detailForm.fleetId,
                    tripTimeMin: this.detailForm.startTime + '-01 00:00:00',
                    tripTimeMax: this.detailForm.endTime + '-01 00:00:00'
                },
                pageNo: this.currentPage,
                pageSize: this.pageSize
            }

            // 根据tab切换接口路径和labelname
            jogglePath = this.activeNameObj[this.activeName].jogglePath
            this.labelName = this.activeNameObj[this.activeName].labelName

            this.instance
                .post('/ubi/api/vehicle/dashboard/' + jogglePath, joggleData)
                .then((res) => {
                    let data = res.data
                    this.loading = false
                    if (data.code == 200) {
                        data.data.avgVal = String(data.data.avgVal).indexOf('.') > -1 ? Number(data.data.avgVal).toFixed(2) : data.data.avgVal
                        data.data.highPerc = String(data.data.highPerc).indexOf('.') > -1 ? Number(data.data.highPerc).toFixed(2) : data.data.highPerc
                        data.data.lowPerc = String(data.data.lowPerc).indexOf('.') > -1 ? Number(data.data.lowPerc).toFixed(2) : data.data.lowPerc

                        this.detailData = data.data
                        if (this.detailData.lowN > this.detailData.highN) {
                            this.isUnder = true
                        } else {
                            this.isUnder = false
                        }
                        var tableData = data.data.pageData.list
                        this.totalPage = parseInt(data.data.pageData.total)
                        if (tableData.length > 0) {
                            tableData.forEach((val, key) => {
                                val.harshNum = val.highN <= 0 ? '行驶里程过短' : val.highN
                                val.value = String(val.value).indexOf('.') > -1 ? Number(val.value).toFixed(2) : val.value
                            })
                            this.tableData = tableData
                        }
                    }
                })
                .catch((err) => {
                    this.loading = false
                    console.log(err)
                    this.$message.error('接口请求异常，请稍后重试！')
                })
        },
        //分页数据多少条change
        handleSizeChange(val) {
            this.pageSize = val
            this.getTbodyData()
        },
        //分页：当前页改变之后的函数
        handleCurrentChange: function (val) {
            this.currentPage = Number(val)
            this.getTbodyData()
        }
    }
}
</script>
<style scoped>
@import '../assets/css/table_white.css';
.table_box {
    width: 100%;
    display: flex;
    padding-top: 0.1rem;
}
.round_box {
    width: 3.5rem;
    height: 3.5rem;
    border: 1px solid #00a8c5;
    border-radius: 50%;
    margin-right: 0.5rem;
    position: relative;
    overflow: hidden;
}
.table {
    width: 70%;
}
.round_middle {
    position: absolute;
    top: 0.8rem;
    left: 0;
    right: 0;
    z-index: 50;
    border-bottom: 1px solid #00a8c5;
    height: 0.5rem;
    font-size: 0.24rem;
    padding-top: 0.2rem;
    text-align: center;
}
.under .round_middle {
    top: 1.7rem;
}
.round_top,
.round_bottom {
    width: 100%;
    text-align: center;
    height: 1.2rem;
    line-height: 1.2rem;
    font-size: 0.2rem;
    color: #666;
}
.under .round_top {
    margin-top: 0.5rem;
}
.round_bottom {
    margin-top: 0.5rem;
}
.round_text b {
    font-size: 0.24rem;
    color: #e4393c;
}
.dialog_height {
    height: 600px !important;
}
</style>
