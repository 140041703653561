<template>
	<div v-if="dialogVisible">
		<div class="modal" @click="beforeClose"></div>
		<div class="modal_dialog">
			<div class="dialog_header">
				<!-- <div class="detail_title">用户信息管理/用户详情</div> -->
				<div></div>
				<i class="el-icon-close" @click="beforeClose"></i>
			</div>
			<div class="dialog_section">
				<div class="detail_title" style="margin-top: 0;">用户详情</div>
				<el-form :inline="true" :rules="rules" ref="detailForm" :model="detailForm" class="demo-form-inline" :class="{disabled_null_border:userDisabled}" label-width="110px" >
					<el-form-item label="用户标识:" class="width_3" prop="uid">
						<el-input v-model="detailForm.uid" placeholder="请输入用户标识" size="medium" :disabled="userDisabled" clearable></el-input>
					</el-form-item>
					<el-form-item label="姓名:" class="width_3" prop="userName">
						<el-input v-model="detailForm.userName" placeholder="请输入姓名" size="medium" :disabled="userDisabled" clearable></el-input>
					</el-form-item>
					<el-form-item label="联系电话:" class="width_3" prop="userPhone">
						<el-input v-model="detailForm.userPhone" type="tel" maxlength="11" :disabled="userDisabled" size="medium" placeholder="请输入手机号" clearable></el-input>
					</el-form-item>
					<el-form-item label="用户分类:" class="width_3" prop="userType">
						<el-select v-model="detailForm.userType" placeholder="请选择" :disabled="userDisabled"  size="medium">
						    <el-option label="普通用户" value="普通用户"></el-option>
						    <el-option label="公司司机" value="公司司机"></el-option>
						    <el-option label="查勘员" value="查勘员"></el-option>
						    <el-option label="员工用户" value="员工用户"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="获客来源:" class="width_3" prop="origin">
						<el-select v-model="detailForm.origin" :placeholder="userDisabled?' ':'请选择'" :disabled="userDisabled" size="medium">
						    <el-option label="网销" value="网销"></el-option>
						    <el-option label="车商" value="车商"></el-option>
						    <el-option label="综拓" value="综拓"></el-option>
						    <el-option label="银保" value="银保"></el-option>
						    <el-option label="重客" value="重客"></el-option>
						    <el-option label="经代" value="经代"></el-option>
						    <el-option label="其他" value="其他"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="性别:" class="width_3" prop="sex">
						<el-select v-model="detailForm.sex" :placeholder="userDisabled?' ':'请选择'" :disabled="userDisabled" size="medium">
						    <el-option label="男" value="男"></el-option>
						    <el-option label="女" value="女"></el-option>
						  </el-select>
					</el-form-item>
					<el-form-item label="出生日期:" class="width_3" prop="birthday">
						<el-date-picker
						 size="medium"
						  :disabled="userDisabled"
						        :placeholder="userDisabled?' ':'请选择'"
						        v-model="detailForm.birthday"
						        type="date"
						        value-format="yyyy-MM-dd"
						        style="width: 100%;">
						</el-date-picker>
					</el-form-item>
					<!-- <el-form-item label="年龄:" class="width_3">
						<el-input v-model="detailForm.name" disabled size="medium"></el-input>
					</el-form-item> -->
					<el-form-item label="最高学历:" class="width_3" prop="education">
						<el-select v-model="detailForm.education" :placeholder="userDisabled?' ':'请选择'"  :disabled="userDisabled" size="medium">
						    <el-option label="专科" value="专科"></el-option>
						    <el-option label="本科" value="本科"></el-option>
						    <el-option label="硕士研究生" value="硕士研究生"></el-option>
						    <el-option label="博士研究生" value="博士研究生"></el-option>
						    <el-option label="其他" value="其他"></el-option>
						  </el-select>
					</el-form-item>
					<el-form-item label="职业:" class="width_3" prop="profession">
						<el-select v-model="detailForm.profession" :placeholder="userDisabled?' ':'请选择'" size="medium" :disabled="userDisabled">
						    <el-option label="党、政、国家机关工作者" value="党、政、国家机关工作者"></el-option>
						    <el-option label="群众团体和社会组织工作者" value="群众团体和社会组织工作者"></el-option>
						    <el-option label="企事业单位负责人" value="企事业单位负责人"></el-option>
						    <el-option label="专业技术人员" value="专业技术人员"></el-option>
						    <el-option label="办事人员和有关人员" value="办事人员和有关人员"></el-option>
						    <el-option label="社会生产服务和生活服务人员" value="社会生产服务和生活服务人员"></el-option>
						    <el-option label="农、林、牧、渔业生产及辅助人员" value="农、林、牧、渔业生产及辅助人员"></el-option>
						    <el-option label="军人" value="军人"></el-option>
						    <el-option label="教育工作者" value="教育工作者"></el-option>
						    <el-option label="其他" value="其他"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="车辆数:" class="width_3" prop="vehicleNum">
						<el-input v-model="detailForm.vehicleNum" disabled size="medium"></el-input>
					</el-form-item>
					<el-form-item label="系统录入时间:" class="width_3" prop="addTime">
						<el-input v-model="detailForm.addTime" disabled size="medium"></el-input>
					</el-form-item>
					<el-form-item label="用户积分:" class="width_3" prop="points">
						<el-input v-model="detailForm.points" disabled size="medium"></el-input>
					</el-form-item>
					<!-- <br> -->
					<el-form-item label="住址:" prop="address" class="width_3">
						<el-input v-model="detailForm.address" :rows="2" :disabled="userDisabled" :placeholder="userDisabled?' ':'请输入'" clearable></el-input>
					</el-form-item>
				</el-form>
				<div class="dialog-footer" style="text-align: right;">
				  <el-button v-if="userDisabled" type="primary" @click="editUserForm('edit')" size="medium">修 改</el-button>
				  <template v-else>
					  <el-button @click="editUserForm('cancel')" size="medium">取 消</el-button>
					  <el-button type="danger" @click="submitUserForm('detailForm')" size="medium">提 交</el-button>
				  </template>
				</div> 
				<div class="title_box" style="align-items: center;">
					<div class="detail_title">车辆详情</div>
					<el-button type="primary" size="small" :disabled="detailForm.vehicleList.length>=5" icon="el-icon-circle-plus-outline" @click="handleAddVehicle">添加车辆</el-button>
					<span class="hint_text">（最多可添加5辆车）</span>
				</div>
				<div v-if="detailForm.vehicleList.length>0">
					<div class="vehicle_box" v-for="(item,index) in detailForm.vehicleList" :key="index">
						<p class="vehicle_title">车辆{{numberConversion(index)}}：</p>
						<el-form :inline="true" :rules="vehicleRules" :ref="'detailForm'+index" :model="item" class="demo-form-inline" :class="{disabled_null_border:item.disabled}" label-width="110px" >
							<el-form-item label="行驶证:" class="license_box" prop="url">
								<!-- <el-input style="display: none;" v-model="item.url"></el-input> -->
								<el-upload style="display: inline-block;"
										  accept=".png,.jpg,.jpeg,.bmp.JPG,.JPEG,.PNG,"
										  :disabled="item.disabled"
										  :action="$store.getters.baseURL+'/user/uploadeImage'"
										  :show-file-list="false"
										  :headers="unloaderHeader"
										  :on-success="handleAvatarSuccess"
										  :before-upload="beforeAvatarUpload">
									<div v-show="item.url" @click="handleUpload(index)" class="uploade_image"><img :src="item.url" ></div>
									<el-button v-show="!item.url" type="success" plain size="small" @click="handleUpload(index)">上传行驶证</el-button>
								</el-upload>
							</el-form-item>
							<br>
							<el-form-item label="所有人:" class="width_3" prop="ownerName">
								<el-input v-model="item.ownerName" :disabled="item.disabled" :placeholder="item.disabled?' ':'请输入'" size="medium" clearable></el-input>
							</el-form-item>
							<el-form-item label="车牌号:" class="width_3" prop="plateno">
								<el-input v-model="item.plateno" :disabled="item.disabled" placeholder="请输入" size="medium" clearable></el-input>
							</el-form-item>
							<el-form-item label="车辆类型:" class="width_3" prop="vehicleType">
								<el-input v-model="item.vehicleType" :disabled="item.disabled"  :placeholder="item.disabled?' ':'请输入'" size="medium" clearable></el-input>
							</el-form-item>
							<el-form-item label="VIN码:" class="width_3" prop="vin">
								<el-input v-model="item.vin" :disabled="item.disabled" placeholder="请输入" size="medium" clearable></el-input>
							</el-form-item>
							<el-form-item label="发动机号:" class="width_3" prop="engineNumber">
								<el-input v-model="item.engineNumber" :disabled="item.disabled"  :placeholder="item.disabled?' ':'请输入'" size="medium" clearable></el-input>
							</el-form-item>
							<el-form-item label="品牌型号:" class="width_3" prop="brandModel">
								<el-input v-model="item.brandModel" :disabled="item.disabled"  :placeholder="item.disabled?' ':'请输入'" size="medium" clearable></el-input>
							</el-form-item>
							<el-form-item label="座位数:" class="width_3" prop="seatingCapacity">
								<el-input v-model="item.seatingCapacity" :disabled="item.disabled" type="tel" maxlength="2" size="medium"  :placeholder="item.disabled?' ':'请输入'" clearable></el-input>
							</el-form-item>
							<el-form-item label="车辆注册日期:" class="width_3" prop="vehicleRegistrationDate">
								 <el-date-picker
								      v-model="item.vehicleRegistrationDate"
									   :disabled="item.disabled"
								      type="date"
								       :placeholder="item.disabled?' ':'请输入'">
								    </el-date-picker>
							</el-form-item>
							<el-form-item label="使用性质:" class="width_3" prop="vehUse">
								<el-radio v-model="item.vehUse" :disabled="item.disabled" :label="1">营运</el-radio>
								<el-radio v-model="item.vehUse" :disabled="item.disabled" :label="2">非营运</el-radio>
							</el-form-item>
						</el-form>
						<div class="dialog-footer" style="text-align: right;">
						  <el-button v-if="!item.vehicleId" type="danger" @click="deleteVehicle(index)" size="medium">删 除</el-button>
						  <el-button v-if="item.disabled" type="primary" @click="editVehicle('edit',index)" size="medium">修 改</el-button>
						  <template v-else>
							  <el-button v-if="item.vehicleId" @click="editVehicle('cancel',index)" size="medium">取 消</el-button>
							  <el-button type="primary" @click="submitVehicle(index,item)" size="medium">确 认</el-button>
						  </template>
						</div>
					</div>
				</div>
				<div class="null_vehicle" v-else>
					<div><img src="../../public/images/msi/user/zanwucheliang.png" ></div>
					<p>暂无车辆，请添加</p>
				</div>
			</div>
		</div>	
	</div>
</template>

<script>
	import {getToken} from '../utils/auth.js'
export default {
  name: 'UserDetail',
  props: {
    dialogVisible: Boolean,
    detailForm: Object,
  },
  data(){
	  var checkphone = (rule, value, callback) => {
	  		  if (value == "") {
	  			callback(new Error("请输入手机号"));
	  		  } else if (!this.isCellPhone(value)) {//引入methods中封装的检查手机格式的方法 
	  			callback(new Error("请输入正确的手机号!"));
	  		  } else {
	  			callback();
	  		  }
	  		};
	  return{
		    // dialogVisible:true  
		    userDisabled:true,
		    vehicleObj:{
				  brandModel: '',
				  engineNumber: '',
				  ownerName: '',
				  plateno: '',
				  seatingCapacity:'',
				  url:'',
				  vehUse:'',
				  vehicleRegistrationDate:'',
				  vehicleType:'',
				  vin:''
			},
			checkedUploadIndex:0,
			fullscreenLoading:false,
			unloaderHeader:{token:''},//上传的请求头
		    rules: {
				uid: [
					{ required: true, message: '请输入用户标识', trigger: 'blur' }
				], 
				userName: [
					{ required: true, message: '请输入姓名', trigger: 'blur' }
				],  
				userType: [
					{ required: true, message: '请选择用户分类', trigger: 'change' }
				], 
				userPhone: [
					{ required: true, validator: checkphone, trigger: 'blur' }
				],
		    },
			vehicleRules: {
				plateno: [
					{ required: true, message: '请输入车牌号', trigger: 'blur' }
				],  
				vin: [
					{ required: true, message: '请输入车架号', trigger: 'blur' }
				]
		    },
		    tableData:[]
	  }
  },
  mounted() {
	  
  },
  methods:{
	  //点击用户详情的修改按钮
	  editUserForm(sign){
		  // console.log(this.detailForm);
		  if(sign=='edit'){
			 this.userDisabled=false; 
		  }else{
			 this.userDisabled=true; 
			 // console.log(this.$refs.detailForm)
			 this.$refs.detailForm.resetFields();
		  }
	  },
	  //点击车辆详情的取消按钮
	  editVehicle(sign,index){
		  if(sign=='edit'){
			 this.detailForm.vehicleList[index].disabled=false; 
		  }else{
			 this.detailForm.vehicleList[index].disabled=true; 
			 // console.log(this.$refs.detailForm)
			 this.$refs['detailForm'+index][0].resetFields();
		  }
		   
	  },
	  //点击添加车辆
	  handleAddVehicle(){
		  // if(this.detailForm.vehicleList.length>=5){
			 //  this.$message.error('抱歉，最多只能添加5辆车！')
			 //  return false
		  // }
		  this.detailForm.vehicleList.push(
			{
				brandModel: '',
				engineNumber: '',
				ownerName: '',
				plateno: '',
				seatingCapacity:'',
				url:'',
				vehUse:1,
				vehicleRegistrationDate:'',
				vehicleType:'',
				disabled:false,
				userId:this.detailForm.userId,
				vin:''
			}
		  );
	  },
	  //提交车辆信息
	  submitVehicle(index,item){
		  if(!item.plateno){
			  this.$message.error('抱歉，车牌号不能为空！')
			  return false
		  }else{
			  item.plateno=item.plateno.toUpperCase();
			  if(!this.isLicenseNo(item.plateno)){
				  this.$message.error('抱歉，请输入正确的车牌号！')
				  return false
			  }
		  }
		  if(!item.vin){
			  this.$message.error('抱歉，VIN码不能为空！')
			  return false
		  }else{
			  item.vin=item.vin.toUpperCase();
			 if(!this.isVin(item.vin)){
				  this.$message.error('抱歉，请输入正确的车架号！')
				  return false
			 }
		  }
		 var par=item.vehicleId?'updateVehicle':'addVehicle';
		 this.instance({
		 	  method:'post',
		 	  url:'/user/'+par,
		 	  data:item,
		 	  headers:{
		 		'Content-Type':"application/json;charset=UTF-8",
		 	  }
		 }).then(res=>{
		 	if(res.data.code==1){
		 		this.$message.success(res.data.message);
				this.$emit('refreshList');
				 this.$emit('refreshDetail');
		 	}else{
		 		this.$message(res.data.message);
		 	}
		 });
	  },
	  //校验车牌号reg
	  isLicenseNo(str) {
		    var xreg=/^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([0-9]{5}[DF]$)|([DF][A-HJ-NP-Z0-9][0-9]{4}$))/;
		    var creg=/^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1}$/;
		    if(str.length == 7){
				return creg.test(str);
		    } else if(str.length == 8){
				return xreg.test(str);
		    } else{
				return false;
		    }
	   },
	   //校验车架号
	   isVin(vin){
		  if(vin.length!=17){
		      return false
		    }
		    let mapObj = {
		      A:1,
		      B:2,
		      C:3,
		      D:4,
		      E:5,
		      F:6,
		      G:7,
		      H:8,
		      J:1,
		      K:2,
		      L:3,
		      M:4,
		      N:5,
		      P:7,
		      R:9,
		      S:2,
		      T:3,
		      U:4,
		      V:5,
		      W:6,
		      X:7,
		      Y:8,
		      Z:9,
		    }
		    let reg = /^[A-Za-z0-9]+$/;
		    if(reg.test(vin)){
		      let upCasrVin = vin;
		      if(upCasrVin.indexOf('I')>=0||upCasrVin.indexOf('O')>=0||upCasrVin.indexOf('Q')>=0){
		        return false
		      }else{
		        let arr = vin.split("");
		        arr.forEach((element , index) => {
		          for(let ltem in mapObj){
		            if(element == ltem){
		              arr[index] = mapObj[ltem]
		            }
		          }
		        });
		        let weightedArr = [8,7,6,5,4,3,2,10,0,9,8,7,6,5,4,3,2]
		        let num = 0;
		        let newArr = [];
		        arr.forEach((element , index) => {
		          newArr.push(Number(arr[index])*Number(weightedArr[index]))
		        })
		        newArr.forEach(element=>{
		          num+=element
		        })
		        let checkNum = num%11
		        if(checkNum==10){
		          checkNum='X'
		        }
		        let distinguishNum = vin.toUpperCase().substring(8,9);
		        if(checkNum==distinguishNum){
		          return true;
		        }
		        return false;
		      }
		    }else{
		      return false;
		    }
	   },
	  //点击删除车辆
	  deleteVehicle(index){
		  this.detailForm.vehicleList.splice(index,1);
	  },
	  //阿拉伯数字转汉字
	  numberConversion(index){
		  return index==0?'一':index==1?'二':index==2?'三':index==3?'四':index==4?'五':index==5?'六':index==6?'七':index==7?'八':index==8?'九':'十'
	  },
	  handleUpload(index){
		  this.checkedUploadIndex=index;
	  },
	  //文件上传成功时的钩子
	  handleAvatarSuccess(res, file) {
	  	// console.log(file);
		this.fullscreenLoading.close();
		var _index=this.checkedUploadIndex;
		var data=file.response.data;
	    this.detailForm.vehicleList[_index].brandModel = data.brand;
	    this.detailForm.vehicleList[_index].engineNumber = data.motorNo;
	    this.detailForm.vehicleList[_index].ownerName = data.owner;
	    this.detailForm.vehicleList[_index].plateno = data.plateNo;
	    this.detailForm.vehicleList[_index].vehicleRegistrationDate = data.registrationDate;
	    this.detailForm.vehicleList[_index].vehUse = data.vehUse;
	    this.detailForm.vehicleList[_index].url = data.url;
	    this.detailForm.vehicleList[_index].vin = data.vin;
	    this.detailForm.vehicleList[_index].vehicleType = data.vehicleType;
	  },
	  //上传文件之前的钩子  
	  beforeAvatarUpload(file) {
	  	console.log(file.size/1024);
	  	if(file.size/1024>5000){
	  		this.$message.error('抱歉，上传图片不得大于5M！');
	  		return false
	  	}
	  	this.unloaderHeader.token = getToken();
	  	this.fullscreenLoading=this.$loading({
	  	  lock: true,
	  	  text: '上传中，请稍后',
	  	  spinner: 'el-icon-loading',
	  	  background: 'rgba(0, 0, 0, 0.7)'
	  	});
	  },
	  //点击添加用户的确定按钮 
	  submitUserForm(formName){
		  this.$refs[formName].validate((valid) => {
			if (valid) {
			  this.instance({
				  method:'post',
				  url:'/user/updateUser',
				  data:this[formName],
				  headers:{
					'Content-Type':"application/json;charset=UTF-8",
				  }
			  }).then(res=>{
				// console.log(res);
				if(res.data.code==1){
					this.$message.success(res.data.message);
					this.$emit('refreshList');
					 this.$emit('refreshDetail');
				}else{
					this.$message(res.data.message);
				}
			  });
			} else {
			  console.log('error submit!!');
			  return false;
			}
		  });
	  },
	  isCellPhone(val) {
	  		  if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(val)) {
	  			return false;
	  		  } else {
	  			return true;
	  		  }
	  		},
	  beforeClose(done ){
		  // console.log(done)
		  this.$emit('beforeClose');
	  }
  }
}
</script>
<style scoped>
	@import "../assets/css/table_white.css";
	.null_vehicle{
		width: 1.7rem;
		margin : 0 auto;
	}
	.null_vehicle p{
		color: #828893;
		margin-top:0.15rem;
		text-align: center;
	}
	.dialog_section{
		top:0.5rem;
	}
	.uploade_image{
		width: 1.5rem;
		display: inline-block;
	}
	.hint_text{
		
	}
</style>
