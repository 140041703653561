<template>
    <div class="content">
        <div class="page_title">
            <b>车辆风险洞察</b>
        </div>
        <div class="content_box">
            <div class="search_box public_box">
                <!--检索部分-->
                <el-form :inline="true" :model="navForm" class="demo-form-inline">
                    <el-form-item>
                        <el-button @click="handleDownload" type="primary" plain><i class="el-icon-download el-icon--left"></i>下载车辆列表模板</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-upload class="upload_box" accept=".xls,.xlsx" :action="baseURL + '/risk/uploadRiskPlateNo'" :show-file-list="true" :headers="unloaderHeader" :limit="1" :on-remove="handleRemove" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                            <template>
                                <el-button type="primary"><i class="el-icon-upload el-icon--left"></i>上传车辆列表</el-button>
                            </template>
                        </el-upload>
                    </el-form-item>
                    <br />
                    <el-form-item label="风险洞察时间：" class="dateBox">
                        <el-row style="width: 370px">
                            <el-col :span="11">
                                <el-date-picker placeholder="请选择日期" clearable v-model="navForm.startTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                            </el-col>
                            <el-col class="line" style="text-align: center" :span="2">至</el-col>
                            <el-col :span="11">
                                <el-date-picker placeholder="请选择日期" clearable v-model="navForm.endTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item label="设备编号/车架号/车牌号：">
                        <el-input class="width_150" v-model="navForm.search" :disabled="caseExcelData != ''" placeholder="请输入" clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="searchTable">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="main">
                <div class="table_box public_box">
                    <div class="table_title" v-if="tableData.length > 0">
                        <el-button @click="searchResult" class="download" type="text"><i class="el-icon-s-data el-icon--left"></i>查询结果概览</el-button>
                        <!-- <el-button @click="handleDownload" class="download" type="text"><i class="el-icon-download el-icon--left"></i>数据导出</el-button> -->
                    </div>
                    <!--table表格部分-->
                    <el-table ref="multipleTable" :data="tableData" v-loading="loading">
                        <!-- @selection-change="handleSelectionChange" -->
                        <!-- <el-table-column type="selection" width="55"></el-table-column> -->
                        <el-table-column prop="plateNo" label="车牌号"></el-table-column>
                        <el-table-column prop="vin" label="车辆VIN码"></el-table-column>
                        <el-table-column prop="deviceNumber" label="绑定设备号"></el-table-column>
                        <el-table-column prop="drivingDay" label="出行天数"></el-table-column>
                        <el-table-column prop="tripsNum" label="行程条数"></el-table-column>
                        <el-table-column prop="score" label="驾驶行为风险评分"></el-table-column>
                        <el-table-column prop="content" label="风险画像"></el-table-column>
                    </el-table>
                    <!--分页部分-->
                    <el-pagination style="margin-top: 0.2rem" @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="currentPage" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="10" :total="totalPage"> </el-pagination>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false" width="650px">
            <div class="dialog_box">
                <ul class="dialog_ul">
                    <li>
                        <div>查询车辆总数：</div>
                        <span>{{ riskResultVo.vehtotalNum }}辆（{{ riskResultVo.realVehNum }}辆有效）</span>
                    </li>
                    <li>
                        <div>车辆驾驶行为风险平均分：</div>
                        <span>{{ riskResultVo.avgScore }}分</span>
                    </li>
                    <li>
                        <div>低于平均分车辆：</div>
                        <span>{{ riskResultVo.surplusVehNum }}辆</span>
                    </li>
                </ul>
                <div style="color: #333">车辆风险评分分布：</div>
                <div id="pie_charts"></div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
// @ is an alias to /src
import { getToken } from '../../utils/auth.js'
import * as echarts from 'echarts'
export default {
    data() {
        return {
            caseExcelData: '',
            dialogVisible: false,
            baseURL: '',
            unloaderHeader: { token: '' }, //上传的请求头
            fullscreenLoading: false,
            uploadFile: {}, //上传成功后的值
            fileName: '',
            activeName: 'first',
            loading: false,
            nowFilter: '',
            navForm: {
                //储存搜索框的检索条件信息
                startTime: '',
                endTime: '',
                search: '',
                plateno: ''
            },
            riskResultVo: {}, //洞察车辆总数
            chartPie: '',
            currentPage: 1, //当前页
            pageSize: 10, //一页多少条数据
            totalPage: 0, //总页数tableData:[],
            tableData: []
        }
    },
    methods: {
        //加载插拔频次统计图
        loadPieCharts(response) {
            // var response=[12,3,5];
            var chartDom = document.getElementById('pie_charts')
            this.chartPie = echarts.init(chartDom, null, { renderer: 'svg' })
            var travelData = []
            response.length > 0 &&
                response.forEach((val, key) => {
                    let obj = {
                        name: val.scoreName,
                        value: val.scoreNum
                    }
                    travelData.push(obj)
                })
            var option = {
                // color:['rgba(5, 169, 197, 1)','rgba(255, 180, 0, 1)','rgba(220, 76, 0, 1)'],
                tooltip: {
                    // formatter: '{b0} : {c0}辆 ',
                    formatter: '{b0} : {c0}辆 ({d}%)',
                    show: true
                },
                legend: {
                    // orient: 'vertical',
                    bottom: '20',
                    left: 'center',
                    itemGap: 30,
                    itemWidth: 14
                },
                series: [
                    {
                        // name: 'Nightingale Chart',
                        type: 'pie',
                        radius: ['35%', '60%'],
                        center: ['50%', '40%'],
                        label: {
                            show: false
                        },
                        data: travelData
                    }
                ]
            }
            this.chartPie.setOption(option)
        },
        //点击查看结果概览
        searchResult() {
            this.dialogVisible = true
            var json = JSON.parse(JSON.stringify(this.navForm))
            json.plateno = this.caseExcelData
            //洞察车辆总数查询接口
            this.instance.post('/risk/getRiskResultVo', json).then((res) => {
                if (res.data.code == 1) {
                    this.riskResultVo = res.data.data
                }
            })
            //洞察车辆风险评分分布接口
            this.instance.post('/risk/getRiskScoreResultVo', json).then((res) => {
                if (res.data.code == 1) {
                    this.loadPieCharts(res.data.data)
                }
            })
        },
        //删除文件
        handleRemove(file, fileList) {
            this.caseExcelData = ''
        },
        //文件上传成功时的钩子
        handleAvatarSuccess(res, file) {
            this.fullscreenLoading.close()
            this.fileName = file.name
            this.uploadFile = res.data
            // console.log(res,file);
            if (res.code == 1) {
                this.$message.success(res.message)
                this.caseExcelData = res.data
                this.navForm.search = ''
                // console.log(this.caseExcelData);
                // this.$emit('refreshList');
            } else {
                this.$message.error(res.message)
                // this.tableData=res.data;
            }
            // this.getTbodyData();
        },
        //上传文件之前的钩子
        beforeAvatarUpload(file) {
            // console.log(file.size/1024);
            // this.tableData=[];
            if (file.size / 1024 > 5000) {
                this.$message.error('抱歉，上传文件不得大于5M！')
                return false
            }
            this.unloaderHeader.token = getToken()
            this.fullscreenLoading = this.$loading({
                lock: true,
                text: '上传中，请稍后',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
        },
        //点击查询
        searchTable() {
            this.currentPage = 1
            this.getTbodyData()
            // if(this.caseExcelData.length>0||(this.navForm.startTime&&this.navForm.endTime)){
            // 	this.currentPage=1;
            // 	this.getTbodyData();
            // }else{
            // 	this.$message.error('请上传文件或者选择结案时间之后再查询！')
            // }
        },
        //获取tbody里的数据
        getTbodyData: function () {
            this.loading = true
            this.tableData = [] //  清空table的值
            // this.totalPage=0;
            var json = JSON.parse(JSON.stringify(this.navForm))
            json.plateno = this.caseExcelData
            //储存当前的检索条件，下载用
            this.nowFilter = JSON.parse(JSON.stringify(json))
            json.pageNum = this.currentPage
            json.pageSize = this.pageSize
            this.instance.post('/risk/getRiskListResultList', json).then((res) => {
                // console.log(res);
                this.loading = false
                if (res.data.code == 1) {
                    var response = res.data.data.list
                    response.length > 0 &&
                        response.forEach((val, key) => {
                            val.score = val.score ? val.score.toFixed(2) : 0
                        })
                    this.tableData = response
                    this.totalPage = parseInt(res.data.data.total)
                } else {
                    this.totalPage = 0
                }
            })
        },
        //分页数据多少条change
        handleSizeChange(val) {
            this.pageSize = val
            this.getTbodyData()
        },
        //分页：当前页改变之后的函数
        handleCurrentChange: function (val) {
            // console.log(val);
            this.currentPage = Number(val)
            this.getTbodyData()
        },
        //下载工单
        handleDownload() {
            // var json=JSON.parse(JSON.stringify(this.nowFilter));
            this.fullscreenLoading = this.$loading({
                lock: true,
                text: '下载中，请稍后',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            this.instance({
                method: 'get',
                url: '/risk/downLoad',
                data: '',
                responseType: 'blob',
                headers: {
                    // 'Content-Type':"application/x-www-form-urlencoded",
                }
            })
                .then((res) => {
                    // this.loading=false;
                    this.fullscreenLoading.close()
                    let url = window.URL.createObjectURL(new Blob([res.data]))
                    let link = document.createElement('a')
                    link.style.display = 'none'
                    link.href = url
                    link.setAttribute('download', '车辆列表模板.csv') //指定下载后的文件名，防跳转
                    document.body.appendChild(link)
                    link.click()
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    },
    mounted() {
        //
        this.baseURL = this.$store.getters.baseURL
        window.addEventListener('resize', () => {
            if (this.chartPie) {
                setTimeout(() => {
                    this.chartPie.resize()
                }, 500)
            }
        })
        // this.getTbodyData();
    }
}
</script>
<style scoped>
@import '../../assets/css/table_white.css';
#pie_charts {
    width: 500px;
    height: 3.5rem;
    margin-left: 60px;
}
.dialog_ul li {
    display: flex;
    width: 100%;
    margin-bottom: 0.2rem;
}
.dialog_ul div {
    width: 200px;
    color: #333;
}
.dialog_ul span {
    color: #000000;
}
.table_title {
    display: flex;
    /* margin-bottom: 0.3rem; */
    align-items: center;
    justify-content: flex-end;
}
.table_title b {
    font-size: 0.26rem;
    font-weight: 600;
}
.hint_text {
    color: #909399;
    font-size: 0.16rem;
}
.warning_icon {
    width: 0.14rem;
    margin-right: 0.18rem;
}
.download {
    margin-left: 0.5rem;
    font-size: 0.18rem;
}
</style>
