<template >
	<div class="content">
		<div class="page_title">危险驾驶车辆规则列表</div>

		<div class="page_card">
			<!--<div style="width: 100%;text-align: right;padding-right: 50px; line-height: 50px;">
				<el-button size="small" type="primary" @click="addNewRule">新增</el-button>
			</div>-->
			<el-dialog v-loading="saveLoading" :title="editFormTitle" :visible.sync="showEditForm" width="40%"
				append-to-body>
				<el-form ref="ruleForm" :model="editForm" label-width="150px" size="small">
					<el-form-item label="规则名称" required>
						<el-input v-model="editForm.ruleName" v-bind:readonly="editFormReadOnly"
							@focus="showError.ruleName = false"></el-input>
						<template v-if="showError.ruleName"><span
								style="color:red; font-size:small">规则名称不能为空！</span></template>
					</el-form-item>
					<el-form-item label="驾驶评分" required>
						<el-row>
							<el-col :span="10">
								<el-form-item required>
									<el-input v-model="editForm.drvingScoreMonthCountOfTrigger"
										v-bind:readonly="editFormReadOnly"
										@focus="showError.drvingScoreMonthCountOfTrigger = false">
										<template slot="append">月</template>
									</el-input>
									<template v-if="showError.drvingScoreMonthCountOfTrigger"><span
											style="color:red; font-size:small">月不能为空！</span></template>
								</el-form-item>
							</el-col>
							<el-col :span="2" style="text-align:center"> &lt;= </el-col>
							<el-col :span="10">
								<el-form-item required>
									<el-input v-model="editForm.drivingScoreOfTrigger" v-bind:readonly="editFormReadOnly"
										@focus="showError.drivingScoreOfTrigger = false">
										<template slot="append">分</template>
									</el-input>
									<template v-if="showError.drivingScoreOfTrigger"><span
											style="color:red; font-size:small">驾驶评分不能为空！</span></template>
								</el-form-item>
							</el-col>
						</el-row>
					</el-form-item>
					<el-form-item label="日均疲劳驾驶时长" required>
						<el-row>
							<el-col :span="2"> >= </el-col>
							<el-col :span="22">
								<el-input v-model="editForm.fatiguedDrivingDurationAvgDayOfTrigger"
									v-bind:readonly="editFormReadOnly"
									@focus="showError.fatiguedDrivingDurationAvgDayOfTrigger = false">
									<template slot="append">h</template>
								</el-input>
								<template v-if="showError.fatiguedDrivingDurationAvgDayOfTrigger"><span
										style="color:red; font-size:small">日均疲劳驾驶时长不能为空！</span></template>
							</el-col>
						</el-row>
					</el-form-item>
					<el-form-item label="百公里三急次数" required>
						<el-row>
							<el-col :span="2"> >= </el-col>
							<el-col :span="22">
								<el-input v-model="editForm.sharpAlarmCount100KMOfTrigger"
									v-bind:readonly="editFormReadOnly"
									@focus="showError.sharpAlarmCount100KMOfTrigger = false">
									<template slot="append">次</template>
								</el-input>
								<template v-if="showError.sharpAlarmCount100KMOfTrigger"><span
										style="color:red; font-size:small">百公里三急次数不能为空！</span></template>
							</el-col>
						</el-row>
					</el-form-item>
					<el-form-item label="百公里超速次数" required>
						<el-row>
							<el-col :span="2"> >= </el-col>
							<el-col :span="22">
								<el-input v-model="editForm.overSpeedCount100KMOfTrigger" v-bind:readonly="editFormReadOnly"
									@focus="showError.overSpeedCount100KMOfTrigger = false">
									<template slot="append">次</template>
								</el-input>
								<template v-if="showError.overSpeedCount100KMOfTrigger"><span
										style="color:red; font-size:small">百公里超速次数不能为空！</span></template>
							</el-col>
						</el-row>
					</el-form-item>
					<div class="page_title" style="font-size: 14px;font-weight:bold;">| 解除跟踪条件</div>
					<el-form-item label="驾驶评分" required inline="true">
						<el-row>
							<el-col :span="10">
								<el-form-item required>
									<el-input v-model="editForm.drvingScoreMonthCountOfEnd"
										v-bind:readonly="editFormReadOnly"
										@focus="showError.drvingScoreMonthCountOfEnd = false">
										<template slot="append">月</template>
									</el-input>
									<template v-if="showError.drvingScoreMonthCountOfEnd"><span
											style="color:red; font-size:small">月不能为空！</span></template>
								</el-form-item>
							</el-col>
							<el-col :span="2" style="text-align:center"> >= </el-col>
							<el-col :span="10">
								<el-form-item required>
									<el-input v-model="editForm.drivingScoreOfEnd" v-bind:readonly="editFormReadOnly"
										@focus="showError.drivingScoreOfEnd = false">
										<template slot="append">分</template>
									</el-input>
									<template v-if="showError.drivingScoreOfEnd"><span
											style="color:red; font-size:small">驾驶评分不能为空！</span></template>
								</el-form-item>
							</el-col>
						</el-row>
					</el-form-item>
					<el-form-item label="日均疲劳驾驶时长" required>
						<el-row>
							<el-col :span="2"> &lt;= </el-col>
							<el-col :span="22">
								<el-input v-model="editForm.fatiguedDrivingDurationAvgDayOfEnd"
									v-bind:readonly="editFormReadOnly"
									@focus="showError.fatiguedDrivingDurationAvgDayOfEnd = false">
									<template slot="append">h</template>
								</el-input>
								<template v-if="showError.fatiguedDrivingDurationAvgDayOfEnd"><span
										style="color:red; font-size:small">日均疲劳驾驶时长不能为空！</span></template>
							</el-col>
						</el-row>
					</el-form-item>
					<el-form-item label="百公里三急次数" required>
						<el-row>
							<el-col :span="2"> &lt;= </el-col>
							<el-col :span="22">
								<el-input v-model="editForm.sharpAlarmCount100KMOfEnd" v-bind:readonly="editFormReadOnly"
									@focus="showError.sharpAlarmCount100KMOfEnd = false">
									<template slot="append">次</template>
								</el-input>
								<template v-if="showError.sharpAlarmCount100KMOfEnd"><span
										style="color:red; font-size:small">百公里三急次数不能为空！</span></template>
							</el-col>
						</el-row>
					</el-form-item>
					<el-form-item label="百公里超速次数" required>
						<el-row>
							<el-col :span="2"> &lt;= </el-col>
							<el-col :span="22">
								<el-input v-model="editForm.overSpeedCount100KMOfEnd" v-bind:readonly="editFormReadOnly"
									@focus="showError.overSpeedCount100KMOfEnd = false">
									<template slot="append">次</template>
								</el-input>
								<template v-if="showError.overSpeedCount100KMOfEnd"><span
										style="color:red; font-size:small">百公里超速次数不能为空！</span></template>
							</el-col>
						</el-row>
					</el-form-item>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<template v-if="editFormReadOnly">
						<el-button @click="showEditForm = false">关闭</el-button>
					</template>
					<template v-else>
						<el-button @click="showEditForm = false">取 消</el-button>
						<el-button type="primary" @click="submitForm">确认</el-button>
					</template>
				</div>
			</el-dialog>
			<el-table :data="tableData" style="width: 100%" v-loading="loading">
				<el-table-column type="index" width="50" label="序号"> </el-table-column>
				<el-table-column prop="ruleName" label="规则名称" width="80"> </el-table-column>
				<el-table-column label="启动跟踪条件">
					<el-table-column prop="dangerousCheckRule.drvingScoreMonthCountOfTrigger" label="月" width="60" />
					<el-table-column prop="dangerousCheckRule.drivingScoreOfTrigger" label="驾驶评分" width="100" />
					<el-table-column prop="dangerousCheckRule.fatiguedDrivingDurationAvgDayOfTrigger" label="日均疲劳驾驶时长">
						<template slot-scope="scope">
							<span>{{ scope.row.dangerousCheckRule.fatiguedDrivingDurationAvgDayOfTrigger }} h</span>
						</template>
					</el-table-column>
					<el-table-column prop="dangerousCheckRule.sharpAlarmCount100KMOfTrigger" label="百公里三急次数" />
					<el-table-column prop="dangerousCheckRule.overSpeedCount100KMOfTrigger" label="百公里超速次数" />
				</el-table-column>
				<el-table-column label="解除跟踪条件">
					<el-table-column prop="dangerousCheckRule.drvingScoreMonthCountOfEnd" label="月" width="60" />
					<el-table-column prop="dangerousCheckRule.drivingScoreOfEnd" label="驾驶评分" width="100" />
					<el-table-column prop="dangerousCheckRule.fatiguedDrivingDurationAvgDayOfEnd" label="日均疲劳驾驶时长">
						<template slot-scope="scope">
							<span>{{ scope.row.dangerousCheckRule.fatiguedDrivingDurationAvgDayOfEnd }} h</span>
						</template>
					</el-table-column>
					<el-table-column prop="dangerousCheckRule.sharpAlarmCount100KMOfEnd" label="百公里三急次数" />
					<el-table-column prop="dangerousCheckRule.overSpeedCount100KMOfEnd" label="百公里超速次数" />
				</el-table-column>
				<el-table-column label="是否启用" width="120">
					<template slot-scope="scope">
                        <div>
                            <el-switch :value="scope.row.recordStatus == 0" @change="changeRuleRecordStatus(scope.row)" active-text="开" inactive-text="关" active-color="#409EFF" inactive-color="#C0C0C0"> </el-switch>
                        </div>
                    </template>
				</el-table-column>
				<el-table-column label="操作" width="120">
					<template slot-scope="scope">
						<!--<el-button type="text" size="small" @click="deleteRule(scope.row)">关闭</el-button>
						<el-divider direction="vertical"></el-divider>-->
						<el-button type="text" size="small" @click="editRule(scope.row)">修改</el-button>
						<el-divider direction="vertical"></el-divider>
						<el-button type="text" size="small" @click="ruleDetail(scope.row)">查看</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!--分页部分-->
			<el-pagination style="margin-top: 0.2rem" @current-change="handleCurrentChange" @size-change="handleSizeChange"
				:current-page="navForm.pageNo" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]"
				:page-size="navForm.pageSize" :total="total">
			</el-pagination>
		</div>
	</div>
</template>
<script>

export default {
	data() {
		return {
			loading: false,
			saveLoading: false,
			showEditForm: false,
			editFormReadOnly: false,
			editFormTitle: "",
			navForm: {
				//储存搜索框的检索条件信息
				condition: {
					ruleType: 0
				},
				orders: [],
				pageNo: 1,
				pageSize: 20
			},
			editForm: {},
			showError: {},
			rangeDate: [],
			companyList: [],
			fleetList: [],
			tableData: [],
			total: 0,
			currentUser: {
				userTeantType: 2
			},
			userInfo: null
		}
	},
	mounted() {
		this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
		this.loadCompanyList();
		this.getCurrentUser();
		this.loadRecords();
	},
	methods: {
		submitForm() {
			this.showError = {
				ruleName: this.editForm.ruleName ? false : true,
				drvingScoreMonthCountOfTrigger: this.editForm.drvingScoreMonthCountOfTrigger ? false : true,
				drivingScoreOfTrigger: this.editForm.drivingScoreOfTrigger ? false : true,
				fatiguedDrivingDurationAvgDayOfTrigger: this.editForm.fatiguedDrivingDurationAvgDayOfTrigger ? false : true,
				sharpAlarmCount100KMOfTrigger: this.editForm.sharpAlarmCount100KMOfTrigger ? false : true,
				overSpeedCount100KMOfTrigger: this.editForm.overSpeedCount100KMOfTrigger ? false : true,
				drvingScoreMonthCountOfEnd: this.editForm.drvingScoreMonthCountOfEnd ? false : true,
				drivingScoreOfEnd: this.editForm.drivingScoreOfEnd ? false : true,
				fatiguedDrivingDurationAvgDayOfEnd: this.editForm.fatiguedDrivingDurationAvgDayOfEnd ? false : true,
				sharpAlarmCount100KMOfEnd: this.editForm.sharpAlarmCount100KMOfEnd ? false : true,
				overSpeedCount100KMOfEnd: this.editForm.overSpeedCount100KMOfEnd ? false : true,
			}
			var isValid = this.editForm.ruleName &&
				this.editForm.drvingScoreMonthCountOfTrigger &&
				this.editForm.drivingScoreOfTrigger &&
				this.editForm.fatiguedDrivingDurationAvgDayOfTrigger &&
				this.editForm.sharpAlarmCount100KMOfTrigger &&
				this.editForm.overSpeedCount100KMOfTrigger &&
				this.editForm.drvingScoreMonthCountOfEnd &&
				this.editForm.drivingScoreOfEnd &&
				this.editForm.fatiguedDrivingDurationAvgDayOfEnd &&
				this.editForm.sharpAlarmCount100KMOfEnd &&
				this.editForm.overSpeedCount100KMOfEnd;
			if (isValid) {
				this.saveRule();
			} else {
			}
		},
		editRule(row) {
			this.editFormReadOnly = false;
			this.showError = {};
			this.editForm = {
				...row.dangerousCheckRule,
				ruleName: row.ruleName,
				ruleId: row.id,
			}
			this.showEditForm = true;
			this.editFormTitle = "编辑危险驾驶车辆规则";
		},
		changeRuleRecordStatus(row) {
			if(row.recordStatus == 0) {
				this.instance.get('/ubi/api/checkRule/delete?id=' + row.id).then(({ data }) => {
					if (data.code == 200) {
						this.$message({ message: "规则已停用", type: "success" });
						this.loadRecords();
					}
				})
			} else {
				this.instance.get('/ubi/api/checkRule/recover?id=' + row.id).then(({ data }) => {
					if (data.code == 200) {
						this.$message({ message: "规则已启用", type: "success" });
						this.loadRecords();
					}
				})
			}
		},
		deleteRule(row) {
			this.$confirm('此操作将永久删除记录, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.instance.get('/ubi/api/checkRule/delete?id=' + row.id).then(({ data }) => {
					if (data.code == 200) {
						this.$message({ message: "删除成功", type: "success" });
						this.loadRecords();
					}
				})
			}).catch(() => this.$message({
				type: 'info', message: '已取消删除'
			}));
		},
		ruleDetail(row) {
			this.editForm = {
				...row.dangerousCheckRule,
				ruleName: row.ruleName,
				ruleId: row.id,
			}
			this.showEditForm = true;
			this.editFormReadOnly = true;
			this.editFormTitle = "查看危险驾驶车辆规则";
		},
		saveRule() {
			this.saveLoading = true;

			const postData = JSON.parse(JSON.stringify(this.editForm));

			this.instance.post('/ubi/api/dangerousDrivingVehicleRule/save', postData)
				.then(({ data }) => {
					this.saveLoading = false;
					if (data.code == 200) {
						this.$message({ message: "提交成功！", type: "success" });
						this.loadRecords();
						this.showEditForm = false;
					}
				})
		},
		addNewRule() {
			this.editFormReadOnly = false;
			this.editForm = {};
			this.showEditForm = true;
			this.editFormTitle = "新增危险驾驶车辆规则";
			this.showError = {};
		},
		loadRecords() {
			this.loading = true;

			this.instance
				.post('/ubi/api/badDrivingCheckRule/page', JSON.parse(JSON.stringify(this.navForm)))
				.then(({ data }) => {
					this.loading = false;
					if (data.code == 200) {
						this.tableData = data.data.list
						this.total = data.data.total
					} else {
						this.total = 0
					}
					this.loading = false;
				});
		},
		handleCurrentChange(val) {
			this.navForm.pageNo = val;
			this.loadRecords();
		},
		handleSizeChange(val) {
			this.navForm.pageSize = val;
			this.loadRecords();
		},
		//获取车队
		loadFleetList(id) {
			this.navForm.fleetId = null
			if (id) {
				this.instance.get('/ubi/api/tenant/fleetList?companyId=' + id).then((res) => {
					let data = res.data
					if (data.code == 200) {
						this.batchIdList = data.data
					}
				})
			} else {
				this.batchIdList = []
			}
		},
		//获取分公司
		loadCompanyList() {
			this.instance.get('/ubi/api/tenant/companyList').then((res) => {
				let data = res.data
				if (data.code == 200) {
					this.companyList = data.data
				}
			})
		},
		getCurrentUser() {
			this.instance.get('/ubi/api/tenant/currentUser').then((res) => {
				let data = res.data
				if (data.code == 200) {
					this.currentUser = res.data
				}
			})
		},
	}
}
</script>
<style scoped>
@import '../../assets/css/table_white.css';
@import '../../assets/css/public.css';

.page_content {
	width: 100%;
	height: 100%;
	overflow: auto;
}

.page_none {
	background: #fff;
}

.big_num {
	font-size: 48px;
	font-weight: bold;
	color: darkturquoise;
}

.index_style {
	font-size: 24px;
	font-weight: bold;
	height: 60px;
	text-align: center;
	vertical-align: middle;
}

.title_bar {
	padding-left: 20px;
	line-height: 40px;
	font-size: 16px;
	font-weight: bold;
	background-color: #e5e9f2;
}

.accident_detail {
	line-height: 40px;
	font-size: medium;
}

.accident_content {
	width: 70%;
	margin-top: 10px;
	padding: 10px 10px 20px 30px;
	border-style: solid;
	border-radius: 4px;
	border-width: 1px;
	border-color: rgba(156, 15, 15, 0.1);
	line-height: normal;
}</style>