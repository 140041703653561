<template>
    <div class="content" v-cloak>
        <div class="page_title">消息提醒列表</div>
        <div class="content_box">
            <div class="search_box">
                <!--检索部分-->
                <el-form :inline="true" :model="navForm" class="demo-form-inline">
                    <el-form-item label="消息名称:">
                        <el-input class="width_150" v-model="navForm.remindName" size="small" placeholder="请输入消息名称" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="提醒事件类型:">
                        <el-select v-model="navForm.remindType" placeholder="请选择" size="small" clearable>
                            <el-option v-for="item in this.remindTypeOptions" :key="item.code" :label="item.desc" :value="item.code"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="通知方式:">
                        <el-select v-model="navForm.remindMode" placeholder="请选择" size="small" clearable>
                            <el-option v-for="item in this.remindModeOptions" :key="item.code" :label="item.desc" :value="item.code"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="small" @click="searchTable" style="margin-left: 0.2rem">查询 </el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="flex title_and_btn">
                <div class="detail_title">消息提醒</div>
                <el-row>
                    <el-button type="primary" size="small" @click="addMessageHint('add')">添加消息提醒</el-button>
                </el-row>
            </div>
            <div class="table_box">
                <!--table表格部分-->
                <el-table ref="multipleTable" :data="tableData" v-loading="loading" :height="tableHeight" style="width: 100%">
                    <el-table-column label="序号" type="index" width="80" :index="hIndex"></el-table-column>
                    <el-table-column prop="remindName" label="消息名称"></el-table-column>
                    <el-table-column prop="remindEventTypeDesc" label="提醒事件类型"></el-table-column>
                    <el-table-column prop="condition" label="提醒方式" width="80">
                        <template slot-scope="scope">
                            <template v-if="scope.row.remindModeList && scope.row.remindModeList.length > 0">
                                <div v-for="item in scope.row.remindModeList" :key="item.code">{{ item.desc }}</div>
                            </template>
                            <span v-else>--</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="triggerCondition" label="触发条件">
                        <template slot-scope="scope">
                            <div>{{ scope.row.condition ? scope.row.condition.condition + scope.row.condition.unit : '' }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="提醒对象">
                        <template slot-scope="scope">
                            <div>
                                <span v-if="scope.row.remindPersonVo.remindDriver">驾驶员</span>
                                <span v-if="scope.row.remindPersonVo.remindManager">管理员</span>
                                <span v-if="scope.row.remindPersonVo.otherPerson && scope.row.remindPersonVo.otherPerson.length > 0">
                                    <template v-for="item in scope.row.remindPersonVo.otherPerson">
                                        <span> {{ item.personName }} </span>
                                    </template>
                                </span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="状态" width="110">
                        <template slot-scope="scope">
                            <div>
                                <el-switch :value="scope.row.messageStatus !== 0" @change="updateState(scope.row)" active-text="开" inactive-text="关" active-color="#409EFF" inactive-color="#C0C0C0"> </el-switch>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="100px">
                        <template slot-scope="scope">
                            <div class="operation_box">
                                <span @click="handleLookDetail(scope.row)">编辑</span>
                                <span class="color_red" @click="deleteInfo(scope.row.id)">删除</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <!--分页部分-->
                <el-pagination style="margin-top: 0.2rem" @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="currentPage" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="10" :total="total"> </el-pagination>
            </div>
        </div>
        <!-- 添加消息提醒 -->
        <el-dialog :title="messageHintTitle" class="detail_dialog" :visible.sync="dialogAddUserVisible" append-to-body :modal-append-to-body="false" width="12rem">
            <el-dialog width="600px" :visible.sync="innerVisible" center append-to-body>
                <div class="hint_obj_box">
                    <el-form ref="staffsForm" :model="staffsForm">
                        <el-form-item label="驾驶员:">
                            <el-checkbox-group v-model="staffsForm.driver">
                                <el-checkbox border label="1">驾驶员</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                        <el-form-item label="车队管理员:">
                            <el-checkbox-group v-model="staffsForm.administrators">
                                <el-checkbox border label="1">车队管理员</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                        <el-form-item label="其他人员:">
                            <el-checkbox-group v-model="staffsForm.others">
                                <el-checkbox v-for="item in triggerObjectVo" :key="item.id" border :value="item.id" :label="item.id"> {{ item.personName }} </el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-form>
                </div>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="innerVisible = false">取 消</el-button>
                    <el-button type="primary" @click="handleSelectStaffs">确 定</el-button>
                </div>
            </el-dialog>
            <div class="form_box">
                <el-form :rules="rules" ref="addMessageForm" :model="addMessageForm" label-width="150px">
                    <el-form-item label="消息名称:" prop="remindName" class="width_300">
                        <el-input v-model="addMessageForm.remindName" placeholder="请输入消息名称" clearable />
                    </el-form-item>
                    <el-form-item label="提醒事件类型:" prop="remindEventType">
                        <el-select v-model="addMessageForm.remindEventType" placeholder="请选择" @change="reminderEventTypeChange">
                            <el-option v-for="item in remindTypeOptions" :key="item.code" :label="item.desc" :value="item.code"> </el-option>
                            <!-- <el-option label="超速" value="超速"></el-option> -->
                        </el-select>
                    </el-form-item>
                    <el-form-item label="触发条件:" prop="triggerRules">
                        <el-select v-model="addMessageForm.triggerRules" placeholder="请选择">
                            <el-option v-for="item in triggerRulesOptions" :label="item.condition + item.unit" :value="item.code" :key="item.code"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="提醒方式:" prop="remindModeList">
                        <el-checkbox-group v-model="addMessageForm.remindModeList" :disabled="!addMessageForm.remindEventType">
                            <el-checkbox v-for="item in remindModeOptions" :key="item.code" :label="item.code">{{ item.desc }} </el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <!-- <el-form-item :label="userInfo.tenantId == '10004' ? '提醒分公司' : '提醒车队'" prop="subTenantIdList"> -->
                    <el-form-item label="提醒分公司" prop="subTenantIdList">
                        <el-select v-model="addMessageForm.remindPersonDto.subTenantIdList" multiple size="small" placeholder="请选择" style="width: 100%">
                            <el-option v-for="item in companyList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="提醒对象:" prop="uid">
                        <template v-if="!addMessageForm.remindPersonDto.remindDriver && !addMessageForm.remindPersonDto.remindManager && addMessageForm.remindPersonDto.otherPerson && addMessageForm.remindPersonDto.otherPerson.length === 0">
                            <el-button type="primary" @click="handleRemindObj">选择提醒对象</el-button>
                        </template>
                        <el-button v-if="addMessageForm.remindPersonDto.remindDriver" type="primary" @click="handleRemindObj"> 驾驶员 </el-button>
                        <el-button v-if="addMessageForm.remindPersonDto.remindManager" type="primary" @click="handleRemindObj">管理员 </el-button>
                        <template v-for="(item, index) in addMessageForm.remindPersonDto.otherPerson">
                            <el-button type="primary" @click="handleRemindObj" :key="index">{{ item.personName }}</el-button>
                        </template>
                    </el-form-item>
                    <el-form-item label="备注:">
                        <el-input v-model="addMessageForm.remarks" type="textarea" placeholder="请输入备注" clearable></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer" style="text-align: center">
                <!-- <el-button @click="dialogAddUserVisible = false">取 消</el-button> -->
                <el-button type="primary" @click="submitAddMessageForm('addMessageForm')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'NotificationList',
    components: {},
    props: {
        msg: String
    },
    data() {
        return {
            tableHeight: window.innerHeight > 700 ? 400 : 382,
            rid: '',
            dialogActiveName: 'first',
            messageHintTitle: '添加消息提醒',

            dialogAddUserVisible: false,
            innerVisible: false,
            hIndex: 1,

            remindModeOptions: [],
            remindTypeOptions: [],
            triggerRulesOptions: [],
            tenantListOption: [],

            addMessageForm: {
                id: undefined,
                remindName: '',
                remindPersonDto: {
                    subTenantIdList: [],
                    remindManager: false,
                    remindDriver: false,
                    otherPerson: []
                },
                remindModeList: [],
                messageStatus: '',
                remarks: '',
                remindEventType: undefined,
                triggerRules: undefined
            },

            rules: {
                remindName: [{ required: true, message: '请输入消息名称', trigger: 'blur' }],
                remindEventType: [{ required: true, message: '请选择消息提醒事件类型', trigger: 'change' }],
                triggerRules: [{ required: true, message: '请选择触发条件', trigger: 'change' }],
                // subTenantIdList: [
                //     {required: true, message: '请至少选择一个提醒车队', trigger: 'change'}
                // ],
                remindModeList: [{ required: true, message: '请至少选择一个消息提醒方式', trigger: 'change' }]
            },

            staffsForm: {
                driver: [],
                administrators: [],
                others: []
            },
            otherRemindPageParam: {
                pageNo: 1,
                pageSize: 20,
                condition: {
                    personType: 3
                }
            },
            triggerObjectVo: [], //提醒对象

            loading: false,
            tableData: [],
            tableDataObj: [],
            navForm: {
                //储存搜索框的检索条件信息
                remindName: '',
                remindMode: undefined,
                remindType: undefined
            },

            pageParam: {
                pageNo: 1,
                pageSize: 20,
                condition: {
                    remindMode: undefined,
                    remindType: undefined,
                    remindName: ''
                }
            },
            currentPage: 1, //当前页
            pageSize: 10,
            total: 0,
            companyList: []
        };
    },
    mounted() {
        this.getTriggerObjectVo();
        this.getTbodyData();

        this.getRemindModeEnum();
        this.getRemindTypeEnum();
        this.getTenantList();
        this.getCompanyList();
    },
    methods: {
        //获取分公司
        getCompanyList() {
            this.instance.get('/ubi/api/tenant/companyList').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.companyList = data.data;
                }
            });
        },
        // 请求通知方式
        getRemindModeEnum: function () {
            this.instance
                .get('/ubi/api/enum/getRemindModeEnum')
                .then((res) => {
                    let data = res.data;
                    if (data.code === 200) {
                        this.remindModeOptions = data.data;
                    } else {
                        console.log(res);
                        this.$message.error(data.message);
                    }
                })
                .catch((res) => {
                    console.log(res);
                    this.$message.error('请求通知类型失败!');
                });
        },

        // 获取消息提醒类型
        getRemindTypeEnum: function () {
            this.instance
                .get('/ubi/api/enum/getRemindTypeEnum')
                .then((res) => {
                    let data = res.data;
                    if (data.code === 200) {
                        this.remindTypeOptions = data.data;
                    } else {
                        console.log(res);
                        this.$message.error(data.message);
                    }
                })
                .catch((res) => {
                    console.log(res);
                    this.$message.error('请求通知类型失败!');
                });
        },

        //提醒事件类型
        reminderEventTypeChange(val) {
            this.remindTypeOptions.forEach((item) => {
                if (val === item.code) {
                    this.triggerRulesOptions = item.conditionList;
                }
            });
            this.addMessageForm.triggerTime = undefined;
        },

        // 获取租户
        getTenantList() {
            this.instance
                .get('/ubi/api/tenant/fleetList')
                .then((res) => {
                    let data = res.data;
                    if (data.code === 200) {
                        this.tenantListOption = data.data;
                    } else {
                        console.log(res);
                    }
                })
                .catch((res) => {
                    console.log(res);
                });
        },

        //添加页面获取提醒对象
        getTriggerObjectVo() {
            this.instance.get('/ubi/api/person/others').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.triggerObjectVo = data.data;
                }
            });
        },
        //添加添加消息提醒
        addMessageHint(sign) {
            this.messageHintTitle = '添加消息提醒';
            this.dialogAddUserVisible = true;
            this.addMessageForm = JSON.parse(JSON.stringify(this.addMessageForm));
            this.addMessageForm = {
                id: undefined,
                remindName: '',
                remindPersonDto: {
                    subTenantIdList: [],
                    remindManager: false,
                    remindDriver: false,
                    otherPerson: []
                },
                remindModeList: [],
                messageStatus: '',
                remarks: '',
                remindEventType: null,
                triggerRules: null
            };
        },

        //点击选择人员的确定按钮
        handleSelectStaffs() {
            this.addMessageForm.remindPersonDto.remindDriver = this.staffsForm.driver;
            this.addMessageForm.remindPersonDto.remindManager = this.staffsForm.administrators;
            this.addMessageForm.remindPersonDto.otherPerson = this.staffsForm.others;
            this.innerVisible = false;
        },

        //点击提醒对象
        handleRemindObj() {
            let tmpOthers = [];
            // 回显选中的其他人员
            this.addMessageForm.remindPersonDto.otherPerson.forEach((item) => {
                tmpOthers.push(item.id);
            });
            this.staffsForm.others = tmpOthers;
            this.staffsForm.administrators = this.addMessageForm.remindPersonDto.remindManager;
            this.staffsForm.driver = this.addMessageForm.remindPersonDto.remindDriver;
            this.innerVisible = true;
        },

        //点击添加消息提醒的确定按钮
        submitAddMessageForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let path = '/ubi/api/remind/event/definition/add';
                    if (this.addMessageForm.id !== undefined) {
                        path = '/ubi/api/remind/event/definition/edit';
                    }
                    let formData = JSON.parse(JSON.stringify(this[formName]));
                    this.instance.post(path, formData).then((res) => {
                        if (res.data.code === 200) {
                            this.$message.success(res.data.message);
                            this.dialogAddUserVisible = false;
                            this.getTbodyData();
                        } else {
                            this.$message(res.data.message);
                        }
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },

        //获取tbody里的数据
        getTbodyData() {
            this.loading = true;
            this.tableData = []; //  清空table的值
            let json = JSON.parse(JSON.stringify(this.navForm));
            this.pageParam.condition.remindName = json.remindName;
            this.pageParam.condition.remindMode = json.remindMode;
            this.pageParam.condition.remindType = json.remindType;
            this.pageParam.pageSize = this.pageSize;
            this.pageParam.pageNo = this.currentPage;
            this.instance.post('/ubi/api/remind/event/definition/page', this.pageParam).then((res) => {
                this.loading = false;
                let data = res.data;
                if (data.code == 200) {
                    this.tableData = data.data.list;
                    this.total = parseInt(data.data.total);
                }
            });
        },
        //分页数据多少条change
        handleSizeChange(val) {
            this.pageSize = val;
            this.getTbodyData();
        },
        //分页：当前页改变之后的函数
        handleCurrentChange: function (val) {
            // console.log(val);
            this.currentPage = Number(val);
            this.getTbodyData(this.activityState);
        },

        //点击查看详情
        handleLookDetail(row) {
            this.checkedRow = row;
            this.messageHintTitle = '编辑消息提醒';
            this.dialogAddUserVisible = true;

            let subTenantIdList = row.remindPersonVo.subTenantIdList;
            this.remindTypeOptions.forEach((item) => {
                if (row.remindEventType === item.code) {
                    this.triggerRulesOptions = item.conditionList;
                }
            });

            let remindModeList = [];
            row.remindModeList.forEach((item) => {
                remindModeList.push(item.code);
            });

            this.addMessageForm = {
                id: row.id,
                remindName: row.remindName,
                remindPersonDto: {
                    subTenantIdList: subTenantIdList,
                    remindManager: row.remindPersonVo.remindManager,
                    remindDriver: row.remindPersonVo.remindDriver,
                    otherPerson: row.remindPersonVo.otherPerson
                },
                remindModeList: remindModeList,
                messageStatus: row.messageStatus,
                remarks: row.remarks,
                remindEventType: row.remindEventType,
                triggerRules: Number(row.triggerRules)
            };
        },

        //更新模板状态
        updateMessageModelStatus(id) {
            this.instance.post('/ubi/api/remind/event/definition/update/' + id).then((res) => {
                if (res.data.code === 200) {
                    this.$message({
                        type: 'success',
                        message: res.data.message
                    });
                    this.getTbodyData();
                }
            });
        },
        //点击删除按钮
        deleteInfo(id) {
            this.$confirm('此操作将永久删除该消息提醒, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.instance.post('/ubi/api/remind/event/definition/remove/' + id).then((res) => {
                        if (res.data.code === 200) {
                            this.$message({
                                type: 'success',
                                message: res.data.message
                            });
                            this.getTbodyData();
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
        },

        //更新消息提醒状态   1启用-0停用
        updateState(row) {
            let _statusName = row.messageStatus === 0 ? '启用' : '停用';
            this.$confirm('此操作将' + _statusName + '当前消息提醒, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.updateMessageModelStatus(row.id);
                })
                .catch(() => {});
        },
        //点击查询
        searchTable() {
            this.currentPage = 1;
            this.getTbodyData();
        },

        //关闭模态框
        beforeClose() {
            this.$emit('update:messageParent', false);
            this.dialogVisible = false;
            this.$refs['addMessageForm'].clearValidate();
        },
        //关闭模态框
        beforeCloseUser() {
            this.dialogAddUserMoreVisible = false;
        }
    }
};
</script>
<style scoped>
@import '../../assets/css/table_white.css';

.content {
    position: relative;
    height: 100%;
}

.hint_obj_box /deep/ .el-form-item__label {
    display: block;
    width: 100%;
    text-align: left;
}

.form_box {
    margin-top: 0.2rem;
}

.form_box .el-form-item {
    /* margin-bottom: 0.2rem; */
}

/deep/ .el-dialog__body {
    padding: 0 0.5rem;
}

.dialog_box {
    padding: 0 0 0.5rem;
}
</style>
