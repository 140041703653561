<template>
    <div class="content" v-cloak>
        <div class="page_title">用户信息管理</div>
        <div class="content_box">
            <div class="search_box">
                <!--检索部分-->
                <el-form :inline="true" :model="navForm" class="demo-form-inline">
                    <el-form-item label="用户姓名:">
                        <el-input class="width_150" v-model="navForm.userName" size="small" placeholder="请输入姓名" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话:">
                        <el-input class="width_150" v-model="navForm.userPhone" type="tel" maxlength="11" size="small" placeholder="请输入手机号" clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="small" @click="searchTable" style="margin-left: 0.2rem">查询</el-button>
                        <!-- <el-button type="primary"  size="small" @click="linkHeat" style="margin-left: 0.2rem;">热力图</el-button> -->
                    </el-form-item>
                </el-form>
            </div>
            <div class="flex title_and_btn">
                <div class="detail_title">用户信息</div>
                <el-row>
                    <el-button type="primary" size="small" @click="dialogAddUserVisible = true">添加用户</el-button>
                    <el-button type="primary" size="small" @click="dialogAddUserMoreVisible = true">批量添加用户</el-button>
                </el-row>
            </div>
            <el-dialog title="填写用户基本信息" class="detail_dialog" :visible.sync="dialogAddUserVisible" :modal-append-to-body="false" width="12rem" center>
                <el-form :inline="true" :rules="rules" ref="addUserForm" :model="addUserForm" class="demo-form-inline" label-width="1.5rem">
                    <el-form-item label="用户标识:" class="width_300" prop="uid">
                        <el-input v-model="addUserForm.uid" placeholder="请输入用户标识" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="姓名:" class="width_300" prop="userName">
                        <el-input v-model="addUserForm.userName" placeholder="请输入姓名" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话:" class="width_300" prop="userPhone">
                        <el-input v-model="addUserForm.userPhone" type="tel" maxlength="11" placeholder="请输入手机号" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="用户分类:" class="width_300" prop="userType">
                        <el-select v-model="addUserForm.userType" placeholder="请选择">
                            <el-option label="普通用户" value="普通用户"></el-option>
                            <el-option label="公司司机" value="公司司机"></el-option>
                            <el-option label="查勘员" value="查勘员"></el-option>
                            <el-option label="员工用户" value="员工用户"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="获客来源:" class="width_300">
                        <el-select v-model="addUserForm.origin" placeholder="请选择">
                            <el-option label="网销" value="网销"></el-option>
                            <el-option label="车商" value="车商"></el-option>
                            <el-option label="综拓" value="综拓"></el-option>
                            <el-option label="银保" value="银保"></el-option>
                            <el-option label="重客" value="重客"></el-option>
                            <el-option label="经代" value="经代"></el-option>
                            <el-option label="其他" value="其他"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="性别:" class="width_300">
                        <el-select v-model="addUserForm.sex" placeholder="请选择">
                            <el-option label="男" value="男"></el-option>
                            <el-option label="女" value="女"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="出生日期:" class="width_300">
                        <el-date-picker placeholder="请选择日期" v-model="addUserForm.birthday" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                    </el-form-item>
                    <el-form-item label="最高学历:" class="width_300">
                        <el-select v-model="addUserForm.education" placeholder="请选择">
                            <el-option label="专科" value="专科"></el-option>
                            <el-option label="本科" value="本科"></el-option>
                            <el-option label="硕士研究生" value="硕士研究生"></el-option>
                            <el-option label="博士研究生" value="博士研究生"></el-option>
                            <el-option label="其他" value="其他"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="职业:" class="width_300">
                        <el-select v-model="addUserForm.profession" placeholder="请选择">
                            <el-option label="党、政、国家机关工作者" value="党、政、国家机关工作者"></el-option>
                            <el-option label="群众团体和社会组织工作者" value="群众团体和社会组织工作者"></el-option>
                            <el-option label="企事业单位负责人" value="企事业单位负责人"></el-option>
                            <el-option label="专业技术人员" value="专业技术人员"></el-option>
                            <el-option label="办事人员和有关人员" value="办事人员和有关人员"></el-option>
                            <el-option label="社会生产服务和生活服务人员" value="社会生产服务和生活服务人员"></el-option>
                            <el-option label="农、林、牧、渔业生产及辅助人员" value="农、林、牧、渔业生产及辅助人员"></el-option>
                            <el-option label="军人" value="军人"></el-option>
                            <el-option label="教育工作者" value="教育工作者"></el-option>
                            <el-option label="其他" value="其他"></el-option>
                        </el-select>
                    </el-form-item>
                    <!-- <br> -->
                    <el-form-item label="住址:" class="width_300">
                        <el-input v-model="addUserForm.address" placeholder="请输入家庭住址" clearable></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogAddUserVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submitAddUserForm('addUserForm')">确 定</el-button>
                </div>
            </el-dialog>
            <batch-add-user :dialogAddUserMoreVisible="dialogAddUserMoreVisible" @refreshList="getTbodyData" @beforeCloseUser="beforeCloseUser"></batch-add-user>

            <div class="table_box">
                <!--table表格部分-->
                <el-table ref="multipleTable" :data="tableData" v-loading="loading" height="395px">
                    <el-table-column label="序号" type="index" width="80" :index="hIndex"></el-table-column>
                    <el-table-column prop="userName" label="姓名"></el-table-column>
                    <el-table-column prop="sex" label="性别"></el-table-column>
                    <el-table-column prop="userPhone" label="联系方式"></el-table-column>
                    <el-table-column prop="addTime" label="系统录入时间"></el-table-column>
                    <el-table-column prop="userType" label="用户分类"></el-table-column>
                    <el-table-column prop="vehicleNum" label="车辆数"></el-table-column>
                    <el-table-column prop="points" label="用户积分"></el-table-column>
                    <el-table-column label="操作" width="120px">
                        <template slot-scope="scope">
                            <div class="operation_box">
                                <span @click="handleLookDetail(scope.row)">详情</span>
                                <span class="color_red" @click="deleteInfo(scope.row)">删除</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <!--分页部分-->
                <el-pagination style="margin-top: 0.2rem" @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="currentPage" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="10" :total="totalPage"> </el-pagination>
            </div>
        </div>
        <user-detail ref="userDetail" :dialogVisible="dialogVisible" :detailForm="detailForm" @refreshDetail="handleLookDetail(checkedRow)" @refreshList="getTbodyData" @beforeClose="beforeClose"></user-detail>
    </div>
</template>

<script>
import UserDetail from '@/components/UserDetail.vue'
import BatchAddUser from '@/components/BatchAddUser.vue'
import qs from 'qs'
export default {
    components: {
        UserDetail,
        BatchAddUser
    },
    data() {
        var checkphone = (rule, value, callback) => {
            if (value == '') {
                callback(new Error('请输入手机号'))
            } else if (!this.isCellPhone(value)) {
                //引入methods中封装的检查手机格式的方法
                callback(new Error('请输入正确的手机号!'))
            } else {
                callback()
            }
        }
        return {
            rid: '',
            loading: false,
            dialogVisible: false,
            fullscreenLoading: false,
            detailPageVisible: false,
            dialogAddUserVisible: false,
            dialogAddUserMoreVisible: false,
            imageUrl: '',
            checkedRow: '',
            formType: '', //页面类型
            navForm: {
                //储存搜索框的检索条件信息
                userName: '',
                userPhone: '',
                companyId: ''
            },
            addUserForm: {
                uid: '',
                address: '',
                birthday: '',
                education: '',
                origin: '',
                profession: '',
                sex: '',
                userName: '',
                userPhone: '',
                userType: ''
            }, //添加用户form
            rules: {
                uid: [{ required: true, message: '请输入用户标识', trigger: 'blur' }],
                userName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
                userType: [{ required: true, message: '请选择用户分类', trigger: 'change' }],
                userPhone: [{ required: true, validator: checkphone, trigger: 'blur' }]
            },
            detailForm: {
                vehicleList: []
            },
            currentPage: 1, //当前页
            pageSize: 10, //一页多少条数据
            totalPage: 1, //总页数tableData:[],
            tableData: [{}]
        }
    },
    mounted() {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
        this.navForm.companyId = this.userInfo.company
        //   this.getTbodyData();
        this.getData()
    },
    methods: {
        //点击删除按钮
        deleteInfo(row) {
            if (row.vehicleNum > 0) {
                this.$message.error('抱歉，系统目前不支持删除已绑定车辆的用户')
                return false
            }
            this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.instance({
                        method: 'post',
                        url: '/user/deleteUser',
                        data: qs.stringify({ userId: row.userId }),
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }).then((res) => {
                        if (res.data.code == 1) {
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            })
                            this.getTbodyData()
                        }
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    })
                })
        },
        //点击添加用户的确定按钮
        submitAddUserForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.instance({
                        method: 'post',
                        url: '/user/addUser',
                        data: this[formName],
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8'
                        }
                    }).then((res) => {
                        if (res.data.code == 1) {
                            this.$message.success(res.data.message)
                            this.dialogAddUserVisible = false
                            this.getTbodyData()
                        } else {
                            this.$message(res.data.message)
                        }
                    })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        isCellPhone(val) {
            if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(val)) {
                return false
            } else {
                return true
            }
        },
        //重新计算index序号
        hIndex(index) {
            // index索引从零开始，index +1即为当前数据序号
            this.currentPage <= 0 ? (this.currentPage = 1) : this.currentPage
            // 如果当前不是第一页数据
            if (this.currentPage != 1) {
                // index + 1 + (( 当前页 - 1) * 每页展示条数)
                // 比如是第二页数据 1 + ((2 - 1)*5) = 6,第二页数据也就是从序号6开始
                return index + 1 + (this.currentPage - 1) * this.pageSize
            }
            // 否则直接返回索引+1作为序号
            return index + 1
        },
        //关闭模态框
        beforeClose() {
            this.$emit('update:messageParent', false)
            this.dialogVisible = false
        },
        //关闭模态框
        beforeCloseUser() {
            this.dialogAddUserMoreVisible = false
        },
        linkHeat() {
            let routeUrl = this.$router.resolve({
                path: '/vehicleMapHeat'
            })
            window.open(routeUrl.href, '_blank')
        },
        getData() {
            this.instance({
                method: 'get',
                url: '/ubi/api/enum/getRemindModeEnum',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((res) => {})
        },
        //点击查看详情
        handleLookDetail(row) {
            this.checkedRow = row
            this.$emit('update:messageParent', true)
            this.$refs.userDetail.userDisabled = true
            this.detailForm = { vehicleList: [] }
            this.instance({
                method: 'post',
                url: '/user/userDetail',
                data: qs.stringify({ userId: row.userId }),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((res) => {
                if (res.data.code == 1) {
                    var response = res.data.data
                    response.vehicleList.length > 0 &&
                        response.vehicleList.forEach((val, key) => {
                            val.disabled = true
                        })
                    this.detailForm = response
                    this.dialogVisible = true
                }
            })
        },
        //选择日期
        selectDate(sign) {
            const start = new Date()
            const end = new Date()
            if (sign == 'today') {
                this.navForm.startTime = this.dateFormatting(start, 'yyyy-MM-dd')
                this.navForm.endTime = this.dateFormatting(end, 'yyyy-MM-dd')
            } else {
                this.navForm.startTime = this.dateFormatting(new Date(start.getTime() - 3600 * 1000 * 24 * sign), 'yyyy-MM-dd')
                this.navForm.endTime = this.dateFormatting(end, 'yyyy-MM-dd')
            }
        },
        //点击查询
        searchTable() {
            this.currentPage = 1
            this.getTbodyData()
        },
        //获取tbody里的数据
        getTbodyData: function () {
            this.loading = true
            var _this = this
            _this.tableData = [] //  清空table的值
            var json = JSON.parse(JSON.stringify(this.navForm))
            json.pageNum = _this.currentPage
            json.pageSize = _this.pageSize
            this.instance({
                method: 'post',
                url: '/user/getUserList',
                data: json
                //  headers:{
                // 'Content-Type':"application/x-www-form-urlencoded",
                //  },
            }).then((res) => {
                this.loading = false
                _this.tableData = res.data.data.list
                _this.totalPage = parseInt(res.data.data.total)
            })
        },
        //分页数据多少条change
        handleSizeChange(val) {
            this.pageSize = val
            this.getTbodyData()
        },
        //分页：当前页改变之后的函数
        handleCurrentChange: function (val) {
            this.currentPage = Number(val)
            this.getTbodyData(this.activityState)
        }
    }
}
</script>
<style scoped>
@import '../../assets/css/table_white.css';
.content {
    position: relative;
    height: 100%;
}
</style>
